// External components
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import React, { useState } from 'react';

// Utils & Config
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';

// Internal components
import SmartOfficeService from '../../services/SmartOfficeService';
import SmartBookingsDisplay from '../organisms/SmartBookingsDisplay';

const CompanySmartBookingsModal = ({ open, setOpen, company }) => {
    const [smartBookingsRequest, setSmartBookingsRequest] = useState({ status: ResponseStatusEnum.IDLE, data: [] });

    const getSmartBookings = async (body) => {
        setSmartBookingsRequest({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            const res = await SmartOfficeService.searchSmartBookings({ ...body, companyId: company?.id });

            setSmartBookingsRequest({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setSmartBookingsRequest({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'lg'} fullWidth>
            <DialogContent>
                <SmartBookingsDisplay
                    smartBookingsRequest={smartBookingsRequest}
                    getSmartBookings={getSmartBookings}
                />
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {smartBookingsRequest.status === ResponseStatusEnum.ERROR &&
                        <Typography color={'error'}>
                            Hubo un error
                        </Typography>
                    }
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={() => setOpen(null)}
                    >
                        Cerrar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CompanySmartBookingsModal;

// External components
import React, { useState } from "react";
import { Box, TextField, Typography, IconButton } from "@material-ui/core";

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import { abbreviatedNumberFormatter } from "../../helpers/funcs/abbreviatedNumberFormatter";

// Internal Components
import { CountriesListModal } from "../modals/CountriesListModal";
import { PriceDisplay } from "../atoms/PriceDisplay";
import Loader from "../atoms/Loader";

// Icons
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const ColorfulTable = (props) => {

    const {
        isBookingsByType,
        isCompaniesByCountry,
        isScrollable,
        isLoading,
        withInput,
        inputValue,
        onInputChange,
        onClearInput,
        title,
        placeholder,
        headers,
        data,
        labels
    } = props;

    const [companyAdminModalOpen, setCompanyAdminModalOpen] = useState(false);
    const [clickedCompany, setClickedCompany] = useState({});

    const showAdminsModal = (company) => {
        setClickedCompany(company);
        setCompanyAdminModalOpen(true);
    };

    const stylesProps = { isScrollable, isCompaniesByCountry };
    const { table, container, btn, input, tbGrid, tbGridHover, ttl, subttl, tbHead, tbItem } = useStyles(stylesProps);

    return (
        <Box className={table}>
            <Box className={container}>
                <Typography className={ttl}>{title}</Typography>

                {withInput &&
                    <>
                        {inputValue !== '' &&
                            <IconButton className={btn} onClick={onClearInput}>
                                <HighlightOffIcon />
                            </IconButton>
                        }

                        <TextField
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            className={input}
                            placeholder={placeholder}
                            value={inputValue}
                            onChange={onInputChange}
                        />
                    </>
                }
            </Box>

            <Box className={tbGrid}>
                {headers.map((header, i) => (
                    <Typography className={tbHead} key={i}>{header}</Typography>
                ))}
            </Box>

            {isLoading
                ? <Loader marginTop={'24px'} />
                : <>
                    {isBookingsByType &&
                        data.map((booking, i) => (
                            <Box className={tbGrid} key={i}>
                                <Typography className={tbItem}>
                                    {Object.entries(labels).map(([key, val]) => {
                                        return key.toString().replace('_', '-') === booking.space_type ? val : '';
                                    })}
                                </Typography>

                                <Typography className={tbItem}>{booking.monthly_bookings}</Typography>
                                <PriceDisplay className={tbItem} value={booking.monthly_revenue} currency='USD' />
                            </Box>
                        ))
                    }

                    {isCompaniesByCountry &&
                        !data[0]
                        ? <Typography className={subttl}>No hay resultados.</Typography>
                        : isCompaniesByCountry && data.map((company, i) => (
                            <Box className={tbGridHover} key={i} onClick={() => showAdminsModal(company)}>
                                <Typography className={tbItem}>{company.name?.trimStart()}</Typography>
                                <Typography className={tbItem}>
                                    {abbreviatedNumberFormatter(company.active_employees, 1)}
                                    /
                                    {abbreviatedNumberFormatter(company.pending_employees, 1)}
                                </Typography>
                                <Typography className={tbItem}>
                                    {abbreviatedNumberFormatter(company.limit_amount, 1)}
                                    /
                                    {abbreviatedNumberFormatter(company.used_amount, 1)}
                                </Typography>
                            </Box>
                        ))
                    }
                </>
            }

            {clickedCompany.id &&
                <CountriesListModal
                    companyId={clickedCompany.id}
                    open={companyAdminModalOpen}
                    setOpen={setCompanyAdminModalOpen}
                />
            }
        </Box>
    );
};

export default ColorfulTable;

const useStyles = makeStyles((theme) => ({
    table: {
        padding: `${theme.spacing(2)}px`,
        paddingBottom: `${theme.spacing(3)}px`,
        marginTop: `${theme.spacing(4)}px`,
        background: 'linear-gradient(45deg, #9902cc, #53fafe)',
        // maxWidth: '30%'
        height: '380px',
        overflowY: props => props.isScrollable ? 'scroll' : '',
        width: '30vw',
        [theme.breakpoints.down('lg')]: {
            margin: '0 auto',
            width: '40vw',
        },
        [theme.breakpoints.down('md')]: {
            margin: '0 auto',
            width: '60vw',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 auto',
            width: '85vw',
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginBottom: `${theme.spacing(4)}px`,
        }
    },
    btn: {
        color: 'white',
        position: 'absolute',
        right: '240px',
        top: '4px',
        [theme.breakpoints.down('xs')]: {
            right: '290px',
            top: '78px',
        }
    },
    ttl: {
        padding: `${theme.spacing(.5)}px ${theme.spacing(2)}px`,
        paddingBottom: `${theme.spacing(3)}px`,
        fontSize: `${theme.spacing(4)}px`,
        fontFamily: 'PoppinsBold',
        textAlign: 'left',
        color: 'white',
    },
    subttl: {
        marginTop: `${theme.spacing(4)}px`,
        fontSize: `${theme.spacing(3)}px`,
        fontFamily: 'PoppinsBold',
        color: 'white',
    },
    input: {
        marginTop: `${theme.spacing(.5)}px`,
        background: 'white',
        borderRadius: `${theme.spacing(2)}px`,
        border: 'none',
        padding: `${theme.spacing(1)}px`,
        paddingLeft: `${theme.spacing(3)}px`,
        fontFamily: 'Poppins',
        height: `${theme.spacing(4)}px`,
    },
    tbGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        // gridTemplateRows: 'auto',
        justifyItems: 'start',
        padding: `${theme.spacing(.5)}px ${theme.spacing(2)}px`,
        "& :nth-child(2)": {
            placeSelf: 'center'
        },
        "& :nth-child(3)": {
            placeSelf: 'center'
        },
    },
    tbGridHover: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        justifyItems: 'start',
        padding: `${theme.spacing(.5)}px ${theme.spacing(2)}px`,
        '&:focus, &:hover': {
            cursor: props => props.isCompaniesByCountry ? 'pointer' : '',
            backgroundColor: props => props.isCompaniesByCountry ? 'rgba(51, 255, 255, 0.2)' : ''
        },
        "& :nth-child(2)": {
            placeSelf: 'center'
        },
        "& :nth-child(3)": {
            placeSelf: 'center'
        },
    },
    tbHead: {
        fontFamily: 'PoppinsBold',
        color: 'white',
        textTransform: 'uppercase',
        fontSize: `${theme.spacing(1.5)}px`,
    },
    tbItem: {
        fontFamily: 'Poppins',
        color: 'white',
        fontSize: `${theme.spacing(2.5)}px`,
        padding: `${theme.spacing(1)}px 0`,
        textAlign: 'left'
    }
}));

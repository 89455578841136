import React from 'react';
import NumberFormat from 'react-number-format';

const PriceDisplay = ({ value, currency = '', className = '' }) => {
    return (
        <NumberFormat
            prefix={`${currency} `.toUpperCase()}
            value={+value}
            decimalSeparator={','}
            thousandSeparator={'.'}
            decimalScale={2}
            fixedDecimalScale={2}
            displayType={'text'}
            renderText={(value) => <span className={className}>{value}</span>}
        />
    );
};

export { PriceDisplay };

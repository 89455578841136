// External components
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import RUG, { DropArea } from 'react-upload-gallery';

import AddIcon from '@material-ui/icons/Add';

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import DataService from '../../services/DataService';
import { hackFnToUpdateOrderAfterLoading } from '../../helpers/funcs/hackFnToUpdateOrderAfterLoading';
import Axios from 'axios';
import { BlockServiceTypeEnum, blockServiceTypeLabels } from '../../helpers/enums/BlockServiceTypeEnum';

// Internal Components
import UploadImagesButton from '../atoms/UploadImagesButton';
import CreateBlocksModal from './CreateBlocksModal';
import SmartOfficeService from '../../services/SmartOfficeService';
import CreateSmartOfficeLocationModal from './CreateSmartOfficeLocationModal';
import { daysDictionary } from '../../helpers/dictionaries/daysDictionary';
import moment from 'moment';
import CalculateMonthlyDayPriceModal from './CalculateMonthlyDayPriceModal';

const OpenModalEnum = {
    NONE: null,
    BLOCKS: 'BLOCKS',
    LOCATION: 'LOCATION',
    PRICE_CALC: 'PRICE_CALC'
};

const LOCATION_FIELDS = [
    { key: 'country', label: 'País' },
    { key: 'city', label: 'Ciudad' },
    { key: 'street', label: 'Calle' },
    { key: 'street_number', label: 'Número' },
    { key: 'apt_number', label: 'Piso' },
    { key: 'zip_code', label: 'Código Postal' },
    { key: 'latitude', label: 'Latitud' },
    { key: 'longitude', label: 'Longitud' },
];

const SmartOfficeStatusEnum = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
};

const SmartOfficeStatusEnumDictionary = {
    [SmartOfficeStatusEnum.ENABLED]: 'Habilitado',
    [SmartOfficeStatusEnum.DISABLED]: 'Deshabilitado',
};

/**
 * @param {Object} props
 * @param {'EDIT' | 'CREATE'} props.mode
 */
const CreateSmartOfficeModal = ({ open, setOpen, onConfirm, smartOffice, mode }) => {

    const [openModal, setOpenModal] = useState(OpenModalEnum.NONE);
    const [reqStatus, setReqStatus] = useState(ResponseStatusEnum.IDLE);
    const [imageWarning, setImageWarning] = useState(false);

    const initialPrices = {
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
    };

    const initialFormValues = {
        name: '',
        description: '',
        currencyCode: 'USD',
        openingDate: '',
        status: SmartOfficeStatusEnum.ENABLED,
        imageHashes: [],
        blocks: null,
        location: null,
        prices: initialPrices,
    };

    const [formValues, setFormValues] = useState(mode === 'EDIT' ? smartOffice : initialFormValues);

    const [currencies, setCurrencies] = useState([]);

    const handleFormValues = (path, value) => {
        setFormValues({
            ...formValues,
            [path]: value
        });
    };

    const handlePrices = (day, value) => {
        setFormValues({
            ...formValues,
            prices: {
                ...formValues.prices,
                [day]: value
            }
        });
    };

    const setAllDayPrices = (calculatedDaysPrice) => {
        setFormValues({
            ...formValues,
            prices: {
                ...calculatedDaysPrice
            }
        });
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleConfirm = async () => {
        setReqStatus(ResponseStatusEnum.LOADING);

        try {
            const req = {
                ...formValues,
                blocks: Object.keys(formValues.blocks)
                    .sort()
                    .map((key) => formValues.blocks[key])
                    .map((block) => ({
                        ...block,
                        imageHashes: block.imageHashes.map((image) => image.uuid),
                        newImageHashes: block.newImageHashes?.map((image) => image.uuid), // used for patch
                        services: block.services.map((service) => ({
                            blockServiceId: service.id,
                            price: service.price,
                            serviceId: service.service_id,
                            quantity: service.quantity,
                            type: service.type
                        })),
                    })),
                imageHashes: formValues.imageHashes.map((image) => image.uuid),
                newImageHashes: formValues.newImageHashes?.map((image) => image.uuid), // used for patch
            };

            if (mode === 'EDIT') await SmartOfficeService.updateSmartOffice(req, formValues.id);
            if (mode === 'CREATE') await SmartOfficeService.createSmartOffice(req);

            setReqStatus(ResponseStatusEnum.SUCCESS);
            setOpen(null);

            await onConfirm();
        } catch (error) {
            console.log(error);
            setReqStatus(ResponseStatusEnum.ERROR);
        }
    };

    const getCurrencies = async () => {
        const res = await DataService.getCurrencies();
        setCurrencies(res.data);
    };

    const removeImage = async (image) => {
        if (mode === 'EDIT') {
            await SmartOfficeService.deleteSmartOfficeImage(smartOffice.id, image.code);
        }

        const newBlockImages = formValues.imageHashes.filter((i) => i.uuid != image.code);
        setFormValues({ ...formValues, imageHashes: newBlockImages });
        return null;
    };

    const customRequest = ({ uid, file, action, onProgress, onSuccess, onError }) => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();

        const uploadProgressFn = ({ total, loaded }) => {
            onProgress(uid, Math.round(loaded / total * 100));
        };

        var reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = async function () {
            const res = await SmartOfficeService.uploadSmartOfficeImage(reader.result, uploadProgressFn, source.token);

            const formValuesDraft = { ...formValues };

            if (mode === 'EDIT') {
                formValuesDraft.newImageHashes = [...formValues.newImageHashes ?? [], res.data];
                formValuesDraft.imageHashes = [...formValues.imageHashes, res.data];
            }

            if (mode === 'CREATE') {
                formValuesDraft.imageHashes = [...formValues.imageHashes, res.data];
            }

            setFormValues(formValuesDraft);

            onSuccess(uid, res.data, res.data);
        };

        reader.onerror = function (error) {
            onError(uid, {
                action,
                status: error.request,
                response: error.response
            });
        };

        return {
            abort() {
                source.cancel();
            }
        };
    };

    const handlePreventClosing = () => {
        if (mode === 'CREATE' && formValues.blocks || formValues.blocks?.length) {
            const confirmed = window.confirm('No se guardaron los cambios. ¿Desea cerrar el modal sin guardarlos?');

            if (confirmed) setOpen(null);
        } else {
            setOpen(null);
        }
    };

    useEffect(() => {
        getCurrencies();
    }, []);

    const { _content } = useStyles();

    return (
        <Dialog open={open} onClose={handlePreventClosing} maxWidth={'lg'} fullWidth>
            {openModal === OpenModalEnum.BLOCKS && (
                <CreateBlocksModal
                    mode={mode}
                    open={openModal === OpenModalEnum.BLOCKS}
                    setOpen={setOpenModal}
                    formValues={formValues}
                    setFormValues={setFormValues}
                />
            )}

            {openModal === OpenModalEnum.LOCATION && (
                <CreateSmartOfficeLocationModal
                    open={openModal === OpenModalEnum.LOCATION}
                    setOpen={setOpenModal}
                    onConfirm={() => null}
                    formValues={formValues}
                    setFormValues={setFormValues}
                />
            )}

            {openModal === OpenModalEnum.PRICE_CALC && (
                <CalculateMonthlyDayPriceModal
                    open={openModal === OpenModalEnum.PRICE_CALC}
                    setOpen={setOpenModal}
                    setPrices={(p) => setAllDayPrices(p)}
                />
            )}

            <DialogTitle>
                Smart Office
            </DialogTitle>

            <DialogContent className={_content}>
                <TextField
                    value={formValues.name}
                    onChange={(e) => handleFormValues('name', e.target.value)}
                    label={'Nombre'}
                    variant={'outlined'}
                />

                <TextField
                    value={formValues.description}
                    onChange={(e) => handleFormValues('description', e.target.value)}
                    label={'Descripción'}
                    variant={'outlined'}
                    multiline
                    minRows={5}
                />

                <FormControl variant={'outlined'}>
                    <InputLabel id={'currency_select'}>Moneda</InputLabel>

                    <Select
                        labelId={'currency_select'}
                        value={formValues.currencyCode}
                        onChange={(e) => handleFormValues('currencyCode', e.target.value)}
                        label={'Moneda'}
                    >
                        {currencies.map((currency) => (
                            <MenuItem
                                value={currency.code}
                                key={currency.id + '_curr'}
                            >
                                {currency.code}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <InputLabel style={{ marginTop: '16px' }}>Precios por día por asiento</InputLabel>

                <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    {Object.keys(initialPrices).map((day) => (
                        <TextField
                            value={formValues.prices[day]}
                            onChange={(e) => handlePrices(day, e.target.value)}
                            key={day}
                            label={daysDictionary[day]}
                            type={'number'}
                            variant={'outlined'}
                            style={{ width: '180px' }}
                        />
                    ))}
                    <Button variant={'contained'} onClick={() => setOpenModal(OpenModalEnum.PRICE_CALC)}>Calcular</Button>
                </Box>

                <TextField
                    label={'Fecha de apertura'}
                    variant={'outlined'}
                    type={'date'}
                    value={moment(formValues.openingDate).format('YYYY-MM-DD')}
                    onChange={(e) => handleFormValues('openingDate', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />

                <FormControl variant={'outlined'}>
                    <InputLabel id={'status_select'}>Estado</InputLabel>

                    <Select
                        labelId={'status_select'}
                        value={formValues.status}
                        onChange={(e) => handleFormValues('status', e.target.value)}
                        label={'Estado'}
                    >
                        {Object.keys(SmartOfficeStatusEnum).map((status) => (
                            <MenuItem value={status} key={status + '_curr'}>
                                {SmartOfficeStatusEnumDictionary[status]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <InputLabel style={{ marginTop: '16px' }}>Ubicación</InputLabel>

                <Box>
                    {formValues.location && LOCATION_FIELDS.map((field) => (
                        <Box key={field.key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '500px', marginLeft: 48 }}>
                            <Typography>
                                {field.label}:
                            </Typography>

                            <Typography>
                                {!formValues.location[field.key] && 'N/A'}

                                {formValues.location[field.key] && `${formValues.location[field.key]}`}
                            </Typography>
                        </Box>
                    ))}

                    <br />
                    <br />

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant={'contained'} color={'primary'} onClick={() => setOpenModal(OpenModalEnum.LOCATION)}>
                            <AddIcon style={{ marginRight: 16 }} />
                            {formValues.location ? 'Editar Ubicación' : 'Crear Ubicación'}
                        </Button>
                    </Box>
                </Box>

                <InputLabel style={{ marginTop: '16px' }}>Fotos de la Oficina</InputLabel>

                <FormControl>
                    {imageWarning && <FormHelperText>{imageWarning}</FormHelperText>}

                    <RUG
                        rules={{ limit: 30, size: 70000 }}
                        inOrder={true}
                        onSuccess={hackFnToUpdateOrderAfterLoading}
                        source={(response) => response.original_url}
                        sorting={{ axis: 'xy', pressDelay: 200, distance: 0 }}
                        customRequest={customRequest}
                        initialState={formValues.imageHashes?.map((i) => ({ source: i.original_url, code: i.uuid })) || []}
                        onDeleted={removeImage}
                        header={({ openDialogue }) => (
                            <DropArea>
                                {(isDrag) => (
                                    <div style={{ border: 'dashed', borderColor: 'rgba(0, 0, 0, 0.12)', borderRadius: '4px', background: isDrag ? 'lightgrey' : '#fff' }}>
                                        <UploadImagesButton openDialogue={openDialogue} />
                                    </div>
                                )}
                            </DropArea>
                        )}
                        onChange={() => null}
                        onWarning={(type, rules) => {
                            switch (type) {
                            case 'limit':
                                setImageWarning('Has cargado el máximo de imagenes' + ': ' + rules.limit);
                                break;
                            case 'size':
                                setImageWarning('El tamaño máximo de imagen es 5mb.');
                                break;
                            default:
                            }
                        }}
                    />
                </FormControl>

                <Typography variant={'h4'}>Bloques</Typography>

                {Object.keys(formValues.blocks ?? {})?.sort().map((block, i) => (
                    <React.Fragment key={i}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 16, alignItems: 'center' }}>
                            <Box>
                                <Typography variant={'h5'}>Bloque {i + 1} - {formValues.blocks[block].name}</Typography>

                                <br />

                                <Box style={{ marginLeft: 32 }}>
                                    <Typography style={{ marginBottom: 12 }}>Cantidad de escritorios: {formValues.blocks[block].capacity}</Typography>

                                    <Typography>Area: {formValues.blocks[block].area} m2</Typography>

                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography>
                                            Servicios:
                                            {!formValues.blocks[block].services.length && ' No posee'}
                                        </Typography>

                                        {formValues.blocks[block].services.map((service) => (
                                            <Tooltip
                                                key={service.id}
                                                title={
                                                    <Box>
                                                        <Typography gutterBottom>
                                                            {service.description ?? service.info.description}
                                                        </Typography>

                                                        <br />

                                                        <Typography>
                                                            {service.type === BlockServiceTypeEnum.FREE
                                                                ? 'Incluído sin costo'
                                                                : `
                                                                ${blockServiceTypeLabels[service.type]} - $${service.price} ${formValues.currencyCode ?? smartOffice.currencyCode}
                                                            `
                                                            }
                                                        </Typography>

                                                        <Typography>
                                                            {service.quantity && `Capacidad: ${service.quantity}`}
                                                        </Typography>
                                                    </Box>
                                                }
                                            >
                                                <IconButton color={'primary'}>
                                                    <img
                                                        src={service.image?.url ?? service.info.image.url}
                                                        style={{ height: '20px', width: '20px', objectFit: 'scale-down' }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                {formValues.blocks[block].imageHashes?.map((image) => (
                                    <img key={image.id} src={image.original_url} style={{ height: '100px', width: '100px', objectFit: 'scale-down' }} />
                                ))}
                            </Box>
                        </Box>

                        <Divider style={{ margin: '16px 0' }} />
                    </React.Fragment>
                ))}

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant={'contained'} color={'primary'} onClick={() => setOpenModal(OpenModalEnum.BLOCKS)}>
                        <AddIcon style={{ marginRight: 16 }} />
                        Añadir Bloques
                    </Button>
                </Box>
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {reqStatus === ResponseStatusEnum.LOADING &&
                        <CircularProgress size={25} />
                    }

                    {reqStatus === ResponseStatusEnum.ERROR &&
                        <Typography color={'error'}>
                            Hubo un error
                        </Typography>
                    }
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={handleClose}
                        disabled={reqStatus === ResponseStatusEnum.LOADING}
                    >
                        Cerrar
                    </Button>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleConfirm}
                        disabled={reqStatus === ResponseStatusEnum.LOADING}
                    >
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CreateSmartOfficeModal;

const useStyles = makeStyles((theme) => ({
    _content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));


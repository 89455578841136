import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { IconButton, Tooltip } from '@material-ui/core';

import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <Tooltip title={'Salir'}>
            <IconButton onClick={() => logout()} variant={'outlined'}>
                <ExitToAppIcon style={{ width: '30px', height: '30px' }} />
            </IconButton>
        </Tooltip>
    );

};

export default LogoutButton;

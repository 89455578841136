import React, { useContext, useState, useEffect } from "react";
import { Box, InputBase, FormHelperText, Checkbox, TextField, Paper, Button, Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import DataService from "../../services/DataService";
import EditionButtons from "./EditionButtons";
import is from "is_js";
import SpaceTitle from "../SpaceTitle";
import { PriceDisplay } from "../atoms/PriceDisplay";
import DiscountsByChair from './DiscountsByChair';
import moment from 'moment';
import { RequestedSpacePriceChangeStatusEnum } from "../../helpers/enums/RequestedSpacePriceChangeStatusEnum";
import { Alert } from "@material-ui/lab";
import LaunchIcon from '@material-ui/icons/Launch';

const DAY_PERIODS = {
    WEEK: 5,
    MONTH: 22,
    QUARTER: 66,
    BIANNUAL: 132,
    ANNUAL: 260
}

const additionalBenefitsUnits = {
    P: "hojas",
    H: "horas"
}

const hoursDiff = (from, to, format = 'hh:mm A') => {
    let resp = 0;
    var a = moment(from, format);
    var b = moment(to, format);
    resp = b.diff(a, 'hours');
    return is.nan(resp) ? 0 : resp;
}

const CATEGORY_TYPES = [
    'A',
    'AA',
    'AAA'
]

const Pricing = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { context } = props;
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    // eslint-disable-next-line
    const [isPrivate, setPrivate] = useState(space?.private);
    // eslint-disable-next-line
    const [userAdminCompanies, setUserAdminCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState(space?.companies?.map(c => c.id) || []);

    const [dailyBooking, setDailyBooking] = useState(space?.daily_booking);
    const [hourlyBooking, setHourlyBooking] = useState(space?.hourly_booking);

    const [price, setPrice] = useState(space?.price || '');
    const [hourlyPrice, setHourlyPrice] = useState(space?.hourly_price || '');
    const [currency, setCurrency] = useState('');
    const [dailyPointsprice, setDailyPointsprice] = useState(space?.daily_points);
    const [hourlyPointsprice, setHourlyPointsprice] = useState(space?.hourly_points);
    const [category, setCategory] = useState(space?.category || 'A');

    const calcMinHourlyBooking = () => {
        let min = space?.min_hourly_booking;
        return min;
    };
    const [minHourlyBooking, setMinHourlyBooking] = useState(1);

    const [sixHourDiscountCheck, setSixHourDiscountCheck] = useState(false);
    const [sixHourDiscount, setSixHourDiscount] = useState(0);

    const [discountCheck, setDiscountCheck] = useState(false);
    const [discount, setDiscount] = useState(0);

    const [weekDiscountCheck, setWeekDiscountCheck] = useState(false);
    const [weekDiscount, setWeekDiscount] = useState(0);

    const [monthDiscountCheck, setMonthDiscountCheck] = useState(false);
    const [monthDiscount, setMonthDiscount] = useState(0);

    const [quarterlyDiscountCheck, setQuarterlyDiscountCheck] = useState(false);
    const [quarterlyDiscount, setQuarterlyDiscount] = useState(0);

    const [biannualDiscountCheck, setBiannualDiscountCheck] = useState(false);
    const [biannualDiscount, setBiannualDiscount] = useState(0);

    const [annualDiscountCheck, setAnnualDiscountCheck] = useState(false);
    const [annualDiscount, setAnnualDiscount] = useState(0);

    const [chairDiscountRanges, setChairDiscountRanges] = useState(space?.quantityDiscounts?.sort((a, b) => a.quantity_from - b.quantity_from) || []);

    const [multidateServiceOptions, setMultidateServiceOptions] = useState([]);
    const [weekSelectedServices, setWeekSelectedServices] = useState([]);
    const [weekServicesValues, setWeekServicesValues] = useState({});
    const [monthSelectedServices, setMonthSelectedServices] = useState([]);
    const [monthServicesValues, setMonthServicesValues] = useState({});

    const [currencyOptions, setCurrencyOptions] = useState([])
    const [errors, setErrors] = useState({});
    const [init, setInit] = useState(false);

    const [showDiscountsByChair, setShowDiscountsByChair] = useState(false);

    const getOpenHours = () => {
        let open = 12;
        if (space?.open_all_day) open = 24;
        if (space?.check_in_time && space?.check_out_time) open = hoursDiff(space?.check_in_time, space?.check_out_time);
        return open;
    }

    useEffect(() => {
        if (!init) {
            setInit(true);
            if (space?.discount_six_hours_percentage > 0) {
                setSixHourDiscountCheck(true);
                setSixHourDiscount(+space?.discount_six_hours_percentage);
            }
            if (space?.discount_percentage > 0) {
                setDiscountCheck(true);
                setDiscount(+space?.discount_percentage);
            }
            if (space?.discount_week_percentage > 0) {
                setWeekDiscountCheck(true);
                setWeekDiscount(+space?.discount_week_percentage);
            }
            if (space?.discount_month_percentage > 0) {
                setMonthDiscountCheck(true);
                setMonthDiscount(+space?.discount_month_percentage);
            }
            if (space?.discount_quarterly_percentage > 0) {
                setQuarterlyDiscountCheck(true);
                setQuarterlyDiscount(+space?.discount_quarterly_percentage);
            }
            if (space?.discount_biannual_percentage > 0) {
                setBiannualDiscountCheck(true);
                setBiannualDiscount(+space?.discount_biannual_percentage);
            }
            if (space?.discount_annual_percentage > 0) {
                setAnnualDiscountCheck(true);
                setAnnualDiscount(+space?.discount_annual_percentage);
            }

            let weekSelected = [];
            let weekValues = {};
            let monthSelected = [];
            let monthValues = {};
            for (const sab of space?.spaceAdditionalBenefits || []) {
                if (sab.days_required === DAY_PERIODS.WEEK) {
                    weekSelected.push({ benefit_id: sab.additionalBenefit?.id });
                    weekValues[sab.additionalBenefit?.id] = sab.quantity;
                };
                if (sab.days_required === DAY_PERIODS.MONTH) {
                    monthSelected.push({ benefit_id: sab.additionalBenefit?.id });
                    monthValues[sab.additionalBenefit?.id] = sab.quantity;
                };
            }
            setWeekSelectedServices(weekSelected);
            setWeekServicesValues(weekValues);
            setMonthSelectedServices(monthSelected);
            setMonthServicesValues(monthValues);
        }
        // eslint-disable-next-line
    }, [init])


    const calcDailyPrice = currentValue => {
        let dayPrice;
        if (!price && hourlyPrice) {
            dayPrice = hourlyPrice * 8;
        }
        return dayPrice ? dayPrice.toFixed(0) : currentValue;
    };
    const calcHourlyPrice = currentValue => {
        let hourPrice;
        if (!hourlyPrice && price) hourPrice = price / 9;
        return hourPrice ? hourPrice.toFixed(0) : currentValue;
    };

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getCurrencyOptions = async () => {
            let resp = await DataService.getCurrencies(space?.country_id);
            mounted && setCurrencyOptions(resp.data);
        }
        space && getCurrencyOptions().then(() => {
            mounted && setCurrency(space?.currency?.id || '');
        });

        DataService.getSpaceAdditionalServices().then(resp => {
            mounted && setMultidateServiceOptions(resp.data?.sort((a, b) => a.order - b.order));
        });


        return () => mounted = false;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (space) {
            setShowDiscountsByChair(space?.type?.partial_booking);
            setCurrency(space?.currency?.id || '');
            setMinHourlyBooking(calcMinHourlyBooking());
        }
        // eslint-disable-next-line
    }, [space]);

    useEffect(() => {
        if (isPrivate) {
            setPrice(0);
            setHourlyPrice(0);
            setCurrency('');
            setDiscountCheck(false);
            setDiscount(0);
            setWeekDiscountCheck(false);
            setWeekDiscount(0);
            setMonthDiscountCheck(false);
            setMonthDiscount(0);
            setQuarterlyDiscountCheck(false);
            setQuarterlyDiscount(0);
            setBiannualDiscountCheck(false);
            setBiannualDiscount(0);
            setAnnualDiscountCheck(false);
            setAnnualDiscount(0);
            setChairDiscountRanges([]);
            setDailyPointsprice(null);
            setHourlyPointsprice(null);
        } else {
            setSelectedCompanies([]);
        }
    }, [isPrivate]);

    const suggestedHourlyValues = () => {
        // default week discounts and additional services
    }

    const suggestedMonthValues = () => {
        // default month discounts and additional services

    }

    useEffect(() => {
        if (dailyBooking) {
            suggestedMonthValues();
            setDiscountCheck(!!+space?.discount_percentage);
            setDiscount(+space?.discount_percentage);
            setWeekDiscountCheck(!!+space?.discount_week_percentage); // if onboarding, suggested values
            setWeekDiscount(+space?.discount_week_percentage); // if onboarding, suggested values
            setMonthDiscountCheck(!!+space?.discount_month_percentage); // if onboarding, suggested values
            setMonthDiscount(+space?.discount_month_percentage); // if onboarding, suggested values
            setQuarterlyDiscountCheck(!!+space?.discount_quarterly_percentage);
            setQuarterlyDiscount(+space?.discount_quarterly_percentage);
            setBiannualDiscountCheck(!!+space?.discount_biannual_percentage);
            setBiannualDiscount(+space?.discount_biannual_percentage);
            setAnnualDiscountCheck(!!+space?.discount_annual_percentage);
            setAnnualDiscount(+space?.discount_annual_percentage);
        } else {
            setPrice(0);
            setDiscountCheck(false);
            setDiscount(0);
            setWeekDiscountCheck(false);
            setWeekDiscount(0);
            setMonthDiscountCheck(false);
            setMonthDiscount(0);
            setQuarterlyDiscountCheck(false);
            setQuarterlyDiscount(0);
            setBiannualDiscountCheck(false);
            setBiannualDiscount(0);
            setAnnualDiscountCheck(false);
            setAnnualDiscount(0);
            setChairDiscountRanges([]);
        }
        // eslint-disable-next-line
    }, [dailyBooking]);

    useEffect(() => {
        if (hourlyBooking) {
            suggestedHourlyValues();
            setSixHourDiscountCheck(!!+space?.discount_six_hours_percentage);
            setSixHourDiscount(+space?.discount_six_hours_percentage);
        } else {
            setHourlyPrice(0);
            setMinHourlyBooking(1);
            setSixHourDiscount(0);
            setSixHourDiscountCheck(false);
        }
        // eslint-disable-next-line
    }, [hourlyBooking]);

    const suggestedValues = () => {
        suggestedHourlyValues();
        suggestedMonthValues();
    };

    useEffect(() => {
        suggestedValues();
        // eslint-disable-next-line
    }, [multidateServiceOptions]);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '70%'
        },
        ...contextStyles
    }

    // ========= WEEK SERVICES =========
    const weekServiceSelected = (checked, s) => {
        if (checked) {
            let spaceService = {
                benefit_id: s.id,
            };

            // avoid duplicates
            let isAlreadySelected = weekSelectedServices.filter(serv => serv.benefit_id === s.id).length > 0;
            if (isAlreadySelected) return;

            setWeekSelectedServices(currentSelection => [
                ...currentSelection.filter(i => i.benefit_id !== s.id),
                spaceService
            ]);
        }
        else {
            // remove service by id
            setWeekSelectedServices(currentSelection => currentSelection.filter(i => i.benefit_id !== s.id));
            setWeekServicesValues(psp => ({ ...psp, [s.id]: '' }));
        }
    }

    const updateWeekServiceValues = (s, value) => {
        setWeekServicesValues(psp => ({ ...psp, [s.id]: value }));
    }

    // ========= MONTH SERVICES =========
    const monthServiceSelected = (checked, s) => {
        if (checked) {
            let spaceService = {
                benefit_id: s.id,
            };

            // avoid duplicates
            let isAlreadySelected = monthSelectedServices.filter(serv => serv.benefit_id === s.id).length > 0;
            if (isAlreadySelected) return;

            setMonthSelectedServices(currentSelection => [
                ...currentSelection.filter(i => i.benefit_id !== s.id),
                spaceService
            ]);
        }
        else {
            // remove service by id
            setMonthSelectedServices(currentSelection => currentSelection.filter(i => i.benefit_id !== s.id));
            setMonthServicesValues(psp => ({ ...psp, [s.id]: '' }));
        }
    }

    const updateMonthServiceValue = (s, value) => {
        setMonthServicesValues(psp => ({ ...psp, [s.id]: value }));
    }

    const validateFormData = () => {
        let errorsAcum = {};

        if (!dailyBooking && !hourlyBooking) errorsAcum.bookingType = 'Este campo es requerido';

        if (isPrivate) {
            if (is.empty(selectedCompanies)) errorsAcum.selectedCompanies = 'Este campo es requerido';
        } else {
            if (dailyBooking && is.empty(price)) errorsAcum.price = 'Este campo es requerido';
            if (hourlyBooking && is.empty(hourlyPrice)) errorsAcum.hourlyPrice = 'Este campo es requerido';
            if (hourlyBooking && !minHourlyBooking) errorsAcum.minHourlyBooking = 'Este campo es requerido';
            if (is.empty(currency) || is.falsy(currency)) errorsAcum.currency = 'Este campo es requerido';
            if (annualDiscountCheck && is.empty(annualDiscount)) errorsAcum.monthDiscount = 'Este campo es requerido';
            if (biannualDiscountCheck && is.empty(biannualDiscount)) errorsAcum.monthDiscount = 'Este campo es requerido';
            if (quarterlyDiscountCheck && is.empty(quarterlyDiscount)) errorsAcum.monthDiscount = 'Este campo es requerido';
            if (monthDiscountCheck && is.empty(monthDiscount)) errorsAcum.monthDiscount = 'Este campo es requerido';
            if (weekDiscountCheck && is.empty(weekDiscount)) errorsAcum.weekDiscount = 'Este campo es requerido';
            if (discountCheck && is.empty(discount)) errorsAcum.discount = 'Este campo es requerido';

            weekSelectedServices.forEach(ps => {
                if (is.falsy(weekServicesValues[ps.benefit_id])) errorsAcum[ps.benefit_id + '_week'] = 'Este campo es requerido';
            });

            monthSelectedServices.forEach(ps => {
                if (is.falsy(monthServicesValues[ps.benefit_id])) errorsAcum[ps.benefit_id + '_month'] = 'Este campo es requerido';
            });
        }

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getSpaceData = (active = space.active) => ({
        daily_booking: dailyBooking,
        price: price || 0,
        hourly_booking: hourlyBooking,
        hourly_price: hourlyPrice || 0,
        dailyPointsprice,
        hourlyPointsprice,
        category,
        min_hourly_booking: minHourlyBooking,
        currency_id: currency,
        discount_six_hours_percentage: sixHourDiscountCheck ? sixHourDiscount : 0,
        discount_percentage: discountCheck ? discount : 0,
        discount_week_percentage: weekDiscountCheck ? weekDiscount : 0,
        discount_month_percentage: monthDiscountCheck ? monthDiscount : 0,
        discount_quarterly_percentage: quarterlyDiscountCheck ? quarterlyDiscount : 0,
        discount_biannual_percentage: biannualDiscountCheck ? biannualDiscount : 0,
        discount_annual_percentage: annualDiscountCheck ? annualDiscount : 0,
        active,
        company_ids: isPrivate ? selectedCompanies : [],
        private: isPrivate,
        quantityDiscounts: chairDiscountRanges,
        spaceAdditionalBenefits: [
            ...weekSelectedServices.map(wss => ({
                additional_benefit_id: wss.benefit_id,
                days_required: DAY_PERIODS.WEEK,
                quantity: weekServicesValues[wss.benefit_id],
            })),
            ...monthSelectedServices.map(wss => ({
                additional_benefit_id: wss.benefit_id,
                days_required: DAY_PERIODS.MONTH,
                quantity: monthServicesValues[wss.benefit_id],
            }))
        ]
    });

    const DailyBookingCheckbox = () => <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <FormControlLabel control={<>
            <Checkbox value={dailyBooking} onChange={e => {
                const chkd = e.target.checked;
                setDailyBooking(chkd);
                if (chkd) setPrice(calcDailyPrice);
            }} checked={dailyBooking} />
            <Box style={{ marginRight: 5 }}>Aceptar reservas por día</Box>
        </>} />
    </Box>;

    const HourlyBookingCheckbox = () => <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <FormControlLabel control={<>
            <Checkbox value={hourlyBooking} onChange={e => {
                const chkd = e.target.checked;
                setHourlyBooking(chkd);
                if (chkd) setHourlyPrice(calcHourlyPrice);
            }} checked={hourlyBooking} />
            <Box style={{ marginRight: 5 }}>Aceptar reservas por hora</Box>
        </>} />
    </Box>;

return (
    <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Valor del Espacio</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>

            {space?.requestedSpacePriceChange?.some((request) => request.status === RequestedSpacePriceChangeStatusEnum.PENDING) && (
                <Alert variant={'outlined'} severity={'warning'} style={{ marginBottom: 32}}>
                    <Typography>
                        El espacio tiene solicitudes de cambio de precio pendientes de aprobación.
                    </Typography>

                    <br />
                    <br />

                    <Typography>
                        <a href={`/price-changes/${space.id}`} target="_blank" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            Ir a Solicitudes

                            <LaunchIcon color={'primary'} style={{ width: 16, height: 16 }} />
                        </a>
                    </Typography>
                </Alert>
            )}

            {isPrivate ?
                <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                    Espacio Privado
                </Box>
                :
                <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>

                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>

                        {space?.type?.name === 'shared-desk' ?
                            <Box>
                                <Box>Precio en POINTS por día (Empresas FIXED)</Box>
                                <FormControl style={{ margin: 5, width: 100 }}>
                                    <TextField
                                        value={dailyPointsprice}
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        onChange={e => setDailyPointsprice(e.target.value)}
                                        type="number"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true, }} />
                                </FormControl>
                            </Box>
                            :
                            <Box>
                                <Box>Precio en POINTS por hora (Empresas FIXED)</Box>
                                <FormControl style={{ margin: 5, width: 100 }}>
                                    <TextField
                                        value={hourlyPointsprice}
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        onChange={e => setHourlyPointsprice(e.target.value)}
                                        type="number"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true, }} />
                                </FormControl>
                            </Box>
                        }

                        <FormControl style={{ width: '300px' }}>
                            <InputLabel shrink id="category-label" style={{}}>Categoría:</InputLabel>
                            <Select
                                labelId="category-label"
                                id="category"
                                value={category}
                                onChange={e => setCategory(e.target.value)}
                                required
                                label={'Categoría'}>
                                {CATEGORY_TYPES.map(t => <MenuItem key={t + '_type'} value={t}>{t}</MenuItem>)}
                            </Select>
                        </FormControl>

                    </Box>

                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                        <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>{'Establecé un precio de base por defecto'}</div>
                        <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>{'El precio será por el uso durante una jornada diaria'}{space?.type?.partial_booking && `, por persona`}</div>
                    </Box>


                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                        <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>
                            <SpaceTitle space={space} />
                        </div>

                        <Paper elevation={dailyBooking ? 4 : 0} style={{ margin: '10px 0', padding: '10px 20px', maxWidth: isMobile ? '88%' : '96%', width: '100%' }}>
                            <DailyBookingCheckbox />
                            {dailyBooking && <>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Precio por dia{space?.type?.partial_booking && `, por persona`}</div>
                                <FormControl style={{ ...styles.formControl, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                                    <FormControl
                                        error={!!errors.price}
                                        style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <NumberFormat
                                                style={{ flexGrow: 4 }}
                                                value={price}
                                                onBlur={() => setHourlyPrice(calcHourlyPrice)}
                                                onValueChange={e => setPrice(e.floatValue)}
                                                id="currency" label={'Precio'} required
                                                customInput={TextField}
                                                autoComplete="false"
                                                decimalSeparator={','}
                                                thousandSeparator={'.'}
                                                prefix={'$ '} />
                                        </div>
                                        <FormHelperText>{errors.price}</FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        error={!!errors.currency}
                                        style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 0 0 10px', height: '48px' }}>
                                        <InputLabel id="currency-label">{'Moneda'}</InputLabel>
                                        <Select id="curr" labelId="currency-label" value={currency} onChange={e => setCurrency(e.target.value)} displayEmpty>
                                            {currencyOptions.map(c => <MenuItem key={'currency_' + c.id} value={c.id}>{`${c.code}`}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>{errors.currency}</FormHelperText>
                                    </FormControl>
                                </FormControl>
                            </>}

                        </Paper>

                        <Paper elevation={hourlyBooking ? 4 : 0} style={{ margin: '10px 0', padding: '10px 20px', maxWidth: isMobile ? '88%' : '96%', width: '100%' }}>
                            <HourlyBookingCheckbox />
                            {hourlyBooking && <>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Precio por hora{space?.type?.partial_booking && `, por persona`}</div>
                                <FormControl style={{ ...styles.formControl, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                                    <FormControl
                                        error={!!errors.hourlyPrice}
                                        style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <NumberFormat
                                                style={{ flexGrow: 4 }}
                                                value={hourlyPrice}
                                                onBlur={() => setPrice(calcDailyPrice)}
                                                onValueChange={e => setHourlyPrice(e.floatValue)}
                                                id="currency" label={'Precio'} required
                                                customInput={TextField}
                                                autoComplete="false"
                                                decimalSeparator={','}
                                                thousandSeparator={'.'}
                                                prefix={'$ '} />
                                        </div>
                                        <FormHelperText>{errors.hourlyPrice}</FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        error={!!errors.currency}
                                        style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 0 0 10px', height: '48px' }}>
                                        <InputLabel shrink id="sex-label">Moneda</InputLabel>
                                        <Select id="sex" value={currency} onChange={e => setCurrency(e.target.value)} displayEmpty>
                                            {currencyOptions.map(c => <MenuItem key={'currency_' + c.id} value={c.id}>{`${c.code}`}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>{errors.currency}</FormHelperText>
                                    </FormControl>
                                </FormControl>
                                <FormControl style={{ minWidth: 200 }}>
                                    <InputLabel shrink id="sex-label">Reserva minima</InputLabel>
                                    <Select id="minHours" value={minHourlyBooking} onChange={e => setMinHourlyBooking(e.target.value)} displayEmpty>
                                        {Array.from({ length: getOpenHours() }, (_, i) => i + 1).map(i => <MenuItem value={+i} key={i + '_item_q'}>{i}hs</MenuItem>)}
                                    </Select>
                                    <FormHelperText error>{errors?.minHourlyBooking}</FormHelperText>
                                </FormControl>
                            </>}
                        </Paper>
                        {errors?.bookingType && <FormHelperText error={true}>Debe seleccionar un tipo de reserva</FormHelperText>}

                    </Box>

                    {(dailyBooking || hourlyBooking) && <div style={styles.onboardingTitle}>
                        <span>Descuentos</span>
                        <Box style={{ minHeight: '20px' }}></Box>
                    </div>}

                    {/* ================= SIX HOURS DISCOUNTS ================= */}
                    {hourlyBooking && <Paper elevation={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: isMobile ? 5 : 20, margin: '20px 0' }}>
                        <Box style={{ marginBottom: 15, fontWeight: 600, fontSize: 18, textTransform: 'capitalize', textAlign: 'center', width: '100%' }}>Mas de 6hs</Box>
                        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', width: '100%' }}>
                            <FormControl error={!!errors.sixHourDiscount} style={{ marginBottom: isMobile ? 20 : 0, display: 'flex', alignItems: 'center', minWidth: 350 }}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Podes oferecer un descuento</div>
                                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <FormControlLabel control={<>
                                        <Checkbox value={sixHourDiscountCheck} onChange={e => {
                                            setSixHourDiscountCheck(e.target.checked);
                                            setSixHourDiscount(0);
                                        }} checked={sixHourDiscountCheck} />
                                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                            <NumberFormat value={sixHourDiscount}
                                                decimalScale={1}
                                                onValueChange={e => {
                                                    let value = e.value;
                                                    if (value >= 101) value = 100;
                                                    if (value <= 0) {
                                                        value = ''
                                                        if (value < 0) setSixHourDiscountCheck(false);
                                                    } else {
                                                        setSixHourDiscountCheck(true);
                                                    }
                                                    setSixHourDiscount(value);
                                                }}
                                                id="sixHoursDiscount" label="" required
                                                isNumericString={true}
                                                placeholder="0% OFF"
                                                customInput={InputBase}
                                                suffix={'% OFF'} />
                                        </div>
                                    </>} />
                                    <Box style={{ width: 350, textAlign: 'center' }}>{(sixHourDiscount && hourlyPrice) ? <Box><PriceDisplay value={hourlyPrice} currency={currencyOptions.find(c => c.id === currency)?.code} />{` x 6hs - ${sixHourDiscount}% = `} <b><PriceDisplay value={hourlyPrice * 6 - (hourlyPrice * 6 * sixHourDiscount / 100)} currency={currencyOptions.find(c => c.id === currency)?.code} /></b></Box> : ''}</Box>
                                </Box>
                                <FormHelperText>{errors.sixHourDiscount}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Paper>}

                    {/* ================= MONTH DISCOUNTS ================= */}
                    {dailyBooking && <Paper elevation={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: isMobile ? 5 : 20, margin: '20px 0' }}>
                        <Box style={{ marginBottom: 15, fontWeight: 600, fontSize: 18, textTransform: 'capitalize', textAlign: 'center', width: '100%' }}>Mensual</Box>
                        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', width: '100%' }}>

                            <FormControl error={!!errors.monthDiscount} style={{ marginBottom: isMobile ? 20 : 0, display: 'flex', alignItems: 'center', minWidth: 350 }}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ofrecer un descuento</div>
                                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <FormControlLabel control={<>
                                        <Checkbox value={monthDiscountCheck} onChange={e => {
                                            setMonthDiscountCheck(e.target.checked);
                                            setMonthDiscount(0);
                                        }} checked={monthDiscountCheck} />
                                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                            <NumberFormat value={monthDiscount}
                                                decimalScale={1}
                                                onValueChange={e => {
                                                    let value = e.value;
                                                    if (value >= 101) value = 100;
                                                    if (value <= 0) {
                                                        value = ''
                                                        if (value < 0) setMonthDiscountCheck(false);
                                                    } else {
                                                        setMonthDiscountCheck(true);
                                                    }
                                                    setMonthDiscount(value);
                                                }}
                                                id="monthDiscount" label="" required
                                                isNumericString={true}
                                                placeholder="0% OFF"
                                                customInput={InputBase}
                                                suffix={'% OFF'} />
                                        </div>
                                    </>} />
                                    <Box style={{ width: 350, textAlign: 'center' }}>{monthDiscountCheck && price && <Box><PriceDisplay value={price} currency={currencyOptions.find(c => c.id === currency)?.code} />{` x ${DAY_PERIODS.MONTH} dias - ${monthDiscount}% = `} <b><PriceDisplay value={price * DAY_PERIODS.MONTH - (price * DAY_PERIODS.MONTH * monthDiscount / 100)} currency={currencyOptions.find(c => c.id === currency)?.code} /></b></Box>}</Box>
                                </Box>
                                <FormHelperText>{errors.monthDiscount}</FormHelperText>
                            </FormControl>

                            <FormControl style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', flexDirection: 'column', }} error={!!errors.service}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ofrecer servicios adicionales</div>
                                {multidateServiceOptions.map(s => <FormControl key={`service_${s.id}`} className="servicesPriceInput" error={!!errors[s.id + '_month']}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <FormControlLabel
                                            key={`service_${s.id}`}
                                            control={<>
                                                <Checkbox onChange={(e) => monthServiceSelected(e.target.checked, s)}
                                                    checked={monthSelectedServices.map(serv => serv.benefit_id).indexOf(s.id) !== -1} />
                                                <img src={s.image.url} style={{ margin: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} alt="" />
                                            </>}
                                            label={s.name} />
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <NumberFormat
                                                onValueChange={e => updateMonthServiceValue(s, e.floatValue)}
                                                required
                                                value={monthServicesValues[s.id]}
                                                placeholder="0"
                                                customInput={TextField}
                                                decimalSeparator={','}
                                                variant="outlined" style={{ width: '50px', padding: 0 }}
                                                thousandSeparator={'.'} />
                                            <Box style={{ marginLeft: 5, width: 60 }}>{additionalBenefitsUnits[s.granularity]}</Box>
                                        </Box>
                                    </div>
                                    <FormHelperText>{errors[s.id + '_month']}</FormHelperText>
                                </FormControl>
                                )}
                                <FormHelperText>{errors.service}</FormHelperText>
                            </FormControl>

                        </Box>
                    </Paper>}



                    {/* ================= WEEK DISCOUNTS ================= */}
                    {dailyBooking && <Paper elevation={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: isMobile ? 5 : 20, margin: '20px 0' }}>

                        <Box style={{ marginBottom: 15, fontWeight: 600, fontSize: 18, textTransform: 'capitalize', textAlign: 'center', width: '100%' }}>Semanal</Box>
                        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', width: '100%' }}>
                            <FormControl error={!!errors.weekDiscount} style={{ marginBottom: isMobile ? 20 : 0, display: 'flex', alignItems: 'center', minWidth: 350 }}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ofrecer un descuento</div>
                                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <FormControlLabel control={<>
                                        <Checkbox value={weekDiscountCheck} onChange={e => {
                                            setWeekDiscountCheck(e.target.checked);
                                            setWeekDiscount(0);
                                        }} checked={weekDiscountCheck} />
                                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                            <NumberFormat value={weekDiscount}
                                                decimalScale={1}
                                                onValueChange={e => {
                                                    let value = e.value;
                                                    if (value >= 101) value = 100;
                                                    if (value <= 0) {
                                                        value = ''
                                                        if (value < 0) setWeekDiscountCheck(false);
                                                    } else {
                                                        setWeekDiscountCheck(true);
                                                    }
                                                    setWeekDiscount(value);
                                                }}
                                                id="weekDiscount" label="" required
                                                isNumericString={true}
                                                placeholder="0% OFF"
                                                customInput={InputBase}
                                                suffix={'% OFF'} />
                                        </div>
                                    </>} />
                                    <Box style={{ width: 350, textAlign: 'center' }}>{weekDiscountCheck && price && <Box><PriceDisplay value={price} currency={currencyOptions.find(c => c.id === currency)?.code} />{` x ${DAY_PERIODS.WEEK} dias - ${weekDiscount}% = `} <b><PriceDisplay value={price * DAY_PERIODS.WEEK - (price * DAY_PERIODS.WEEK * weekDiscount / 100)} currency={currencyOptions.find(c => c.id === currency)?.code} /></b></Box>}</Box>
                                </Box>
                                <FormHelperText>{errors.weekDiscount}</FormHelperText>
                            </FormControl>

                            <FormControl style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', flexDirection: 'column', }} error={!!errors.service}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ofrecer servicios adicionales</div>
                                {multidateServiceOptions.map(s => <FormControl key={`service_${s.id}`} className="servicesPriceInput" error={!!errors[s.id + '_week']}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 10, alignItems: 'center' }}>
                                        <FormControlLabel
                                            key={`service_${s.id}`}
                                            control={<>
                                                <Checkbox onChange={(e) => weekServiceSelected(e.target.checked, s)}
                                                    checked={weekSelectedServices.map(serv => serv.benefit_id).indexOf(s.id) !== -1} />
                                                <img src={s.image.url} style={{ margin: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} alt="" />
                                            </>}
                                            label={s.name} />
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <NumberFormat
                                                onValueChange={e => updateWeekServiceValues(s, e.floatValue)}
                                                required
                                                value={weekServicesValues[s.id]}
                                                placeholder="0"
                                                customInput={TextField}
                                                decimalSeparator={','}
                                                variant="outlined" style={{ width: '50px' }}
                                                thousandSeparator={'.'} />
                                            <Box style={{ marginLeft: 5, width: 60 }}>{additionalBenefitsUnits[s.granularity]}</Box>
                                        </Box>
                                    </div>
                                    <FormHelperText>{errors[s.id + '_week']}</FormHelperText>
                                </FormControl>
                                )}
                                <FormHelperText>{errors.service}</FormHelperText>
                            </FormControl>
                        </Box>

                    </Paper>}



                    {/* ================= DAILY DISCOUNTS ================= */}
                    {dailyBooking && <Paper elevation={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: isMobile ? 5 : 20, margin: '20px 0' }}>
                        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', width: '100%' }}>
                            <FormControl error={!!errors.weekDiscount} style={{ marginBottom: isMobile ? 20 : 0, display: 'flex', alignItems: 'center', minWidth: 350 }}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ademas dar un descuento a los usuarios por dia (reservas de hasta 4 dias)</div>
                                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <FormControlLabel control={<>
                                        <Checkbox value={discountCheck} onChange={e => {
                                            setDiscountCheck(e.target.checked);
                                            setDiscount(0);
                                        }} checked={discountCheck} />
                                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                            <NumberFormat value={discount}
                                                decimalScale={1}
                                                onValueChange={e => {
                                                    let value = e.value;
                                                    if (value >= 101) value = 100;
                                                    if (value <= 0) {
                                                        value = ''
                                                        if (value < 0) setDiscountCheck(false);
                                                    } else {
                                                        setDiscountCheck(true);
                                                    }
                                                    setDiscount(value);
                                                }}
                                                id="discount" label="" required
                                                isNumericString={true}
                                                placeholder="0% OFF"
                                                customInput={InputBase}
                                                suffix={'% OFF'} />
                                        </div>
                                    </>} />
                                    <Box style={{ width: 350, textAlign: 'center' }}>{discountCheck && price && <Box><PriceDisplay value={price} currency={currencyOptions.find(c => c.id === currency)?.code} />{` - ${discount}% = `} <b><PriceDisplay value={price - (price * discount / 100)} currency={currencyOptions.find(c => c.id === currency)?.code} /></b></Box>}</Box>
                                </Box>
                                <FormHelperText>{errors.discount}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Paper>}



                    {/* ================= QUARTER DISCOUNTS ================= */}
                    {dailyBooking && <Paper elevation={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: isMobile ? 5 : 20, margin: '20px 0' }}>
                        <Box style={{ marginBottom: 15, fontWeight: 600, fontSize: 18, textTransform: 'capitalize', textAlign: 'center', width: '100%' }}>Trimestral</Box>
                        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', width: '100%' }}>
                            <FormControl error={!!errors.quarterlyDiscount} style={{ marginBottom: isMobile ? 20 : 0, display: 'flex', alignItems: 'center', minWidth: 350 }}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ofrecer un descuento</div>
                                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <FormControlLabel control={<>
                                        <Checkbox value={quarterlyDiscountCheck} onChange={e => {
                                            setQuarterlyDiscountCheck(e.target.checked);
                                            setQuarterlyDiscount(0);
                                        }} checked={quarterlyDiscountCheck} />
                                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                            <NumberFormat value={quarterlyDiscount}
                                                decimalScale={1}
                                                onValueChange={e => {
                                                    let value = e.value;
                                                    if (value >= 101) value = 100;
                                                    if (value <= 0) {
                                                        value = ''
                                                        if (value < 0) setQuarterlyDiscountCheck(false);
                                                    } else {
                                                        setQuarterlyDiscountCheck(true);
                                                    }
                                                    setQuarterlyDiscount(value);
                                                }}
                                                id="quarterlyDiscount" label="" required
                                                isNumericString={true}
                                                placeholder="0% OFF"
                                                customInput={InputBase}
                                                suffix={'% OFF'} />
                                        </div>
                                    </>} />
                                    <Box style={{ width: 350, textAlign: 'center' }}>{quarterlyDiscountCheck && price && <Box><PriceDisplay value={price} currency={currencyOptions.find(c => c.id === currency)?.code} />{` x ${DAY_PERIODS.QUARTER} dias - ${quarterlyDiscount}% = `} <b><PriceDisplay value={price * DAY_PERIODS.QUARTER - (price * DAY_PERIODS.QUARTER * quarterlyDiscount / 100)} currency={currencyOptions.find(c => c.id === currency)?.code} /></b></Box>}</Box>
                                </Box>
                                <FormHelperText>{errors.quarterlyDiscount}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Paper>}



                    {/* ================= BIANNUAL DISCOUNTS ================= */}
                    {dailyBooking && <Paper elevation={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: isMobile ? 5 : 20, margin: '20px 0' }}>
                        <Box style={{ marginBottom: 15, fontWeight: 600, fontSize: 18, textTransform: 'capitalize', textAlign: 'center', width: '100%' }}>Semestral</Box>
                        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', width: '100%' }}>
                            <FormControl error={!!errors.biannualDiscount} style={{ marginBottom: isMobile ? 20 : 0, display: 'flex', alignItems: 'center', minWidth: 350 }}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ofrecer un descuento</div>
                                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <FormControlLabel control={<>
                                        <Checkbox value={biannualDiscountCheck} onChange={e => {
                                            setBiannualDiscountCheck(e.target.checked);
                                            setBiannualDiscount(0);
                                        }} checked={biannualDiscountCheck} />
                                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                            <NumberFormat value={biannualDiscount}
                                                decimalScale={1}
                                                onValueChange={e => {
                                                    let value = e.value;
                                                    if (value >= 101) value = 100;
                                                    if (value <= 0) {
                                                        value = ''
                                                        if (value < 0) setBiannualDiscountCheck(false);
                                                    } else {
                                                        setBiannualDiscountCheck(true);
                                                    }
                                                    setBiannualDiscount(value);
                                                }}
                                                id="biannualDiscount" label="" required
                                                isNumericString={true}
                                                placeholder="0% OFF"
                                                customInput={InputBase}
                                                suffix={'% OFF'} />
                                        </div>
                                    </>} />
                                    <Box style={{ width: 350, textAlign: 'center' }}>{biannualDiscountCheck && price && <Box><PriceDisplay value={price} currency={currencyOptions.find(c => c.id === currency)?.code} />{` x ${DAY_PERIODS.BIANNUAL} dias - ${biannualDiscount}% = `} <b><PriceDisplay value={price * DAY_PERIODS.BIANNUAL - (price * DAY_PERIODS.BIANNUAL * biannualDiscount / 100)} currency={currencyOptions.find(c => c.id === currency)?.code} /></b></Box>}</Box>
                                </Box>
                                <FormHelperText>{errors.biannualDiscount}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Paper>}



                    {/* ================= ANNUAL DISCOUNTS ================= */}
                    {dailyBooking && <Paper elevation={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: isMobile ? 5 : 20, margin: '20px 0' }}>
                        <Box style={{ marginBottom: 15, fontWeight: 600, fontSize: 18, textTransform: 'capitalize', textAlign: 'center', width: '100%' }}>Anual</Box>
                        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-around', width: '100%' }}>
                            <FormControl error={!!errors.annualDiscount} style={{ marginBottom: isMobile ? 20 : 0, display: 'flex', alignItems: 'center', minWidth: 350 }}>
                                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: 5, fontSize: '16px' }}>Puedes ofrecer un descuento</div>
                                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <FormControlLabel control={<>
                                        <Checkbox value={annualDiscountCheck} onChange={e => {
                                            setAnnualDiscountCheck(e.target.checked);
                                            setAnnualDiscount(0);
                                        }} checked={annualDiscountCheck} />
                                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                            <NumberFormat value={annualDiscount}
                                                decimalScale={1}
                                                onValueChange={e => {
                                                    let value = e.value;
                                                    if (value >= 101) value = 100;
                                                    if (value <= 0) {
                                                        value = ''
                                                        if (value < 0) setAnnualDiscountCheck(false);
                                                    } else {
                                                        setAnnualDiscountCheck(true);
                                                    }
                                                    setAnnualDiscount(value);
                                                }}
                                                id="annualDiscount" label="" required
                                                isNumericString={true}
                                                placeholder="0% OFF"
                                                customInput={InputBase}
                                                suffix={'% OFF'} />
                                        </div>
                                    </>} />
                                    <Box style={{ width: 350, textAlign: 'center' }}>{annualDiscountCheck && price && <Box><PriceDisplay value={price} currency={currencyOptions.find(c => c.id === currency)?.code} />{` x ${DAY_PERIODS.ANNUAL} dias - ${annualDiscount}% = `} <b><PriceDisplay value={price * DAY_PERIODS.ANNUAL - (price * DAY_PERIODS.ANNUAL * annualDiscount / 100)} currency={currencyOptions.find(c => c.id === currency)?.code} /></b></Box>}</Box>
                                </Box>
                                <FormHelperText>{errors.annualDiscount}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Paper>}


                    <br />
                    <br />

                    {/* ================= CHAIR DISCOUNTS ================= */}
                    {showDiscountsByChair && <DiscountsByChair
                        space={{ ...space, price, currency: { name: currencyOptions.find(c => c.id === currency)?.code } }}
                        {...{ chairDiscountRanges, setChairDiscountRanges }} />}
                </Box>
            }
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        <EditionButtons stepUtils={stepUtils} saveFn={() => validateFormData() && stepUtils.updateSpace(getSpaceData())} />
    </Box>
)
};

export default Pricing;

import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { useStyles } from '../pages/store.styles';

const FormInput = ({ width, height, title, value, required, action, propName, multiline = false }) => {

    const { _formInputTextField,
        _formInputRoot,
        _formInputTitle,
        _rowFlex,
    } = useStyles();

    return (
        <Box className={_formInputRoot}>
            <Box className={_rowFlex}>
                <Box className={_formInputTitle}>{title}</Box>
            </Box>

            <TextField
                multiline={multiline}
                className={_formInputTextField}
                onChange={(e) => action(e.target.value)}
                style={{ width: width, height: height, padding: 10 }}
                required={required}
                name={propName}
                value={value}
                InputProps={{
                    disableUnderline: true
                }}
            />
        </Box>
    );
};

export default FormInput;

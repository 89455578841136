export const BlockServiceTypeEnum = {
    FREE: 'FREE',
    PER_DESKTOP: 'PER_DESKTOP',
    PER_UNIT: 'PER_UNIT',
    FIXED: 'FIXED',
};

export const blockServiceTypeLabels = {
    [BlockServiceTypeEnum.FREE]: 'Incluído sin costo',
    [BlockServiceTypeEnum.PER_DESKTOP]: 'Precio por escritorio',
    [BlockServiceTypeEnum.PER_UNIT]: 'Precio por unidad',
    [BlockServiceTypeEnum.FIXED]: 'Precio fijo',
};

/* eslint-disable react/display-name */
// External components
import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import ReactCountryFlag from 'react-country-flag';

// Utils & Config
import moment from 'moment';
import AdminService from '../../services/AdminService';

// Internal Components
import { PriceDisplay } from '../atoms/PriceDisplay';
import TableIcons from '../molecules/TableIcons';
import { EmployeesListModal } from '../Companies';
import { BudgetsListModal } from '../Companies';
import { countryCodes } from '../Companies';
import SummaryPdfSelector from '../molecules/SummaryPdfSelector/SummaryPdfSelector';

// Icons
import PaymentIcon from '@material-ui/icons/Payment';
import AllowUnlimitedSwitch from '../atoms/AllowUnlimitedSwitch/AllowUnlimitedSwitch';
import FeaturesService from '../../services/FeaturesService';
import CategoryDisplay from './CategoryDisplay';
import DataService from '../../services/DataService';
import { FeatureProviderNameEnum } from '../../helpers/enums/FeatureProviderNameEnum';

export const CountriesListModal = ({ companyId, open, setOpen, refreshCompanies }) => {
    const [selectedCompany, setSelectedCompany] = useState();
    const [clickedCountry, setClickedCountry] = useState({});
    const [employeesModalOpen, setEmployeesModalOpen] = useState(false);
    const [budgetsModalOpen, setBudgetsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [companyCountryFeatures, setCompanyCountryFeatures] = useState([]);
    const [countryOptions, setContryOptions] = useState([]);
    const [spaceTypesOptions, setSpaceTypesOptions] = useState([]);
    const [ccEmployees, setCcEmployees] = useState([]);

    useEffect(() => {
        DataService.getSpaceTypes().then((resp) => {
            setSpaceTypesOptions(resp.data);
        });
    }, []);

    useEffect(() => {
        DataService.getCountries(false).then((resp) => {
            setContryOptions(resp.data);
        });
    }, []);

    const getCompany = async () => {
        try {
            setLoading(true);
            const res = await AdminService.getFullCompanyById(companyId);
            setSelectedCompany(res.data);
            res && setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const getActiveFeatureCategories = async () => {
        setLoading(true);
        FeaturesService.getActiveFeatureCategories(companyId)
            .then((resp) => {
                setCompanyCountryFeatures(resp?.data || []);
            })
            .catch(console.log);
        //.finally(() => setLoading(false));
    };

    const getEmployeesByCompanyCountry = async (country) => {
        setCcEmployees([]);
        AdminService.getEmployeesByCompanyCountry(companyId, country.id)
            .then((resp) => {
                country.employees = resp?.data || [];
                setClickedCountry({ ...selectedCompany, countries: [country] });
            })
            .catch(console.log);
        //.finally(() => setLoading(false));
    };

    useEffect(() => {
        if (open && companyId) {
            getCompany();
            getActiveFeatureCategories();
        } else
            setSelectedCompany(null);
        // eslint-disable-next-line
    }, [open, companyId]);

    useEffect(() => {
        const ctry = selectedCompany?.countries?.find((cc) => cc.id === clickedCountry.countries?.[0]?.id);
        setClickedCountry({ ...selectedCompany, countries: [ctry] });
        // eslint-disable-next-line
    }, [selectedCompany]);

    const showEmployeesModal = (country) => {
        setClickedCountry({ ...selectedCompany, countries: [] });
        if (+country?.employees_count > 0) {
            setEmployeesModalOpen(true);
            getEmployeesByCompanyCountry(country);
        }
    };

    const showBudgetsModal = (country) => {
        setClickedCountry({ ...selectedCompany, countries: [country] });
        setBudgetsModalOpen(true);
    };

    const normaliseUsed = (employee) => {
        if (+employee?.limit_amount > 0)
            return ((+employee.used_amount || 0) * 100 / +employee.limit_amount).toFixed(0);
        return 0;
    };

    const isCompanyFixed = selectedCompany?.type === 'FIXED';

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                <b></b>
            </DialogTitle>
            <DialogContent>
                {loading ?
                    <CircularProgress style={{ marginLeft: 600 }} />
                    :
                    <>
                        <EmployeesListModal company={clickedCountry} open={employeesModalOpen} setOpen={setEmployeesModalOpen} />
                        <BudgetsListModal company={clickedCountry} open={budgetsModalOpen} setOpen={setBudgetsModalOpen} refreshCompanies={refreshCompanies} />
                        <MaterialTable
                            icons={TableIcons}
                            title={
                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box>{'Paises de '}<b>{selectedCompany?.name}</b>{' (' + selectedCompany?.countries?.length + ')'}</Box>
                                    {selectedCompany && !isCompanyFixed && <SummaryPdfSelector company={selectedCompany} />}
                                </Box>
                            }
                            options={{ pageSize: 30, pageSizeOptions: [30, 100, 500], sorting: true, thirdSortClick: false, detailPanelType: 'single' }}
                            columns={[
                                { title: 'ID', render: (b) => <div>{`#${b.id}`}</div> },
                                {
                                    title: <b>Pais</b>, field: 'country',
                                    render: (rowData) => <Box style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                        <ReactCountryFlag
                                            svg
                                            countryCode={rowData.country?.name || ''}
                                            style={{ marginRight: 5, width: 32, height: 32 }}
                                        />
                                        <Box>{countryCodes[rowData.country?.name]}</Box>
                                    </Box>,
                                    editable: 'never'
                                },

                                { title: <b>ID</b>, field: 'fiscal_number', hidden: isCompanyFixed },
                                { title: <b>Historial de presupuestos</b>, hidden: isCompanyFixed, field: 'budgets_historys', render: (c) => <Link onClick={() => showBudgetsModal(c)} style={{ cursor: 'pointer' }}>Ver meses anteriores</Link> },
                                { title: <b>Servicios activos</b>, render: (cc) => <Box>{companyCountryFeatures.filter((ccf) => ccf.companyCountry.id === cc.id)?.map((ccf) => ccf.featureProvider.featureCategory.name).join(', ')}</Box> },
                                { title: <b>Empleados</b>, field: 'employees_count', defaultSort: 'desc', customSort: (a, b) => a.employees_count - b.employees_count, render: (c) => <Link onClick={() => showEmployeesModal(c)} style={{ cursor: 'pointer' }}>{`${c.employees_count}`}</Link> },

                                { title: <b>Limite Global</b>, hidden: isCompanyFixed, field: 'limit_amount', render: (c) => c.has_employee_global_limit ? <PriceDisplay value={c.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year())?.employee_global_limit_amount || 0} currency={c.currency} /> : (!c.has_employee_global_limit && !c.has_employee_monthly_limit) ? <b>ilimit</b> : <b>-</b> },
                                { title: <b>Limite por Empleado</b>, hidden: isCompanyFixed, field: 'employee_default_limit_amount', render: (c) => c.has_employee_monthly_limit ? <PriceDisplay value={c.employee_default_limit_amount || 0} currency={c.currency} /> : <b>-</b> },
                                {
                                    title: <b>Uso del empleado</b>, field: 'employee_used_amount', render: (rowData) => <Box style={{ color: normaliseUsed(rowData.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year())) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                                        <Box style={{ marginRight: 5 }}>{normaliseUsed(rowData.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year()))}%</Box>
                                        (<b><PriceDisplay value={+rowData.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year())?.employee_used_amount || 0} currency={rowData.currency} /></b>)
                                    </Box>, hidden: isCompanyFixed,
                                    editable: 'never',
                                    customSort: (a, b) => +normaliseUsed(a) - +normaliseUsed(b),
                                    defaultSort: 'desc'
                                },
                                {
                                    title: <b>Uso de la empresa</b>, field: 'company_used_amount', render: (rowData) => <Box style={{ color: normaliseUsed(rowData.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year())) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                                        <b><PriceDisplay value={+rowData.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year())?.company_used_amount || 0} currency={rowData.currency} /></b>
                                    </Box>,
                                    editable: 'never', hidden: isCompanyFixed,
                                    customSort: (a, b) => +normaliseUsed(a) - +normaliseUsed(b),
                                    defaultSort: 'desc'
                                },
                                {
                                    title: <b>Uso total</b>, field: 'used', render: (rowData) => <Box style={{ color: normaliseUsed(rowData.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year())) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                                        <b><PriceDisplay value={+rowData.budgets.find((b) => moment(b.date).month() === moment().month() && moment(b.date).year() === moment().year())?.used_amount || 0} currency={rowData.currency} /></b>
                                    </Box>,
                                    editable: 'never', hidden: isCompanyFixed,
                                    customSort: (a, b) => +normaliseUsed(a) - +normaliseUsed(b),
                                    defaultSort: 'desc'
                                },
                                {
                                    title: <b>Acumulación</b>,
                                    sorting: false, hidden: isCompanyFixed,
                                    field: '',
                                    render: (rowData) => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Box>{selectedCompany?.has_accumulated ? (rowData?.accumulate_employee_credits ? 'Activado' : 'Desactivado') : 'Bloqueado por admin'}</Box>
                                    </div>,
                                },
                                { title: <b>Tarjeta asociada</b>, field: 'card', render: (c) => c.card ? <PaymentIcon /> : '-', hidden: isCompanyFixed },
                                { title: <b>Permitir Ilimitado</b>, field: 'allow_unlimited', render: (c) => <AllowUnlimitedSwitch refresh={() => refreshCompanies()} active={c.is_unlimited_allowed} idCompany={selectedCompany?.id} companyCountry={c} />, hidden: isCompanyFixed },
                            ]}
                            data={selectedCompany?.countries}
                            detailPanel={[
                                {
                                    disabled: selectedCompany?.type === 'BASIC',
                                    render: (cc) => <CompanyCountryFeaturesConfig
                                        currency={cc.currency}
                                        countryOptions={countryOptions}
                                        spaceTypesOptions={spaceTypesOptions}
                                        companyCountry={{ ...cc, company: selectedCompany }}
                                    />

                                }
                            ]}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} empleado(s) selecionados'
                                },
                                header: {
                                    actions: ''
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay empleados para mostrar',
                                    filterRow: {
                                        filterTooltip: 'Filtros'
                                    },
                                    editRow: {
                                        deleteText: '¿Desea eliminar este empleado?'
                                    }
                                }
                            }}
                        />
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color={'primary'}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

const CompanyCountryFeaturesConfig = ({ companyCountry, spaceTypesOptions, countryOptions, currency = '' }) => {

    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeFeatures, setActiveFeatures] = useState([]);

    const getFeatureCategoryOptions = async () => {
        setLoading(true);
        companyCountry?.company && FeaturesService.getFeatureCategoriesOptions(companyCountry.company.id, companyCountry.id)
            .then((resp) => {
                setCategoriesOptions(resp?.data || []);
            })
            .catch(console.log);
    };

    const getActiveFeatureCategories = async () => {
        setLoading(true);
        companyCountry?.company?.id && FeaturesService.getActiveFeatureCategories(companyCountry?.company?.id)
            .then((resp) => {
                const res = resp?.data || [];
                setActiveFeatures(res.filter((ccf) => ccf.companyCountry.id === companyCountry.id));
            })
            .catch(console.log)
            .finally(() => setLoading(false));
    };

    console.log(companyCountry);

    useEffect(() => {
        if(companyCountry) {
            getFeatureCategoryOptions();
            getActiveFeatureCategories();
        }
    }, [companyCountry]);

    return <Box style={{ textAlign: 'left', padding: 20, maxWidth: '500px' }}>
        <h4>Servicios disponibles</h4>
        {loading ? <CircularProgress /> : categoriesOptions.map((co) => {
            const currentActive = activeFeatures.find((af) => af.featureProvider?.featureCategory?.name == co.name);
            const isCompanyFixed = companyCountry.company.type === 'FIXED';

            if (isCompanyFixed && co.name !== 'COWORKINGS') return;

            return <CategoryDisplay
                currency={currency}
                countryOptions={countryOptions}
                spaceTypesOptions={spaceTypesOptions}
                reloadActiveFeatures={() => getActiveFeatureCategories()}
                category={currentActive?.featureProvider?.featureCategory || co}
                key={co.id + '_features'}
                active={!!currentActive}
                disabled={co.name === 'COWORKINGS' && !companyCountry.company.features.find((feature) => feature.featureProvider.providerName === FeatureProviderNameEnum.INTERNAL_FLEX)}
                companyCountry={companyCountry}
            />;
        })}
    </Box>;
};


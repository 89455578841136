// External components
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

// Utils & Config
import { useStyles } from './SmartOffice.styles';
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import SmartOfficeService from '../../services/SmartOfficeService';
import { useMediaQuery } from 'react-responsive';

// Internal components
import CreateSmartOfficeModal from '../modals/CreateSmartOfficeModal';
import SmartOfficeCard from '../molecules/SmartOfficeCard';

const ROWS_PER_PAGE = 20;

const SmartOffice = () => {
    const [page, setPage] = useState(1);

    const [openModal, setOpenModal] = useState(OpenModalEnum.NONE);

    const [smartOfficesRequest, setSmartOfficesRequest] = useState({ status: ResponseStatusEnum.IDLE, data: [] });

    const getSmartOffices = async () => {
        setSmartOfficesRequest({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            const res = await SmartOfficeService.getSmartOffices();

            setSmartOfficesRequest({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setSmartOfficesRequest({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    useEffect(() => {
        getSmartOffices();
    }, []);

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1080px)' });
    const { _root } = useStyles({ isNarrowWidth });

    if (smartOfficesRequest.status === ResponseStatusEnum.LOADING) {
        return (
            <Box className={_root}>
                <h1>Smart Office</h1>
                <br />
                <CircularProgress />
            </Box>
        );
    }

    if (smartOfficesRequest.status === ResponseStatusEnum.ERROR) {
        return (
            <Box className={_root}>
                <h1>Smart Office</h1>
                <br />
                <h1>Error</h1>
            </Box>
        );
    }

    return (
        <Box className={_root}>
            <h1>Smart Office</h1>

            {openModal === OpenModalEnum.CREATE && (
                <CreateSmartOfficeModal
                    open={openModal === OpenModalEnum.CREATE}
                    setOpen={setOpenModal}
                    onConfirm={getSmartOffices}
                    mode={'CREATE'}
                />
            )}

            <Box>
                <Button variant={'contained'} color={'primary'} onClick={() => setOpenModal(OpenModalEnum.CREATE)}>Crear</Button>
            </Box>

            {smartOfficesRequest.data
                .slice((page - 1) * ROWS_PER_PAGE, ((page - 1) * ROWS_PER_PAGE) + ROWS_PER_PAGE)
                .map((smartOffice) => (
                    <Box key={smartOffice.id}>
                        <SmartOfficeCard  smartOffice={smartOffice} getSmartOffices={getSmartOffices} />
                    </Box>
                ))}

            <Pagination
                style={{
                    position: 'fixed',
                    bottom: '16px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(5px)',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                }}
                count={parseInt(smartOfficesRequest.data?.length / ROWS_PER_PAGE)}
                color={'primary'}
                page={page}
                onChange={(e, p) => setPage(p)}
                showFirstButton
                showLastButton
            />
        </Box>
    );
};

const OpenModalEnum = {
    NONE: null,
    CREATE: 'CREATE',
};

export default SmartOffice;

import axios from 'axios';

const SmartOfficeService = {

    createSmartOffice: (body) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-office`;
        return axios.post(url, body);
    },

    updateSmartOffice: (body, smartOfficeId) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-office/${smartOfficeId}`;
        return axios.patch(url, body);
    },

    getSmartOffices: () => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-office`;
        return axios.get(url);
    },

    uploadSmartOfficeImage: (base64) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-office-image/upload`;
        return axios.post(url, { base64 });
    },

    uploadBlockImage: (base64, onUploadProgress, cancelToken) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/block-image/upload`;
        return axios.post(url, { base64 }, { onUploadProgress, cancelToken })
    },

    deleteSmartOfficeImage: (smartOfficeId, hash) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-office-image/smart-office/${smartOfficeId}/hash/${hash}`;
        return axios.delete(url);
    },

    deleteBlockImage: (blockId, hash) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/block-image/block/${blockId}/hash/${hash}`;
        return axios.delete(url)
    },

    deleteBlock: (blockId) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/block/${blockId}`;
        return axios.delete(url)
    },

    deleteBlockService: (blockServiceId) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/block-service/${blockServiceId}`;
        return axios.delete(url)
    },

    getLocationFromStringAddress: async (address, region) => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=es&region=${region}`;
        const res = await fetch(url)
        return res.json();
    },

    searchSmartBookings: (body) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-booking`;
        return axios.post(url, body);
    },

    updateBooking: (id, body) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-booking/${id}`;
        return axios.patch(url, body);
    },

    searchSmartBookingsEmployee: (body) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/smart-booking-employee`;
        return axios.post(url, body);
    },

    searchResOwnOffices : (body) => {
        const url = `${process.env.REACT_APP_API_SMART_OFFICE_URL}/admin/res-own-office`;
        return axios.post(url, body);
    }
}

export default SmartOfficeService;

// External components
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import SmartOfficeService from '../../services/SmartOfficeService';
import { getEmployeeDisplayName } from '../../helpers/funcs/getEmployeeDisplayName';

const EmployeeSmartBookingModal = ({ open, setOpen, smartBooking }) => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const [onlyFromPast, setOnlyFromPast] = useState(false);

    const [req, setReq] = useState({ status: ResponseStatusEnum.IDLE, data: null });

    const getEmployeeBookings = async () => {
        setReq({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            const req = { smartBookingId: smartBooking?.id };

            if (onlyFromPast) {
                req.dateTo = new Date();
            } else {
                req.dateFrom = new Date();
            }

            const res = await SmartOfficeService.searchSmartBookingsEmployee(req);
            setReq({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setReq({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    useEffect(() => {
        getEmployeeBookings();
    }, [onlyFromPast]);

    const { _content } = useStyles();

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'lg'} fullWidth>
            <DialogTitle>
                Smart Booking <b>#{smartBooking.id}</b> - Smart Office #<b>{smartBooking?.smartOffice?.id}</b> &ldquo;{smartBooking?.smartOffice?.name}&ldquo;

                <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <Avatar src={smartBooking?.company?.avatar?.original_url} />
                    <Typography>{smartBooking?.company?.name} (#{smartBooking?.company?.id})</Typography>
                </Box>
            </DialogTitle>

            <DialogContent className={_content}>

                <h3>Employee Bookings</h3>

                <Box style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 16 }}>
                    <Button
                        variant={onlyFromPast  ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOnlyFromPast(true)}
                    >
                        Anteriores
                    </Button>

                    <Button
                        variant={!onlyFromPast  ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOnlyFromPast(false)}
                    >
                        Posteriores
                    </Button>
                </Box>

                {Boolean(req.status === ResponseStatusEnum.SUCCESS && !req.data?.length) && (
                    <h3>No posee</h3>
                )}

                {Boolean(req.status === ResponseStatusEnum.SUCCESS && req.data?.length) && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Id</b></TableCell>
                                    <TableCell><b>Fecha de creación</b></TableCell>
                                    <TableCell><b>Fecha</b></TableCell>
                                    <TableCell><b>Empleado</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {req.data
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((booking) => (
                                        <TableRow key={booking.id}>
                                            <TableCell>{booking.id}</TableCell>
                                            <TableCell>{booking.createdDate}</TableCell>
                                            <TableCell>{booking.date}</TableCell>
                                            <TableCell style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Avatar src={booking.employee?.user?.avatar?.original_url} />
                                                <b>#{booking.employee?.id}</b> {getEmployeeDisplayName(booking.employee)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component={'div'}
                                        count={req.data?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(e, p) => setPage(p)}
                                        onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {req.status === ResponseStatusEnum.LOADING &&
                        <CircularProgress size={25} />
                    }

                    {req.status === ResponseStatusEnum.ERROR &&
                        <Typography color={'error'}>
                            Hubo un error
                        </Typography>
                    }
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={() => setOpen(null)}
                        disabled={req.status === ResponseStatusEnum.LOADING}
                    >
                        Cerrar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default EmployeeSmartBookingModal;

const useStyles = makeStyles((theme) => ({
    _content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));


import React, { useEffect, useState } from "react";

//external components
import { Box, Button, Link } from "@material-ui/core";
import TableIcons from "./molecules/TableIcons";
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from "@material-ui/lab";
import MaterialTable from "material-table";

//internal components
import UserInfoModal from "./UserInfoModal";
import { BookingDatesCalendarModal } from "./BookingDatesCalendarModal";
import BookingInfoModal from "./BookingInfoModal";
import { PriceDisplay } from "./atoms/PriceDisplay";

//utils & configs
import moment from 'moment';
import AdminService from "../services/AdminService";
import { useMediaQuery } from "react-responsive";

const PAGE_SIZE = 20;
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "25px",
        marginBottom: "25px"
    },
}));

const Bookings = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [bookings, setBookings] = useState([]);
    const [bookingsLoading, setBookingsLoading] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [paginatorNumber, setPaginatorNumber] = useState(10);
    const [totalCount, setTotalCount] = useState(1);

    const [clickedHost, setClickedHost] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const [clickedBooking, setClickedBooking] = useState({});
    const [bookingInfoModalOpen, setBookingInfoModalOpen] = useState(false);
    const [datesModalOpen, setDatesModalOpen] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState(1);

    const getPendingToApproveBookings = (pageNum, searchQuery) => { setSelectedStatus(1); return AdminService.getBookingsByStatus([1, 16], [1, 2, 3, 5, 19], PAGE_SIZE, pageNum, searchQuery); };
    const getAcceptedBookings = (pageNum, searchQuery) => { setSelectedStatus(2); return AdminService.getBookingsByStatus([2, 4, 5, 6,], [1, 2, 3, 5, 19], PAGE_SIZE, pageNum, searchQuery); };
    const getRejectedBookings = (pageNum, searchQuery) => { setSelectedStatus(3); return AdminService.getBookingsByStatus([3], null, PAGE_SIZE, pageNum, searchQuery); };
    const getCancelledByUserBookings = (pageNum, searchQuery) => { setSelectedStatus(4); return AdminService.getBookingsByStatus(null, [4], PAGE_SIZE, pageNum, searchQuery); };
    const getPendingToSignBookings = (pageNum, searchQuery) => { setSelectedStatus(5); return AdminService.getBookingsByStatus([16], [18], PAGE_SIZE, pageNum, searchQuery); };

    const classes = useStyles();

    const statusGetFunctions = {
        1: getPendingToApproveBookings,
        2: getAcceptedBookings,
        3: getRejectedBookings,
        4: getCancelledByUserBookings,
        5: getPendingToSignBookings
    };

    const getBookings = (bookingsRequest, pageNum, searchQuery) => {
        setBookingsLoading(true);
        if (pageNum) setSelectedPage(pageNum);
        bookingsRequest(pageNum || selectedPage, searchQuery)
            .then(resp => {
                const { bookings: bkn, total_count } = resp.data;
                setBookings(bkn);
                setTotalCount(total_count);
                setBookingsLoading(false);
            }).catch(e => {
                setBookingsLoading(false);
                console.log(e);
            });
    };

    const reloadBookings = () => {
        getBookings(statusGetFunctions[selectedStatus]);
    };

    useEffect(() => {
        getBookings(getPendingToApproveBookings);
        // eslint-disable-next-line
    }, []);

    const getById = (searchQuery) => {
        setSelectedPage(1);
        getBookings(statusGetFunctions[selectedStatus], 1, searchQuery);
    };

    const handleChangePagination = (e, value) => {
        setSelectedPage(value);
        getBookings(statusGetFunctions[selectedStatus], value);
    };

    const updatePaginator = () => {
        setPaginatorNumber(Math.ceil(totalCount / PAGE_SIZE));
    };

    useEffect(() => {
        updatePaginator();
        // eslint-disable-next-line
    }, [totalCount]);

    return (<Box>
        <UserInfoModal host={clickedHost} open={infoModalOpen} setOpen={setInfoModalOpen} />
        <BookingInfoModal booking={clickedBooking} open={bookingInfoModalOpen} setOpen={setBookingInfoModalOpen} reloadBookings={reloadBookings} selectedStatus={selectedStatus} />
        <BookingDatesCalendarModal open={datesModalOpen} setOpen={setDatesModalOpen} booking={clickedBooking} />
        <div style={{ marginLeft: isMobile ? 5 : '170px' }}>
            <h1>Bookings</h1>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button variant={selectedStatus === 5 ? "contained" : "outlined"} color="primary" onClick={() => getBookings(getPendingToSignBookings, 1)}>{`Pendientes de Firma`}</Button>
                <Button variant={selectedStatus === 1 ? "contained" : "outlined"} color="primary" onClick={() => getBookings(getPendingToApproveBookings, 1)}>{`Pendientes de Aprobación`}</Button>
                <Button variant={selectedStatus === 2 ? "contained" : "outlined"} color="primary" onClick={() => getBookings(getAcceptedBookings, 1)}>{`Aceptados`}</Button>
                <Button variant={selectedStatus === 3 ? "contained" : "outlined"} color="primary" onClick={() => getBookings(getRejectedBookings, 1)}>{`Rechazados por Host`}</Button>
                <Button variant={selectedStatus === 4 ? "contained" : "outlined"} color="primary" onClick={() => getBookings(getCancelledByUserBookings, 1)}>{`Cancelados por Usuario`}</Button>
            </div>
            <MaterialTable
                title={''}
                icons={TableIcons}
                isLoading={bookingsLoading}
                onSearchChange={getById}
                options={{ pageSize: 20 }}
                actions={[
                    {
                        icon: () => <InfoIcon />,
                        tooltip: 'User Info',
                        onClick: (event, rowData) => {
                            setClickedBooking(rowData);
                            setBookingInfoModalOpen(true);
                        }
                    }
                ]}
                columns={[
                    { title: 'ID', render: b => <div>{`#${b.id}`}</div>, customFilterAndSearch: (term, rowData) => `${rowData.id}`?.includes(term) },
                    { title: 'Host', render: b => <Link style={{ cursor: 'pointer' }} onClick={() => { setClickedHost({ ...b.space?.host, ...b.space?.host.user }); setInfoModalOpen(true); }} >{`${b.space.host.name} ${b.space.host.lastname || ''}`}</Link> },
                    { title: 'Trabajador', render: b => <Link style={{ cursor: 'pointer' }} onClick={() => { setClickedHost(b.user); setInfoModalOpen(true); }} >{`${b.user.name} ${b.user.lastname || ''}`}</Link> },
                    { title: 'Prepago por Empresa', render: b => `${b.prepaid_booking ? 'Si' : 'No'}` },
                    { title: 'Split de Creditos', render: b => `${b.company_credit_split ? 'Si' : 'No'}` },
                    { title: 'Reserva mensual', render: b => `${b.monthly ? 'Si' : 'No'}` },
                    { title: 'Contrato', render: b => `${b.booking_contract ? 'Si' : 'No'}` },
                    {
                        title: 'Medio de Pago', render: b => {
                            const isCompanyCreditsByCompany = b.prepaid_booking && +b.company_budget_amount;
                            const isCompanyCreditsByEmployee = !b.prepaid_booking && +b.employee_budget_amount;
                            const isPaidWithCoins = +b.coins_spent;
                            const isPaidWithPoints = +b.points_spent;

                            let paymentMethod = b.payment_method.name;
                            if (paymentMethod == 'cash') {
                                if (isCompanyCreditsByCompany) paymentMethod = 'creditos-empresa'
                                else if (isCompanyCreditsByEmployee) paymentMethod = 'creditos-empleado'
                                else if (isPaidWithCoins) paymentMethod = 'OFFi Coins'
                                else if (isPaidWithPoints) paymentMethod = 'Points'
                            } else {
                                if (isCompanyCreditsByEmployee) paymentMethod = paymentMethod + ', creditos-empleado'
                            }
                            return paymentMethod;
                        }
                    },
                    {
                        title: 'Total', render: b => {
                            if (b.private) return <b>Privado</b>;
                            if (b.parentBooking) return <b>{`Sub-Reserva de #${b.parentBooking?.id}`}</b>;
                            if (b.total_points_spent) return b.total_points_spent + ' POINTS';

                            const paidByOrganizer = +b.final_price + +b.employee_budget_amount + +b.company_budget_amount;
                            const paidByPartners = +b?.partners?.map(p => +p.employee_budget_amount).reduce((prev, current) => prev + current, 0) || 0;
                            return <PriceDisplay value={paidByOrganizer + paidByPartners} currency={b.currency} />
                        }
                    },
                    { title: 'Fecha de creación', render: b => `${moment(b.creation_date).format('lll')}` },
                    {
                        title: 'Fecha de booking', render: b => <Box style={{ color: moment(b.date).diff(moment(), 'days') < 3 ? 'red' : '' }}>
                            {b.multiple_date ?
                                <Box>
                                    <span>{moment(b.date).format('ll')}</span>
                                    <b>{` al `}</b>
                                    <span>{moment(b.last_date).format('ll')}</span>
                                </Box>
                                :
                                <>
                                    <Box>{`${moment(b.date).format('ll')}`}</Box>
                                    <Box>{b.hourly && `${moment.utc(b.start_time).format('HH:mm A')} - ${moment.utc(b.end_time).format('HH:mm A')}`}</Box>
                                </>
                            }
                        </Box>
                    },
                    {
                        title: 'Cantidad de dias', render: b => <Link style={{ cursor: 'pointer' }} onClick={() => {
                            setClickedBooking(b);
                            setDatesModalOpen(true);
                        }}>
                            {`${b.multiple_date ? b.dates.length + ' dias' : 1 + ' dia'}`}
                        </Link>
                    },

                    {
                        title: 'Penalidad',
                        render:
                            b =>
                                <>
                                    {b.private ? <b>Privado</b> : b.parentBooking ? <b>{`Sub-Reserva de #${b.parentBooking?.id}`}</b> : <PriceDisplay value={+b.cancellation_fee_amount + +(b?.partners?.map(p => +p.cancellation_fee_amount).reduce((prev, current) => prev + current, 0) || 0)} currency={b.currency} />}
                                    {b?.cancellation_fee_amount && b?.cancellation_fee_percentage && <Box>{`(${b?.cancellation_fee_percentage})%`}</Box>}
                                </>,
                        hidden: selectedStatus !== 4
                    }


                ]}
                components={{
                    Pagination: props => (
                        <div className={classes.root}>
                            <Pagination style={{ width: "450px", margin: "0 auto" }} count={paginatorNumber} color="primary" page={selectedPage} onChange={handleChangePagination} showFirstButton showLastButton />
                        </div>
                    ),
                }}
                data={bookings}
                localization={{
                    header: {
                        actions: ' '
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay bookings para mostrar.',
                    }
                }}
            />
        </div>
    </Box>);
};

export default Bookings;

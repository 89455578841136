import React from 'react';
import { Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

export const EmailStatusIcon = ({ status }) => {
    const tooltipText = status ? 'Verificado' : 'Verificación pendiente';
    return <Tooltip title={tooltipText}>
        {status ? <VerifiedUserIcon style={{ color: 'green' }} /> : <WarningIcon style={{ color: 'darkorange' }} />}
    </Tooltip>;
};

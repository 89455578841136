// External components
import {
    Box,
    Button, Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

// Icons & Images
import AddBoxIcon from '@material-ui/icons/AddBox';

// Utils & Config
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './store.styles';

// Internal components
import SearchFilter from '../../components/atoms/SearchFilter';
import StoreService from '../../services/StoreService';
import ProductsTableCell from '../molecules/ProductsTableCell';
import ProductModal from '../modals/ProductModal';
import ImageVisualizerModal from '../modals/ImageVisualizerModal';


const Store = () => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    const { _leftBtnsContainer, _btnroot, _newProductBtn, _rigthBtnsPanel, _searchFilterContainer, _subNavbarContainer } = useStyles();
    const [openForm, setOpenForm] = useState(false);
    const [loader, setLoader] = useState(false);
    const [filterText, setFilterText] = useState('');

    const tableTitles = [
        'edit',
        'delete',
        'ID',
        'Producto',
        'Imagenes',
        'País',
        'Precio',
        'Stock',
        'Vendidos',
        'Descripción',
        'Estado',
    ];


    // const productList = [];

    const [productList, setProductList] = useState([]);

    const [openImageModal, setOpenImageModal] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);

    const [editInstance, setEditInstance] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);

    const [imageModalData, setImageModalData] = useState(null);

    const editProduct = (product) => {
        setDataToEdit(product);
        setEditInstance(true);
        setOpenForm(true);
    };
    const handleImageModal = (props) => {
        setImageModalData(props);
        setOpenImageModal(true);
    };
    const getProducts = async () => {
        let resp = await StoreService.getProducts();
        setProductList(resp.data);
    };

    useEffect(() => {
        getProducts();
    }, []);

    const handleNewProductBtn = () => {
        setOpenForm(true);
        setDataToEdit(null);
        setCurrentProduct(null);
        // setEditInstance(false);
    };
    return (<>
        <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
            <Box className={_subNavbarContainer}>

                <Box className={_leftBtnsContainer}>

                    <Button
                        color={'primary'}
                        variant={'contained'}
                        classes={{ root: _newProductBtn }}
                        onClick={() => { handleNewProductBtn(); }}
                    >
                        <AddBoxIcon variant={'primary'} style={{ marginRight: 10, color: 'white' }} />
                        NUEVO PRODUCTO
                    </Button>


                </Box>
                <Box className={_rigthBtnsPanel}>
                    <Box className={_searchFilterContainer}>
                        <SearchFilter filterText={filterText} setFilterText={setFilterText} />
                    </Box>
                </Box>

                {/* NEW PRODUCT MODAL  */}
                <ProductModal
                    open={openForm}
                    setOpen={setOpenForm}
                    dataToEdit={editInstance ? dataToEdit : null}
                    editInstance={editInstance}
                    setEditInstance={setEditInstance}
                    refreshList={getProducts}
                    currentProduct={currentProduct}
                    setCurrentProduct={setCurrentProduct}
                />

                <ImageVisualizerModal open={openImageModal} setOpen={setOpenImageModal} data={imageModalData} />

            </Box>


            {loader ? <Box>Cargando ...</Box>
                :
                <Box overflow={isNarrowWidth ? 'scroll' : ''}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableTitles.map((title, i) => (
                                    <TableCell align={'center'} key={i}>
                                        <b>{title}</b>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {productList
                                .sort((a, b) => b.id - a.id)
                                .map((p
                                ) => (
                                    <TableRow hover key={p.id + '_code'}>
                                        <ProductsTableCell data={p} openEditModal={editProduct} openImageModal={handleImageModal} refreshList={getProducts}/>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>}
        </div>
    </>);
};

export default Store;

import axios from 'axios';

const AlliesService = {

    getAllies: (data) => {
        console.log(`AlliesService.getAllies()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/allies/feed/search`;
        return axios.post(url, data);
    },

    getPerks: (data) => {
        console.log(`AlliesService.getPerks()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/perks/feed/search`;
        return axios.post(url, data);
    },

    createAlly: (data) => {
        console.log(`AlliesService.createAlly()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/ally/create`;
        return axios.post(url, data);
    },

    createBenefit: (data) => {
        console.log(`AlliesService.createBenefit(ally_id:${data.ally_id})`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/perks/create`;
        return axios.post(url, data);
    },

    postImage: (data) => {
        console.log(`AlliesService.postImage()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/image`;
        return axios.post(url, data);
    },

    getTags: () => {
        console.log(`AlliesService.getTags()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/all_tags`;
        return axios.get(url);
    },

    createTag: (tag_name) => {
        console.log(`AlliesService.createTag()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/perks/tags/create`;
        return axios.post(url, tag_name);
    },

    changeAllyStatus: (data) => {
        console.log(`AlliesService.changeAllyStatus()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/ally/status`;
        return axios.patch(url, data);
    },

    changePerkStatus: (data) => {
        console.log(`AlliesService.changePerkStatus()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/perk/status`;
        return axios.patch(url, data);
    },

    getAllyDictionary: (id) => {
        console.log(`AlliesService.getAllyDictionary()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/ally/${id}`;
        return axios.get(url);

    },

    getPerkDictionary: (id) => {
        console.log(`AlliesService.getPerkDictionary()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/perk/${id}`;
        return axios.get(url);

    },

    updateAlly: (payload) => {
        console.log(`AlliesService.gettAlyyById${payload.id}()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/update/ally`;
        return axios.patch(url, payload);
    },

    updatePerk: (payload) => {
        console.log(`AlliesService.updatePerk${payload.id}()`);
        const url = `${process.env.REACT_APP_API_BENEFITS_URL}/allies_admin/update/perk`;
        return axios.patch(url, payload);
    },

};

export default AlliesService;

import { isValid as isValidCpf } from '@fnando/cpf';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import { ValidateCountryDocument } from '../helpers/funcs/ValidateCountryDocument';

export const bankOptions =

{
    // BRASIL
    'BR': [
        {
            'name': 'Banco do Brasil S.A.',
            'id': '001'
        },
        {
            'name': 'BANCO DA AMAZONIA S.A.',
            'id': '003'
        },
        {
            'name': 'Banco do Nordeste do Brasil S.A.',
            'id': '004'
        },
        {
            'name': 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
            'id': '007'
        },
        {
            'name': 'CREDICOAMO CREDITO RURAL COOPERATIVA',
            'id': '010'
        },
        {
            'name': 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
            'id': '011'
        },
        {
            'name': 'Banco Inbursa S.A.',
            'id': '012'
        },
        {
            'name': 'STATE STREET BRASIL S.A. - BANCO COMERCIAL',
            'id': '014'
        },
        {
            'name': 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
            'id': '015'
        },
        {
            'name': 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
            'id': '016'
        },
        {
            'name': 'BNY Mellon Banco S.A.',
            'id': '017'
        },
        {
            'name': 'Banco Tricury S.A.',
            'id': '018'
        },
        {
            'name': 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
            'id': '021'
        },
        {
            'name': 'Banco Bandepe S.A.',
            'id': '024'
        },
        {
            'name': 'Banco Alfa S.A.',
            'id': '025'
        },
        {
            'name': 'Banco Itaú Consignado S.A.',
            'id': '029'
        },
        {
            'name': 'BANCO SANTANDER (BRASIL) S.A.',
            'id': '033'
        },
        {
            'name': 'Banco Bradesco BBI S.A.',
            'id': '036'
        },
        {
            'name': 'Banco do Estado do Pará S.A.',
            'id': '037'
        },
        {
            'name': 'Banco Cargill S.A.',
            'id': '040'
        },
        {
            'name': 'Banco do Estado do Rio Grande do Sul S.A.',
            'id': '041'
        },
        {
            'name': 'Banco do Estado de Sergipe S.A.',
            'id': '047'
        },
        {
            'name': 'Confidence Corretora de Câmbio S.A.',
            'id': '060'
        },
        {
            'name': 'Hipercard Banco Múltiplo S.A.',
            'id': '062'
        },
        {
            'name': 'Banco Bradescard S.A.',
            'id': '063'
        },
        {
            'name': 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
            'id': '064'
        },
        {
            'name': 'Banco AndBank (Brasil) S.A.',
            'id': '065'
        },
        {
            'name': 'BANCO MORGAN STANLEY S.A.',
            'id': '066'
        },
        {
            'name': 'BRB - BANCO DE BRASILIA S.A.',
            'id': '070'
        },
        {
            'name': 'Banco J. Safra S.A.',
            'id': '074'
        },
        {
            'name': 'Banco ABN Amro S.A.',
            'id': '075'
        },
        {
            'name': 'Banco KDB do Brasil S.A.',
            'id': '076'
        },
        {
            'name': 'Banco Inter S.A.',
            'id': '077'
        },
        {
            'name': 'Haitong Banco de Investimento do Brasil S.A.',
            'id': '078'
        },
        {
            'name': 'Banco Original do Agronegócio S.A.',
            'id': '079'
        },
        {
            'name': 'B&T CORRETORA DE CAMBIO LTDA.',
            'id': '080'
        },
        {
            'name': 'BancoSeguro S.A.',
            'id': '081'
        },
        {
            'name': 'BANCO TOPÁZIO S.A.',
            'id': '082'
        },
        {
            'name': 'Banco da China Brasil S.A.',
            'id': '083'
        },
        {
            'name': 'UNIPRIME DO BRASIL - COOPERATIVA DE CRéDITO LTDA.',
            'id': '084'
        },
        {
            'name': 'Cooperativa Central de Crédito - Ailos',
            'id': '085'
        },
        {
            'name': 'BANCO RANDON S.A.',
            'id': '088'
        },
        {
            'name': 'CREDISAN COOPERATIVA DE CRÉDITO',
            'id': '089'
        },
        {
            'name': 'BRK S.A. Crédito, Financiamento e Investimento',
            'id': '092'
        },
        {
            'name': 'PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
            'id': '093'
        },
        {
            'name': 'Banco Finaxis S.A.',
            'id': '094'
        },
        {
            'name': 'Travelex Banco de Câmbio S.A.',
            'id': '095'
        },
        {
            'name': 'Banco B3 S.A.',
            'id': '096'
        },
        {
            'name': 'Credisis - Central de Cooperativas de Crédito Ltda.',
            'id': '097'
        },
        {
            'name': 'Credialiança Cooperativa de Crédito Rural',
            'id': '098'
        },
        {
            'name': 'UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.',
            'id': '099'
        },
        {
            'name': 'Planner Corretora de Valores S.A.',
            'id': '100'
        },
        {
            'name': 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
            'id': '101'
        },
        {
            'name': 'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
            'id': '102'
        },
        {
            'name': 'CAIXA ECONOMICA FEDERAL',
            'id': '104'
        },
        {
            'name': 'Lecca Crédito, Financiamento e Investimento S/A',
            'id': '105'
        },
        {
            'name': 'Banco Bocom BBM S.A.',
            'id': '107'
        },
        {
            'name': 'PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '108'
        },
        {
            'name': 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
            'id': '111'
        },
        {
            'name': 'MAGLIANO S.A. CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS',
            'id': '113'
        },
        {
            'name': 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
            'id': '114'
        },
        {
            'name': 'ADVANCED CORRETORA DE CÂMBIO LTDA',
            'id': '117'
        },
        {
            'name': 'Banco Western Union do Brasil S.A.',
            'id': '119'
        },
        {
            'name': 'BANCO RODOBENS S.A.',
            'id': '120'
        },
        {
            'name': 'Banco Agibank S.A.',
            'id': '121'
        },
        {
            'name': 'Banco Bradesco BERJ S.A.',
            'id': '122'
        },
        {
            'name': 'Banco Woori Bank do Brasil S.A.',
            'id': '124'
        },
        {
            'name': 'BANCO GENIAL S.A.',
            'id': '125'
        },
        {
            'name': 'BR Partners Banco de Investimento S.A.',
            'id': '126'
        },
        {
            'name': 'Codepe Corretora de Valores e Câmbio S.A.',
            'id': '127'
        },
        {
            'name': 'MS Bank S.A. Banco de Câmbio',
            'id': '128'
        },
        {
            'name': 'UBS Brasil Banco de Investimento S.A.',
            'id': '129'
        },
        {
            'name': 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '130'
        },
        {
            'name': 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
            'id': '131'
        },
        {
            'name': 'ICBC do Brasil Banco Múltiplo S.A.',
            'id': '132'
        },
        {
            'name': 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
            'id': '133'
        },
        {
            'name': 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
            'id': '134'
        },
        {
            'name': 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI',
            'id': '136'
        },
        {
            'name': 'Get Money Corretora de Câmbio S.A.',
            'id': '138'
        },
        {
            'name': 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
            'id': '139'
        },
        {
            'name': 'NU INVEST CORRETORA DE VALORES S.A.',
            'id': '140'
        },
        {
            'name': 'Broker Brasil Corretora de Câmbio Ltda.',
            'id': '142'
        },
        {
            'name': 'Treviso Corretora de Câmbio S.A.',
            'id': '143'
        },
        {
            'name': 'BEXS BANCO DE CÂMBIO S/A',
            'id': '144'
        },
        {
            'name': 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
            'id': '145'
        },
        {
            'name': 'GUITTA CORRETORA DE CAMBIO LTDA.',
            'id': '146'
        },
        {
            'name': 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
            'id': '149'
        },
        {
            'name': 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
            'id': '157'
        },
        {
            'name': 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
            'id': '159'
        },
        {
            'name': 'Commerzbank Brasil S.A. - Banco Múltiplo',
            'id': '163'
        },
        {
            'name': 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
            'id': '173'
        },
        {
            'name': 'PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '174'
        },
        {
            'name': 'Guide Investimentos S.A. Corretora de Valores',
            'id': '177'
        },
        {
            'name': 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
            'id': '180'
        },
        {
            'name': 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
            'id': '183'
        },
        {
            'name': 'Banco Itaú BBA S.A.',
            'id': '184'
        },
        {
            'name': 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
            'id': '188'
        },
        {
            'name': 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS',
            'id': '189'
        },
        {
            'name': 'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN',
            'id': '190'
        },
        {
            'name': 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
            'id': '191'
        },
        {
            'name': 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
            'id': '194'
        },
        {
            'name': 'VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '195'
        },
        {
            'name': 'FAIR CORRETORA DE CAMBIO S.A.',
            'id': '196'
        },
        {
            'name': 'Stone Pagamentos S.A.',
            'id': '197'
        },
        {
            'name': 'Banco BTG Pactual S.A.',
            'id': '208'
        },
        {
            'name': 'Banco Original S.A.',
            'id': '212'
        },
        {
            'name': 'Banco Arbi S.A.',
            'id': '213'
        },
        {
            'name': 'Banco John Deere S.A.',
            'id': '217'
        },
        {
            'name': 'Banco BS2 S.A.',
            'id': '218'
        },
        {
            'name': 'BANCO CRÉDIT AGRICOLE BRASIL S.A.',
            'id': '222'
        },
        {
            'name': 'Banco Fibra S.A.',
            'id': '224'
        },
        {
            'name': 'Banco Cifra S.A.',
            'id': '233'
        },
        {
            'name': 'Banco Bradesco S.A.',
            'id': '237'
        },
        {
            'name': 'BANCO CLASSICO S.A.',
            'id': '241'
        },
        {
            'name': 'BANCO MASTER S/A',
            'id': '243'
        },
        {
            'name': 'Banco ABC Brasil S.A.',
            'id': '246'
        },
        {
            'name': 'Banco Investcred Unibanco S.A.',
            'id': '249'
        },
        {
            'name': 'BCV - BANCO DE CRÉDITO E VAREJO S.A.',
            'id': '250'
        },
        {
            'name': 'Bexs Corretora de Câmbio S/A',
            'id': '253'
        },
        {
            'name': 'PARANÁ BANCO S.A.',
            'id': '254'
        },
        {
            'name': 'MONEYCORP BANCO DE CÂMBIO S.A.',
            'id': '259'
        },
        {
            'name': 'NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
            'id': '260'
        },
        {
            'name': 'Banco Fator S.A.',
            'id': '265'
        },
        {
            'name': 'BANCO CEDULA S.A.',
            'id': '266'
        },
        {
            'name': 'BARI COMPANHIA HIPOTECÁRIA',
            'id': '268'
        },
        {
            'name': 'BANCO HSBC S.A.',
            'id': '269'
        },
        {
            'name': 'Sagitur Corretora de Câmbio Ltda.',
            'id': '270'
        },
        {
            'name': 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
            'id': '271'
        },
        {
            'name': 'AGK CORRETORA DE CAMBIO S.A.',
            'id': '272'
        },
        {
            'name': 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
            'id': '273'
        },
        {
            'name': 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
            'id': '274'
        },
        {
            'name': 'BANCO SENFF S.A.',
            'id': '276'
        },
        {
            'name': 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
            'id': '278'
        },
        {
            'name': 'COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE',
            'id': '279'
        },
        {
            'name': 'WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '280'
        },
        {
            'name': 'Cooperativa de Crédito Rural Coopavel',
            'id': '281'
        },
        {
            'name': 'RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA',
            'id': '283'
        },
        {
            'name': 'Frente Corretora de Câmbio Ltda.',
            'id': '285'
        },
        {
            'name': 'COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO',
            'id': '286'
        },
        {
            'name': 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
            'id': '288'
        },
        {
            'name': 'DECYSEO CORRETORA DE CAMBIO LTDA.',
            'id': '289'
        },
        {
            'name': 'Pagseguro Internet S.A.',
            'id': '290'
        },
        {
            'name': 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
            'id': '292'
        },
        {
            'name': 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
            'id': '293'
        },
        {
            'name': 'VISION S.A. CORRETORA DE CAMBIO',
            'id': '296'
        },
        {
            'name': 'Vip\'s Corretora de Câmbio Ltda.',
            'id': '298'
        },
        {
            'name': 'BANCO SOROCRED S.A. - BANCO MÚLTIPLO',
            'id': '299'
        },
        {
            'name': 'Banco de la Nacion Argentina',
            'id': '300'
        },
        {
            'name': 'BPP Instituição de Pagamento S.A.',
            'id': '301'
        },
        {
            'name': 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
            'id': '306'
        },
        {
            'name': 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
            'id': '307'
        },
        {
            'name': 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
            'id': '309'
        },
        {
            'name': 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
            'id': '310'
        },
        {
            'name': 'DOURADA CORRETORA DE CÂMBIO LTDA.',
            'id': '311'
        },
        {
            'name': 'HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT',
            'id': '312'
        },
        {
            'name': 'AMAZÔNIA CORRETORA DE CÂMBIO LTDA.',
            'id': '313'
        },
        {
            'name': 'PI Distribuidora de Títulos e Valores Mobiliários S.A.',
            'id': '315'
        },
        {
            'name': 'Banco BMG S.A.',
            'id': '318'
        },
        {
            'name': 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
            'id': '319'
        },
        {
            'name': 'China Construction Bank (Brasil) Banco Múltiplo S/A',
            'id': '320'
        },
        {
            'name': 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
            'id': '321'
        },
        {
            'name': 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
            'id': '322'
        },
        {
            'name': 'MERCADOPAGO.COM REPRESENTACOES LTDA.',
            'id': '323'
        },
        {
            'name': 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '324'
        },
        {
            'name': 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
            'id': '325'
        },
        {
            'name': 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
            'id': '326'
        },
        {
            'name': 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA',
            'id': '328'
        },
        {
            'name': 'QI Sociedade de Crédito Direto S.A.',
            'id': '329'
        },
        {
            'name': 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
            'id': '330'
        },
        {
            'name': 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
            'id': '331'
        },
        {
            'name': 'Acesso Soluções de Pagamento S.A.',
            'id': '332'
        },
        {
            'name': 'Banco Digio S.A.',
            'id': '335'
        },
        {
            'name': 'Banco C6 S.A.',
            'id': '336'
        },
        {
            'name': 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
            'id': '340'
        },
        {
            'name': 'ITAÚ UNIBANCO S.A.',
            'id': '341'
        },
        {
            'name': 'Creditas Sociedade de Crédito Direto S.A.',
            'id': '342'
        },
        {
            'name': 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
            'id': '343'
        },
        {
            'name': 'Banco XP S.A.',
            'id': '348'
        },
        {
            'name': 'AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '349'
        },
        {
            'name': 'COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE',
            'id': '350'
        },
        {
            'name': 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
            'id': '352'
        },
        {
            'name': 'NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
            'id': '354'
        },
        {
            'name': 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '355'
        },
        {
            'name': 'MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '358'
        },
        {
            'name': 'ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
            'id': '359'
        },
        {
            'name': 'TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
            'id': '360'
        },
        {
            'name': 'CIELO S.A.',
            'id': '362'
        },
        {
            'name': 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
            'id': '363'
        },
        {
            'name': 'GERENCIANET S.A.',
            'id': '364'
        },
        {
            'name': 'SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A.',
            'id': '365'
        },
        {
            'name': 'BANCO SOCIETE GENERALE BRASIL S.A.',
            'id': '366'
        },
        {
            'name': 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
            'id': '367'
        },
        {
            'name': 'Banco CSF S.A.',
            'id': '368'
        },
        {
            'name': 'Banco Mizuho do Brasil S.A.',
            'id': '370'
        },
        {
            'name': 'WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
            'id': '371'
        },
        {
            'name': 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
            'id': '373'
        },
        {
            'name': 'REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
            'id': '374'
        },
        {
            'name': 'BANCO J.P. MORGAN S.A.',
            'id': '376'
        },
        {
            'name': 'BMS SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '377'
        },
        {
            'name': 'BBC LEASING S.A. - ARRENDAMENTO MERCANTIL',
            'id': '378'
        },
        {
            'name': 'COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU',
            'id': '379'
        },
        {
            'name': 'PICPAY INSTITUIçãO DE PAGAMENTO S.A.',
            'id': '380'
        },
        {
            'name': 'BANCO MERCEDES-BENZ DO BRASIL S.A.',
            'id': '381'
        },
        {
            'name': 'FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L',
            'id': '382'
        },
        {
            'name': 'EBANX INSTITUICAO DE PAGAMENTOS LTDA.',
            'id': '383'
        },
        {
            'name': 'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO',
            'id': '384'
        },
        {
            'name': 'NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento',
            'id': '386'
        },
        {
            'name': 'Banco Toyota do Brasil S.A.',
            'id': '387'
        },
        {
            'name': 'Banco Mercantil do Brasil S.A.',
            'id': '389'
        },
        {
            'name': 'BANCO GM S.A.',
            'id': '390'
        },
        {
            'name': 'COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM',
            'id': '391'
        },
        {
            'name': 'Banco Volkswagen S.A.',
            'id': '393'
        },
        {
            'name': 'Banco Bradesco Financiamentos S.A.',
            'id': '394'
        },
        {
            'name': 'F.D\'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
            'id': '395'
        },
        {
            'name': 'HUB PAGAMENTOS S.A',
            'id': '396'
        },
        {
            'name': 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
            'id': '397'
        },
        {
            'name': 'IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
            'id': '398'
        },
        {
            'name': 'Kirton Bank S.A. - Banco Múltiplo',
            'id': '399'
        },
        {
            'name': 'COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE - CREDIT',
            'id': '400'
        },
        {
            'name': 'IUGU INSTITUIÇÃO DE PAGAMENTO S.A.',
            'id': '401'
        },
        {
            'name': 'COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '402'
        },
        {
            'name': 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '403'
        },
        {
            'name': 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '404'
        },
        {
            'name': 'ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '406'
        },
        {
            'name': 'ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
            'id': '407'
        },
        {
            'name': 'BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '408'
        },
        {
            'name': 'PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A.',
            'id': '410'
        },
        {
            'name': 'Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos',
            'id': '411'
        },
        {
            'name': 'BANCO CAPITAL S.A.',
            'id': '412'
        },
        {
            'name': 'BANCO BV S.A.',
            'id': '413'
        },
        {
            'name': 'WORK SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '414'
        },
        {
            'name': 'LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '416'
        },
        {
            'name': 'ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A',
            'id': '418'
        },
        {
            'name': 'NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '419'
        },
        {
            'name': 'LAR COOPERATIVA DE CRÉDITO - LAR CREDI',
            'id': '421'
        },
        {
            'name': 'Banco Safra S.A.',
            'id': '422'
        },
        {
            'name': 'COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS',
            'id': '423'
        },
        {
            'name': 'SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '425'
        },
        {
            'name': 'Biorc Financeira - Crédito, Financiamento e Investimento S.A.',
            'id': '426'
        },
        {
            'name': 'COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO',
            'id': '427'
        },
        {
            'name': 'CRED-SYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '428'
        },
        {
            'name': 'Crediare S.A. - Crédito, financiamento e investimento',
            'id': '429'
        },
        {
            'name': 'COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA',
            'id': '430'
        },
        {
            'name': 'BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
            'id': '433'
        },
        {
            'name': 'DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '435'
        },
        {
            'name': 'PLANNER TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
            'id': '438'
        },
        {
            'name': 'ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
            'id': '439'
        },
        {
            'name': 'CREDIBRF - COOPERATIVA DE CRÉDITO',
            'id': '440'
        },
        {
            'name': 'MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
            'id': '442'
        },
        {
            'name': 'CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '443'
        },
        {
            'name': 'TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '444'
        },
        {
            'name': 'PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
            'id': '445'
        },
        {
            'name': 'MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CÂMBIO, TÍTULOS E VALORES MO',
            'id': '447'
        },
        {
            'name': 'HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
            'id': '448'
        },
        {
            'name': 'DMCARD SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '449'
        },
        {
            'name': 'FITBANK PAGAMENTOS ELETRONICOS S.A.',
            'id': '450'
        },
        {
            'name': 'CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '452'
        },
        {
            'name': 'MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
            'id': '454'
        },
        {
            'name': 'Banco MUFG Brasil S.A.',
            'id': '456'
        },
        {
            'name': 'UY3 SOCIEDADE DE CRÉDITO DIRETO S/A',
            'id': '457'
        },
        {
            'name': 'HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
            'id': '458'
        },
        {
            'name': 'COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CRE',
            'id': '459'
        },
        {
            'name': 'ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.',
            'id': '461'
        },
        {
            'name': 'STARK SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '462'
        },
        {
            'name': 'Banco Sumitomo Mitsui Brasileiro S.A.',
            'id': '464'
        },
        {
            'name': 'CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.',
            'id': '465'
        },
        {
            'name': 'MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES MOBILIáRIOS',
            'id': '467'
        },
        {
            'name': 'Banco Caixa Geral - Brasil S.A.',
            'id': '473'
        },
        {
            'name': 'Citibank N.A.',
            'id': '477'
        },
        {
            'name': 'Banco ItauBank S.A.',
            'id': '479'
        },
        {
            'name': 'DEUTSCHE BANK S.A. - BANCO ALEMAO',
            'id': '487'
        },
        {
            'name': 'JPMorgan Chase Bank, National Association',
            'id': '488'
        },
        {
            'name': 'ING Bank N.V.',
            'id': '492'
        },
        {
            'name': 'Banco de La Provincia de Buenos Aires',
            'id': '495'
        },
        {
            'name': 'Banco Credit Suisse (Brasil) S.A.',
            'id': '505'
        },
        {
            'name': 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
            'id': '545'
        },
        {
            'name': 'Banco Luso Brasileiro S.A.',
            'id': '600'
        },
        {
            'name': 'Banco Industrial do Brasil S.A.',
            'id': '604'
        },
        {
            'name': 'Banco VR S.A.',
            'id': '610'
        },
        {
            'name': 'Banco Paulista S.A.',
            'id': '611'
        },
        {
            'name': 'Banco Guanabara S.A.',
            'id': '612'
        },
        {
            'name': 'Omni Banco S.A.',
            'id': '613'
        },
        {
            'name': 'Banco Pan S.A.',
            'id': '623'
        },
        {
            'name': 'BANCO C6 CONSIGNADO S.A.',
            'id': '626'
        },
        {
            'name': 'BANCO LETSBANK S.A.',
            'id': '630'
        },
        {
            'name': 'Banco Rendimento S.A.',
            'id': '633'
        },
        {
            'name': 'BANCO TRIANGULO S.A.',
            'id': '634'
        },
        {
            'name': 'BANCO SOFISA S.A.',
            'id': '637'
        },
        {
            'name': 'Banco Pine S.A.',
            'id': '643'
        },
        {
            'name': 'Itaú Unibanco Holding S.A.',
            'id': '652'
        },
        {
            'name': 'BANCO VOITER S.A.',
            'id': '653'
        },
        {
            'name': 'BANCO DIGIMAIS S.A.',
            'id': '654'
        },
        {
            'name': 'Banco Votorantim S.A.',
            'id': '655'
        },
        {
            'name': 'Banco Crefisa S.A.',
            'id': '69'
        },
        {
            'name': 'Banco Daycoval S.A.',
            'id': '707'
        },
        {
            'name': 'Banco Ourinvest S.A.',
            'id': '712'
        },
        {
            'name': 'BANCO RNX S.A.',
            'id': '720'
        },
        {
            'name': 'Banco Cetelem S.A.',
            'id': '739'
        },
        {
            'name': 'BANCO RIBEIRAO PRETO S.A.',
            'id': '741'
        },
        {
            'name': 'Banco Semear S.A.',
            'id': '743'
        },
        {
            'name': 'Banco Citibank S.A.',
            'id': '745'
        },
        {
            'name': 'Banco Modal S.A.',
            'id': '746'
        },
        {
            'name': 'Banco Rabobank International Brasil S.A.',
            'id': '747'
        },
        {
            'name': 'BANCO COOPERATIVO SICREDI S.A.',
            'id': '748'
        },
        {
            'name': 'Scotiabank Brasil S.A. Banco Múltiplo',
            'id': '751'
        },
        {
            'name': 'Banco BNP Paribas Brasil S.A.',
            'id': '752'
        },
        {
            'name': 'Novo Banco Continental S.A. - Banco Múltiplo',
            'id': '753'
        },
        {
            'name': 'Banco Sistema S.A.',
            'id': '754'
        },
        {
            'name': 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
            'id': '755'
        },
        {
            'name': 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
            'id': '756'
        },
        {
            'name': 'BANCO KEB HANA DO BRASIL S.A.',
            'id': '757'
        },
    ],

    //ARGENTINA
    'AR': [
        {
            'name': 'CVU Account',
            'id': '000'
        },
        {
            'name': 'Citibank',
            'id': '016'
        },
        {
            'name': 'Bacs Banco de Credito Y Securitizacion',
            'id': '340'
        },
        {
            'name': 'Banco B. I. Creditanstalt',
            'id': '147'
        },
        {
            'name': 'Banco Bradesco Argentina',
            'id': '336'
        },
        {
            'name': 'Banco Cetelem Argentina',
            'id': '331'
        },
        {
            'name': 'Banco CMF',
            'id': '319'
        },
        {
            'name': 'Banco Columbia',
            'id': '389'
        },
        {
            'name': 'Banco Comafi',
            'id': '299'
        },
        {
            'name': 'Banco Credicoop Coop. L',
            'id': '191'
        },
        {
            'name': 'Banco de Corrientes',
            'id': '094'
        },
        {
            'name': 'Banco de Formosa',
            'id': '315'
        },
        {
            'name': 'Banco de Galicia Y Buenos Aires',
            'id': '007'
        },
        {
            'name': 'Banco de Inversion Y Comercio Exterior',
            'id': '300'
        },
        {
            'name': 'Banco de La Ciudad de Buenos Aires',
            'id': '029'
        },
        {
            'name': 'Banco de La Nacion Argentina',
            'id': '011'
        },
        {
            'name': 'Banco de La Pampa Sociedad de Economia M',
            'id': '093'
        },
        {
            'name': 'Banco de La Provincia de Buenos Aires',
            'id': '014'
        },
        {
            'name': 'Banco de La Provincia de Cordoba',
            'id': '020'
        },
        {
            'name': 'Banco de La Republica Oriental Del Uruguay',
            'id': '269'
        },
        {
            'name': 'Banco de San Juan',
            'id': '045'
        },
        {
            'name': 'Banco de Santa Cruz',
            'id': '086'
        },
        {
            'name': 'Banco de Santiago del Estero',
            'id': '321'
        },
        {
            'name': 'Banco de Servicios Financieros',
            'id': '332'
        },
        {
            'name': 'Banco de Servicios Y Transacciones',
            'id': '338'
        },
        {
            'name': 'Banco de Valores',
            'id': '198'
        },
        {
            'name': 'Banco Del Chubut',
            'id': '083'
        },
        {
            'name': 'Banco Del Sol',
            'id': '310'
        },
        {
            'name': 'Banco Hipotecario',
            'id': '044'
        },
        {
            'name': 'Banco Itau',
            'id': '259'
        },
        {
            'name': 'Banco Julio',
            'id': '305'
        },
        {
            'name': 'Banco Macro',
            'id': '285'
        },
        {
            'name': 'Banco Mariva',
            'id': '254'
        },
        {
            'name': 'Banco Mas Ventas',
            'id': '341'
        },
        {
            'name': 'Banco Meridian',
            'id': '281'
        },
        {
            'name': 'Banco Municipal de Rosario',
            'id': '065'
        },
        {
            'name': 'Banco Patagonia Sudameris',
            'id': '034'
        },
        {
            'name': 'Banco Piano',
            'id': '301'
        },
        {
            'name': 'Banco Provincia de Tierra Del Fuego',
            'id': '268'
        },
        {
            'name': 'Banco Provincia Del Neuquen',
            'id': '097'
        },
        {
            'name': 'Banco Santander',
            'id': '072'
        },
        {
            'name': 'Banco Roela',
            'id': '247'
        },
        {
            'name': 'Banco Saenz',
            'id': '277'
        },
        {
            'name': 'Banco Supervielle S.A.',
            'id': '027'
        },
        {
            'name': 'Bank Of America, National Associa',
            'id': '262'
        },
        {
            'name': 'Industrial and Commercial Bank of China (ICBC) Argentina',
            'id': '015'
        },
        {
            'name': 'BBVA',
            'id': '017'
        },
        {
            'name': 'Bnp Paribas',
            'id': '266'
        },
        {
            'name': 'HSBC Bank Argentina',
            'id': '150'
        },
        {
            'name': 'J P Morgan Chase Bank Sucursal Buenos Aires',
            'id': '165'
        },
        {
            'name': 'BANCO VOII S.A.',
            'id': '312'
        },
        {
            'name': 'Nuevo Banco de Entre Rios',
            'id': '386'
        },
        {
            'name': 'Nuevo Banco de La Rioja',
            'id': '309'
        },
        {
            'name': 'Nuevo Banco de Santa Fe',
            'id': '330'
        },
        {
            'name': 'Nuevo Banco Del Chaco',
            'id': '311'
        },
        {
            'name': 'Nuevo Banco Industrial de Azul',
            'id': '322'
        },
        {
            'name': 'RCI Banque Argentina',
            'id': '339'
        },
        {
            'name': 'Wilobank S.A.',
            'id': '384'
        },
        {
            'name': 'Banco Bica S.A.',
            'id': '426'
        },
        {
            'name': 'Banco Coinag S.A.',
            'id': '431'
        },
        {
            'name': 'Banco Sucredito Regional S.A.U.',
            'id': '435'
        },
        {
            'name': 'Bank of Chine Limited Sucursal Buenos Aires',
            'id': '515'
        },
        {
            'name': 'Brubank S.A.U.',
            'id': '143'
        },
        {
            'name': 'Banco de Comercio S.A.',
            'id': '432'
        },
        {
            'name': 'Banco Dino S.A.',
            'id': '448'
        }
    ],

    //CHILE
    'CL': [
        {
            'name': 'Banco Bice',
            'id': '28'
        },
        {
            'name': 'Banco Consorcio',
            'id': '55'
        },
        {
            'name': 'Banco Crédito e Inversiones',
            'id': '16'
        },
        {
            'name': 'Banco de Chile',
            'id': '1'
        },
        {
            'name': 'Banco del Desarrollo',
            'id': '507'
        },
        {
            'name': 'Banco del Estado de Chile',
            'id': '12'
        },
        {
            'name': 'Banco Falabella',
            'id': '51'
        },
        {
            'name': 'Banco Internacional',
            'id': '9'
        },
        {
            'name': 'Banco Ripley',
            'id': '53'
        },
        {
            'name': 'Banco Santander - Santiago',
            'id': '37'
        },
        {
            'name': 'Banco Security',
            'id': '49'
        },
        {
            'name': 'BBVA Chile',
            'id': '504'
        },
        {
            'name': 'HSBC Bank',
            'id': '31'
        },
        {
            'name': 'Itau Corpbanca',
            'id': '39'
        },
        {
            'name': 'Scotiabank Chile',
            'id': '14'
        },
        {
            'name': 'Coopeuch',
            'id': '672'
        },
        {
            'name': 'Prepago los Héroes',
            'id': '729'
        },
        {
            'name': 'Tenpo Prepago',
            'id': '730'
        }
    ],

    //COLOMBIA
    'CO': [

        {
            'name': 'Popular',
            'id': '2'
        },
        {
            'name': 'Santander',
            'id': '6'
        },
        {
            'name': 'Bancolombia',
            'id': '7'
        },
        {
            'name': 'HSBC',
            'id': '10'
        },
        {
            'name': 'Sudameris',
            'id': '12'
        },
        {
            'name': 'BBVA',
            'id': '13'
        },
        {
            'name': 'Itau',
            'id': '14'
        },
        {
            'name': 'Scotiabank Colpatria',
            'id': '19'
        },
        {
            'name': 'Banco de Occidente',
            'id': '23'
        },
        {
            'name': 'Caja social BCSC',
            'id': '32'
        },
        {
            'name': 'Agrario',
            'id': '40'
        },
        {
            'name': 'Davivienda',
            'id': '51'
        },
        {
            'name': 'Av. Villas',
            'id': '52'
        },
        {
            'name': 'Procredit',
            'id': '58'
        },
        {
            'name': 'Pichincha',
            'id': '60'
        },
        {
            'name': 'Bancoomeva',
            'id': '61'
        },
        {
            'name': 'Falabella S.A.',
            'id': '62'
        },
        {
            'name': 'Coopcentral S.A.',
            'id': '76'
        },
        {
            'name': 'Cooperativa financiera de Antioquía',
            'id': '283'
        },
        {
            'name': 'Cotrafa cooperativa financiera',
            'id': '289'
        },
        {
            'name': 'Confiar',
            'id': '292'
        },
        {
            'name': 'Financiera Juriscoop',
            'id': '296'
        },
        {
            'name': 'Banco de Bogota',
            'id': '1'
        }
    ],

    //ECUADOR
    'EC': [
        {
            'name': 'Banco Amazonas',
            'id': '034'
        },
        {
            'name': 'Banco Asistencia Comunitaria Finca S.A.',
            'id': '323'
        },
        {
            'name': 'Banco Bolivariano',
            'id': '037'
        },
        {
            'name': 'Banco Capital S.A.',
            'id': '061'
        },
        {
            'name': 'Banco Citibank',
            'id': '024'
        },
        {
            'name': 'Banco Comercial De Manabi',
            'id': '039'
        },
        {
            'name': 'Banco Coopnacional S.A.',
            'id': '064'
        },
        {
            'name': 'Banco de Guayaquil',
            'id': '017'
        },
        {
            'name': 'Banco de Loja',
            'id': '029'
        },
        {
            'name': 'Banco de Machala',
            'id': '025'
        },
        {
            'name': 'Banco del Austro',
            'id': '035'
        },
        {
            'name': 'Banco del Instituto Ecuatoriano de Seguridad Social',
            'id': '066'
        },
        {
            'name': 'Banco del Litoral',
            'id': '043'
        },
        {
            'name': 'Banco del Pacifico',
            'id': '030'
        },
        {
            'name': 'Banco del Pichincha',
            'id': '010'
        },
        {
            'name': 'Banco Delbank',
            'id': '027'
        },
        {
            'name': 'Banco Ecuatoriano de la Vivienda',
            'id': '052'
        },
        {
            'name': 'Banco General Ruminahui',
            'id': '042'
        },
        {
            'name': 'Banco Internacional',
            'id': '032'
        },
        {
            'name': 'Banco Procredit',
            'id': '060'
        },
        {
            'name': 'Banco Produbanco',
            'id': '036'
        },
        {
            'name': 'Banco Solidario',
            'id': '059'
        },
        {
            'name': 'Coop. 15 de Abril Ltda',
            'id': '057'
        },
        {
            'name': 'Coop. 15 de Agosto Pilacoto',
            'id': '290'
        },
        {
            'name': 'Coop. Accion y Desarrollo',
            'id': '051'
        },
        {
            'name': 'Coop. Aho. y Cred. 16 de Junio',
            'id': '660'
        },
        {
            'name': 'Coop. Aho. y Cred. 1Ro de Enero del Austro',
            'id': '284'
        },
        {
            'name': 'Coop. Aho. y Cred. 23 de Julio',
            'id': '058'
        },
        {
            'name': 'Coop. Aho. y Cred. 23 de Mayo Ltda.',
            'id': '606'
        },
        {
            'name': 'Coop. Aho. y Cred. 29 de Octubre',
            'id': '095'
        },
        {
            'name': 'Coop. Aho. y Cred. 4 de Octubre Ltda.',
            'id': '294'
        },
        {
            'name': 'Coop. Aho. y Cred. 9 de Octubre Ltda',
            'id': '325'
        },
        {
            'name': 'Coop. Aho. y Cred. Accion Rural',
            'id': '326'
        },
        {
            'name': 'Coop. Aho. y Cred. Accion Tungurahua Ltda.',
            'id': '636'
        },
        {
            'name': 'Coop. Aho. y Cred. Agraria Mushuk Kawsay Ltda.',
            'id': '275'
        },
        {
            'name': 'Coop. Aho. y Cred. Agricola Junin Ltda',
            'id': '424'
        },
        {
            'name': 'Coop. Aho. y Cred. Alfonso Jaramillo',
            'id': '296'
        },
        {
            'name': 'Coop. Aho. y Cred. Alianza del Valle Ltda',
            'id': '062'
        },
        {
            'name': 'Coop. Aho. y Cred. Alianza Minas Ltda.',
            'id': '278'
        },
        {
            'name': 'Coop. Aho. y Cred. Ambato Ltda',
            'id': '414'
        },
        {
            'name': 'Coop. Aho. y Cred. Andina Ltda.',
            'id': '642'
        },
        {
            'name': 'Coop. Aho. y Cred. Artesanos Ltda',
            'id': '417'
        },
        {
            'name': 'Coop. Aho. y Cred. Atuntaqui Ltda.',
            'id': '086'
        },
        {
            'name': 'Coop. Aho. y Cred. Cacpe Celica',
            'id': '656'
        },
        {
            'name': 'Coop. Aho. y Cred. Cam Com Canton Bolivar',
            'id': '426'
        },
        {
            'name': 'Coop. Aho. y Cred. Camara Comer Ambato',
            'id': '411'
        },
        {
            'name': 'Coop. Aho. y Cred. Camara Comercio Indigena',
            'id': '295'
        },
        {
            'name': 'Coop. Aho. y Cred. Cariamanga Ltda',
            'id': '422'
        },
        {
            'name': 'Coop. Aho. y Cred. Carroceros de Tungurahua',
            'id': '634'
        },
        {
            'name': 'Coop. Aho. y Cred. Catamayo Ltda. Mie',
            'id': '657'
        },
        {
            'name': 'Coop. Aho. y Cred. Chone Ltda',
            'id': '067'
        },
        {
            'name': 'Coop. Aho. y Cred. Coca Ltda',
            'id': '607'
        },
        {
            'name': 'Coop. Aho. y Cred. Comercio Ltda Portoviejo',
            'id': '088'
        },
        {
            'name': 'Coop. Aho. y Cred. Const Comercio y Produccion',
            'id': '407'
        },
        {
            'name': 'Coop. Aho. y Cred. Cotocollao',
            'id': '065'
        },
        {
            'name': 'Coop. Aho. y Cred. Credi Facil Ltda.',
            'id': '628'
        },
        {
            'name': 'Coop. Aho. y Cred. Crediamigo Ltda. Loja Mi',
            'id': '653'
        },
        {
            'name': 'Coop. Aho. y Cred. Cristo Rey',
            'id': '291'
        },
        {
            'name': 'Coop. Aho. y Cred. de la Peq. Emp. Cacpe Macara',
            'id': '658'
        },
        {
            'name': 'Coop. Aho. y Cred. de la Peq. Emp. Cacpe Zamora Ltda.',
            'id': '602'
        },
        {
            'name': 'Coop. Aho. y Cred. de Los Serv. Publ. del Min. de Ed.',
            'id': '605'
        },
        {
            'name': 'Coop. Aho. y Cred. Desarrollo Integral Ltda.',
            'id': '603'
        },
        {
            'name': 'Coop. Aho. y Cred. Dorado Ltda',
            'id': '415'
        },
        {
            'name': 'Coop. Aho. y Cred. Ecuafuturo Ltda.',
            'id': '630'
        },
        {
            'name': 'Coop. Aho. y Cred. Educ del Tungurahua',
            'id': '413'
        },
        {
            'name': 'Coop. Aho. y Cred. Educadores Chimborazo',
            'id': '292'
        },
        {
            'name': 'Coop. Aho. y Cred. Educadores de Pastaza Ltda.',
            'id': '204'
        },
        {
            'name': 'Coop. Aho. y Cred. Educadores Tulcan Ltda.',
            'id': '085'
        },
        {
            'name': 'Coop. Aho. y Cred. El Calvario Ltda.',
            'id': '627'
        },
        {
            'name': 'Coop. Aho. y Cred. El Sagrario',
            'id': '069'
        },
        {
            'name': 'Coop. Aho. y Cred. Erco Ltda',
            'id': '410'
        },
        {
            'name': 'Coop. Aho. y Cred. Esc.Sup.Politec. Agrop. de Manabi Man',
            'id': '420'
        },
        {
            'name': 'Coop. Aho. y Cred. Escencia Indigena Ltda.',
            'id': '641'
        },
        {
            'name': 'Coop. Aho. y Cred. Familia Austral',
            'id': '286'
        },
        {
            'name': 'Coop. Aho. y Cred. Fernando Daquilema',
            'id': '429'
        },
        {
            'name': 'Coop. Aho. y Cred. Fortuna Mies',
            'id': '654'
        },
        {
            'name': 'Coop. Aho. y Cred. Fundesarrollo',
            'id': '406'
        },
        {
            'name': 'Coop. Aho. y Cred. Futuro y Progreso de Galapagos Lt',
            'id': '661'
        },
        {
            'name': 'Coop. Aho. y Cred. Gonzanama Mies',
            'id': '650'
        },
        {
            'name': 'Coop. Aho. y Cred. Grameen Amazonas',
            'id': '604'
        },
        {
            'name': 'Coop. Aho. y Cred. Guamote Ltda.',
            'id': '664'
        },
        {
            'name': 'Coop. Aho. y Cred. Guaranda Ltda.',
            'id': '072'
        },
        {
            'name': 'Coop. Aho. y Cred. Huaicana Ltda',
            'id': '609'
        },
        {
            'name': 'Coop. Aho. y Cred. Huayco Pungo Ltda',
            'id': '419'
        },
        {
            'name': 'Coop. Aho. y Cred. Huinara Ltda. Mies',
            'id': '626'
        },
        {
            'name': 'Coop. Aho. y Cred. Inka Kipu Ltda.',
            'id': '635'
        },
        {
            'name': 'Coop. Aho. y Cred. Integral',
            'id': '409'
        },
        {
            'name': 'Coop. Aho. y Cred. Jadan Ltda. Mies',
            'id': '625'
        },
        {
            'name': 'Coop. Aho. y Cred. Juan de Salinas Ltda.',
            'id': '270'
        },
        {
            'name': 'Coop. Aho. y Cred. Juan Pio de Mora Ltda.',
            'id': '638'
        },
        {
            'name': 'Coop. Aho. y Cred. la Dolorosa Ltda',
            'id': '073'
        },
        {
            'name': 'Coop. Aho. y Cred. la Merced',
            'id': '557'
        },
        {
            'name': 'Coop. Aho. y Cred. Llanganates',
            'id': '348'
        },
        {
            'name': 'Coop. Aho. y Cred. Lucha Campesina Ltda.',
            'id': '663'
        },
        {
            'name': 'Coop. Aho. y Cred. Malchingui Ltda.',
            'id': '273'
        },
        {
            'name': 'Coop. Aho. y Cred. Manantial de Oro Ltda.',
            'id': '601'
        },
        {
            'name': 'Coop. Aho. y Cred. Maquita Cushun Ltda.',
            'id': '631'
        },
        {
            'name': 'Coop. Aho. y Cred. Marcabeli Ltda',
            'id': '423'
        },
        {
            'name': 'Coop. Aho. y Cred. Mi Tierra',
            'id': '403'
        },
        {
            'name': 'Coop. Aho. y Cred. Minga Ltda.',
            'id': '293'
        },
        {
            'name': 'Coop. Aho. y Cred. Mujeres Unidas Tantanakushk',
            'id': '333'
        },
        {
            'name': 'Coop. Aho. y Cred. Mushuc Runa Ltda',
            'id': '412'
        },
        {
            'name': 'Coop. Aho. y Cred. Nuestros Abuelos Ltda',
            'id': '416'
        },
        {
            'name': 'Coop. Aho. y Cred. Nueva Esperanza',
            'id': '427'
        },
        {
            'name': 'Coop. Aho. y Cred. Nueva Huancavilca',
            'id': '408'
        },
        {
            'name': 'Coop. Aho. y Cred. Nueva Jerusalen',
            'id': '272'
        },
        {
            'name': 'Coop. Aho. y Cred. Nuevos Horizontes El Oro Ltda.',
            'id': '659'
        },
        {
            'name': 'Coop. Aho. y Cred. Once de Junio',
            'id': '075'
        },
        {
            'name': 'Coop. Aho. y Cred. Oscus',
            'id': '076'
        },
        {
            'name': 'Coop. Aho. y Cred. Pablo Munoz Vega',
            'id': '093'
        },
        {
            'name': 'Coop. Aho. y Cred. Padre Julian Lorente Ltda',
            'id': '077'
        },
        {
            'name': 'Coop. Aho. y Cred. Pedro Moncayo Ltda.',
            'id': '279'
        },
        {
            'name': 'Coop. Aho. y Cred. Peq Emp Cacpe Yanzatza',
            'id': '404'
        },
        {
            'name': 'Coop. Aho. y Cred. Peq Empr Cacpe Biblian',
            'id': '078'
        },
        {
            'name': 'Coop. Aho. y Cred. Peq Empresa Gualaquiza',
            'id': '402'
        },
        {
            'name': 'Coop. Aho. y Cred. Peq. Emp. de Loja Cacpe',
            'id': '350'
        },
        {
            'name': 'Coop. Aho. y Cred. Pijal',
            'id': '640'
        },
        {
            'name': 'Coop. Aho. y Cred. Pilahuin',
            'id': '643'
        },
        {
            'name': 'Coop. Aho. y Cred. Pilahuin Tio Ltda',
            'id': '608'
        },
        {
            'name': 'Coop. Aho. y Cred. Profesionales del Volante Union L',
            'id': '655'
        },
        {
            'name': 'Coop. Aho. y Cred. Progreso',
            'id': '079'
        },
        {
            'name': 'Coop. Aho. y Cred. Provida',
            'id': '281'
        },
        {
            'name': 'Coop. Aho. y Cred. Pucara Ltda.',
            'id': '644'
        },
        {
            'name': 'Coop. Aho. y Cred. Puellaro Ltda',
            'id': '271'
        },
        {
            'name': 'Coop. Aho. y Cred. Puerto Lopez Ltda',
            'id': '425'
        },
        {
            'name': 'Coop. Aho. y Cred. Quilanga Ltda.',
            'id': '651'
        },
        {
            'name': 'Coop. Aho. y Cred. Riobamba',
            'id': '080'
        },
        {
            'name': 'Coop. Aho. y Cred. Salasaca',
            'id': '637'
        },
        {
            'name': 'Coop. Aho. y Cred. San Antonio Ltda.',
            'id': '289'
        },
        {
            'name': 'Coop. Aho. y Cred. San Francisco',
            'id': '081'
        },
        {
            'name': 'Coop. Aho. y Cred. San Francisco de Asis',
            'id': '054'
        },
        {
            'name': 'Coop. Aho. y Cred. San Gabriel Ltda.',
            'id': '328'
        },
        {
            'name': 'Coop. Aho. y Cred. San Jorge Ltda',
            'id': '428'
        },
        {
            'name': 'Coop. Aho. y Cred. San Jose Ltda',
            'id': '082'
        },
        {
            'name': 'Coop. Aho. y Cred. San Jose S.J.',
            'id': '283'
        },
        {
            'name': 'Coop. Aho. y Cred. San Miguel de Los Bancos',
            'id': '330'
        },
        {
            'name': 'Coop. Aho. y Cred. San Miguel de Sigchos',
            'id': '646'
        },
        {
            'name': 'Coop. Aho. y Cred. Santa Ana Ltda',
            'id': '084'
        },
        {
            'name': 'Coop. Aho. y Cred. Santa Anita Ltda',
            'id': '418'
        },
        {
            'name': 'Coop. Aho. y Cred. Santa Rosa de Patutan Ltda.',
            'id': '334'
        },
        {
            'name': 'Coop. Aho. y Cred. Santa Rosa Ltda',
            'id': '096'
        },
        {
            'name': 'Coop. Aho. y Cred. Semilla del Progreso Ltda',
            'id': '332'
        },
        {
            'name': 'Coop. Aho. y Cred. Senor de Giron',
            'id': '285'
        },
        {
            'name': 'Coop. Aho. y Cred. Sierra Centro Ltda.',
            'id': '647'
        },
        {
            'name': 'Coop. Aho. y Cred. Simiatug Ltda',
            'id': '639'
        },
        {
            'name': 'Coop. Aho. y Cred. Sinchi Runa Ltda',
            'id': '645'
        },
        {
            'name': 'Coop. Aho. y Cred. Sumac Llacta Ltda.',
            'id': '662'
        },
        {
            'name': 'Coop. Aho. y Cred. Tena Ltda.',
            'id': '276'
        },
        {
            'name': 'Coop. Aho. y Cred. Tulcan',
            'id': '222'
        },
        {
            'name': 'Coop. Aho. y Cred. Tungurahua Ltda.',
            'id': '287'
        },
        {
            'name': 'Coop. Aho. y Cred. Union Mercedaria Ltda.',
            'id': '648'
        },
        {
            'name': 'Coop. Aho. y Cred. Valles del Lirio',
            'id': '632'
        },
        {
            'name': 'Coop. Aho. y Cred. Vencedores de Tungurahua',
            'id': '629'
        },
        {
            'name': 'Coop. Calceta Ltda',
            'id': '087'
        },
        {
            'name': 'Coop. Capeco Ltda',
            'id': '203'
        },
        {
            'name': 'Coop. Esfuerzo Unido Para El Desarr. del Chilco La',
            'id': '633'
        },
        {
            'name': 'Coop. Jardin Azuayo',
            'id': '015'
        },
        {
            'name': 'Coop. Juventud Ecuatoriana Progresista Ltda.',
            'id': '091'
        },
        {
            'name': 'Coop. Manuel Esteban Godoy Ortega Ltda Coopmego',
            'id': '092'
        },
        {
            'name': 'Coop. Peq. Empresa de Pastaza',
            'id': '089'
        },
        {
            'name': 'Coop. Policia Nacional',
            'id': '053'
        },
        {
            'name': 'Coop. Prevision Ahorro y Desarrollo',
            'id': '094'
        },
        {
            'name': 'Financiera - Diners Club del Ecuador',
            'id': '232'
        },
        {
            'name': 'Financiera Financoop',
            'id': '097'
        },
        {
            'name': 'Interdin S.A.',
            'id': '349'
        },
        {
            'name': 'Mutualista Ambato',
            'id': '098'
        },
        {
            'name': 'Mutualista Azuay',
            'id': '070'
        },
        {
            'name': 'Mutualista Imbabura',
            'id': '099'
        },
        {
            'name': 'Mutualista Pichincha',
            'id': '071'
        },
        {
            'name': 'Pacificard',
            'id': '005'
        },
        {
            'name': 'BANCO D MIRO SA',
            'id': '063'
        },
        {
            'name': 'BANECUADOR B.P.',
            'id': '066'
        },
        {
            'name': 'BANCO DESARROLLO DE LOS PUEBLOS SA',
            'id': '068'
        },
        {
            'name': 'COOP. DE A. Y C. UNIDAD Y PROGRESO',
            'id': '100'
        },
        {
            'name': 'COOP DE A. Y C. MUSHUK YUYAY LTDA',
            'id': '102'
        },
        {
            'name': 'COOP. DE A. Y C. NIZAG LTDA.',
            'id': '103'
        },
        {
            'name': 'COOP. DE A. Y C. EL BUEN SEMBRADOR LTDA.',
            'id': '104'
        },
        {
            'name': 'COOP.DE AHORRO Y CREDITO 29 DE ENERO',
            'id': '108'
        },
        {
            'name': 'COAC SINDICATO DE CHOFERES PROFESIONALES DE YANTZA',
            'id': '109'
        },
        {
            'name': 'COOP. DE A. Y C. SENOR DEL ARBOL',
            'id': '114'
        },
        {
            'name': 'COOP. DE A. Y C. QUILOTOA',
            'id': '118'
        },
        {
            'name': 'COOP DE A. Y C. 22 DE JUNIO-ORIANGA',
            'id': '119'
        },
        {
            'name': 'COOPERATIVA DE AHORRO Y CREDITO AMBATO LTDA.',
            'id': '122'
        },
        {
            'name': 'COOPERATIVA DE AHORRO Y CREDITO CAMPESINA COOPAC',
            'id': '125'
        },
        {
            'name': 'COOPERATIVA DE AHORRO Y CREDITO SANTA ISABEL LTDA',
            'id': '176'
        },
        {
            'name': 'COOPERATIVA DIVINO NINO',
            'id': '177'
        },
        {
            'name': 'COOPERATIVA LLACTA PURA',
            'id': '178'
        },
        {
            'name': 'COOPERATIVA DE AHORRO Y CREDITO SEMBRANDO FUTURO',
            'id': '179'
        },
        {
            'name': 'COAC AHORRISTA SOLIDARIO',
            'id': '181'
        },
        {
            'name': 'COOP.DE AHORRO Y CREDITO CIUDAD DE ZAMORA',
            'id': '182'
        },
        {
            'name': 'COAC LOJA INTERNACIONAL LTDA.',
            'id': '186'
        },
        {
            'name': 'COOP DE AHORRO Y CREDITO BCE',
            'id': '189'
        },
        {
            'name': 'COOP. DE A. Y C. EL MOLINO LTDA.',
            'id': '193'
        },
        {
            'name': 'COOPERATIVA DE AHORRO Y CREDITO ARMADA NACIONAL',
            'id': '194'
        },
        {
            'name': 'COOPERATIVA DE AHORRO Y CREDITO DISTRITO METROPOL',
            'id': '196'
        },
        {
            'name': 'LA COOPERATIVA DE AH Y CRED SIMON BOLIVAR',
            'id': '198'
        },
        {
            'name': 'COOPERATIVA DE AHORRO Y CREDITO GENERAL ANGEL FLOR',
            'id': '200'
        },
        {
            'name': 'COOP.AHO Y CRED CAMARA DE COMERCIO PINDAL CADECOPI',
            'id': '649'
        },
        {
            'name': 'COOP. DE A. Y C. COOPAC AUSTRO LTDA (MIESS)',
            'id': '666'
        },
        {
            'name': 'FONDO DE CESANTIA DEL MAGISTERIO ECUATORIANO FCME-',
            'id': '667'
        },
        {
            'name': 'COPP AHO CRED CREDISUR',
            'id': '668'
        },
        {
            'name': 'COOP AHO Y CRED MAQUITA CUSHUNCHIC',
            'id': '669'
        },
        {
            'name': 'COOP AHO Y CRED GRAL RUMINAHUI',
            'id': '670'
        },
        {
            'name': 'COOP AHO Y CRED CASEG LTDA',
            'id': '671'
        },
        {
            'name': 'COOP AHO Y CRED FOCLA',
            'id': '672'
        },
        {
            'name': 'COOP AHO Y CRED FINAN INDIGENA',
            'id': '673'
        },
        {
            'name': 'COOP AHO Y CRED 20 DE FEBRERO LTDA',
            'id': '674'
        },
        {
            'name': 'COOP AHO Y CRED INDIGENA ALFA',
            'id': '675'
        },
        {
            'name': 'COOP AHO Y CRED HUAQUILLAS',
            'id': '677'
        }

    ],

    //MEXICO
    'MX': [
        {
            'name': 'ABC Capital',
            'id': '138'
        },
        {
            'name': 'Accival',
            'id': '614'
        },
        {
            'name': 'Actinver',
            'id': '133'
        },
        {
            'name': 'Afirme',
            'id': '062'
        },
        {
            'name': 'Akala',
            'id': '638'
        },
        {
            'name': 'American Express',
            'id': '103'
        },
        {
            'name': 'Asea',
            'id': '652'
        },
        {
            'name': 'Autofin',
            'id': '128'
        },
        {
            'name': 'Azteca',
            'id': '127'
        },
        {
            'name': 'B&B',
            'id': '610'
        },
        {
            'name': 'Bajio',
            'id': '030'
        },
        {
            'name': 'Bamsa',
            'id': '106'
        },
        {
            'name': 'Banamex',
            'id': '002'
        },
        {
            'name': 'Banco Famsa',
            'id': '131'
        },
        {
            'name': 'Bancomext',
            'id': '006'
        },
        {
            'name': 'Bancoppel',
            'id': '137'
        },
        {
            'name': 'Banjercito',
            'id': '019'
        },
        {
            'name': 'Banobras',
            'id': '009'
        },
        {
            'name': 'Banorte',
            'id': '072'
        },
        {
            'name': 'Banregio',
            'id': '058'
        },
        {
            'name': 'Bansefi',
            'id': '166'
        },
        {
            'name': 'Bansi',
            'id': '060'
        },
        {
            'name': 'Barclays',
            'id': '129'
        },
        {
            'name': 'Bbase',
            'id': '145'
        },
        {
            'name': 'BBVA Bancomer',
            'id': '012'
        },
        {
            'name': 'BMonex',
            'id': '112'
        },
        {
            'name': 'BMultiva',
            'id': '132'
        },
        {
            'name': 'Bulltick CB',
            'id': '632'
        },
        {
            'name': 'CB Actinver',
            'id': '621'
        },
        {
            'name': 'CB Intercam',
            'id': '630'
        },
        {
            'name': 'CB JPMorgan',
            'id': '640'
        },
        {
            'name': 'CI Bolsa',
            'id': '631'
        },
        {
            'name': 'Cibanco',
            'id': '143'
        },
        {
            'name': 'CLS',
            'id': '901'
        },
        {
            'name': 'Compartamos',
            'id': '130'
        },
        {
            'name': 'Consubanco',
            'id': '140'
        },
        {
            'name': 'Credit Suisse',
            'id': '126'
        },
        {
            'name': 'Deutsche Bank',
            'id': '124'
        },
        {
            'name': 'Estructuradores',
            'id': '606'
        },
        {
            'name': 'Evercore',
            'id': '648'
        },
        {
            'name': 'Finamex',
            'id': '616'
        },
        {
            'name': 'Fincomun',
            'id': '634'
        },
        {
            'name': 'GBM',
            'id': '601'
        },
        {
            'name': 'HDI Seguros',
            'id': '636'
        },
        {
            'name': 'Hipotecaria Federal',
            'id': '168'
        },
        {
            'name': 'HSBC',
            'id': '021'
        },
        {
            'name': 'Inbursa',
            'id': '036'
        },
        {
            'name': 'Indeval',
            'id': '902'
        },
        {
            'name': 'ING',
            'id': '116'
        },
        {
            'name': 'Interacciones',
            'id': '037'
        },
        {
            'name': 'Interbanco',
            'id': '136'
        },
        {
            'name': 'Invex',
            'id': '059'
        },
        {
            'name': 'IXE',
            'id': '032'
        },
        {
            'name': 'JP Morgan',
            'id': '110'
        },
        {
            'name': 'Kuspit',
            'id': '653'
        },
        {
            'name': 'Libertad',
            'id': '670'
        },
        {
            'name': 'Mapfre',
            'id': '619'
        },
        {
            'name': 'Masari',
            'id': '602'
        },
        {
            'name': 'Merrill Lynch',
            'id': '615'
        },
        {
            'name': 'Mifel',
            'id': '042'
        },
        {
            'name': 'Monexcb',
            'id': '600'
        },
        {
            'name': 'Nafin',
            'id': '135'
        },
        {
            'name': 'Oactin',
            'id': '622'
        },
        {
            'name': 'Opciones Empresariales del Noroeste',
            'id': '659'
        },
        {
            'name': 'Order',
            'id': '637'
        },
        {
            'name': 'Profuturo',
            'id': '620'
        },
        {
            'name': 'Reforma',
            'id': '642'
        },
        {
            'name': 'Santander',
            'id': '014'
        },
        {
            'name': 'Scotiabank',
            'id': '044'
        },
        {
            'name': 'Segmty',
            'id': '651'
        },
        {
            'name': 'Skandia Vida',
            'id': '623'
        },
        {
            'name': 'Skandia Operadora',
            'id': '649'
        },
        {
            'name': 'Sofiexpress',
            'id': '655'
        },
        {
            'name': 'Sterling',
            'id': '633'
        },
        {
            'name': 'STP',
            'id': '646'
        },
        {
            'name': 'Su Casita',
            'id': '629'
        },
        {
            'name': 'Telecomm',
            'id': '647'
        },
        {
            'name': 'The Royal Bank',
            'id': '102'
        },
        {
            'name': 'Tiber',
            'id': '607'
        },
        {
            'name': 'Tokyo',
            'id': '108'
        },
        {
            'name': 'UBS Bank',
            'id': '139'
        },
        {
            'name': 'Unagra',
            'id': '656'
        },
        {
            'name': 'Unica',
            'id': '618'
        },
        {
            'name': 'Valmex',
            'id': '617'
        },
        {
            'name': 'Value',
            'id': '605'
        },
        {
            'name': 'Ve Por Mas',
            'id': '113'
        },
        {
            'name': 'Vector',
            'id': '608'
        },
        {
            'name': 'Volkswagen',
            'id': '141'
        },
        {
            'name': 'Walmart',
            'id': '134'
        },
        {
            'name': 'Zurich',
            'id': '627'
        },
        {
            'name': 'Zurichvi',
            'id': '628'
        },
    ],

    //PANAMA
    'PA': [
        {
            'name': 'BANCO NACIONAL DE PANAMA',
            'id': '1'
        },
        {
            'name': 'BANISTMO S.A.',
            'id': '2'
        },
        {
            'name': 'CITIBANK',
            'id': '3'
        },
        {
            'name': 'BANCO GENERAL',
            'id': '7'
        },
        {
            'name': 'DAVIVIENDA',
            'id': '18'
        },
        {
            'name': 'MULTIBANK',
            'id': '37'
        },
        {
            'name': 'TOWERBANK',
            'id': '40'
        },
        {
            'name': 'SCOTIABANK',
            'id': '42'
        },
        {
            'name': 'BICSA',
            'id': '51'
        },
        {
            'name': 'COOPERATIVA PROFESIONALES',
            'id': '71'
        },
        {
            'name': 'CAJA DE AHORROS',
            'id': '77'
        },
        {
            'name': 'BANCO DEL PACÍFICO',
            'id': '91'
        },
        {
            'name': 'METROBANK',
            'id': '106'
        },
        {
            'name': 'BANCO ALIADO',
            'id': '108'
        },
        {
            'name': 'CREDICORP BANK',
            'id': '110'
        },
        {
            'name': 'GLOBAL BANK',
            'id': '115'
        },
        {
            'name': 'BANK OF CHINA',
            'id': '116'
        },
        {
            'name': 'CANAL BANK',
            'id': '125'
        },
        {
            'name': 'BAC INTERNATIONAL BANK',
            'id': '138'
        },
        {
            'name': 'BCT BANK INTERNATIONAL',
            'id': '139'
        },
        {
            'name': 'MMG BANK',
            'id': '147'
        },
        {
            'name': 'ST. GEORGES BANK',
            'id': '149'
        },
        {
            'name': 'BANCO AZTECA',
            'id': '150'
        },
        {
            'name': 'BANCO PICHINCHA PANAMA',
            'id': '151'
        },
        {
            'name': 'BANCO DELTA',
            'id': '156'
        },
        {
            'name': 'BANCO LAFISE',
            'id': '157'
        },
        {
            'name': 'BANESCO',
            'id': '158'
        },
        {
            'name': 'CAPITAL BANK',
            'id': '159'
        },
        {
            'name': 'BANISI',
            'id': '161'
        },
        {
            'name': 'MERCANTIL BANK',
            'id': '163'
        },
        {
            'name': 'BBP BANK',
            'id': '165'
        },
        {
            'name': 'PRIVAL BANK',
            'id': '167'
        },
        {
            'name': 'UNI BANK & TRUST, INC.',
            'id': '170'
        },
        {
            'name': 'BANCO FICOHSA',
            'id': '172'
        },
        {
            'name': 'BANCOLOMBIA',
            'id': '175'
        },
        {
            'name': 'BI-BANK',
            'id': '178'
        },
        {
            'name': 'COOPEDUC',
            'id': '250'
        },
        {
            'name': 'COOESAN',
            'id': '251'
        },
        {
            'name': 'CACECHI',
            'id': '252'
        },
        {
            'name': 'COEDUCO',
            'id': '253'
        },
        {
            'name': 'COOPEVE',
            'id': '254'
        },
        {
            'name': 'COOPERATIVA CRISTOBAL',
            'id': '500'
        },
        {
            'name': 'EDIOACC',
            'id': '501'
        },
        {
            'name': 'ECASESO',
            'id': '502'
        },
        {
            'name': 'COOPRAC, R.L.',
            'id': '503'
        },
    ],

    //PARAGUAY
    'PY': [
        {
            'name': 'BANCO CENTRAL DEL PARAGUAY',
            'id': '6'
        },
        {
            'name': 'BANCO COMERCIAL PARAGUAYO SA',
            'id': '7'
        },
        {
            'name': 'BANCO CONTINENTAL SAECA',
            'id': '8'
        },
        {
            'name': 'BANCO CORPORACION S.A.',
            'id': '9'
        },
        {
            'name': 'BANCO DE DESARROLLO DEL PARAGUAY S.A.',
            'id': '10'
        },
        {
            'name': 'BANCO DE INVERSIONES DEL PARAGUAY',
            'id': '11'
        },
        {
            'name': 'BANCO DE LA NACION ARGENTINA',
            'id': '12'
        },
        {
            'name': 'BANCO DO BRASIL S.A.',
            'id': '13'
        },
        {
            'name': 'BANCO FAMILIAR S.A.E.C.A.',
            'id': '14'
        },
        {
            'name': 'BANCO FINAMERICA SAECA.',
            'id': '15'
        },
        {
            'name': 'BANCO GENERAL S.A.',
            'id': '16'
        },
        {
            'name': 'BANCO GNB PARAGUAY S.A.',
            'id': '17'
        },
        {
            'name': 'BANCO ITAPUA SAECA',
            'id': '18'
        },
        {
            'name': 'BANCO ITAU PARAGUAY S.A.',
            'id': '19'
        },
        {
            'name': 'BANCO NACIONAL DE FOMENTO',
            'id': '20'
        },
        {
            'name': 'BANCO NACIONAL DE TRABAJADORES',
            'id': '21'
        },
        {
            'name': 'BANCO PARA LA COMERCIALIZACION Y LA PRODUCCION S.A (BANCOP S.A.)',
            'id': '22'
        },
        {
            'name': 'BANCO PARAGUAYO ORIENTAL DE INVERSION Y FOMENTO S.A.',
            'id': '23'
        },
        {
            'name': 'BANCO REGIONAL S.A.E.C.A.',
            'id': '24'
        },
        {
            'name': 'Banco RIO S.A.E.C.A.',
            'id': '25'
        },
        {
            'name': 'BOLPAR SOCIEDAD ANONIMA',
            'id': '26'
        },
        {
            'name': 'BOLSA DE VALORES Y PRODUCTOS DE ASUNCION S.A.',
            'id': '27'
        },
        {
            'name': 'BRITISH AMERICAN TOBACCO PRODUCTORA DE CIGARILLOS S.A.',
            'id': '28'
        },
        {
            'name': 'CITIBANK N.A.',
            'id': '29'
        },
        {
            'name': 'CRISOL Y ENCARNACIN FINANCIERA S.A.',
            'id': '30'
        },
        {
            'name': 'FINANCIERA EL COMERCIO S.A.E.C.A.',
            'id': '31'
        },
        {
            'name': 'FINANCIERA EXPORTADORA PARAGUAYA S.A.',
            'id': '32'
        },
        {
            'name': 'FINANCIERA PARAGUAYO JAPONESA SAECA',
            'id': '33'
        },
        {
            'name': 'FONPLATA - FONDO FINANCIERO PARA EL DESAROLLO DE LA CUENCA DEL PLATA',
            'id': '34'
        },
        {
            'name': 'GRUPO INTERNACIONAL DE FINANZAS S.A .C.A. (INTERFISA FINANCIERA)',
            'id': '35'
        },
        {
            'name': 'HSBC BANK PARAGUAY SA',
            'id': '36'
        },
        {
            'name': 'SUDAMERIS BANK S.A.E.C.A.',
            'id': '37'
        },
        {
            'name': 'SUMMA ASESORIES',
            'id': '38'
        },
        {
            'name': 'VISION BANCO S.A.E.C.A.',
            'id': '39'
        },
        {
            'name': 'FINLANTINA S.A DE FINANZAS',
            'id': '40'
        }
    ],

    //PERU
    'PE': [
        {
            'name': 'Banco Central de Reserva',
            'id': '001'
        },
        {
            'name': 'Banco de Crédito del Perú',
            'id': '002'
        },
        {
            'name': 'Interbank',
            'id': '003'
        },
        {
            'name': 'Citibank',
            'id': '007'
        },
        {
            'name': 'Scotiabank',
            'id': '009'
        },
        {
            'name': 'BBVA Continental',
            'id': '011'
        },
        {
            'name': 'Banco de la Nación',
            'id': '018'
        },
        {
            'name': 'Banco de Comercio',
            'id': '023'
        },
        {
            'name': 'Banco Financiero',
            'id': '035'
        },
        {
            'name': 'Banco Interamericano de Finanzas (BIF)',
            'id': '038'
        },
        {
            'name': 'Crediscotia Financiera',
            'id': '043'
        },
        {
            'name': 'Mi Banco',
            'id': '049'
        },
        {
            'name': 'Banco GNB Perú S.A.',
            'id': '053'
        },
        {
            'name': 'Banco Falabella',
            'id': '054'
        },
        {
            'name': 'Santander',
            'id': '056'
        },
        {
            'name': 'Caja Metropolitana de Lima',
            'id': '800'
        },
        {
            'name': 'Caja Municipal de Ahorro y Crédito Piura SAC',
            'id': '801'
        },
        {
            'name': 'Caja Municipal de Ahorro y Crédito Trujillo',
            'id': '802'
        },
        {
            'name': 'Caja Municipal de Ahorro y Crédito Arequipa',
            'id': '803'
        },
        {
            'name': 'Caja Municipal de Ahorro y Crédito Sullana',
            'id': '805'
        },
        {
            'name': 'Caja Municipal de Ahorro y Crédito Cuzco',
            'id': '806'
        },
        {
            'name': 'Caja Municipal de Ahorro y Crédito Huancayo',
            'id': '808'
        },
        {
            'name': 'Caja Municipal de Ahorro y Crédito Tacna',
            'id': '813'
        }
    ],

    //URUGUAY
    'UY': [
        {
            'name': 'BROU - Banco de la República Oriental del Uruguay',
            'id': '001'
        },
        {
            'name': 'Banco Hipotecario del Uruguay',
            'id': '091'
        },
        {
            'name': 'Banco ITAU',
            'id': '113'
        },
        {
            'name': 'Scotiabank',
            'id': '128'
        },
        {
            'name': 'Banco Santander',
            'id': '137'
        },
        {
            'name': 'Banco Bilbao Vizcaya Argentaria',
            'id': '153'
        },
        {
            'name': 'HSBC Bank',
            'id': '157'
        },
        {
            'name': 'Banque Heritage',
            'id': '162'
        },
        {
            'name': 'Citibank N.A. Sucursal',
            'id': '205'
        },
        {
            'name': 'Banco de la Nación Argentina',
            'id': '246'
        },
        {
            'name': 'BAPRO',
            'id': '61'
        },
        {
            'name': 'Prex',
            'id': '603'
        }
    ],

    //COSTA RICA
    'CR': [{ 'name': 'Banco', 'id': '1' }],
    //EGIPTO
    'EG': [{ 'name': 'Banco', 'id': '1' }],
    //REP. DOMINCANA
    'DO': [{
        'name': 'Banco Popular',
        'id': '1'
    },
    {
        'name': 'Banco del Progreso',
        'id': '2'
    },
    {
        'name': 'Banco BHD León',
        'id': '3'
    },
    {
        'name': 'Banco de Reservas',
        'id': '4'
    },
    {
        'name': 'Banco Santa Cruz',
        'id': '6'
    },
    {
        'name': 'Citibank',
        'id': '7'
    },
    {
        'name': 'Scotiabank',
        'id': '8'
    },
    {
        'name': 'Asoc. Popular',
        'id': '9'
    },
    {
        'name': 'Banco López de Haro',
        'id': '10'
    },
    {
        'name': 'Banco BDI',
        'id': '11'
    },
    {
        'name': 'Banco Promerica',
        'id': '12'
    },
    {
        'name': 'Banco Vimenca',
        'id': '13'
    },
    {
        'name': 'Banco Caribe',
        'id': '14'
    },
    {
        'name': 'Asoc. Cibao',
        'id': '15'
    },
    {
        'name': 'Banco de las Americas',
        'id': '16'
    },
    {
        'name': 'Banesco (Banco Multiple)',
        'id': '17'
    },
    {
        'name': 'Banco de Ahorro y Credito Ademi',
        'id': '18'
    },
    {
        'name': 'Asoc. La Nacional',
        'id': '19'
    },
    {
        'name': 'Banco Multiple Lafise',
        'id': '21'
    },
    {
        'name': 'Banco Empire',
        'id': '23'
    },
    {
        'name': 'Bellbank',
        'id': '24'
    },
    {
        'name': 'Banco Atlántico',
        'id': '25'
    },
    {
        'name': 'Banco Unión',
        'id': '26'
    },
    {
        'name': 'Banco Multiple Activo',
        'id': '28'
    }]



};

export const documentTypeCombo = {
    'AR': [
        { 'type': 'DNI', 'value': '1' },
        { 'type': 'CUIT/CUIL', 'value': '2' }
    ], // AR

    'BR': [
        { 'type': 'CPF', 'value': '1' },
        { 'type': 'CNPJ', 'value': '2' }
    ], // BR

    'CL': [
        { 'type': 'Nro de Identificación Personal', 'value': '1' }
    ],// CL

    'CO': [
        { 'type': 'CC', 'value': '1' },
        { 'type': 'NIT', 'value': '2' },
        { 'type': 'PASS', 'value': '3' },
        { 'type': 'CE', 'value': '4' }
    ],// CO

    'CR': [
        { 'type': 'CI', 'value': '1' },
        { 'type': 'CJ', 'value': '2' },
        { 'type': 'CR', 'value': '3' }
    ], // CR

    'EC': [
        { 'type': 'CI', 'value': '1' },
        { 'type': 'RUC', 'value': '2' },
        { 'type': 'PASS', 'value': '3' }
    ],  // EC

    'EG': [
        { 'type': 'ID NUMBER', 'value': '1' }
    ],// EG

    'MX': [
        { 'type': 'CLABE', 'value': '1' }
    ], // MX

    'PA': [
        { 'type': 'Nro de Identificación Personal', 'value': '1' }
    ], // PA

    'PE': [
        { 'type': 'DNI', 'value': '1' },
        { 'type': 'RUC', 'value': '2' },
        { 'type': 'PASS', 'value': '3' }
    ],  // PE

    'PY': [
        { 'type': 'CI', 'value': '1' },
        { 'type': 'RUC', 'value': '2' }
    ], // PY

    'UY': [
        { 'type': 'CI', 'value': '1' },
        { 'type': 'RUT', 'value': '2' },
        { 'type': 'DE', 'value': '3' },
        { 'type': 'PASS', 'value': '4' }
    ], // UY

    'DO': [
        { 'type': 'RN', 'value': '1' },
        { 'type': 'CE', 'value': '2' },
        { 'type': 'PASS', 'value': '3' },
    ],
};

export const contryPayerIdValidations = {
    'CO': (documentId, documentType) => ValidateCountryDocument.Colombia(documentId, documentType),
    'CR': (documentId, documentType) => ValidateCountryDocument.CostaRica(documentId, documentType),
    'PE': (documentId, documentType) => ValidateCountryDocument.Peru(documentId, documentType),
    'PY': (documentId, documentType) => ValidateCountryDocument.Paraguay(documentId, documentType),
    'EC': (documentId, documentType) => ValidateCountryDocument.Ecuador(documentId, documentType),
    'BR': (documentId) => (isValidCnpj(documentId) || isValidCpf(documentId)),
    'CL': (documentId) => (documentId.length >= 8 && documentId.length <= 9),
    'MX': (documentId) => (documentId.length >= 10 && documentId.length <= 18),
    'UY': (documentId) => (documentId.length === 8 || documentId.length === 12),
    'AR': (documentId) => (documentId.length === 11),
    'EG': (documentId) => (documentId.length === 14),
    'PA': (documentId) => (documentId.length === 8),
};

export const bankAccountValidations = {
    'AR': (payerId) => (payerId.length === 22), // AR CBU/CVU
    'CL': (payerId) => (payerId.length >= 1 && payerId.length <= 45), // CL
    'CO': (payerId) => (payerId.length >= 8 && payerId.length <= 11), // CO
    'CR': (payerId) => (payerId.length >= 1 && payerId.length === 22), // CR
    'EC': (payerId) => (payerId.length >= 1 && payerId.length <= 30), // EC
    'EG': (payerId) => (payerId.length >= 1 && payerId.length === 14), // EG
    'MX': (payerId) => (payerId.length >= 1 && payerId.length === 18), // MX
    'PA': (payerId) => (payerId.length >= 1 && payerId.length <= 45), // PA
    'PE': (payerId) => (payerId.length >= 1 && payerId.length === 20), // PE
    'PY': (payerId) => (payerId.length >= 1 && payerId.length === 16), // PY
    'DO': (payerId) => (payerId.length >= 8 && payerId.length === 17), // DO
    // "BR": payerId => (payerId.length >= 1), // BR validacion externa
    // "UY": payerId => (payerId.length >= 1 ), // UY validacion externa
};


export const branchNameValidations = {
    'BR': {
        '001': {
            'branch': '9999-*',
            'checking': '99999999-9',
            'saving': '**-9999999'
        },

        '033':
        {
            'branch': '9999',
            'checking': '99999999-9',
            'saving': '99999999-9'
        },
        '104':
        {
            'branch': '9999-9',
            'checking': '999.99999999-9',
            'saving': '999.999999999-9'
        },

        '237':
        {
            'branch': '9999-9',
            'checking': '9999999-9',
            'saving': '9999999-9',
        },
        '341':
        {
            'branch': '9999',
            'checking': '99999-9',
            'saving': '99999-9',
        },
        '269':
        {
            'branch': '9999**',
            'checking': '99999-9',
            'saving': '99999-9',
        },
        'default':
        {
            'branch': '9999**',
            'checking': '9999999999999',
            'saving': '9999999999999'
        },
    },

    'UY': {
        '001': { 'branch': '99999999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '091': { 'branch': 'T999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '205': { 'branch': 'T999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '113': { 'branch': '9999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '128': { 'branch': '9999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '137': { 'branch': '999999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '246': { 'branch': '999999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '157': { 'branch': '9999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        '162': { 'branch': '999999999', 'checking': '99999999999999', 'saving': '99999999999999' },
        'default': { 'branch': '99999999999999', 'checking': '99999999999999', 'saving': '99999999999999' }

    }

};

export const dinamicFieldsCountry = {
    'AR': ['document_id', 'beneficiary_name', 'beneficiary_lastname', 'bank_code', 'country', 'bank_account'],
    'CL': ['document_id', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_code', 'bank_account', 'account_type'],
    'CO': ['document_id', 'document_type', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_code', 'bank_account', 'account_type', 'address', 'email', 'phone'],
    'CR': ['document_id', 'document_type', 'beneficiary_name', 'bank_code', 'beneficiary_lastname', 'country', 'bank_account', 'address'],
    'EC': ['document_id', 'document_type', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_code', 'bank_account', 'account_type'],
    'EG': ['beneficiary_name', 'country', 'bank_branch', 'bank_account', 'purpose'],
    'MX': ['beneficiary_name', 'beneficiary_lastname', 'country', 'bank_code', 'bank_account'],
    'PA': ['document_id', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_code', 'bank_account', 'account_type'],
    'PE': ['document_id', 'document_type', 'beneficiary_name', 'beneficiary_lastname', 'bank_code', 'country', 'bank_account', 'account_type'],
    'PY': ['document_id', 'document_type', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_code', 'bank_account', 'currency'],
    'DO': ['document_id', 'document_type', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_code', 'bank_account', 'account_type'],
    'BR': ['document_id', 'document_type', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_branch', 'bank_code', 'bank_account', 'account_type', 'address', 'city', 'purpose', 'remitter_full_name', 'remitter_birth_date', 'remitter_country', 'pix'],
    'UY': ['document_id', 'document_type', 'beneficiary_name', 'beneficiary_lastname', 'country', 'bank_branch', 'bank_code', 'bank_account', 'account_type'],
};


export const accountTypeCombo = [
    { 'value': 'c', 'tag': 'bankAccounts.checking' },
    { 'value': 's', 'tag': 'bankAccounts.saving' }
];

export const currencyComboPY = [
    { 'value': '1', 'tag': 'PYG' },
    { 'value': '2', 'tag': 'USD' }
];


import React from 'react';

import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';

const CodeUsesModal = ({ open, setOpen, users }) => {

    const handleCloseCodeUses = () => {
        setOpen(false);
    };

    return (
        <Dialog onClose={handleCloseCodeUses} aria-labelledby={'simple-dialog-title'} open={open}>
            <DialogTitle id={'simple-dialog-title'}>Listado de usuarios</DialogTitle>

            <List>
                {users.map((c) => (
                    <ListItem key={`user_${c.id}`}>
                        <ListItemText primary={c.email} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default CodeUsesModal;


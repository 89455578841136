import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    _switchBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#F0F0F0',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '269px',
        height: '50px',
        textTransform: 'none',
        fontFamily: [
            'Poppins'
        ],
        [theme.breakpoints.down('md')]: {
            width: 129
        },
    },
    _switchSelectedBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '269px',
        height: '50px',
        textTransform: 'none',
        fontWeight: 700,
        borderTop: '2px #F0F0F0 solid',
        borderLeft: '2px #F0F0F0 solid',
        borderRight: '2px #F0F0F0 solid',
        fontFamily: [
            'Poppins'
        ],
        [theme.breakpoints.down('md')]: {
            width: 129
        },
    },
    _navBarContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: '122px',
        marginLeft: '182px',
        [theme.breakpoints.down('md')]: {
            marginLeft: 1
        },
    },
    _navBarBox: {
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        },
    },
}));

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { LocalizationProvider, PickersDay, StaticDatePicker } from "@material-ui/pickers";
import MomentUtils from '@material-ui/pickers/adapter/moment';
import is from 'is_js';

export const BookingDatesCalendarModal = ({ open, setOpen, booking }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    let bookingDates = booking?.dates?.map(d => moment(d.date)) || [];
    if (is.empty(bookingDates)) bookingDates = [moment(booking.date)];

    return <Dialog open={open} fullScreen={isMobile}>
        <DialogTitle style={{ padding: 15 }}><span style={{ fontFamily: 'Poppins' }}>{`Fechas reservadas (${bookingDates.length})`}</span></DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            <LocalizationProvider dateAdapter={MomentUtils}>
                <StaticDatePicker
                    readOnly={true}
                    displayStaticWrapperAs="desktop"
                    onChange={() => null}
                    value={moment(booking?.date)}
                    renderInput={(props) => <TextField {...props} />}
                    renderDay={(date, _selectedDates, DayComponentProps) => {
                        const isBookedDay = bookingDates.filter(d => d.format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))[0];
                        return <PickersDay {...DayComponentProps} key={date.toString()} style={{ backgroundColor: !!isBookedDay ? '#9d0ace' : '#ffffff', color: !!isBookedDay ? '#ffffff' : '#383839' }} />;
                    }}
                    disableHighlightToday={true} />
            </LocalizationProvider>
        </DialogContent>
        <DialogActions style={{ padding: 15, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Button
                style={{ borderColor: '#00D1BF', color: '#00D1BF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => setOpen(false)}
                color="primary"
                variant="outlined"
                autoFocus>
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>;
};

// External components
import React, { useState, useEffect } from 'react';
import { Backdrop, Box, CircularProgress, ThemeProvider, createTheme } from '@material-ui/core';
import { Router, Switch, Route, Link, Redirect } from 'react-router-dom';

// Utils & Config
import { createBrowserHistory } from 'history';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import './App.css';

// Internal components
import Hosts from './components/Hosts';
import Spaces from './components/Spaces';
import Payments from './components/Payments';
import Users from './components/Users';
import PromoCodes from './components/PromoCodes';
import Bookings from './components/Bookings';
import Companies from './components/Companies';
import PublicationEdit from './components/space/PublicationEdit';
import CompanyPayments from './components/CompanyPayments';
import RevenueList from './components/RevenueList';
import KPI from './components/pages/kpi/KPI';
import Navigator from './components/organisms/Navigator';
import Allies from './components/pages/Allies';
import Store from './components/pages/Store';
import Transactions from './components/pages/Transactions';
import MRR from './components/pages/MRR';
import RequestedSpacePriceChanges from './components/pages/RequestedSpacePriceChanges';
import SmartOffice from './components/pages/SmartOffice';
import SmartBooking from './components/pages/SmartBooking';

export const history = createBrowserHistory();

const theme = createTheme({
    palette: {
        primary: {
            main: '#7647cd'
        },
        secondary: {
            main: '#BD7CF3'
        },
    }
});

function App() {
    const { isLoading, getAccessTokenSilently, user, isAuthenticated, logout } = useAuth0();

    const [appReady, setAppReady] = useState(false);

    const userRoles = user?.[process.env.REACT_APP_AUTH0_NAMESPACE + 'roles'];
    const isReadOnlyRole = !userRoles?.includes('admin');

    useEffect(() => {
        async function getToken() {
            // get access_token
            const access_token = isAuthenticated ? await getAccessTokenSilently() : null;

            // set interceptor to use access_token on every request
            axios.interceptors.request.use(
                (config) => {
                    config.headers.Authorization = 'Bearer ' + access_token;
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
            setAppReady(true); // flag app as ready
        }

        if (!isLoading) getToken();

    }, [appReady, isLoading, getAccessTokenSilently, isAuthenticated]);

    if (isLoading || !appReady) {
        return <Backdrop open={true}><CircularProgress /></Backdrop>;
    }

    let params = new URLSearchParams(window.location.search);
    let error = params.get('error');
    let location = history.location.pathname;

    if (!isAuthenticated && error === 'unauthorized') {
        logout({ returnTo: window.location.origin + '/unauthorized' });
        return <></>;
    }

    if (isReadOnlyRole && !location.includes('/unauthorized')) {
        history.push('/');
    }

    return (
        <div className={'App'}>
            <ThemeProvider theme={theme}>

                <Router history={history}>
                    <Navigator isReadOnlyRole={isReadOnlyRole} />

                    <Box style={{ marginTop: '84px' }}>
                        <Switch>
                            <Route path={'/unauthorized'}>
                                <div style={{ margin: 20 }}>Este sitio es solo para Administradores de OFFI. Su usuario no tiene autorización para acceder.</div>
                                <Link to={'/'}>Home</Link>
                            </Route>

                            <ProtectedRoute path={'/'} exact component={Transactions} />
                            <ProtectedRoute path={'/hosts'} exact component={Hosts} />
                            <ProtectedRoute path={'/spaces'} exact component={Spaces} />
                            <ProtectedRoute path={'/payments'} exact component={Payments} />
                            <ProtectedRoute path={'/users'} exact component={Users} />
                            <ProtectedRoute path={'/promocodes'} exact component={PromoCodes} />
                            <ProtectedRoute path={'/transactions'} exact component={Transactions} />
                            <ProtectedRoute path={'/companies'} exact component={Companies} />
                            <ProtectedRoute path={'/publication/edit/:spaceId/:initialTab'} exact component={PublicationEdit} />
                            <ProtectedRoute path={'/publication/edit/:spaceId'} exact component={PublicationEdit} />
                            <ProtectedRoute path={'/companypayments'} exact component={CompanyPayments} />
                            <ProtectedRoute path={'/revenue'} exact component={RevenueList} />
                            <ProtectedRoute path={'/allies'} exact component={Allies} />
                            <ProtectedRoute path={'/store'} exact component={Store} />
                            <ProtectedRoute path={'/mrr'} exact component={MRR} />
                            <ProtectedRoute path={'/price-changes/:searchQuery?'} exact component={RequestedSpacePriceChanges} />
                            <ProtectedRoute path={'/smart-office'} exact component={SmartOffice} />
                            <ProtectedRoute path={'/smart-booking'} exact component={SmartBooking} />
                        </Switch>
                    </Box>
                </Router>
            </ThemeProvider>
        </div>
    );
}

export default App;

import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { PriceDisplay } from "./atoms/PriceDisplay";
import MaterialTable from 'material-table'
import TableIcons from "./molecules/TableIcons";
import * as moment from 'moment';
import 'moment/locale/pt-br';
import AdminService from '../services/AdminService'
import { useMediaQuery } from 'react-responsive';

const RevenueList = () => {
    moment.locale('en');

    const [revenues, setRevenues] = useState([]);
    const [isLoading, setIsLoading] = useState();


    const dateFormat = (string) => {
        let date = '';
        date = moment.utc(string).format('MMMM YYYY');
        return date;
    };

    const getRevenues = async () => {
        setIsLoading(true);
        try {
            const resp = await AdminService.getRevenues('USD');
            const revs = resp?.data || [];

            // filter: 
            //      - months before February 2021, 
            //      - future months with amount 0
            setRevenues(revs
                .filter(r => new Date(r.month) > new Date('2021-01-31'))
                .filter(r => !(new Date(r.month) > new Date() && +r.monthly_revenue <= 0))
            );
        }
        catch (e) { }
        setIsLoading(false);
    };

    useEffect(() => {
        getRevenues()
    }, []);

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <Box>
            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                {isLoading ? <> <Box style={{ marginTop: '200px' }}><CircularProgress /></Box> </> :
                    <MaterialTable
                        title={'GMV'}
                        icons={TableIcons}
                        columns={[
                            {
                                title: 'Period', render: b => <Box>{`${(dateFormat(b.month))}`}</Box>,
                                customSort: (a, b) => {
                                    const aDate = a.month
                                    const bDate = b.month
                                    if (aDate < bDate) return -1;
                                    if (aDate > bDate) return 1;
                                    return 0;
                                },
                                defaultSort: 'desc',
                                width: '200px'
                            },
                            {
                                title: 'GMV (in USD)', render: b => <Box style={{ textAlign: 'center', width: 90 }}>
                                    <PriceDisplay value={b.monthly_revenue} currency={'$'} />
                                </Box>,
                                customSort: (a, b) => {
                                    return a.monthly_revenue - b.monthly_revenue
                                }
                            },


                        ]}
                        data={revenues}
                        options={{
                            pageSize: 20,
                            search: false,
                            sorting: true,
                            cellStyle: {
                                padding: '6px 12px'
                            }
                        }}
                        localization={{
                            header: {
                                actions: ' '
                            },
                            body: {
                                emptyDataSourceMessage: ' ',
                            }
                        }}
                    />
                }
            </div>
        </Box>
    )
}

export default RevenueList
// External components
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import React, { useEffect, useState } from 'react';

// Utils & Config
import { BlockServiceTypeEnum, blockServiceTypeLabels } from '../../helpers/enums/BlockServiceTypeEnum';
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import { SmartBookingStatusEnum } from '../../helpers/enums/SmartBookingStatusEnum';
import DataService from '../../services/DataService';
import SmartOfficeService from '../../services/SmartOfficeService';
import { PriceDisplay } from '../atoms/PriceDisplay';
import EmployeeSmartBookingModal from './EmployeeSmartBookingModal';

const actionsDictionary = {
    [SmartBookingStatusEnum.CREATED]: 'Creado',
    [SmartBookingStatusEnum.APPROVED]: 'Aprobado',
    [SmartBookingStatusEnum.CANCELLED]: 'Cancelado',
    [SmartBookingStatusEnum.FINISHED]: 'Finalizado',
};

const OpenModalEnum = {
    NONE: null,
    EMPLOYEE_BOOKINGS: 'EMPLOYEE_BOOKINGS',
};

const OpenDatePickerEnum = {
    NONE: null,
    DATE_FROM: 'DATE_FROM',
    DATE_TO: 'DATE_TO',
};

const SmartBookingDetailModal = ({ open, setOpen, smartBooking, onConfirm }) => {
    const [openModal, setOpenModal] = useState(OpenModalEnum.NONE);

    const [reqStatus, setReqStatus] = useState(ResponseStatusEnum.IDLE);
    const [spaceServicesMap, setSpaceServicesMap] = useState({});
    const [datePickerOpen, setDatePickerOpen] = useState(OpenDatePickerEnum.NONE);

    const [updateBookingReqBody, setUpdateBookingReqBody] = useState({
        dateFrom: smartBooking.dateFrom,
        dateTo: smartBooking.dateTo,
        status: smartBooking.status
    });

    const handleConfirm = async () => {
        const confirm = window.confirm('¿Confirmar los cambios realizados?');

        if (!confirm) return;

        setReqStatus(ResponseStatusEnum.LOADING);

        try {
            await SmartOfficeService.updateBooking(smartBooking.id, updateBookingReqBody);
            setReqStatus(ResponseStatusEnum.SUCCESS);
            await onConfirm();
            setOpen(null);
        } catch (error) {
            setReqStatus(ResponseStatusEnum.ERROR);
        }
    };

    const handleFormValues = (key, value) => {
        setUpdateBookingReqBody({ ...updateBookingReqBody, [key]: value });
    };

    const getAvailableServices = async () => {
        const res = await DataService.getSpaceServices();
        const servicesMap = res.data.reduce((acc, service) => {
            acc[service.id] = service;
            return acc;
        });
        setSpaceServicesMap(servicesMap);
    };

    useEffect(() => {
        getAvailableServices();
    }, []);

    // eslint-disable-next-line no-undef
    const flattenedBookingBlockServices = [...new Set(
        smartBooking?.bookingBlocks
            ?.map((b) => b?.selectedServices)
            .flat()
            .sort((a, b) =>  a.blockService.type.localeCompare(b.blockService.type))
    )];

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(null)}
            maxWidth={'lg'}
            fullWidth
            disableEnforceFocus
            disableAutoFocus
            disablePortal
        >
            {openModal === OpenModalEnum.EMPLOYEE_BOOKINGS && (
                <EmployeeSmartBookingModal
                    open={openModal === OpenModalEnum.EMPLOYEE_BOOKINGS}
                    setOpen={setOpenModal}
                    smartBooking={smartBooking}
                />
            )}

            <DialogTitle>
                Smart Booking <b>#{smartBooking.id}</b> - Smart Office #<b>{smartBooking?.smartOffice?.id}</b> &ldquo;{smartBooking?.smartOffice?.name}&ldquo;

                <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <Avatar src={smartBooking?.company?.avatar?.original_url} />
                    <Typography>{smartBooking?.company?.name} (#{smartBooking?.company?.id})</Typography>
                </Box>
            </DialogTitle>


            <DialogContent>
                <h3>Servicios</h3>

                {!flattenedBookingBlockServices.length
                    ? <h3>No posee</h3>
                    : <TableContainer component={Paper}>
                        <Table>
                            <caption>Total: {flattenedBookingBlockServices.reduce((acc, bbs) => acc + bbs.totalPrice, 0)}</caption>

                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Precio Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {flattenedBookingBlockServices.map((bbs) => (
                                    <TableRow key={bbs.name}>
                                        <TableCell>
                                            <Box style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                                <img
                                                    src={spaceServicesMap[bbs.blockService.service.id]?.image?.url}
                                                    style={{ height: '20px', width: '20px', objectFit: 'scale-down' }}
                                                />

                                                <Typography>
                                                    {bbs.blockService.service.description}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {bbs.blockService.type === BlockServiceTypeEnum.FREE
                                                    ? 'Incluído sin costo'
                                                    : `${blockServiceTypeLabels[bbs.blockService.type]}`
                                                }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{bbs.dailyAvailableQuantity}</TableCell>
                                        <TableCell><PriceDisplay value={bbs.totalPrice} currency={'USD'}/></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }

                <Divider style={{ margin: '16px 0' }} />

                <h3>Fechas</h3>

                <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <ClickAwayListener
                        onClickAway={() => datePickerOpen === OpenDatePickerEnum.DATE_FROM && setDatePickerOpen(OpenDatePickerEnum.NONE)}
                        mouseEvent={'onMouseDown'}
                    >
                        <Box>
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <DatePicker
                                    onOpen={() => setDatePickerOpen(OpenDatePickerEnum.DATE_FROM)}
                                    onClose={() => setDatePickerOpen(OpenDatePickerEnum.NONE)}
                                    open={datePickerOpen === OpenDatePickerEnum.DATE_FROM}
                                    format={'DD/MM/yyyy'}
                                    value={updateBookingReqBody.dateFrom}
                                    onChange={(d) => setUpdateBookingReqBody((prev) => ({ ...prev, dateFrom: d.toDate() }))}
                                    autoOk
                                    variant={'inline'}
                                    disabled={false}
                                    PopperProps={{ disablePortal: true }}
                                    shouldDisableDate={(d) => d > updateBookingReqBody.dateTo}
                                    renderInput={(props) => (
                                        <TextField
                                            variant={'outlined'}
                                            {...props}
                                            helperText={''}
                                            label={'Desde'}
                                            error={false}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                    </ClickAwayListener>

                    <ClickAwayListener
                        onClickAway={() => datePickerOpen === OpenDatePickerEnum.DATE_TO && setDatePickerOpen(OpenDatePickerEnum.NONE)}
                        mouseEvent={'onMouseDown'}
                    >
                        <Box>
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <DatePicker
                                    onOpen={() => setDatePickerOpen(OpenDatePickerEnum.DATE_TO)}
                                    onClose={() => setDatePickerOpen(OpenDatePickerEnum.NONE)}
                                    open={datePickerOpen === OpenDatePickerEnum.DATE_TO}
                                    format={'DD/MM/yyyy'}
                                    value={updateBookingReqBody.dateTo}
                                    onChange={(d) => setUpdateBookingReqBody((prev) => ({ ...prev, dateTo: d.toDate() }))}
                                    autoOk
                                    variant={'inline'}
                                    disabled={false}
                                    PopperProps={{ disablePortal: true }}
                                    shouldDisableDate={(d) => d < updateBookingReqBody.dateFrom}
                                    renderInput={(props) => (
                                        <TextField
                                            variant={'outlined'}
                                            {...props}
                                            helperText={''}
                                            label={'Hasta'}
                                            error={false}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                    </ClickAwayListener>
                </Box>

                <Divider style={{ margin: '16px 0' }} />

                <Box>
                    <h3>Sub Bookings</h3>

                    <Button
                        color={'primary'}
                        onClick={() => setOpenModal(OpenModalEnum.EMPLOYEE_BOOKINGS)}
                    >
                        Ver Sub Bookings
                    </Button>
                </Box>

                <Divider style={{ margin: '16px 0' }} />

                <Box>
                    <h3>Cambiar a estado</h3>

                    <RadioGroup
                        value={updateBookingReqBody.status}
                        onChange={(e, value) => handleFormValues('status', value)}
                    >
                        {Object.keys(SmartBookingStatusEnum).map((key) => (
                            <FormControlLabel
                                key={key}
                                value={key}
                                control={<Radio />}
                                label={actionsDictionary[key]}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {reqStatus === ResponseStatusEnum.LOADING &&
                        <CircularProgress size={25} />
                    }

                    {reqStatus === ResponseStatusEnum.ERROR &&
                        <Typography color={'error'}>
                            Hubo un error
                        </Typography>
                    }
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={() => setOpen(null)}
                        disabled={reqStatus === ResponseStatusEnum.LOADING}
                    >
                        Cerrar
                    </Button>

                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={handleConfirm}
                        disabled={reqStatus === ResponseStatusEnum.LOADING}
                    >
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default SmartBookingDetailModal;

// External components
import React, { useEffect, useState } from "react";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import MaterialTable from "material-table";

// Internal components
import TableIcons from "../molecules/TableIcons";
import { PriceDisplay } from "../atoms/PriceDisplay";

// Utils & Config
import moment from 'moment';
import { ResponseStatusEnum } from "../../helpers/enums/ResponseStatusEnum";
import { CompanyExpendInUSDService } from "../../services/CompanyExpendInUSDService";
import { useMediaQuery } from "react-responsive";
import { getMonthsBetween } from "../../helpers/funcs/getMonthsBetween";

const MRR = () => {
    const startMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    const currentMonth = moment().startOf('month').format('YYYY-MM-DD')

    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [request, setRequest] = useState({ status: ResponseStatusEnum.IDLE, data: null })

    const getData = async (selectedMonth) => {
        setRequest({ status: ResponseStatusEnum.LOADING, data: null })

        try {
            const res = await CompanyExpendInUSDService.getExpendByMonth(selectedMonth);
            setRequest({ status: ResponseStatusEnum.SUCCESS, data: res.data })
        } catch (error) {
            setRequest({ status: ResponseStatusEnum.ERROR, data: null })
        }
    }

    useEffect(() => {
        getData(selectedMonth)
    }, [selectedMonth])

    const months = getMonthsBetween(startMonth, currentMonth).map((month) => moment(month).format('YYYY-MM-DD'));
    const customFilterAndSearch = (term, row) => `${row.company.id} ${row.company.name}`.toLowerCase().includes(term.toLowerCase())

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1080px)' });

    return (
        <Box style={{ marginLeft: isNarrowWidth ? 5 : '170px', padding: 24 }}>
          <FormControl variant="outlined">
                <Select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    style={{ textTransform: 'capitalize' }}
                >
                    {months.map((month) => (
                        <MenuItem
                            style={{ textTransform: 'capitalize' }}
                            value={moment(month).format('YYYY-MM-DD')}
                        >
                            {moment(month).format("MMMM YYYY")}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <MaterialTable
                title={''}
                data={request.data?.summary ? [request.data.summary] : []}
                icons={TableIcons}
                isLoading={request.status === ResponseStatusEnum.LOADING}
                options={{ pageSize: 1, sorting: false }}
                style={{ marginBottom: '24px' }}
                columns={[
                    { title: 'Suscripción', render: row => <PriceDisplay value={row.subscriptionValue} currency={"USD"}/> },
                    { title: 'Transaccional Empresa', render: row => <PriceDisplay value={row.transactionalCompany} currency={"USD"}/> },
                    { title: 'Transaccional Empleados', render: row => <PriceDisplay value={row.transactionalEmployees} currency={"USD"}/> },
                    { title: 'Cantidad de empleados', field: 'employeesCount', },
                ]}
                components={{
                    Toolbar: props => <Typography variant={'h5'} style={{ textAlign: 'left', padding: '8px' }}>Resumen</Typography>,
                    Pagination: props => null
                }}
                localization={{
                    header: { actions: '' },
                    body: { emptyDataSourceMessage: 'No hay datos para mostrar.' }
                }}
            />

            <MaterialTable
                title={''}
                data={request.data?.data ? request.data.data : []}
                icons={TableIcons}
                isLoading={request.status === ResponseStatusEnum.LOADING}
                options={{ pageSize: 50, search: true }}
                columns={[
                    {
                        title: 'ID de Empresa',
                        field: 'company.id',
                        render: row => <div>#{row.company.id}</div>,
                        customFilterAndSearch
                    },
                    {
                        title: 'Empresa',
                        field: 'company.name',
                    },
                    {
                        title: 'Tipo',
                        field: 'company.type',
                        render: row => <div style={{ textTransform: 'capitalize' }}>{row.company?.type?.toLowerCase()}</div>
                    },
                    {
                        title: 'Suscripción',
                        field: 'subscriptionValue',
                        render: row => <PriceDisplay value={row.subscriptionValue} currency={"USD"}/>
                    },
                    {
                        title: 'Transaccional Empresa',
                        field: 'transactionalCompany',
                        render: row => <PriceDisplay value={row.transactionalCompany} currency={"USD"}/>
                    },
                    {
                        title: 'Transaccional Empleados',
                        field: 'transactionalEmployees',
                        render: row => <PriceDisplay value={row.transactionalEmployees} currency={"USD"}/>
                    },
                    {
                        title: 'Cantidad de empleados',
                        field: 'employeesCount',
                    },
                    {
                        title: 'Cantidad de empleados pendientes',
                        field: 'pendingEmployeesCount',
                    },
                ]}
                localization={{
                    header: { actions: '' },
                    toolbar: { searchPlaceholder: 'Buscar...' },
                    body: { emptyDataSourceMessage: 'No hay datos para mostrar.' }
                }}
            />
        </Box>
    )

};

export default MRR;

// External Components
import React from 'react';
import { Box, CircularProgress, IconButton, TextField, } from '@material-ui/core';
import NumberFormat from 'react-number-format';

// Images & Icons
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { PriceDisplay } from '../atoms/PriceDisplay';

// Internal Components


const MoneyInput = (props) => {
    const { isUpdating,
        readOnly,
        inputValue,
        displayValue,
        onInputValueChange,
        onEditClick,
        isEditing,
        currency,
        disableButton,
        onClickConfirm,
        style } = props;

    return (
        <>
            {isUpdating
                ? <CircularProgress style={{ marginLeft: 3 }} size={28} />
                :
                <Box style={style}>
                    <>
                        {!readOnly && isEditing ?
                            <NumberFormat
                                value={inputValue}
                                onValueChange={onInputValueChange}
                                customInput={TextField}
                                prefix={`${currency.toUpperCase()} `}
                                decimalSeparator={','}
                                thousandSeparator={'.'}
                                inputProps={{ min: 0, style: { fontSize: 16, fontWeight: 900 } }}
                                InputLabelProps={{ shrink: true, }}
                            />
                            :
                            <PriceDisplay value={displayValue} currency={currency} />
                        }
                        {!readOnly &&
                            <IconButton
                                onClick={onEditClick}
                                disabled={disableButton}
                            >
                                {isEditing
                                    ? <CloseIcon style={{ color: 'red' }} />
                                    : <EditIcon style={{ color: '#9900CC' }} />
                                }
                            </IconButton>

                        }
                        {!readOnly && isEditing &&
                            <IconButton onClick={onClickConfirm} disabled={disableButton}>
                                <CheckIcon style={{ color: 'green' }} />
                            </IconButton>
                        }
                    </>
                </Box>
            }</>


    );
};


export default MoneyInput;



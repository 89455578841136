export const PaymentConceptEnum = {
    BOOKING: 0,
    REFERRED: 1,
    PROMOCODE: 2,
    COMPANY: 3,
    HOST_PAYOUT_FEE: 4,
    COINS: 5,
    HOST_SERVICE: 6,
    POINTS: 7,
};

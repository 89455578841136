// External components
import {
    Box,
    Button, Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

// Icons & Images
import AddBoxIcon from "@material-ui/icons/AddBox";

// Utils & Config
import { useMediaQuery } from "react-responsive";
import AlliesService from "../../services/AlliesService";
import { useStyles } from "./allies.styles";

// Internal components
import SearchFilter from "../../components/atoms/SearchFilter";
import AlliesTableCell from "../molecules/Allies/AlliesTableCell";
import BenefitsTablecell from "../molecules/Allies/BenefitsTableCell";
import CountrySelector from "../molecules/Allies/CountrySelector";

import { LangSelector } from "../atoms/Allies/LangSelector";
import DetailPerkModal from "../modals/Allies/DetailPerkModal";
import NewAlliesModal from "../modals/Allies/NewAlliesModal";
import NewBenefitModal from "../modals/Allies/NewBenefitModal";

const allyTypeEnum = {
    ALLY_NAME: "ALLY_NAME",
    ALLY_SHORT_DESC: "ALLY_SHORT_DESC"
};

const Allies = () => {

    const isNarrowWidth = useMediaQuery({ query: 'max-width: 800px' });
    const isMobile = useMediaQuery({ query: 'max-width: 800px' });

    const [benefitView, setBenefitView] = useState(false);
    const [allies, setAllies] = useState([]);
    const [perks, setPerks] = useState([]);
    const itemsToShow = benefitView ? perks : allies;

    const [filterText, setFilterText] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedLang, setSelectedLang] = useState("es_ar");


    const [openForm, setOpenForm] = useState(false);
    const [loader, setLoader] = useState(false);

    const getData = async () => {
        setLoader(true);
        let country = benefitView ? selectedCountry : 0;

        let data = {
            country: country,
            language: selectedLang,
            text: filterText,
            status: "",
            tags_id: []
        };
        if (benefitView) {
            await AlliesService.getPerks(data)
                .then((resp) => setPerks(resp.data))
                .then((resp) => setLoader(false));
        }
        else {
            await AlliesService.getAllies(data)
                .then((resp) => setAllies(resp.data))
                .then((resp) => setLoader(false));
        }
    };
    const [newPerkInstance, setNewPerkInstance] = useState(false);
    const [selectedAllyId, setSelectedAllyId] = useState('');

    const addPerkOnTable = (data) => {
        setSelectedAllyId(data);
        setNewPerkInstance(true);
        selectedAllyId && setOpenVisualizer(true);
    };

    const tableTitles = () => {
        if (benefitView) return ['Editar', 'ID', 'Nombre del Aliado', 'País', 'URL', 'Categorias', 'PromoCode', 'Descripción', 'Activo', 'Contra Perk'];
        if (!benefitView) return ['Editar', 'ID', '', 'Nombre de la empresa', 'Descripcion del aliado', 'URL', 'Beneficios', 'Activo'];
    };

    const [openPerkVisualizer, setOpenVisualizer] = useState(false);

    useEffect(() => {
        getData();
    }, [benefitView, filterText, selectedCountry, selectedLang]);

    // style
    const {
        _switchBtn,
        _switchSelectedBtn,
        _navBarContainer,
        _navBarBox,
        _subNavbarContainer,
        _newAllyBtn,
        _leftBtnsContainer,
        _searchFilterContainer,
        _rigthBtnsPanel
    } = useStyles();

    const allyBtnClass = benefitView ? _switchBtn : _switchSelectedBtn;
    const perkBtnClass = benefitView ? _switchSelectedBtn : _switchBtn;

    const [detailPerkModal, setDetailPerkModal] = useState(false);
    const [detailPerks, setDetailPerks] = useState([]);

    const showAllyPerks = (id) => {
        let aux = allies.filter(a => a.id == id)[0].ally_perks;

        setDetailPerks(aux);
        setDetailPerkModal(true);
    };

    const [dataToEdit, setDataToEdit] = useState(null);
    const [editInstance, setEditInstance] = useState(false);

    // format data to edit
    const joinToEditData = async (data) => {
        let aux = {};
        let id = data.id;
        if (benefitView) {
            const resp = await AlliesService.getPerkDictionary(id);
            let dictionary = resp.data;
            let tagArray = data.ally_tags.map((tag) => (tag.id));
            aux.benefitDescription_es = dictionary?.filter(a => a.type == "ALLY_PERK_BENEFIT" && a.language == "es_ar")[0].value;
            aux.benefitDescription_en = dictionary?.filter(a => a.type == "ALLY_PERK_BENEFIT" && a.language == "en_us")[0].value;
            aux.benefitDescription_pt = dictionary?.filter(a => a.type == "ALLY_PERK_BENEFIT" && a.language == "pt_br")[0].value;
            aux.benefitLongDescription_es = dictionary?.filter(a => a.type == "ALLY_LONG_PERK_BENEFIT" && a.language == "es_ar")[0]?.value;
            aux.benefitLongDescription_en = dictionary?.filter(a => a.type == "ALLY_LONG_PERK_BENEFIT" && a.language == "en_us")[0]?.value;
            aux.benefitLongDescription_pt = dictionary?.filter(a => a.type == "ALLY_LONG_PERK_BENEFIT" && a.language == "pt_br")[0]?.value;
            aux.promotional_code = data.promotional_code;
            aux.country_id = data.country.id;
            aux.benefitUrl = data.ally_benefit_url;
            aux.tags_id = tagArray;
            aux.allyId = data.ally.id;
            aux.id = data.id;
            aux.promocode = data?.promocode;
            aux.is_contra_perk = data?.is_contra_perk

            setDataToEdit(aux);
            setOpenVisualizer(true);
        }
        else {
            const resp = await AlliesService.getAllyDictionary(id);
            let dictionary = resp.data;
            aux.company_name_es = dictionary?.filter(a => a.type == allyTypeEnum.ALLY_NAME && a.language == "es_ar")[0].value;
            aux.company_name_en = dictionary?.filter(a => a.type == allyTypeEnum.ALLY_NAME && a.language == "en_us")[0].value;
            aux.company_name_pt = dictionary?.filter(a => a.type == allyTypeEnum.ALLY_NAME && a.language == "pt_br")[0].value;
            aux.short_description_es = dictionary?.filter(a => a.type == allyTypeEnum.ALLY_SHORT_DESC && a.language == "es_ar")[0].value;
            aux.short_description_en = dictionary?.filter(a => a.type == allyTypeEnum.ALLY_SHORT_DESC && a.language == "en_us")[0].value;
            aux.short_description_pt = dictionary?.filter(a => a.type == allyTypeEnum.ALLY_SHORT_DESC && a.language == "pt_br")[0].value;
            aux.id = data.id;
            aux.landing_url = data.ally_landing_url;
            aux.ally_logo = data.ally_logo;
            aux.hash = data.hash_logo;
            setDataToEdit(aux);
            setOpenForm(true);

        }
    };

    const openEditModal = async (data) => {
        setEditInstance(true);
        joinToEditData(data);
    };

    const resetEdit = () => {
        setDataToEdit(null);
        setNewPerkInstance(false);
    };
    return <>
        <Box style={{ width: '100%' }}>

            {/* <Box><span>TITULO</span></Box> */}
            <Box className={_navBarContainer}>

                <Box className={_navBarBox}>
                    <Box
                        className={allyBtnClass}
                        onClick={() => { setBenefitView(false); }}>
                        ALIADOS
                    </Box>
                    <Box className={perkBtnClass}
                        onClick={() => { setBenefitView(true); }}>
                        BENEFICIO
                    </Box>
                </Box>
            </Box>

            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                <Box className={_subNavbarContainer}>

                    <Box className={_leftBtnsContainer}>

                        {!benefitView &&
                            <Button
                                color='primary'
                                variant='contained'
                                className={_newAllyBtn}
                                onClick={() => {
                                    resetEdit();
                                    setOpenForm(true);
                                }}
                            >
                                <AddBoxIcon style={{ marginRight: 10, color: 'black' }} />
                                Agregar Aliado
                            </Button>
                        }

                    </Box>
                    <Box className={_rigthBtnsPanel}>

                        {benefitView
                            ? <CountrySelector selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} noTitle={true} isFilter={true} />
                            : <LangSelector lang={selectedLang} setLang={setSelectedLang} />
                        }

                        <Box className={_searchFilterContainer}>
                            <SearchFilter filterText={filterText} setFilterText={setFilterText} />
                        </Box>
                    </Box>

                    <NewAlliesModal
                        benefitView={benefitView}
                        refreshList={getData}
                        open={openForm}
                        setOpen={setOpenForm}
                        perkInstance={newPerkInstance}
                        id={selectedAllyId}
                        dataToEdit={dataToEdit}
                        editInstance={editInstance}
                        setEditInstance={setEditInstance}
                    />

                    <NewBenefitModal
                        benefitView={benefitView}
                        refreshList={getData}
                        open={openPerkVisualizer}
                        setOpen={setOpenVisualizer}
                        allyId={selectedAllyId}
                        dataToEdit={dataToEdit}
                        editInstance={editInstance}
                        setEditInstance={setEditInstance}
                    />

                    <DetailPerkModal
                        open={detailPerkModal}
                        setOpen={setDetailPerkModal}
                        perks={detailPerks}
                    />

                </Box>


                {loader ? <Box>Cargando ...</Box>
                    :
                    <Box overflow={isNarrowWidth ? 'scroll' : ''}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableTitles().map((title, i) => (
                                        <TableCell align='center' key={i}>
                                            <b>{title}</b>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {itemsToShow
                                    .sort((a, b) => b.id - a.id)
                                    .map((c
                                    ) => (
                                        <TableRow hover key={c.id + "_code"}>
                                            {
                                                benefitView ?

                                                    <BenefitsTablecell
                                                        data={c}
                                                        openEditModal={openEditModal}
                                                    />

                                                    :

                                                    <AlliesTableCell
                                                        data={c}
                                                        openPerkVisualizer={() => setOpenVisualizer()}
                                                        addPerk={(value) => addPerkOnTable(value)}
                                                        showAllyPerks={showAllyPerks}
                                                        openEditModal={openEditModal}
                                                        resetEdit={resetEdit} />
                                            }
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Box>}
            </div>
        </Box>
    </>;
};

export default Allies;

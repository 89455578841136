import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import { Toolbar, Box, Avatar } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import { useMediaQuery } from 'react-responsive';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ApartmentIcon from '@material-ui/icons/Apartment';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';

const NavigationDrawer = ({ drawerOpen, toggleDrawer, isReadOnlyRole, isNavbarInViewport }) => {
    const { isAuthenticated, user } = useAuth0();
    const history = useHistory();
    const { pathname } = useLocation();

    const isActive = (path) => path === pathname;
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1080px)' });
    const { avatarContainer, avatar, listContainer } = useStyles({ isNarrowWidth, isNavbarInViewport });

    return (
        <Drawer
            variant={isNarrowWidth ? 'temporary' : 'permanent'}
            anchor={'left'}
            className={'drawer'}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
        >

            {/* Shitty fix to hide list's margin top on scroll */}
            {!isNarrowWidth && isNavbarInViewport &&
                <Toolbar />
            }

            {isAuthenticated && isNarrowWidth &&
                <Box className={avatarContainer}>
                    <Avatar className={avatar} alt={user.name} src={user.picture} />
                </Box>
            }

            <List className={listContainer}>

                {/*
                <ListItem selected={isActive('/')} button onClick={() => history.push('/')}>
                    <ListItemIcon><EqualizerIcon /></ListItemIcon>
                    <ListItemText primary={'KPIs'} />
                </ListItem>
                */}


                {!isReadOnlyRole &&
                    <>
                        <ListItem selected={isActive('/mrr')} button onClick={() => history.push('/mrr')}>
                            <ListItemIcon><EqualizerIcon /></ListItemIcon>
                            <ListItemText primary={'MRR'} />
                        </ListItem>
                        <ListItem selected={isActive('/users')} button onClick={() => history.push('/users')}>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            <ListItemText primary={'Usuarios'} />
                        </ListItem>
                        <ListItem selected={isActive('/hosts')} button onClick={() => history.push('/hosts')}>
                            <ListItemIcon><GroupIcon /></ListItemIcon>
                            <ListItemText primary={'Hosts'} />
                        </ListItem>
                        <ListItem selected={isActive('/spaces')} button onClick={() => history.push('/spaces')}>
                            <ListItemIcon><HomeWorkIcon /></ListItemIcon>
                            <ListItemText primary={'Espacios'} />
                        </ListItem>
                        <ListItem selected={isActive('/transactions')} button onClick={() => history.push('/transactions')}>
                            <ListItemIcon><EventIcon /></ListItemIcon>
                            <ListItemText primary={'Transactions'} />
                        </ListItem>
                        <ListItem selected={isActive('/payments')} button onClick={() => history.push('/payments')}>
                            <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
                            <ListItemText primary={'Pagos'} />
                        </ListItem>
                        <ListItem selected={isActive('/promocodes')} button onClick={() => history.push('/promocodes')}>
                            <ListItemIcon><ConfirmationNumberIcon /></ListItemIcon>
                            <ListItemText primary={'Códigos'} />
                        </ListItem>
                        <ListItem selected={isActive('/companies')} button onClick={() => history.push('/companies')}>
                            <ListItemIcon><BusinessIcon /></ListItemIcon>
                            <ListItemText primary={'Empresas'} />
                        </ListItem>
                        <ListItem selected={isActive('/companypayments')} button onClick={() => history.push('/companypayments')}>
                            <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
                            <ListItemText primary={'Pagos Emp.'} />
                        </ListItem>
                        <ListItem selected={isActive('/revenue')} button onClick={() => history.push('/revenue')}>
                            <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                            <ListItemText primary={'GMV'} />
                        </ListItem>
                        <ListItem selected={isActive('/allies')} button onClick={() => history.push('/allies')}>
                            <ListItemIcon><GroupIcon /></ListItemIcon>
                            <ListItemText primary={'Aliados'} />
                        </ListItem>
                        <ListItem selected={isActive('/store')} button onClick={() => history.push('/store')}>
                            <ListItemIcon><ReceiptIcon /></ListItemIcon>
                            <ListItemText primary={'Store'} />
                        </ListItem>
                        <ListItem selected={isActive('/price-changes')} button onClick={() => history.push('/price-changes')}>
                            <ListItemIcon><FeedbackIcon /></ListItemIcon>
                            <ListItemText primary={'Cambios de Precio'} />
                        </ListItem>
                        <ListItem selected={isActive('/smart-office')} button onClick={() => history.push('/smart-office')}>
                            <ListItemIcon><ApartmentIcon /></ListItemIcon>
                            <ListItemText primary={'Smart Office'} />
                        </ListItem>
                        <ListItem selected={isActive('/smart-booking')} button onClick={() => history.push('/smart-booking')}>
                            <ListItemIcon><EmojiTransportationIcon /></ListItemIcon>
                            <ListItemText primary={'Smart Booking'} />
                        </ListItem>
                    </>
                }
            </List>
        </Drawer>
    );
};

const useStyles = makeStyles((theme) => ({
    listContainer: {
        marginTop: (props) => !props.isNavbarInViewport ? '0' : (props.isNarrowWidth ? '' : '7px'),
        padding: (props) => !props.isNavbarInViewport ? '0' : '',
        width: '176px'
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '10px'
    },
    avatar: {
        width: (props) => props.isNarrowWidth ? '55px' : '40px',
        height: (props) => props.isNarrowWidth ? '55px' : '40px',
        margin: (props) => props.isNarrowWidth ? '16px 0' : '',
    }
}));

export default NavigationDrawer;

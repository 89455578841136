import React, { useState, useEffect, useCallback, useRef } from "react";
import AdminService from "../services/AdminService";
import MaterialTable from "material-table";
import { Button, Link, Box, IconButton, Tooltip, FormControl, FormControlLabel, CircularProgress, Checkbox, List, ListItem, InputLabel, Select, MenuItem } from "@material-ui/core";
import TableIcons from "./molecules/TableIcons";
import UserInfoModal from "./UserInfoModal";
import BlockIcon from '@material-ui/icons/Block';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { PriceDisplay } from "./atoms/PriceDisplay";
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import ReactCountryFlag from "react-country-flag";
import { green } from "@material-ui/core/colors";
import is from "is_js";
import ImageViewer from "react-simple-image-viewer";
import { SearchInput } from "./Companies";
import DataService from "../services/DataService";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import RequestedSpacePriceChangesModal from "./modals/RequestedSpacePriceChangesModal";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

const PAGE_SIZE = 20;
const STATUS_PROPERTIES = {
    0: 'actives_count',
    1: 'paused_count',
    2: 'blocked_count',
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "25px",
        marginBottom: "25px"
    },
}));

const Spaces = () => {
    const classes = useStyles();
    const history = useHistory();

    const searchQuery = useRef('');

    const [spaces, setSpaces] = useState([]);
    const [spacesLoading, setSpacesLoading] = useState(false);
    const [clickedHost, setClickedHost] = useState({});
    const [UserInfoModalOpen, setUserInfoModalOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('')
    const [spacesCount, setSpacesCount] = useState({});
    const [selectedPage, setSelectedPage] = useState(1);
    const [paginatorNumber, setPaginatorNumber] = useState(10);

    const [spaceType, setSpaceType] = useState('<ALL>');
    const [spaceTypeOptions, setSpaceTypeOptions] = useState([]);

    const [reviewedByAdmin, setReviewedByAdmin] = useState('<ALL>');

    const [openRequestedPriceChangesModal, setOpenRequestedPriceChangesModal] = useState(false)
    const [selectedSpace, setSelectedSpace] = useState(null);

    const getSpacesTypes = () => {
        return DataService.getSpaceTypes()
            .then(resp => {
                setSpaceTypeOptions(resp?.data || [])
            }).catch(console.log);
    }

    useEffect(() => {
        getActiveSpaces();
        getSpacesTypes();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getByType(spaceType);
    }, [spaceType, reviewedByAdmin]);

    const getSpacesByStatus = (req) => {
        setSpacesLoading(true);
        return AdminService.getSpacesByStatus(req)
            .then(resp => {
                const { spaces, ...counts } = resp.data;
                setSpaces(spaces);
                setSpacesCount(counts)
                setSpacesLoading(false);
            }).catch(e => {
                setSpacesLoading(false);
                console.log(e)
            });
    }

    const getActiveSpaces = () => {
        setSelectedPage(1);
        searchQuery.current = null;
        getSpacesByStatus({
            host_status: 1,
            space_host_active: true,
            space_admin_approval: true,
            page_size: PAGE_SIZE,
            page_number: 1,
            space_type_id: spaceType === '<ALL>' ? null : spaceType,
            is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin,
        }).then(resp => {
            setSelectedFilter(0);
        }).catch(e => console.log(e));
    };

    const getPausedSpaces = () => {
        setSelectedPage(1);
        searchQuery.current = null;
        getSpacesByStatus({
            space_host_active: false,
            space_admin_approval: true,
            page_size: PAGE_SIZE,
            page_number: 1,
            space_type_id: spaceType === '<ALL>' ? null : spaceType,
            is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin,
        }).then(resp => {
            setSelectedFilter(1);
        }).catch(e => console.log(e));
    };

    const getBlockedSpaces = () => {
        setSelectedPage(1);
        searchQuery.current = null;
        getSpacesByStatus({
            space_admin_approval: false,
            page_size: PAGE_SIZE,
            page_number: 1,
            space_type_id: spaceType === '<ALL>' ? null : spaceType,
            is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin,
        }).then(resp => {
            setSelectedFilter(2);
        }).catch(e => console.log(e));
    };

    const getByHostName = (searchText) => {
        searchQuery.current = searchText;
        setSelectedPage(1);
        getSpacesByStatus({
            host_status: 1,
            space_host_active: true,
            space_admin_approval: true,
            page_size: PAGE_SIZE,
            page_number: 1,
            space_type_id: spaceType === '<ALL>' ? null : spaceType,
            is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin,
            host_name_or_email: searchText
        })
    };

    const getByType = (type) => {
        setSelectedPage(1);
        getSpacesByStatus({
            host_status: 1,
            space_host_active: true,
            space_admin_approval: true,
            page_size: PAGE_SIZE,
            page_number: 1,
            space_type_id: spaceType === '<ALL>' ? null : spaceType,
            is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin
        })
    };

    const blockSpace = async spaceId => {
        await AdminService.updateSpaceStatus(spaceId, false);
        selectedFilter === 0 ? getActiveSpaces() : getPausedSpaces();
    };

    const unlockSpace = async spaceId => {
        await AdminService.updateSpaceStatus(spaceId, true);
        getBlockedSpaces();
    };

    const handleChangePagination = (e, value) => {
        setSelectedPage(value);
        if (selectedFilter === 0) {
            getSpacesByStatus({
                host_status: 1,
                space_host_active: true,
                space_admin_approval: true,
                page_size: PAGE_SIZE,
                page_number: value,
                space_type_id: spaceType === '<ALL>' ? null : spaceType,
                is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin,
                host_name_or_email: searchQuery.current
            });
        } if (selectedFilter === 1) {
            getSpacesByStatus({
                space_host_active: false,
                space_admin_approval: true,
                page_size: PAGE_SIZE,
                page_number: value,
                space_type_id: spaceType === '<ALL>' ? null : spaceType,
                is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin,
                host_name_or_email: searchQuery.current
            });
        } if (selectedFilter === 2) {
            getSpacesByStatus({
                space_admin_approval: false,
                page_size: PAGE_SIZE,
                page_number: value,
                space_type_id: spaceType === '<ALL>' ? null : spaceType,
                is_admin_reviewed: reviewedByAdmin === '<ALL>' ? null : reviewedByAdmin,
                host_name_or_email: searchQuery.current
            });
        }
    };

    const updatePaginator = (totalCount) => {
        setPaginatorNumber(Math.ceil(totalCount / PAGE_SIZE));
    };

    useEffect(() => {
        updatePaginator(spacesCount[STATUS_PROPERTIES[selectedFilter]]);
        // eslint-disable-next-line
    }, [selectedFilter]);

    const openSpacePublication = spaceId => {
        const newWindow = window.open(`${process.env.REACT_APP_WEB_APP_URL}/publication/${spaceId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    };

    const SpaceActionButtons = ({ space }) => {
        switch (selectedFilter) {
            case 0:
                return <>
                    <Tooltip title="Bloquear Publicación" className="lockPublication">
                        <IconButton color="primary" onClick={() => blockSpace(space.id)}>
                            <BlockIcon />
                        </IconButton>
                    </Tooltip>
                </>
            case 1:
                return <>
                    <Tooltip title="Bloquear Publicación" className="lockPublication">
                        <IconButton color="primary" onClick={() => blockSpace(space.id)}>
                            <BlockIcon />
                        </IconButton>
                    </Tooltip>
                </>
            case 2:
                return <>
                    <Tooltip title="Desbloquear Publicación" className="unlockPublication">
                        <IconButton color="primary" onClick={() => unlockSpace(space.id)}>
                            <ThumbUpIcon />
                        </IconButton>
                    </Tooltip>
                </>
            default:
                return null;
        }
    }

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <Box>
            <UserInfoModal host={clickedHost} open={UserInfoModalOpen} setOpen={setUserInfoModalOpen} />
            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                <h1>Espacios</h1>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant={selectedFilter === 0 ? "contained" : "outlined"} color="primary" onClick={() => getActiveSpaces()}>{`Activos (${spacesCount.actives_count || 0})`}</Button>
                    <Button variant={selectedFilter === 1 ? "contained" : "outlined"} color="primary" onClick={() => getPausedSpaces()}>{`Pausados (${spacesCount.paused_count || 0})`}</Button>
                    <Button variant={selectedFilter === 2 ? "contained" : "outlined"} color="primary" onClick={() => getBlockedSpaces()}>{`Bloqueados (${spacesCount.blocked_count || 0})`}</Button>
                </div>
                <MaterialTable
                    icons={TableIcons}
                    isLoading={spacesLoading}
                    options={{ pageSize: 20 }}
                    onSearchChange={getByHostName}
                    title={' '}
                    actions={[
                        {
                            icon: () => <AddPhotoAlternateIcon />,
                            tooltip: 'Editar Imagenes',
                            onClick: (event, rowData) => history.push(`/publication/edit/${rowData.id}/2`)
                        },
                        {
                            icon: () => <EventIcon />,
                            tooltip: 'Editar Disponibilidad',
                            onClick: (event, rowData) => history.push(`/publication/edit/${rowData.id}/0`)
                        },
                        {
                            icon: () => <AttachMoneyIcon />,
                            tooltip: 'Editar Precio',
                            onClick: (event, rowData) => history.push(`/publication/edit/${rowData.id}/1`)
                        },
                        {
                            icon: () => <LocalAtmIcon />,
                            tooltip: 'Ver solicitudes de cambio de precio',
                            onClick: (event, rowData) => { setSelectedSpace(rowData); setOpenRequestedPriceChangesModal(true) }
                        }
                    ]}
                    columns={[
                        {
                            title: 'Revisado por ADMIN',
                            field: 'admin_review',
                            render: s => <AdminReviewCheck space={s} />
                        },
                        {
                            title: 'Space ID',
                            field: 'id',
                            render: s => <Link style={{ cursor: 'pointer' }} onClick={() => openSpacePublication(s?.id)}>#{s?.id}</Link>,
                            customSort: (a, b) => {
                                const aName = a?.buildingType?.name;
                                const bName = b?.buildingType?.name;
                                let c = 0;
                                if (aName > bName) c = -1;
                                if (bName > aName) c = 1;
                                return c;
                            },
                            customFilterAndSearch: (term, rowData) => `${rowData.id}`?.includes(term)
                        },
                        {
                            title: 'Pais',
                            field: 'location',
                            render: s => <Tooltip title={s?.location?.country}>
                                <ReactCountryFlag
                                    svg
                                    countryCode={s?.location?.country || ""}
                                    style={{ marginRight: 5, width: 32, height: 32 }}
                                />
                            </Tooltip>,
                            customSort: (a, b) => {
                                if (a?.location?.country < b?.location?.country)
                                    return -1;
                                if (a?.location?.country > b?.location?.country)
                                    return 1;
                                return 0;
                            }
                        },
                        {
                            title: 'Host',
                            field: 'host.name',
                            render: s => <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Link style={{ cursor: 'pointer' }} onClick={() => { setClickedHost({ ...s?.host, ...s?.host?.user }); setUserInfoModalOpen(true); }} >{`#${s?.host?.id} ${s?.host?.name} ${s?.host?.lastname || ''}`}</Link>
                            </div>,
                            customSort: (a, b) => {
                                const aName = a?.host?.name;
                                const bName = b?.host?.name;
                                let c = 0;
                                if (aName > bName) c = -1;
                                if (bName > aName) c = 1;
                                return c;
                            }
                        },
                        {
                            title: 'Espacio',
                            field: 'type.name',
                            render: s => `${s?.type?.name}`,
                            customSort: (a, b) => {
                                const aName = a?.type?.name;
                                const bName = b?.type?.name;
                                let c = 0;
                                if (aName > bName) c = -1;
                                if (bName > aName) c = 1;
                                return c;
                            }
                        },
                        {
                            title: 'Activo por host',
                            field: 'active',
                            render: s => <ActiveByHostCheck space={s} />
                        },
                        {
                            title: 'Auto-aprobar reservas',
                            field: 'auto_approval',
                            render: s => <AutoApprovalCheck space={s} />
                        },
                        {
                            title: 'Acciones',
                            sorting: false,
                            field: '',
                            render: s => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <SpaceActionButtons space={s} />
                            </div>,
                        },
                    ]}
                    data={spaces}
                    detailPanel={rowData => <SpaceDetailPanel space={rowData} />}
                    components={{
                        Pagination: props => (
                            <div className={classes.root}>
                                <Pagination style={{ width: "450px", margin: "0 auto" }} count={paginatorNumber} color="primary" page={selectedPage} onChange={handleChangePagination} showFirstButton showLastButton />
                            </div>
                        ),
                        Toolbar: props => <Box style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                            <Box style={{ fontSize: 24 }}>{props.title}</Box>
                            <FormControl style={{ width: '300px' }}>
                                <InputLabel shrink id="review-by-admin" style={{}}>Revisado por ADMIN:</InputLabel>
                                <Select
                                    labelId="review-by-admin"
                                    id="type"
                                    value={reviewedByAdmin}
                                    onChange={e => setReviewedByAdmin(e.target.value)}
                                    required
                                    label={'Revisado por ADMIN'}>
                                    <MenuItem value={'<ALL>'}>Todos</MenuItem>
                                    <MenuItem value={true}>Revisados</MenuItem>
                                    <MenuItem value={false}>Sin revisar</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: '300px' }}>
                                <InputLabel shrink id="space-type" style={{}}>Tipo de Espacio:</InputLabel>
                                <Select
                                    labelId="space-type"
                                    id="type"
                                    value={spaceType}
                                    onChange={e => setSpaceType(e.target.value)}
                                    required
                                    label={'Tipo de Espacio'}>
                                    <MenuItem value={'<ALL>'}>Todos</MenuItem>
                                    {spaceTypeOptions.map(t => <MenuItem key={t.name + '_type'} value={t.id}>{t.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <SearchInput searchFn={getByHostName} />
                        </Box>
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsSelect: 'por página'
                        },
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay hosts para mostrar.',
                        }
                    }}
                />
            </div>

            {openRequestedPriceChangesModal && (
                <RequestedSpacePriceChangesModal
                    open={openRequestedPriceChangesModal}
                    setOpen={setOpenRequestedPriceChangesModal}
                    // onConfirm={onConfirm}
                    space={selectedSpace}
                />
            )}
        </Box>
    );
};

export default Spaces;

export const AutoApprovalCheck = ({ space }) => {
    const [autoApprove, setAutoApprove] = useState(space.auto_approval);
    const [autoApproveLoading, setAutoApproveLoading] = useState(false);

    const handleSetAutoApprove = async e => {
        setAutoApproveLoading(true);
        setAutoApprove(e.target.checked);
        await AdminService.updateSpace({ auto_approval: e.target.checked, id: space.id });
        setAutoApproveLoading(false);
    }

    useEffect(() => {
        setAutoApprove(space.auto_approval);
    }, [space]);

    return (
        <FormControl style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel style={{ justifyContent: 'center' }} control={<>
                {autoApproveLoading ? <CircularProgress size={20} style={{ margin: 9 }} /> : <Checkbox value={autoApprove} onChange={handleSetAutoApprove} checked={autoApprove} color="primary" />}
            </>} />
        </FormControl>
    )
}

export const ActiveByHostCheck = ({ space }) => {
    const [active, setActive] = useState(space.active);
    const [activeLoading, setActiveLoading] = useState(false);

    const handleSetActive = async e => {
        setActiveLoading(true);
        setActive(e.target.checked);
        await AdminService.updateSpace({ active: e.target.checked, id: space.id });
        setActiveLoading(false);
    }

    useEffect(() => {
        setActive(space.active);
    }, [space]);

    return (
        <FormControl style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel style={{ justifyContent: 'center' }} control={<>
                {activeLoading ? <CircularProgress size={20} style={{ margin: 9 }} /> : <Checkbox value={active} onChange={handleSetActive} checked={active} color="secondary" />}
            </>} />
        </FormControl>
    )
}


export const AdminReviewCheck = ({ space }) => {
    const [adminReview, setAdminReview] = useState(space.admin_review);
    const [adminReviewLoading, setAdminReviewLoading] = useState(false);

    const handleSetAdminReview = async e => {
        setAdminReviewLoading(true);
        setAdminReview(e.target.checked);
        await AdminService.updateSpace({ admin_review: e.target.checked, id: space.id });
        setAdminReviewLoading(false);
    }

    useEffect(() => {
        setAdminReview(space.admin_review);
    }, [space]);

    return (
        <FormControl style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel style={{ justifyContent: 'center' }} control={<>
                {adminReviewLoading ? <CircularProgress size={20} style={{ margin: 9 }} /> : <GreenCheckbox value={adminReview} onChange={handleSetAdminReview} checked={adminReview} color="default" />}
            </>} />
        </FormControl>
    )
}

export const SpaceDetailPanel = ({ space }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewerImages, setviewerImages] = useState([]);

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        setviewerImages([]);
    };

    const setSpacesImageViewer = (index, rowData) => {
        setviewerImages(rowData?.images.map(i => i.original_url));
        openImageViewer(index);
    }

    return (<>
        {isViewerOpen && (
            <ImageViewer
                src={viewerImages}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                    zIndex: 9999
                }}
            />
        )}

        <Box style={{ padding: 20 }}>
            <Box>
                <div><b>{space?.type?.name}</b> para <b>{space?.capacity}</b> personas</div>
                <div>{space?.buildingType?.name}</div>
            </Box>
            <br />
            <b> ==== PRECIO EN MONEDA ==== </b>
            <Box><b>Precio por día:</b> {(space.daily_booking ? <>{space?.private ? 'Privado' : <PriceDisplay value={space.price} currency={space?.currency?.name} />}</> : 'NO')}</Box>
            <Box><b>Precio por hora</b> {(space.hourly_booking ? <>{space?.private ? 'Privado' : <PriceDisplay value={space.hourly_price} currency={space?.currency?.name} />}</> : 'NO')}</Box>
            <Box><b>Precio por mes:</b> {(space.monthly_booking ? <>{space?.private ? 'Privado' : <PriceDisplay value={space.monthly_price} currency={space?.currency?.name} />}</> : 'NO')}</Box>
            <br />
            <b> ==== PRECIO EN POINTS ==== </b>
            <Box><b>Points por día:</b> {(space.daily_points ? <>{space?.private ? 'Privado' : `${space.daily_points} POINTS`}</> : 'NO')}</Box>
            <Box><b>Points por hora</b> {(space.hourly_points ? <>{space?.private ? 'Privado' : `${space.hourly_points} POINTS`}</> : 'NO')}</Box>
            <br />
            <Box><b>Capacidad:</b> {space?.capacity} lugares</Box>
            <Box><b>Categoría:</b> {space?.category}</Box>
            <Box> <b>Check In: </b> <span>{space?.check_in_time}</span></Box>
            <Box> <b>Check Out: </b> <span>{space?.check_out_time}</span></Box>
            <Box> <b>Descripción: </b> <span style={{ overflowWrap: 'break-word' }}>{space?.description}</span></Box>
            <Box> <b>Reglas: </b> <ul>{space?.rules?.map(r => <li>{r.name}</li>)} </ul></Box>
            <Box> <b>Dirección: </b>
                <a href={`https://www.google.com/maps/search/?api=1&query=${space?.location?.latitude},${space?.location?.longitude}`} target="_blank" rel="noopener noreferrer">
                    <span>{`${space?.location?.street} ${space?.location?.stret_number || ''} ${space?.location?.apt_number || ''}, ${space?.location?.city}, ${space?.location?.country} ${space?.location?.zip_code || ''}`}</span>
                </a>
            </Box>
            <br />
            <Box><b>Fecha de creación:</b> {moment(space?.created_date).format('LLL')}</Box>
            <br />
            <b> ==== DESCUENTOS ==== </b>
            {+space?.discount_percentage > 0 && <Box>
                <b>Descuento: </b>
                <span>{space.discount_percentage + '%'}</span>
            </Box>}
            {+space?.discount_week_percentage > 0 && <Box>
                <b>Descuento semanal: </b>
                <span>{+space.discount_week_percentage + '%'}</span>
            </Box>}
            {+space?.discount_month_percentage > 0 && <Box>
                <b>Descuento mensual: </b>
                <span>{+space.discount_month_percentage + '%'}</span>
            </Box>}
            {+space?.discount_quarterly_percentage > 0 && <Box>
                <b>Descuento trimestral: </b>
                <span>{+space.discount_quarterly_percentage + '%'}</span>
            </Box>}
            {+space?.discount_biannual_percentage > 0 && <Box>
                <b>Descuento semestral: </b>
                <span>{+space.discount_biannual_percentage + '%'}</span>
            </Box>}
            {+space?.discount_annual_percentage > 0 && <Box>
                <b>Descuento anual: </b>
                <span>{+space.discount_annual_percentage + '%'}</span>
            </Box>}
            {is.not.empty(space?.quantityDiscounts) && <Box>
                <b>Descuentos por cantidad de sillas: </b>
                <List style={{ padding: 0 }}>
                    {space?.quantityDiscounts?.map(qdr => <ListItem style={{ display: 'flex', padding: 0 }}>
                        <Box style={{ marginRight: 10 }}>De {qdr.quantity_from} a {qdr.quantity_to} sillas:</Box>
                        <Box>{+qdr.discount_percentage + '%'}</Box>
                    </ListItem>)}
                </List>
            </Box>}
            <br />
            <Box>
                <b>Fotos: </b>
                <div>
                    {space?.images?.map((i, index) => <img onClick={() => setSpacesImageViewer(index, space)} src={i.url} alt="" style={{ maxWidth: '200px', cursor: 'pointer' }} />)}
                </div>
            </Box>
        </Box>
    </>
    )
}
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

import React from 'react';

const SpaceTitle = ({ space }) => {
    const type = space.space_type ? space.space_type : space.type;
    const typeName = space?.space_type?.name ? space?.space_type?.name : space?.type?.name;
    if (space?.name)
        return <>
            <b>{space.name}</b>
        </>;
    else if (type?.partial_booking)
        return <>
            <b>{typeName}</b>
        </>;
    else
        return <>
            <b style={{ marginRight: 4 }}>{typeName}</b> para <b style={{ marginRight: 4, marginLeft: 4 }}>{space?.space_capacity || space?.capacity}</b> personas
        </>;

};

export default SpaceTitle;
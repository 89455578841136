import React, { useState, useCallback } from "react";
import { Button, Box, List, ListItem } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageViewer from "react-simple-image-viewer";
import { PriceDisplay } from "./atoms/PriceDisplay";
import is from "is_js";

const SpaceInfoModal = ({ space, open, setOpen }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewerImages, setviewerImages] = useState([]);

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        setviewerImages([]);
    };

    const setSpacesImageViewer = (index) => {
        setviewerImages(space?.images.map(i => i.original_url));
        openImageViewer(index);
    }

    return <>
        {isViewerOpen && (
            <ImageViewer
                src={viewerImages}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                    zIndex: 9999
                }}
            />
        )}
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'}>
            <DialogTitle>
                <div><b>{space?.type?.name}</b> para <b>{space?.capacity}</b> personas</div>
                <div>{space?.buildingType?.name}</div>
                <Box>{`#${space.id}`}</Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <b>Precio: </b>
                    {space?.private ? 'Privado' : <PriceDisplay value={space.price} currency={space?.currency?.name} />}
                </Box>
                <br />
                {+space?.discount_percentage > 0 && <Box>
                    <b>Descuento: </b>
                    <span>{space.discount_percentage + '%'}</span>
                </Box>}
                <br />
                {+space?.discount_week_percentage > 0 && <Box>
                    <b>Descuento semanal: </b>
                    <span>{+space.discount_week_percentage + '%'}</span>
                </Box>}
                <br />
                {+space?.discount_month_percentage > 0 && <Box>
                    <b>Descuento mensual: </b>
                    <span>{+space.discount_month_percentage + '%'}</span>
                </Box>}
                <br />
                {+space?.discount_quarterly_percentage > 0 && <Box>
                    <b>Descuento trimestral: </b>
                    <span>{+space.discount_quarterly_percentage + '%'}</span>
                </Box>}
                <br />
                {+space?.discount_biannual_percentage > 0 && <Box>
                    <b>Descuento semestral: </b>
                    <span>{+space.discount_biannual_percentage + '%'}</span>
                </Box>}
                <br />
                {+space?.discount_annual_percentage > 0 && <Box>
                    <b>Descuento anual: </b>
                    <span>{+space.discount_annual_percentage + '%'}</span>
                </Box>}
                <br />
                {is.not.empty(space?.quantityDiscounts) && <Box>
                    <b>Descuentos por cantidad de sillas: </b>
                    <List>
                        {space?.quantityDiscounts?.map(qdr => <ListItem style={{ display: 'flex' }}>
                            <Box style={{ marginRight: 10 }}>De {qdr.quantity_from} a {qdr.quantity_to} sillas:</Box>
                            <Box>{+qdr.discount_percentage + '%'}</Box>
                        </ListItem>)}
                    </List>
                </Box>}
                <br />
                <Box>
                    <b>Activo: </b>
                    <span>{space.active ? 'SI' : 'NO'}</span>
                </Box>
                <br />
                <Box>
                    <b>Descripción: </b>
                    <span style={{ overflowWrap: 'break-word' }}>{space.description}</span>
                </Box>
                <br />
                <Box>
                    <b>Check In: </b>
                    <span>{space.check_in_time}</span>
                </Box>
                <br />
                <Box>
                    <b>Check Out: </b>
                    <span>{space.check_out_time}</span>
                </Box>
                <br />
                <Box>
                    <b>Reglas: </b>
                    <ul>
                        {space?.rules?.map(r => <li>{r.name}</li>)}
                    </ul>
                </Box>
                <br />
                <Box>
                    <b>Dirección: </b>
                    <a href={`https://www.google.com/maps/search/?api=1&query=${space?.location?.latitude},${space?.location?.longitude}`} target="_blank" rel="noopener noreferrer">
                        <span>{`${space?.location?.street} ${space?.location?.stret_number || ''} ${space?.location?.apt_number || ''}, ${space?.location?.city}, ${space?.location?.country} ${space?.location?.zip_code || ''}`}</span>
                    </a>
                </Box>
                <br />
                <Box>
                    <b>Fotos: </b>
                    <div>
                        {space?.images?.map((i, index) => <img onClick={() => setSpacesImageViewer(index)} src={i.url} alt="" style={{ maxWidth: '200px', cursor: 'pointer' }} />)}
                    </div>
                </Box>
                <br />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default SpaceInfoModal;



// external components
import React, { useState } from 'react';
import { CircularProgress, makeStyles, Switch } from '@material-ui/core';
// service
import AdminService from '../../../services/AdminService';

const AllowUnlimitedSwitch = (props) => {


    const { active, idCompany, companyCountry, refresh } = props;

    const [loader, setLoader] =useState(false);
    const [switchActive, setSwitchActive] =useState(active);

    const useStyles = makeStyles((theme) => ({
        switchBase: {
            color: 'lightgray',
            '&$checked': {
                color: '#9d0ace',
            },
            '&$checked + $track': {
                backgroundColor: '#9d0ace',
            },
        },
    }));

    const classes = useStyles();

    const toggleAllowUnlimited = async (id, chk) => {
        setLoader(true);
        const idCompanyCountry = id;
        const is_unlimited_allowed = chk;
        let payload = { idCompany, idCompanyCountry, is_unlimited_allowed };
        try{
            await AdminService.updateAllowUnlimited(payload);
            setSwitchActive(chk);
        }
        catch (err) {
            console.log('err', err);
        }
        finally {
            setLoader(false);
        }
    };
    return (
        <div>
            {loader
                ? <CircularProgress />
                : <Switch
                    className={classes.switchBase}
                    checked={switchActive}
                    value={switchActive}
                    onChange={(e) => toggleAllowUnlimited(companyCountry.id, e.target.checked)}
                    name={'is_unlimited_allowed'}
                    sx={{
                        color: 'black',
                        borderRadius: 15,
                    }}
                />}
        </div>
    );
};

export default AllowUnlimitedSwitch;

import React from 'react';
import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import DataService from '../../../services/DataService';

const CountrySelector = ({ selectedCountry, setSelectedCountry, noTitle = false, isFilter = false }) => {

    const [countries, setCountries] = useState([]);

    const getCountriesOptions = async () => {
        let resp = await DataService.getCountries(true);
        setCountries(resp.data);
    };

    useEffect(() => {
        getCountriesOptions();
    }, []);

    return <>
        {!noTitle && <h4>Seleccione un Pais</h4>}
        <FormControl
            style={{
                minWidth: 300,
                padding: '10px',
                marginBottom: '20px',
                width: '60%',
            }}
        >

            <Select
                id={'country'}
                // style={{ zIndex: 9999 }}
                value={selectedCountry}
                disableUnderline
                defaultValue={selectedCountry}
                required
                name={'country'}
                onChange={(e) => setSelectedCountry(e.target.value)}
                displayEmpty
                inputProps={{ shrink: true }}
            >
                {isFilter && <MenuItem value={0}>Todos los paises</MenuItem>}
                {countries
                    .sort((a, b) => a.order - b.order)
                    .map((co) => (
                        <MenuItem key={co.name + '_ctry'} value={co.id}>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <ReactCountryFlag
                                    svg
                                    countryCode={co.name || ''}
                                    style={{ marginRight: 5, width: 32, height: 32 }}
                                />
                                <span>{co.internal_detail}</span>
                            </Box>
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>

    </>;
};

export default CountrySelector;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: (props) => props.isNarrowWidth ? `${theme.spacing(0)}px` : `${theme.spacing(20)}px`,
        marginTop: (props) => props.isNarrowWidth ? `${theme.spacing(2)}px` : `-${theme.spacing(8)}px`,
        marginBottom: `${theme.spacing(30)}px`,
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(8)}px`,
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '700px',
        margin: 'auto',
        width: '85%'
    }
}));
import { Box, Switch, withStyles, CircularProgress, TextField, FormControl, FormControlLabel, Checkbox, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from './categories.styles';
import SpaceTypeSelect from './SpaceTypeSelect';
import FeaturesService from '../../services/FeaturesService';
import SpaceCountrySelect from './SpaceCountrySelect';
import CategoryFoodLimits from './CategoryFoodLimits';
import useResolutions from '../../helpers/hooks/useResolutions';

const CategoryDisplay = ({
    category,
    disabled,
    currency = '',
    active,
    companyCountry,
    countryOptions,
    spaceTypesOptions,
    reloadActiveFeatures = () => null
}) => {

    const { isMobile, isLargeScreen, isUp1366Res } = useResolutions();

    const [forValue, setForValue] = useState({ relationExist: active });
    const [sectionLoading, setSectionLoading] = useState(true);
    const [loadingSwitch, setLoadingSwitch] = useState(false);

    const {
        _selectedSection,
        _section,
    } = useStyles({ isMobile, isLargeScreen, isUp1366Res, sectionLoading });

    const _containerClass = forValue?.relationExist ? _selectedSection : _section;
    const switchText = forValue.relationExist ? 'habilitado' : 'deshabilitado';

    const createOrDeleteCategoryLimit = async (chkd) => {
        if (chkd) {
            await FeaturesService.updateCategoryLimit(companyCountry.company.id, {
                hasHourlyLimit: false,
                dailyLimitAmount: 0,
                hourTo: '16:00',
                hourFrom: '10:00',
                spaceTypeAllowedIDs: [],
                spaceCountryAllowedIDs: [],
                daysAvailable: [0, 1, 2, 3, 4, 5, 6],
                hasDaysLimitation: false,
                companyCountry: companyCountry.id,
                featureCategory: category.id,
            });
        } else {
            await FeaturesService.deleteCategoryLimit(companyCountry.company.id, {
                companyCountryId: companyCountry.id,
                featureCategory: category.id,
            });
        }
        reloadActiveFeatures();
    };

    const updateCategoryLimitField = async ({ field, value }) => {
        const payload = {
            id: forValue.categoryLimitId,
            companyCountry: companyCountry.id,
            featureCategory: category.id,
            spaceTypeAllowedIDs: forValue.spaceTypes,
            spaceCountryAllowedIDs: forValue.spaceCountries,
        };
        payload[field] = value;
        if (category.id) {
            await FeaturesService.updateCategoryLimit(companyCountry.company.id, payload);
            reloadActiveFeatures();
        }
    };

    const updateWellnessConfig = async () => {
        const payload = {
            id: forValue.categoryLimitId,
            company: companyCountry.company.id,
            featureCategory: category.id,
            minEmployees: forValue.minEmployees,
            employeeMonthlyFee: forValue.employeeMonthlyFee,
            approvedByAdmin: forValue.approvedByAdmin,
            url: forValue.url
        };
        if (category.id) {
            await FeaturesService.updateCategoryLimit(companyCountry.company.id, payload);
            reloadActiveFeatures();
        }
    };


    useEffect(() => {
        const categoryLimit = category?.featureCategoryLimits?.[0];
        if (categoryLimit) {
            setForValue({
                allowDailyLimit: categoryLimit?.hasDailyLimitAmount,
                dailyLimitAmount: Number(categoryLimit?.dailyLimitAmount).toFixed(0),
                relationExist: active,
                allowCheckTimes: categoryLimit?.hasHourlyLimit,
                checkin: categoryLimit?.hourFrom,
                checkout: categoryLimit?.hourTo,
                categoryLimitId: categoryLimit?.id,
                spaceTypes: categoryLimit?.spaceTypeAllowed?.map((st) => st.id) || [],
                spaceCountries: categoryLimit?.spaceCountryAllowed?.map((sc) => sc.id) || [],
                daysAvailable: categoryLimit?.daysAvailable || [],
                hasDaysLimitation: categoryLimit.hasDaysLimitation,
                minEmployees: categoryLimit.minEmployees,
                employeeMonthlyFee: categoryLimit.employeeMonthlyFee,
                approvedByAdmin: categoryLimit.approvedByAdmin,
                url: categoryLimit.url
            });
        } else {
            setForValue({
                relationExist: active,
                spaceTypes: [],
                spaceCountries: [],
                daysAvailable: []
            });
        }
        setSectionLoading(false);
    }, [category]);

    return (<>

        <Box style={{ display: 'flex', flexDirection: 'row' }}>

            <Box
                className={_containerClass}
                style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}
            >
                {!sectionLoading && <>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '97%' }}>

                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, marginBottom: 10, marginLeft: 10, width: '50%' }}>
                            <Box style={{ marginLeft: 10, fontSize: isLargeScreen ? 16 : 14 }}><b>{category.name}</b></Box>
                        </Box>

                        <Box style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight: -35, flexDirection: 'row', marginTop: 10, width: isLargeScreen ? '40%' : '46%' }}>

                            <Box style={{ marginRight: 10 }}>
                                <StyledSwitch
                                    checked={forValue.relationExist}
                                    value={forValue.relationExist}
                                    onChange={async (e) => {
                                        setLoadingSwitch(true);
                                        try {
                                            await createOrDeleteCategoryLimit(e.target.checked);
                                        } catch (error) {
                                            console.log(error);
                                        } finally {
                                            setLoadingSwitch(false);
                                        }
                                    }}
                                    name={'active'}
                                    inputProps={{ 'aria-label': 'primary checkbox', disabled: loadingSwitch }}
                                    size={'small'}
                                    disabled={disabled}
                                />
                                <span style={{ fontSize: isLargeScreen ? 16 : 14 }}>
                                    {switchText}
                                </span>
                                {loadingSwitch && <CircularProgress size={14} style={{ marginLeft: 5 }} />}
                            </Box>
                        </Box>
                    </Box>

                    {category.name == 'COWORKINGS' && <>
                        <Box width={'90%'} style={{ margin: 15 }}>
                            <Box>Permitir reservas SOLO en espacios de tipo:</Box>
                            <SpaceTypeSelect
                                spaceTypesOptions={spaceTypesOptions}
                                setSpaceType={(spaceTypes) => {
                                    setForValue((fv) => ({ ...fv, spaceTypes }));
                                    updateCategoryLimitField({ field: 'spaceTypeAllowedIDs', value: spaceTypes });
                                }}
                                spaceType={forValue.spaceTypes}
                            />
                        </Box>
                        <Box width={'90%'} style={{ margin: 15 }}>
                            <Box>Permitir reservas SOLO en espacios en los siguientes paises:</Box>
                            <SpaceCountrySelect
                                countryOptions={countryOptions}
                                setSpaceCountry={(spaceCountries) => {
                                    setForValue((fv) => ({ ...fv, spaceCountries }));
                                    updateCategoryLimitField({ field: 'spaceCountryAllowedIDs', value: spaceCountries });
                                }}
                                spaceCountries={forValue.spaceCountries}
                            />
                        </Box>

                        <Box width={'90%'}>
                            <Typography>* Para habilitar COWORKINGS es necesario habilitar la categoría FLEX para la empresa</Typography>
                        </Box>
                    </>}

                    {category.name == 'FOOD' && <CategoryFoodLimits
                        forValue={forValue}
                        setForValue={setForValue}
                        category={category}
                        currency={currency}
                        reloadActiveFeatures={reloadActiveFeatures}
                        companyCountry={companyCountry}
                        updateCategoryLimitField={updateCategoryLimitField}
                    />}

                    {category.name == 'WELLNESS' && <Box>

                        <Box style={{ display: 'flex' }}>
                            <FormControl style={{ padding: '10px' }}>
                                <TextField
                                    id={'minEmployees'}
                                    label={'Cupo mínimo'}
                                    value={forValue.minEmployees || ''}
                                    onChange={(e) => {
                                        e.persist();
                                        setForValue((fv) => ({ ...fv, minEmployees: e.target.value }));
                                    }}
                                    required
                                    style={{ backgroundColor: '#ffffff' }}
                                    type={'number'}
                                    InputLabelProps={{ shrink: true, style: { backgroundColor: 'white', borderRadius: 5 } }}
                                />
                            </FormControl>

                            <FormControl style={{ padding: '10px' }}>
                                <TextField
                                    id={'employeeMonthlyFee'}
                                    label={'Valor por empleado (USD)'}
                                    value={forValue.employeeMonthlyFee || ''}
                                    onChange={(e) => {
                                        e.persist();
                                        setForValue((fv) => ({ ...fv, employeeMonthlyFee: e.target.value }));
                                    }}
                                    required
                                    style={{ backgroundColor: '#ffffff' }}
                                    type={'number'}
                                    InputLabelProps={{ shrink: true, style: { backgroundColor: 'white', borderRadius: 5 } }}
                                />
                            </FormControl>
                        </Box>

                        <FormControl style={{ padding: '10px', width: 400 }}>
                            <TextField
                                id={'url'}
                                label={'Link para co-pago de empleados'}
                                value={forValue.url || ''}
                                onChange={(e) => {
                                    e.persist();
                                    setForValue((fv) => ({ ...fv, url: e.target.value }));
                                }}
                                required
                                style={{ backgroundColor: '#ffffff' }}
                                type={'text'}
                                InputLabelProps={{ shrink: true, style: { backgroundColor: 'white', borderRadius: 5 } }}
                            />
                        </FormControl>
                        <br />

                        <FormControlLabel control={<>
                            <Checkbox
                                value={forValue.approvedByAdmin}
                                checked={forValue.approvedByAdmin}
                                disabled={!forValue.relationExist}
                                onChange={(e) => {
                                    e.persist();
                                    setForValue((fv) => ({ ...fv, approvedByAdmin: e.target.checked }));
                                }}
                            />
                            <Box style={{ marginRight: 5 }}>Aprobado por admin</Box>
                        </>}
                        />

                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => updateWellnessConfig()}
                            disabled={!forValue.relationExist}
                        >
                            Guardar cambios
                        </Button>
                    </Box>}

                </>}
            </Box>

        </Box>

    </>);
};

export default CategoryDisplay;

const StyledSwitch = withStyles({
    switchBase: {
        color: 'lightgray',
        '&$checked': {
            color: '#9900CC',
        },
        '&$checked + $track': {
            backgroundColor: '#61257D',
        },
    },
    checked: {},
    track: {},
})(Switch);

import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Container, Box, CircularProgress, Tabs, Tab } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import Availability from './Availability';
import Pricing from './Pricing';
import SpaceEditContext, { SpaceEditProvider } from '../space/SpaceEditContext';
import AdminService from '../../services/AdminService';
import Photos from '../organisms/Photos';

const PublicationEdit = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });

    const styles = {
        stepsView: {
            backgroundColor: '#ffffff',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 159px)',
            paddingBottom: isNarrowWidth ? '20px' : 0,
            paddingLeft: isMobile ? '10px' : '50px',
            paddingRight: isMobile ? '10px' : '50px'
        }
    };

    let { spaceId = 0, initialTab } = useParams();
    const history = useHistory();

    const [space, setSpace] = useState({ id: 0 });
    const [spaceLoading, setSpaceLoading] = useState(false);

    const [contextData, setContectData] = useState({ space: {}, stepUtils: {}, styles });

    const [tab, setTab] = useState(+initialTab || 0);

    useEffect(() => {
        refreshSpace();
        // eslint-disable-next-line
    }, [spaceId])

    const refreshSpace = async () => {
        setSpaceLoading(true);
        try {
            if (spaceId === 0) return;
            let resp = await AdminService.getSpaceById(spaceId);
            let s = resp.data;
            setSpace(s);
        } catch (e) {
            console.log(e);
        } finally {
            setSpaceLoading(false);
        }
    };

    const tabChange = async (e, newTabIndex) => {
        setTab(newTabIndex);
    };



    const stepsProps = {
        context: SpaceEditContext,
        isOnboarding: false
    };

    const updateSpace = async (updatedSpaceData) => {
        console.log(updatedSpaceData);
        await AdminService.updateSpace({ ...updatedSpaceData, id: space.id })
            .catch((e) => { throw new Error(e); });
        refreshSpace();
    };

    const setAvailability = async (spaceId, availability) => {
        availability.space_id = spaceId;
        await AdminService.setAvailability(spaceId, availability)
            .catch((e) => { throw new Error(e); });
    };


    const backToPublications = () => history.push('/spaces');


    useEffect(() => {
        setContectData({
            styles,
            space,
            stepUtils: {
                updateSpace,
                backToPublications,
                setAvailability
            },
        });
        // eslint-disable-next-line
    }, [space]);

    return <SpaceEditProvider value={contextData}>
        <div style={{ backgroundColor: isMobile ? '#ffffff' : '#F2F2F2', marginLeft: 170 }}>
            <Container style={{ minHeight: 'calc(100vh - 104px)', padding: isMobile && 0 }} className={'spaceEditionContainer'}>
                <Tabs value={tab} onChange={tabChange} variant={'scrollable'} scrollButtons={'on'}>
                    <Tab label={'Disponibilidad'} style={{ textTransform: 'none' }} />
                    <Tab label={'Precio'} style={{ textTransform: 'none' }} />
                    <Tab label={'Imágenes'} style={{ textTransform: 'none' }} />
                </Tabs>

                {spaceLoading ?
                    <Box style={{ ...styles.stepsView, justifyContent: 'center', alignItems: 'center', flexFlow: 'row' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <TabPanel value={tab} index={0}>
                            <Availability {...stepsProps} />
                        </TabPanel>

                        <TabPanel value={tab} index={1}>
                            <Pricing {...stepsProps} />
                        </TabPanel>

                        <TabPanel value={tab} index={2}>
                            <Photos {...stepsProps} />
                        </TabPanel>
                    </>
                }
            </Container>
        </div>
    </SpaceEditProvider >;
};

export default PublicationEdit;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role={'tabpanel'}
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

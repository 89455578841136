import axios from 'axios';


const StoreService = {

    getProducts: () => {
        console.log(`StoreService.getProducts()`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/product/search`;
        return axios.get(url)
    },
    postImage: () => {

    },
    createProduct: (data) => {
        console.log(`StoreService.createProduct()`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/product`;
        return axios.post(url, data)

    },
    uploadBase64: (base64, store_product_id, onUploadProgress, cancelToken ) => {
        console.log(`StoreService.Base64ToHash()`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/product/image`;
        return axios.post(url, {
            base64,
            store_product_id,
            order: 1,
          },
            { onUploadProgress, cancelToken })
        },
    updateStatus: (data) => {
        console.log(`StoreService.updateStatus()`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/product/status`;
        return axios.patch(url, data)
    },
    updateProduct: (payload) => {
        console.log(`StoreService.updateProduct()`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/product/update`
        return axios.patch(url, payload)
    },

    getPurchaseByStatus: (status) => {
        console.log(`getPurchaseByStatus(${status})`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/purchases`
        return axios.post(url, status);
    },
    getPurchases: () => {
        console.log(`StoreService.getPurchases()`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/purchases`
        return axios.get(url)
    },
    deleteImage: (idProduct, uuid) => {
        console.log(`StoreService.deleteImage(${idProduct})`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/${idProduct}/image/${uuid}`
        return axios.delete(url)
    },
    changeStatus: (payload) => {
        console.log(`StoreService.changeStatus()`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/purchases/status`
        return axios.patch(url, payload)
    },
    updateCompanyHasStore: (idCompany, hasStore) => {
        console.log(`StoreService.updateCompanyAcumCredit(${idCompany}, ${hasStore})`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/company/${idCompany}/has-store/${hasStore}`
        return axios.patch(url);
    },
    deleteProduct: (product_id) => {
        console.log(`StoreService.deleteProduct(${product_id})`);
        const url = `${process.env.REACT_APP_API_STORE_URL}/admin-store/delete/product`
        return axios.delete(url, { data: { product_id: product_id } });

    }

};

export default StoreService;

// External components
import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Link, IconButton, Tooltip, Typography } from "@material-ui/core";
import MaterialTable from "material-table";

// Icons & Images
import LaunchIcon from '@material-ui/icons/Launch';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

// Utils & Config
import { useStyles } from './RequestedSpacePriceChanges.styles';
import { RequestedSpacePriceChangeStatusEnum, requestedSpacePriceChangeStatusLabels } from '../../helpers/enums/RequestedSpacePriceChangeStatusEnum';
import { RequestedSpacePriceChangeTypeEnum, priceRangeLabels } from '../../helpers/enums/RequestedSpacePriceChangeTypeEnum';
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import AdminService from '../../services/AdminService';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

// Internal components
import TableIcons from "../molecules/TableIcons";
import { PriceDisplay } from "../atoms/PriceDisplay";
import ApproveOrRejectRequestedSpacePriceChangeModal from "../modals/ApproveOrRejectRequestedSpacePriceChangeModal";
import UserInfoModal from "../UserInfoModal";
import moment from "moment";

const RequestedSpacePriceChanges = () => {
    const { searchQuery } = useParams();

    const [openModal, setOpenModal] = useState(null); // 'REJECT' | 'APPROVE' | null
    const [selectedPriceChange, setSelectedPriceChange] = useState(null);

    const [selectedStatus, setSelectedStatus] = useState(RequestedSpacePriceChangeStatusEnum.PENDING);
    const [request, setRequest] = useState({ status: ResponseStatusEnum.IDLE, data: null });

    const [clickedHost, setClickedHost] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const openHostModal = (hostId) => {
        AdminService.getHostById(hostId).then(resp => {
            setClickedHost(resp.data);
            setInfoModalOpen(true);
        }).catch(console.log);
    }

    const getRequestedSpacePriceChanges = async (status) => {
        setSelectedStatus(status);
        setRequest({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            const res = await AdminService.getRequestedSpacePriceChangeByStatus(status);

            setRequest({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setRequest({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    /** @param {'REJECT' | 'APPROVE'} action */
    const handleOpenModal = (action, rowData) => {
        setSelectedPriceChange(rowData);
        setOpenModal(action);
    };

    const handleModalConfirm = async () => {
        setSelectedPriceChange(null);
        getRequestedSpacePriceChanges(selectedStatus);
    };

    useEffect(() => {
        getRequestedSpacePriceChanges(RequestedSpacePriceChangeStatusEnum.PENDING);
    }, []);

    const customFilterAndSearch = (term, row) => {
        const string = `${row.space.id} ${row.space.host_id} ${row.space.host_name} ${row.space.host_lastname} ${row.space.name} ${row.space.city} ${row.space.country} ${row.space.currency.code} ${row.space.building_type} ${priceRangeLabels[row.type]}`;
        return string.toLowerCase().includes(term.toLowerCase());
    };

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1080px)' });
    const { _root } = useStyles({ isNarrowWidth });

    return (
        <Box className={_root}>
            <h1>Solicitudes de Cambio de Precio</h1>

            <Box style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 16 }}>
                {Object.keys(RequestedSpacePriceChangeStatusEnum).map((status) => (
                    <Button
                        variant={selectedStatus === status ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => getRequestedSpacePriceChanges(status)}
                    >
                        {`${requestedSpacePriceChangeStatusLabels[status]}s`}
                    </Button>
                ))}
            </Box>

            {request.status === ResponseStatusEnum.ERROR && <Typography>Error</Typography>}

            {openModal === 'APPROVE' &&
                <ApproveOrRejectRequestedSpacePriceChangeModal
                    open={openModal === 'APPROVE'}
                    setOpen={setOpenModal}
                    onConfirm={handleModalConfirm}
                    action={openModal}
                    requestedPriceChange={selectedPriceChange}
                />
            }

            {openModal === 'REJECT' &&
                <ApproveOrRejectRequestedSpacePriceChangeModal
                    open={openModal === 'REJECT'}
                    setOpen={setOpenModal}
                    onConfirm={handleModalConfirm}
                    action={openModal}
                    requestedPriceChange={selectedPriceChange}
                />
            }

            <UserInfoModal host={{ ...clickedHost, user: clickedHost }} open={infoModalOpen} setOpen={setInfoModalOpen} />

            <MaterialTable
                title={''}
                data={request.data ? request.data : []}
                icons={TableIcons}
                isLoading={request.status === ResponseStatusEnum.LOADING}
                options={{ pageSize: 20, search: true, searchText: searchQuery }}
                columns={[
                    {
                        title: 'Host',
                        field: 'space.host.user.name',
                        render: (row) => (
                            <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                                <Avatar src={row.space.host_avatar.original_url} />

                                <Link onClick={() => openHostModal(row.space.host_id)} style={{ cursor: 'pointer' }}>
                                    {`${row.space.host_name} ${row.space.host_lastname}`}
                                    {' '}
                                    (<b>#{row.space.host_id}</b>)
                                </Link>
                            </Box>
                        ),
                        customFilterAndSearch
                    },
                    {
                        title: 'ID Espacio',
                        field: 'space.id',
                        render: (row) => (
                            <Box style={{ width: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                <Tooltip title={'Ir al Espacio'}>
                                    <IconButton onClick={() => window.open(`${process.env.REACT_APP_WEB_APP_URL}/publication/${row.space.id}`, '_blank')}>
                                        <LaunchIcon color={'primary'} />
                                    </IconButton>
                                </Tooltip>

                                <Typography>#{row.space.id}</Typography>
                            </Box>
                        )
                    },
                    {
                        title: 'Tipo',
                        field: 'space.type.name',
                        render: (row) => <Box style={{ textTransform: 'capitalize' }}>{row.space.type.name}</Box>,
                    },
                    {
                        title: 'Ubicación',
                        render: (row) => <Box>{row.space.city} - {row.space.country}</Box>,
                    },
                    {
                        title: 'Precio Anterior',
                        field: 'oldValue',
                        render: (row) => <PriceDisplay value={row.oldValue} currency={row.space.currency}/>,
                    },
                    {
                        title: 'Precio Solicitado',
                        field: 'newValue',
                        render: (row) => <PriceDisplay value={row.newValue} currency={row.space.currency}/>,
                    },
                    {
                        title: 'Rango',
                        field: 'type',
                        render: (row) => <Box>{priceRangeLabels[row.type]}</Box>,
                    },
                    {
                        title: 'Motivo',
                        field: 'reason',
                        hidden: selectedStatus === RequestedSpacePriceChangeStatusEnum.PENDING,
                        render: row => <Box>{row.reason || '-'}</Box>
                    },
                    {
                        title: 'Fecha de solicitud',
                        field: 'createdDate',
                        render: row => <Box>{moment(row.createdDate).format('LLL')}</Box>
                    },
                    {
                        hidden: selectedStatus !== RequestedSpacePriceChangeStatusEnum.PENDING,
                        render: (row) => (
                            <Box>
                                <Tooltip title={'Rechazar Solicitud'} style={{ color: 'red', rotate: '180deg' }}>
                                    <IconButton color={'inherit'} onClick={() => handleOpenModal('REJECT', row)}>
                                        <ThumbUpIcon color={'inherit'} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={'Aprobar Solicitud'} style={{ color: 'green' }}>
                                    <IconButton color={'primary'} onClick={() => handleOpenModal('APPROVE', row)}>
                                        <ThumbUpIcon color={'inherit'} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )
                    },
                    {
                        title: 'Motivo',
                        field: 'reason',
                        hidden: selectedStatus === RequestedSpacePriceChangeStatusEnum.PENDING,
                        render: (row) => <Box>{row.reason || '-'}</Box>
                    },
                ]}
                localization={{
                    header: { actions: '' },
                    toolbar: { searchPlaceholder: 'Buscar...' },
                    body: { emptyDataSourceMessage: 'No hay datos para mostrar.' }
                }}
            />
        </Box>
    );

};

export default RequestedSpacePriceChanges;

// External components
import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';

// Utils & Config
import { spaceServicesDictionary } from '../../helpers/dictionaries/spaceServicesDictionary';
import { BlockServiceTypeEnum, blockServiceTypeLabels } from '../../helpers/enums/BlockServiceTypeEnum';

const BlockServiceInputs = ({ toggleSelectedService, updateServiceType, updateServiceQuantity, updateServicePrice, blockIndex, dbService, blockService }) => {

    const disabledType = !blockService;
    const disabledQuantity = !blockService?.type || blockService.type !== BlockServiceTypeEnum.PER_UNIT;
    const disabledPrice = !blockService?.type || (!disabledQuantity && !blockService?.quantity) || blockService?.type == BlockServiceTypeEnum.FREE;

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 10 }}>
            <FormControl style={{ marginLeft: '10px' }}>
                <FormControlLabel
                    control={
                        <>
                            <Checkbox
                                onChange={(e) => {
                                    toggleSelectedService(blockIndex, dbService, e.target.checked);
                                }}
                                checked={Boolean(blockService)}
                            />

                            <img src={dbService.image.url} style={{ margin: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} alt={''} />
                        </>
                    }
                    label={spaceServicesDictionary[dbService.name]}
                />
            </FormControl>

            <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: 16 }}>
                <FormControl variant={'outlined'} style={{ width: '240px' }}>
                    <InputLabel id={'service_type_select'}>Tipo</InputLabel>

                    <Select
                        labelId={'service_type_select'}
                        value={blockService?.type || ''}
                        onChange={(e) => updateServiceType(blockIndex, dbService.service_id, e.target.value)}
                        label={'Tipo'}
                        disabled={disabledType}
                    >
                        <MenuItem value={BlockServiceTypeEnum.FREE}>{blockServiceTypeLabels[BlockServiceTypeEnum.FREE]}</MenuItem>
                        <MenuItem value={BlockServiceTypeEnum.PER_DESKTOP}>{blockServiceTypeLabels[BlockServiceTypeEnum.PER_DESKTOP]}</MenuItem>
                        <MenuItem value={BlockServiceTypeEnum.PER_UNIT}>{blockServiceTypeLabels[BlockServiceTypeEnum.PER_UNIT]}</MenuItem>
                        <MenuItem value={BlockServiceTypeEnum.FIXED}>{blockServiceTypeLabels[BlockServiceTypeEnum.FIXED]}</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    style={{ width: '100px' }}
                    label={'Capacidad'}
                    type={'number'}
                    variant={'outlined'}
                    disabled={disabledQuantity}
                    value={blockService?.quantity || ''}
                    onChange={(e) => updateServiceQuantity(blockIndex, dbService.service_id, e.target.value)}
                />

                <TextField
                    style={{ width: '120px' }}
                    label={'Precio'}
                    type={'number'}
                    variant={'outlined'}
                    disabled={disabledPrice}
                    value={blockService?.price || ''}
                    onChange={(e) => updateServicePrice(blockIndex, dbService.service_id, e.target.value)}
                />
            </Box>
        </Box>
    );
};

export default BlockServiceInputs;

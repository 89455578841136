/**
     * This function updates a nested property in an object. (mutates the original object)
     *
     * @param {object} object - The object to update.
     * @param {string} path - The path to the nested property.
     * @param {any} newValue - The new value to set for the nested property.
     */
export const updateNestedProperty = (object, path, newValue) => {
    const [head, ...rest] = path.split('.');

    rest.length
        ? updateNestedProperty(object[head], rest.join('.'), newValue)
        : object[head] = newValue;
};

import { makeStyles } from '@material-ui/core/styles';

export const usePurchaseStyles = makeStyles((theme) => ({
    _shippingRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '40%'
    },
    _formHeader: {
        margin: '10px 0px 10px 20px',
        fontSize: 22,
        fontFamily: 'PoppinsBold',
        textAlign: 'left'
    },
    _formInputTextField: {
        height: 40,
        // fontSize: 16,
        backgroundColor: '#EDEDED'
    },
    _formInputVisualizer: {
        height: 40,
        backgroundColor: 'white'
    },

    _formInputRoot: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'flex-end',
        marginRight: 'auto',
        width: '100%',
        marginLeft: 25
    },
    _formInputTitle: {
        fontSize: 16,
        fontFamily: 'Poppins',
        textAlign: 'left'
    },
    _rowFlex: {
        display: 'flex',
        flexDirection: 'row'
    },


}));

// External components
import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress,
} from "@material-ui/core";

// Utils & Config
import AdminService from "../../services/AdminService";

// Internal components
import { PriceDisplay } from "../atoms/PriceDisplay";

const PromocodeBookingsModal = ({ open, setOpen, promocode }) => {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true);
            AdminService.getPromoCodeBookings(promocode.id).then(resp => {
                setBookings(resp.data);
            }).catch(console.log).finally(() => setLoading(false));
        } else {
            setBookings([]);
        }
        // eslint-disable-next-line
    }, [open]);

    const handleCloseCodeUses = () => {
        setOpen(false);
    };

    return (
        <Dialog onClose={handleCloseCodeUses} aria-labelledby="simple-dialog-title" open={open} maxWidth="lg" fullWidth>
            <DialogTitle>Listado de Bookings donde se usó: <b>{promocode?.coupon}</b></DialogTitle>

            {loading && <CircularProgress />}

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>
                            <b>Booking ID</b>
                        </TableCell>
                        <TableCell align='center'>
                            <b>Host</b>
                        </TableCell>
                        <TableCell align='center'>
                            <b>Trabajador</b>
                        </TableCell>
                        <TableCell align='center'>
                            <b>Monto de Descuento</b>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {bookings.map(b => (
                        <TableRow hover key={b.id + "_bbbb"}>
                            <TableCell align='center'>{'#' + b.id}</TableCell>
                            <TableCell align='center'>{`${b?.space?.host?.name || ''} ${b?.space?.host?.lastname || ''} (${b?.space?.host?.email})`}</TableCell>
                            <TableCell align='center'>{`${b?.user?.name || ''} ${b?.user?.lastname || ''} (${b?.user?.email})`} </TableCell>
                            <TableCell align='center'><PriceDisplay value={b.promocode_amount} currency={b.currency} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <DialogActions>
                <Button onClick={handleCloseCodeUses} color='primary'>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PromocodeBookingsModal;


import { Box, Button, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import AdminService from '../../services/AdminService';

const FinalPdfVisualizer = ({ bookingId }) => {
    const [loading, setLoading] = useState(false);

    const openContract = () => {
        setLoading(true);

        AdminService.getLinkToSignContractByAdmin(bookingId).then(resp => {
            if (resp.data?.redirectUrl) {
                const newWindow = window.open(resp.data?.redirectUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null;
            }
        })
            .catch(console.log)
            .finally(() => setLoading(false));
    }

    const styles = {
        boxContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: 5,

        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '50px',
        },
        contractButton: {
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.180261)',
            backgroundColor: '#F0F0F0',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            height: '50px'
        },
        pdfIcon: {
            width: '25px', height: '25px', marginTop: '5px'
        }
    }

    return <>
        <Box style={styles.boxContainer}>
            <Box style={styles.buttonContainer}>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button style={{ ...styles.contractButton }} onClick={() => openContract()} disabled={loading}>
                        <Box><img src={require('../../assets/images/pdfIcon.png')} alt="" style={{ ...styles.pdfIcon }} /></Box>
                        <Box style={{ marginLeft: '8px' }}>
                            <span style={{ fontSize: "14px", fontWeight: 700 }}>
                                {loading ? <CircularProgress /> : 'Ver contrato'}
                            </span>
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Box>
    </>
}

export default FinalPdfVisualizer
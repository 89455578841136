import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import SummaryPdfSelector from "../molecules/SummaryPdfSelector/SummaryPdfSelector";
import TableIcons from "../molecules/TableIcons";
import moment from "moment";
import { PriceDisplay } from "../atoms/PriceDisplay";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const normalise = (value, total) => +(+value * 100 / +total).toFixed(0) || 0;

export const PointsBudgetsListModal = ({ company, open, setOpen, refreshCompanies }) => {
    const [pointsBudgets, setPointsBudgets] = useState([]);
    const [loading, setLoading] = useState(false);

    const getPointsBudgets = async () => {
        try {
            setLoading(true);
            const res = await AdminService.getCompanyPointsBudgets(company.id);
            const budgets = (res?.data || []).sort((a, b) => new Date(b.date) - new Date(a.date))
            setPointsBudgets(budgets);
            res && setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const updateBudget = async (newData, mode = 'OVERRIDE') => {
        try {
            await AdminService.updatePointsBudget(company?.id, +newData.amountForRenewal, mode, +newData.durationInMonths, +newData.soldValueInUsd);
        } catch (error) {
            console.log(error);
        } finally {
            getPointsBudgets();
        }
    }

    const markAsPaid = (pointsBudgetId) => {
        setLoading(true);
        AdminService.setPointsBudgetPaid(company?.id, pointsBudgetId, true).then(async resp => {
            await getPointsBudgets();
            setLoading(false);
        }).catch(console.log);
    };

    useEffect(() => {
        open && getPointsBudgets();
    }, [company, open]);

    const addPoints = () => {
        let pointsToAdd = prompt(`¿Cuántos POINTS desea agregar al presupuesto del mes actual de la empresa ${company?.name}?`);
        updateBudget({ company_id: company?.id, amountForRenewal: pointsToAdd }, 'ADD');
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                <b>{''}</b>
            </DialogTitle>
            <DialogContent>
                {loading ?
                    <CircularProgress style={{ marginLeft: 600 }} />
                    :
                    <>
                        <MaterialTable
                            icons={TableIcons}
                            title={
                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box>{'Presupuestos de '}<b>{company?.name}</b></Box>
                                    {/*company && <SummaryPdfSelector company={company} />*/}
                                </Box>
                            }
                            editable={{
                                onRowUpdate: (newData) => updateBudget(newData),
                                isEditable: rowData => rowData?.tableData?.id === 0
                            }}
                            options={{ pageSize: 10, pageSizeOptions: [10, 25, 50, 100], sorting: true, thirdSortClick: false }}
                            columns={[
                                { title: 'ID', render: b => <div>{`#${b.id}`}</div> },
                                { title: 'Mes', render: b => <div>{moment(b.date).utc().format('MMMM YYYY')}</div> },
                                { title: 'Suscripción', render: b => <div>{b.amountForRenewal}</div>, field: 'amountForRenewal' },
                                { title: 'Extra', render: b => <div>{+b.total - +b.amountForRenewal}</div> },
                                { title: 'Usado', render: b => <div>{b.usedAmount} ({normalise(b.usedAmount, b.total)}%)<LinearProgress variant="determinate" value={normalise(b.usedAmount, b.total)} /></div> },
                                { title: 'Disponible', render: b => <div>{b.availableAmount}</div> },
                                { title: 'Total del período', render: b => <div>{b.total}</div> },
                                { title: 'Renovacion cada', render: b => <div>{b.durationInMonths} {b.durationInMonths > 1 ? 'meses' : 'mes'}</div>, field: 'durationInMonths' },
                                { title: 'Valor de Venta del Point', render: b => <div><PriceDisplay value={b.soldValueInUsd} currency="USD" /></div>, field: 'soldValueInUsd' },
                                {
                                    title: <b>Pagado</b>,
                                    render: rowData => {
                                        return <>
                                            {rowData.paid ?
                                                <CheckCircleIcon style={{
                                                    paddingLeft: "30px",
                                                    color: "green",
                                                    alignContent: "center",
                                                }} />
                                                : ''
                                            }
                                        </>;
                                    }
                                },
                                {
                                    title: <b>Acciones</b>,
                                    render: rowData => !rowData?.paid && <Tooltip title="Marcar como pagado">
                                        <IconButton color="primary" onClick={() => markAsPaid(rowData.id)} disabled={loading}>
                                            <ThumbUpIcon />
                                        </IconButton>
                                    </Tooltip>
                                },
                            ]}
                            actions={[
                                {
                                  icon: TableIcons.Add,
                                  tooltip: 'Agregar POINTS',
                                  isFreeAction: true,
                                  onClick: () => addPoints()
                                }
                              ]}
                            data={pointsBudgets}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} de {count}'
                                },
                                header: {
                                    actions: ''
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay presupuestos para mostrar',
                                    filterRow: {
                                        filterTooltip: 'Filtros'
                                    },
                                }
                            }}
                        />
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};
import { Box, Checkbox, FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from './categories.styles';
import moment from 'moment';
import useResolutions from '../../helpers/hooks/useResolutions';
import FeaturesService from '../../services/FeaturesService';
import MoneyInput from '../molecules/MoneyInput';

const EARLIER_CHECKIN_ALLOWED = '00:00'; // h:mm
const LATER_CHECKOUT_ALLOWED = '23:30'; // h:mm

const normalizeTime = (t) => t?.toLocaleString('en-US', { minimumIntegerDigits: 2 }) || '';

const completeTimesOptions = Array(24).fill(null).map((_, i) => i).map(h => [`${normalizeTime(h)}:00`, `${normalizeTime(h)}:30`]).flat();

const allowedTimeOptions = completeTimesOptions.slice(
    completeTimesOptions.indexOf(EARLIER_CHECKIN_ALLOWED),
    completeTimesOptions.indexOf(LATER_CHECKOUT_ALLOWED) + 2
);

const CategoryFoodLimits = ({
    category,
    companyCountry,
    currency = '',
    updateCategoryLimitField,
    forValue,
    setForValue,
    reloadActiveFeatures
}) => {

    const { isMobile, isLargeScreen, isUp1366Res, isMediumScreen } = useResolutions();

    const days = [...'0123456'].map((d) => {
        const dayName = moment().day(d).format('dddd')
        return {
            name: dayName.slice(0, 1).toUpperCase() + dayName.slice(1),
            key: d
        }
    })

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: 260,
            marginBottom: '20px',
            width: '50%'
        },
        calendarBoxes: {
            width: '45%',
        },
    };
    const [editDailyLimitAmount, setEditDailyLimitAmount] = useState(false);
    const [checkinOptions, setCheckinOptions] = useState(allowedTimeOptions);
    const [checkoutOptions, setCheckoutOptions] = useState(allowedTimeOptions);

    const {
        _checkBoxStyle,
    } = useStyles({ isMobile, isLargeScreen, isUp1366Res });

    const handleDayChange = async (key) => {
        const payload = {
            id: forValue.categoryLimitId,
            companyCountry: companyCountry?.id,
            featureCategory: category.id,
            spaceCountryAllowedIDs: forValue.spaceCountries
        };
        if (forValue.daysAvailable.map(String).includes(String(key))) {
            setForValue(fv => ({
                ...fv,
                daysAvailable: forValue.daysAvailable.filter((day) => String(day) !== String(key))
            }));
            const value = forValue.daysAvailable.filter((day) => String(day) !== String(key))
            payload.daysAvailable = value
        } else {
            setForValue(fv => ({
                ...forValue,
                daysAvailable: [...forValue.daysAvailable, +key]
            }));
            payload.daysAvailable = [...forValue.daysAvailable, +key];
        }
        if(category.id) {
            await FeaturesService.updateCategoryLimit(companyCountry?.company?.id, payload);
            reloadActiveFeatures();
        } 
    };

    const handleSelectChangeTime = (e, checkin) => {
        const selectedValue = e.target.value;
        const key = checkin ? 'checkin' : 'checkout';
        const field = checkin ? 'hourFrom' : 'hourTo';

        setForValue({ ...forValue, [key]: selectedValue });
        updateCategoryLimitField({ field, value: selectedValue });
    };

    useEffect(() => {
        setCheckoutOptions(allowedTimeOptions.slice(
            allowedTimeOptions.indexOf(forValue?.checkin) + 2,
            allowedTimeOptions.indexOf(allowedTimeOptions[allowedTimeOptions.length - 1]) + 1
        ));
    }, [forValue?.checkin]);

    useEffect(() => {
        setCheckinOptions(allowedTimeOptions.slice(
            allowedTimeOptions.indexOf(allowedTimeOptions[0]),
            allowedTimeOptions.indexOf(forValue?.checkout)
        ));
    }, [forValue?.checkout]);

    return (<>
        {/* FOOD - LIMITE DIARIO  */}
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginLeft: isLargeScreen ? 40 : null }}>
            <Box style={{ alignItems: 'center', width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                <Checkbox
                    classes={{ checked: _checkBoxStyle }}
                    checked={forValue?.allowDailyLimit}
                    disabled={false}
                    onChange={(e) => {
                        setForValue({ ...forValue, allowDailyLimit: e.target.checked });
                        updateCategoryLimitField({ field: 'hasDailyLimitAmount', value: e.target.checked });
                    }}
                    name="has_daily_limit"
                    sx={{ borderRadius: 15 }}
                />
                <Box style={{ minWidth: '50%', color: forValue?.allowDailyLimit ? 'black' : 'grey', fontSize: 14 }}>Limite diario</Box>
            </Box>

            <Box style={{ width: editDailyLimitAmount ? '50%' : null, scale: '85%' }}>
                {forValue?.allowDailyLimit ? <MoneyInput
                    isUpdating={false}
                    readOnly={!forValue?.allowDailyLimit}
                    inputValue={Number(forValue?.dailyLimitAmount).toFixed(0)}
                    displayValue={forValue?.dailyLimitAmount || 0}
                    onInputValueChange={e => {
                        const fixedValue = Number(e.value).toFixed(0)
                        setForValue({ ...forValue, dailyLimitAmount: fixedValue });
                    }}
                    onEditClick={() => { setEditDailyLimitAmount(true); }}
                    isEditing={editDailyLimitAmount}
                    currency={currency}
                    // isLoading={isLoading}
                    onClickConfirm={e => {
                        updateCategoryLimitField({ field: 'dailyLimitAmount', value: +forValue?.dailyLimitAmount });
                        setTimeout(() => {
                            setEditDailyLimitAmount(false);
                        }, 500);
                    }}
                    style={{ fontWeight: 900, marginLeft: 3, display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'left' : 'flex-end', width: editDailyLimitAmount && isMediumScreen ? '85%' : isMediumScreen ? '98%' : '90%', fontSize: isMobile ? 14 : 16, paddingLeft: editDailyLimitAmount ? null : isUp1366Res ? '42px' : '48px' }}
                /> : <Box style={{ fontSize: 13, color: 'grey', paddingLeft: '48px' }}>Credito disponible</Box>}
            </Box>
        </Box>

        {/* FOOD - LIMITE HORARIO  */}

        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginLeft: isLargeScreen ? 40 : null }}>
            <Box style={{ alignItems: 'center', width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>

                <Checkbox
                    classes={{ checked: _checkBoxStyle }}
                    checked={forValue?.allowCheckTimes}
                    disabled={false}
                    onChange={(e) => {
                        updateCategoryLimitField({ field: 'hasHourlyLimit', value: e.target.checked });
                        setForValue({ ...forValue, allowCheckTimes: e.target.checked });
                    }}
                    name="has_hour_limit"
                    sx={{ borderRadius: 15 }}
                />
                <Box style={{ minWidth: '45%', color: forValue?.allowCheckTimes ? 'black' : 'grey', fontSize: 14 }}>Limite horario</Box>
            </Box>
            <Box style={{ width: '50%', scale: '85%' }}>
                {
                    forValue?.allowCheckTimes
                        ? <FormControl style={{ ...styles.formControl, order: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: 10, marginLeft: -20, scale: isUp1366Res ? '88%' : '100%' }} >
                            <FormControl style={{ marginLeft: '-20px', width: isMobile ? '100%' : isUp1366Res || isMediumScreen ? '47%' : '47%', marginBottom: isMobile ? '25px' : '0', marginRight: 15 }}>
                                <Select disabled={!forValue?.allowCheckTimes} id="checkInTime" value={forValue?.checkin} onChange={e => handleSelectChangeTime(e, true)}>
                                    {checkinOptions.map(ht => <MenuItem key={`ht_${ht}`} value={ht}>{moment(ht, ["HH:mm"]).format("hh:mm A")}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: isMobile ? '100%' : isUp1366Res || isMediumScreen ? '47%' : '47%' }}>
                                <Select disabled={!forValue?.allowCheckTimes} id="checkOutTime" value={forValue?.checkout} onChange={e => handleSelectChangeTime(e, false)}>
                                    {checkoutOptions.map(ht => <MenuItem key={`ht_${ht}`} value={ht}>{moment(ht, ["HH:mm"]).format("hh:mm A")}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </FormControl>
                        : <Box style={{ fontSize: 13, color: 'grey', paddingLeft: '48px' }}>sin limite horario</Box>}
            </Box>
        </Box>

        {/* FOOD - LIMITE DE DIAS */}

        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', marginLeft: isLargeScreen ? 40 : null }}>
            <Box style={{ alignItems: 'center', width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>

                <Checkbox
                    classes={{ checked: _checkBoxStyle }}
                    checked={forValue?.hasDaysLimitation}
                    disabled={false}
                    onChange={(e) => {
                        updateCategoryLimitField({ field: 'hasDaysLimitation', value: e.target.checked });
                        setForValue({ ...forValue, hasDaysLimitation: e.target.checked });
                    }}
                    name="has_days_limitation"
                    sx={{ borderRadius: 15 }}
                />
                <Box style={{ minWidth: '45%', color: forValue?.hasDaysLimitation ? 'black' : 'grey', fontSize: 14 }}>dias disponible</Box>
            </Box>

            {forValue.hasDaysLimitation &&
                <Box style={{ alignItems: 'flex-start', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    {days.map((day) => {
                        const checked = forValue?.daysAvailable?.map((d) => `${d}`).includes(day.key);
                        return (
                            <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <Checkbox
                                    classes={{ checked: _checkBoxStyle }}
                                    checked={checked}
                                    onChange={(e) => {
                                        handleDayChange(day.key)
                                    }}
                                    name="days_available"
                                />
                                <Box style={{ minWidth: '45%', color: forValue?.daysAvailable?.includes(day.key) ? 'black' : 'grey', fontSize: 14 }}>
                                    {day.name}
                                </Box>
                            </Box>
                        )
                    })}
                </Box>}
        </Box>

    </>
    );
};

export default CategoryFoodLimits;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
    {
        _root: {
            width: 350,
            height: 45,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        _container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: 35,
            width: '100%',
            margin: '0px 10px'
        },
        _rowIcon: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 5px'
        },
        _pdfRoot:{
            width: '55%', placeSelf: 'center'
        },
        _dateSel: {
            fontSize: 12,
            marginLeft: 5
        },
        _pdfBtn: {
            height: 25,
            width: 'auto',
            border: '2px solid #9900CC',
            borderRadius: 15,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 5px',
            cursor: 'pointer'
        },
        _pdfTxt: {
            fontSize: 11,
            fontFamily: 'PoppinsBold',
            color: '#9900CC',
            marginLeft: 10
        },
        // MONTH SELECTOR
        _monthRoot: {
            maxHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            width: '95%',
        },
        _monthContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width:'100px'
        },
        _monthText: {
            fontFamily: 'Poppins'

        },

        _menuText: {
            marginLeft: '12px',
            textTransform: 'none',
            fontWeight: 400,
            fontSize: 10,
            // fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: '300',
            marginLeft: 5,
            marginRight: 'auto',
            '&:hover': {
                fontWeight: 'bold',
                textDecoration: 'underline',
                cursor: 'pointer',
            },

        },
        // PDF
        _iconPosition: {
            marginTop: 4, marginRight: 10
        },
        _formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            '& .MuiInputLabel-root': {
                fontSize: '1.1rem',
                top: 12
            },
            '& .MuiSelect-outlined': {
                width: 90
            },
        }
    }
));

// not best solution, but works ;) (made by Coco)
export const hackFnToUpdateOrderAfterLoading = () => {
    const element = document.getElementsByClassName('rug-items __card __sorting')[0].getElementsByTagName('div')[0];

    if (!element) return;

    element.dispatchEvent(new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
        screenX: 0,
        screenY: 0
    }));
    setTimeout(() => element.dispatchEvent(new MouseEvent('mouseup', {
        bubbles: true,
        cancelable: true,
        screenX: 0,
        screenY: 0
    })), 201);
};

// External components
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton
} from '@material-ui/core';

// Icons & Images
import AddBoxIcon from '@material-ui/icons/AddBox';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditIcon from '@material-ui/icons/Edit';

// Utils & Config
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import AdminService from '../services/AdminService';
import { PromocodeTypeEnum } from '../helpers/consts';

// Internal components
import NewPromoCodeModal from './modals/NewPromoCodeModal';
import PromocodeBookingsModal from './modals/PromocodeBookingsModal';
import CodeUsesModal from './modals/CodeUsesModal';
import { PriceDisplay } from './atoms/PriceDisplay';

const PromoCodes = () => {
    const [codes, setCodes] = useState([]);
    const [newCodeDialogOpen, setNewCodeDialogOpen] = useState(false);
    const [codeUsesDialogOpen, setCodeUsesDialogOpen] = useState(false);
    const [codeSelectedData, setCodeSelectedData] = useState([{}]);
    const [init, setInit] = useState(false);
    const [codeDataToEdit, setCodeDataToEdit] = useState({});

    const [bookingsDialogOpen, setBookingsDialogOpen] = useState(false);
    const [clickedPromocode, setClickedPromocode] = useState(null);

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    const getPromoCodes = () => {
        AdminService.getPromoCodes().then((resp) => {
            setCodes(resp.data);
        });
    };

    const toggleCodeActive = async (event, code) => {
        const checked = event.target.checked;
        const data = {
            promocode_id: code.id,
            active: event.target.checked,
        };
        await AdminService.updatePromoCode(code.id, data)
            .then((resp) => {
                setCodes((cs) => {
                    const codesFiltered = cs.filter((c) => c.id !== code.id);
                    code.active = checked;
                    return [...codesFiltered, code];
                });
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        if (!init) {
            setInit(true);
            getPromoCodes();
        }
    }, [init]);

    const handleCuponEdit = (c) => {
        setCodeDataToEdit(c);
        setNewCodeDialogOpen(true);
    };

    const handleCodeUses = (c) => {
        setCodeSelectedData(c.users);
        setCodeUsesDialogOpen(true);
    };

    const handlePromocodeBookingsMoreInfo = (promocode) => {
        setClickedPromocode(promocode);
        setBookingsDialogOpen(true);
    };

    const tableTitles = ['Editar', 'Código', 'Monto de Descuento', 'Porcentaje de Descuento', 'Tope de Descuento', 'Tipo', 'Cantidad de Usos', 'Acumulado', 'Desde', 'Hasta', 'Activo'];

    return (
        <Box>
            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                <h1>Códigos Promocionales</h1>

                <Box>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => { setCodeDataToEdit({}); setNewCodeDialogOpen(true); }}
                    >
                        <AddBoxIcon style={{ marginRight: 10 }} />
                        Nuevo Código
                    </Button>
                    <NewPromoCodeModal
                        open={newCodeDialogOpen}
                        setOpen={setNewCodeDialogOpen}
                        getPromoCodes={getPromoCodes}
                        codeData={codeDataToEdit}
                        setCodeDataToEdit={setCodeDataToEdit}
                    />
                    <CodeUsesModal
                        open={codeUsesDialogOpen}
                        setOpen={setCodeUsesDialogOpen}
                        users={codeSelectedData}
                    />
                    <PromocodeBookingsModal
                        open={bookingsDialogOpen}
                        setOpen={setBookingsDialogOpen}
                        promocode={clickedPromocode}
                    />
                </Box>

                <Box overflow={ isNarrowWidth ? 'scroll' : ''}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableTitles.map((title, i) => (
                                    <TableCell align={'center'} key={i}>
                                        <b>{title}</b>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {codes.map((c) => (
                                <TableRow hover key={c.id + '_code'}>
                                    <TableCell align={'center'}>
                                        <IconButton onClick={(e) => handleCuponEdit(c)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        <b>{c.coupon}</b>
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        {c.amount ? <PriceDisplay value={c.amount} currency={c.currency} /> : '-'}
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        {+c.percentage ? `${c.percentage} %` : '-'}
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        {c.limit_amount ? <PriceDisplay value={c.limit_amount} currency={c.currency} /> : '-'}
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        {c.all_users && <b>MÚLTIPLES</b>}
                                        {c.type === +PromocodeTypeEnum.MULTIPLE_USES && <b>MÚLTIPLES</b>}
                                        {c.type === +PromocodeTypeEnum.UNIQUE_USE && <span>ÚNICO</span>}
                                        {c.type === +PromocodeTypeEnum.UNIQUE_USE_PER_USER && <span>ÚNICO POR USUARIO</span>}
                                        {c.type === +PromocodeTypeEnum.CONSUMABLE && <span>CONSUMIBLE</span>}
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        {c.total_uses}
                                        {c.total_uses > 0 &&
                                            <IconButton onClick={(e) => handleCodeUses(c)}>
                                                <InfoOutlinedIcon color={'action'} />
                                            </IconButton>
                                        }
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        <PriceDisplay value={c.total_uses_amount} currency={c.currency || 'USD'} />
                                        {c.total_uses_amount > 0 &&
                                            <IconButton onClick={(e) => handlePromocodeBookingsMoreInfo(c)}>
                                                <InfoOutlinedIcon color={'action'} />
                                            </IconButton>
                                        }
                                    </TableCell>

                                    <TableCell align={'center'}>
                                        {moment(c.valid_from).add(1, 'days').format('DD/MM/YYYY')}
                                    </TableCell>

                                    <TableCell align={'center'}>{moment(c.valid_to).add(1, 'days').format('DD/MM/YYYY')}</TableCell>

                                    <TableCell align={'center'}>
                                        <Switch
                                            checked={c.active}
                                            onChange={(e) => toggleCodeActive(e, c)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </div>
        </Box>
    );
};

export default PromoCodes;

import React, { useState, useEffect } from 'react';
import AdminService from "../services/AdminService";
import {
    Button,
    Box,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import MaterialTable from "material-table";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TableIcons from "./molecules/TableIcons";
import { EmailStatusIcon } from "./EmailStatusIcon";
import PauseIcon from '@material-ui/icons/Pause';
import UserInfoModal from "./UserInfoModal";
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

const PAGE_SIZE = 20;
const STATUS_PROPERTIES = {
    1: 'new_count',
    2: 'approval_pending_count',
    3: 'active_count',
    4: 'blocked_count',
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "25px",
        marginBottom: "25px"
    },
}));

const Store = () => {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState({});
    const [selectedStatus, setSelectedStatus] = useState('');
    const [usersLoading, setUsersLoading] = useState(false);
    const [clickedUser, setClickedUser] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [paginatorNumber, setPaginatorNumber] = useState(10);

    const getUsersByStatus = (statusId, pageSize, pageNumber, searchQuery) => {
        setUsersLoading(true);
        AdminService.getUsersByStatus(statusId, pageSize, pageNumber, searchQuery)
            .then(resp => {
                const { users, ...counts } = resp.data;
                setUsers(users);
                setUsersCount(counts);
                setSelectedStatus(statusId);
                setUsersLoading(false);
            }).catch(e => {
                setUsersLoading(false);
                console.log(e);
            });
    };

    useEffect(() => {
        getUsersByStatus(1, PAGE_SIZE, 1);
    }, []);

    const searchByName = (searchQuery) => {
        setSelectedPage(1);
        getUsersByStatus(selectedStatus, PAGE_SIZE, 1, searchQuery);
    };

    const approveUser = async (userId) => {
        await AdminService.updateUserStatus(userId, 3);
        getUsersByStatus(selectedStatus, PAGE_SIZE, selectedPage);
    };

    const refuseUser = async (userId) => {
        await AdminService.updateUserStatus(userId, 4);
        getUsersByStatus(selectedStatus, PAGE_SIZE, selectedPage);
    };

    const handleChangePagination = (e, value) => {
        setSelectedPage(value);
        getUsersByStatus(selectedStatus, PAGE_SIZE, value);
    };

    const updatePaginator = (totalCount) => {
        setPaginatorNumber(Math.ceil(totalCount / PAGE_SIZE));
    };

    useEffect(() => {
        updatePaginator(usersCount[STATUS_PROPERTIES[selectedStatus]]);
        // eslint-disable-next-line
    }, [selectedStatus]);

    const UserActionButtons = ({ user }) => {
        switch (user.status) {
            case 2:
                return <>
                    <Tooltip title="Activar Usuario" className="approveHost">
                        <IconButton color="primary" onClick={() => approveUser(user.id)}>
                            <ThumbUpIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Suspender Usuario" className="refuseHost">
                        <IconButton color="primary" onClick={() => refuseUser(user.id)}>
                            <PauseIcon />
                        </IconButton>
                    </Tooltip>
                </>;
            case 3:
                return <>
                    <Tooltip title="Suspender Usuario" className="refuseHost">
                        <IconButton color="primary" onClick={() => refuseUser(user.id)}>
                            <PauseIcon />
                        </IconButton>
                    </Tooltip>
                </>;
            case 4:
                return <>
                    <Tooltip title="Activar Usuario" className="approveHost">
                        <IconButton color="primary" onClick={() => approveUser(user.id)}>
                            <ThumbUpIcon />
                        </IconButton>
                    </Tooltip>
                </>;
            default:
                return null;
        }
    };

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <Box>
            <UserInfoModal host={{ ...clickedUser, user: clickedUser }} open={infoModalOpen} setOpen={setInfoModalOpen} />
            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                <h1>Usuarios</h1>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant={selectedStatus === 1 ? "contained" : "outlined"} color="primary" onClick={() => getUsersByStatus(1, PAGE_SIZE, 1)}>{`Nuevos (${usersCount.new_count || 0})`}</Button>
                    <Button variant={selectedStatus === 2 ? "contained" : "outlined"} color="primary" onClick={() => getUsersByStatus(2, PAGE_SIZE, 1)}>{`Pendientes (${usersCount.approval_pending_count || 0})`}</Button>
                    <Button variant={selectedStatus === 3 ? "contained" : "outlined"} color="primary" onClick={() => getUsersByStatus(3, PAGE_SIZE, 1)}>{`Activos (${usersCount.active_count || 0})`}</Button>
                    <Button variant={selectedStatus === 4 ? "contained" : "outlined"} color="primary" onClick={() => getUsersByStatus(4, PAGE_SIZE, 1)}>{`Suspendidos (${usersCount.blocked_count || 0})`}</Button>
                </div>
                <MaterialTable
                    title={statusLabels[selectedStatus] || ''}
                    icons={TableIcons}
                    isLoading={usersLoading}
                    options={{ pageSize: 20 }}
                    onSearchChange={searchByName}
                    actions={[
                        {
                            icon: () => <InfoIcon />,
                            tooltip: 'User Info',
                            onClick: (event, rowData) => {
                                setClickedUser(rowData);
                                setInfoModalOpen(true);
                            }
                        }
                    ]}
                    columns={[
                        { title: 'ID', render: b => <div>{`#${b.id}`}</div> },
                        { title: 'Nombre', field: 'name', render: u => `${u.name} ${u.lastname || ''}` },
                        { title: 'Mail', field: 'email', render: u => <div style={{ display: 'flex', alignItems: 'center' }}>{u.email} <EmailStatusIcon status={u?.email_verified} /></div> },
                        { title: 'Teléfono', field: 'phone' },
                        { title: 'Fecha de creación', field: 'creation_date', render: u => `${moment(u.creation_date).format('DD-MM-YYYY')}` },
                        { title: 'Fecha aceptación T&C', field: 'lastTermsAccepted', render: u => `${u.lastTermsAccepted ? moment(u.lastTermsAccepted).format('DD-MM-YYYY') : '-'}` },
                        { title: 'Estado', field: 'status', render: u => statusLabels[u.status], sorting: false },
                        {
                            title: 'Acciones',
                            sorting: false,
                            field: '',
                            render: u => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <UserActionButtons user={u} />
                            </div>,
                        },
                    ]}
                    data={users}
                    components={{
                        Pagination: props => (
                            <div className={classes.root}>
                                <Pagination style={{ width: "450px", margin: "0 auto" }} count={paginatorNumber} color="primary" page={selectedPage} onChange={handleChangePagination} showFirstButton showLastButton />
                            </div>
                        ),
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsSelect: 'por página'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay hosts para mostrar.',
                        }
                    }}
                />
            </div>
        </Box>
    );
};

export default Store;


const statusLabels = {
    1: 'Nuevos',
    2: 'Pendientes de aprobación',
    3: 'Activos',
    4: 'Suspendidos'
};

// external components
import React, {  useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

// internal components
import MonthSelector from './MonthSelector.js';
import PdfButton from './PdfButton.js';
// util & config
import { useStyles } from './pdfSelector.styles';
import AdminService from '../../../services/AdminService.js';
import moment from 'moment';

const SummaryPdfSelector = ({ company }) => {

    const { _root, _container, _dateSel, _pdfRoot } = useStyles();

    const [months, setMonths] = useState([]);
    const [selectedDate, setSelectedDate] = useState('no hay fechas');

    const idCompany = company.id;

    const getAvailableMonths = async () => {

        const resp = await AdminService.getAvailableMonths(idCompany);
        const sortedResp = resp.data.sort((a, b) => {
            const fechaA = moment(a);
            const fechaB = moment(b);
            return fechaB.isAfter(fechaA) ? 1 : -1;
        });
        setMonths(sortedResp);
        setSelectedDate(moment(sortedResp[0]));
    };

    useEffect(() => {
        idCompany && getAvailableMonths();
    }, []);

    return (
        <Box className={_root}>
            <Box className={_container}>
                <Box className={_dateSel}>
                    <MonthSelector months={months} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                </Box>
                <Box className={_pdfRoot}>
                    <PdfButton selectedDate={selectedDate} idCompany={idCompany}/>
                </Box>
            </Box>
        </Box>

    );
};

export default SummaryPdfSelector;

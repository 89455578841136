import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '74.5vw',
        margin: 'auto',
        marginTop: (props) => props.isNarrowWidth ? `${theme.spacing(1.5)}px` : `${theme.spacing(11)}px`,
        marginBottom: `${theme.spacing(4)}px`,
    },
    cardsContainer: {
        padding: (props) => props.isNarrowWidth ? `${theme.spacing(1)}px` : `${theme.spacing(5)}px ${theme.spacing(10)}px`,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: `${theme.spacing(4)}px`,

    }
}));
// External components
import React, { useState, useRef } from 'react';
import { Box } from '@material-ui/core';

// Utils & Config
import { useAuth0 } from '@auth0/auth0-react';
import useIsInViewport from '../../hooks/useIsInViewport';

// Internal components
import NavigationDrawer from '../molecules/NavigationDrawer';
import NavBar from '../molecules/NavBar';

const Navigator = ({ isReadOnlyRole }) => {

    const {  isAuthenticated } = useAuth0();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const viewRef = useRef(null);
    const isNavbarInViewport = useIsInViewport(viewRef);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <Box>
            <NavBar
                toggleDrawer={toggleDrawer}
                viewRef={viewRef}
            />

            {isAuthenticated &&
                <NavigationDrawer
                    isReadOnlyRole={isReadOnlyRole}
                    drawerOpen={drawerOpen}
                    toggleDrawer={toggleDrawer}
                    isNavbarInViewport={isNavbarInViewport}
                />
            }
        </Box>
    );

};

export default Navigator;

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const DEFAULT_DAYS_WEIGHT = {
    monday: 0.15,
    tuesday: 0.25,
    wednesday: 0.3,
    thursday: 0.25,
    friday: 0.05
}

const CalculateMonthlyDayPriceModal = ({ open, setOpen, setPrices }) => {
    const [inputField, setInputFields] = useState({
        area: null,
        priceByUnitArea: null,
        seats: null
    });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const calcMonthlyPriceByDay = ({ area, priceByUnitArea, seats }) => {
        const priceBySeat = (+area * +priceByUnitArea) / +seats;
        return {
            monday: Math.round(DEFAULT_DAYS_WEIGHT.monday * priceBySeat),
            tuesday: Math.round(DEFAULT_DAYS_WEIGHT.tuesday * priceBySeat),
            wednesday: Math.round(DEFAULT_DAYS_WEIGHT.wednesday * priceBySeat),
            thursday: Math.round(DEFAULT_DAYS_WEIGHT.thursday * priceBySeat),
            friday: Math.round(DEFAULT_DAYS_WEIGHT.friday * priceBySeat),
        };
    }

    const onChangeFieldForm = (field, value) => {
        setInputFields(fv => ({ ...fv, [field]: value }));
    }

    const handleClose = async (isConfirm) => {
        if (isConfirm) {
            const a = calcMonthlyPriceByDay(inputField);
            setPrices(a)
        }
        setOpen(false);
    };

    return <Dialog open={open} onClose={() => handleClose(false)} className={isMobile ? 'mobile cancelBookingDialog' : 'cancelBookingDialog'}>
        <DialogTitle style={{ padding: 15 }}><span style={{ fontFamily: 'Poppins' }}>Calcular precio por día</span></DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            <div style={{ fontFamily: 'Poppins' }}>{`Ingrese los datos necesarios para calcular el precio mensual de cada dia de la semana:`}</div>
        </DialogContent>
        <DialogActions>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box style={{ marginBottom: 20 }}>
                    <TextField
                        required
                        value={inputField?.area}
                        type='number'
                        onChange={(e) => onChangeFieldForm('area', +e.target.value)}
                        label={'Superficie total (m2)'}
                        style={{ width: 200 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <br />
                    <TextField
                        required
                        value={inputField?.priceByUnitArea}
                        type='number'
                        onChange={(e) => onChangeFieldForm('priceByUnitArea', +e.target.value)}
                        label={'Valor por m2'}
                        style={{ width: 200 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <br />
                    <TextField
                        required
                        value={inputField?.seats}
                        type='number'
                        onChange={(e) => onChangeFieldForm('seats', +e.target.value)}
                        label={'Cantidad de asientos'}
                        style={{ width: 200 }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box style={{ padding: 15, display: 'flex', flexDirection: 'row' }}>
                    <Button
                        style={{ fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                        onClick={() => handleClose(false)}
                        variant="outlined"
                        color="primary">
                        {'Salir'}
                    </Button>
                    <Button
                        style={{ fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px', marginLeft: 10 }}
                        onClick={() => handleClose(true)}
                        variant="contained"
                        color="primary"
                        autoFocus>
                        Calcular
                    </Button>
                </Box>
            </Box>
        </DialogActions>
    </Dialog>;
};

export default CalculateMonthlyDayPriceModal;

import { Box, Button, CircularProgress, IconButton, TextField } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from "react";
import AlliesService from "../../../services/AlliesService";
import { useStyles } from "./tagSelector.styles";
// import StyledInputBase from '../../styled/StyledInputBase';

export const TagSelector = ({ selectedTags, setSelectedTags, allyId }) => {

    const [tagOptions, setTagOptions] = useState([]);

    const [tagName, setTagName] = useState('');
    const [newTag, setNewTag] = useState(false);
    const [loadingNewTag, setLoadingNewTag] = useState(false);

    const createTag = () => {
        setLoadingNewTag(true);
        AlliesService.createTag({ tag_name: tagName })
            .then(async () => {
                let resp = await AlliesService.getTags();
                setTagOptions(resp?.data || []);
                setNewTag(false);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                getTags();
                setLoadingNewTag(false);
            });
    };

    const getTags = async () => {
        let resp = await AlliesService.getTags();
        setTagOptions(resp?.data || []);
    }

    useEffect(() => {
        getTags()
    }, []);

    const {
        _title,
        _formControl,
        _inputLabel,
        _selectorContainer,
        _newTagContainer,
        _addBtn
    } = useStyles()

    return <Box>
        <Box className={_title}>Seleccione Categorias</Box>

        <FormControl className={_formControl}>
            <InputLabel shrink id="building-type" className={_inputLabel}>TAGS</InputLabel>

            <Box className={_selectorContainer}>
                <Select
                    id="tags"
                    value={selectedTags || []}
                    onChange={e => setSelectedTags(e.target.value)}
                    displayEmpty
                    multiple
                    style={{ flex: 1 }}
                >
                    {tagOptions.map(bo => <MenuItem key={'bo_' + bo.id} value={bo.id}>{bo.tag_name}</MenuItem>)}
                </Select>
            </Box>

            {newTag ?
                <Box className={_newTagContainer}>
                    <TextField value={tagName} onChange={(e) => setTagName(e.target.value)} placeholder={"Seleccione.."} />

                    <IconButton disabled={loadingNewTag} onClick={() => createTag()}>
                        <CheckIcon style={{ color: 'green' }} />
                    </IconButton>

                    <IconButton disabled={loadingNewTag} onClick={() => {
                        setNewTag(false);
                        setTagName('');
                    }}><CloseIcon style={{ color: 'red' }} />
                    </IconButton>

                    {loadingNewTag && <CircularProgress size={14} />}
                </Box>
                :
                <Button
                    startIcon={<AddIcon />}
                    onClick={() => setNewTag(true)}
                    className={_addBtn}>
                    Agregar Tag
                </Button>}
        </FormControl>
    </Box>;
};

// External components
import React, { useState } from 'react';

// Utils & Config
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import SmartOfficeService from '../../services/SmartOfficeService';
import SmartBookingsDisplay from '../organisms/SmartBookingsDisplay';
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './SmartBooking.styles';
import { Box } from '@material-ui/core';

const SmartBooking = () => {
    const [smartBookingsRequest, setSmartBookingsRequest] = useState({ status: ResponseStatusEnum.IDLE, data: [] });

    const getSmartBookings = async (body) => {
        setSmartBookingsRequest({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            const res = await SmartOfficeService.searchSmartBookings(body);

            setSmartBookingsRequest({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setSmartBookingsRequest({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1080px)' });
    const { _root } = useStyles({ isNarrowWidth });

    return (
        <Box className={_root}>
            <SmartBookingsDisplay
                smartBookingsRequest={smartBookingsRequest}
                getSmartBookings={getSmartBookings}
            />
        </Box>
    );
};

export default SmartBooking;

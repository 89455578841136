import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        // marginRight: `${theme.spacing(4)}px`,
        margin: 'auto'
    },
    container: {
        marginTop: `${theme.spacing(4)}px`,
        width: (props) => props.isLessThan1300pxWidth ? '95vw' : '70vw',
        display: 'flex',
        flexDirection: (props) => props.isLessThan1300pxWidth ? 'column-reverse' : 'row',
        marginLeft: (props) => props.isLessThan1300pxWidth ?  '0' :  `${theme.spacing(8)}px`,
        justifyContent: 'space-around',
    },
    title: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        marginTop: `${theme.spacing(3)}px`,
        marginLeft: (props) => props.isLessThan1300pxWidth ?  '0' :  `${theme.spacing(8)}px`,
        fontSize: `${theme.spacing(2)}px`,
        fontFamily: 'PoppinsBold',
        textAlign: (props) => props.isLessThan1300pxWidth ? 'center' : 'left',
    },
    chartContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: (props) => props.isLessThan1300pxWidth ? '70%' : '30%',
        margin: (props) => props.isLessThan1300pxWidth ? '0 auto' : '',
        marginTop: (props) => props.isLessThan1300pxWidth ? `${theme.spacing(2)}px` : '',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: (props) => props.isLessThan1300pxWidth ? 'column' : 'row',
        gap: (props) => props.isLessThan1300pxWidth ? `${theme.spacing(1)}px` : `${theme.spacing(2.5)}px`,
        justifyContent: 'space-around',
        margin: `${theme.spacing(2)}px`,
        marginBottom: `${theme.spacing(4)}px`,
    },
    selectContainer: {
        marginBottom: `${theme.spacing(4)}px`,
    },
    legendContainer: {
        // maxWidth: '550px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: (props) => props.isLessThan1300pxWidth ? `${theme.spacing(1)}px` : `${theme.spacing(2.5)}px`,
        justifyContent: 'left',
        alignContent: 'center',
        alignItems: 'center',
        margin: `${theme.spacing(3.5)}px`,
    },
    helperText: {
        marginTop: `${theme.spacing(1)}px`,
    },
}));
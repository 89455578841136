export const spaceServicesDictionary = {
    'restroom': 'Baño',
    'kitchen': 'Cocina',
    'air-condition': 'Aire Acondicionado',
    'heating': 'Calefacción',
    'wifi': 'Wifi',
    'chair': 'Silla Confortable',
    'coffee': 'Café',
    'hot-water': 'Agua Caliente',
    'lunch': 'Almuerzo',
    'snack': 'Snacks',
    'smart-tv': 'Smart TV',
    'printer': 'Impresora',
    'play-room': 'Espacio recreación',
    'ping-pong': 'Mesa Ping Pong',
    'balcony': 'Balcón',
    'barbecue': 'Asador Disponible',
    'green-space': 'Espacio Verde',
    'lunch-space': 'Espacio para Almorzar',
    'clean-order': 'Limpio y Ordenado',
    'headphone': 'Auriculares',
    'charger': 'Cargadores',
    'office-item': 'Elementos de oficina',
    'blackboard': 'Pizarra',
    'book': 'Libros de Lectura',
    'play-station': 'PlayStation',
    'projector': 'Proyector',
    'call-box': 'Box para call',
    'sanitized': 'Ambiente sanitizado (Covid-19)',
    'dedicated-wifi': 'WIFI Dedicado',
    'branding': 'Branding Digital',
    'meetingFor2': 'Sala de Reunión (2)',
    'meetingFor6': 'Sala de Reunión (6)',
    'meetingFor12': 'Sala de Reunión (12)',
    'gel-alcohol': 'Alcohol en Gel',
    'fridge': 'Heladera',
    'parking': 'Estacionamiento',
    'phone': 'Telefonía',
    'gym': 'Gimnasio',
    'monitor': 'Monitor'
};

// External components
import {
    Box,
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputBase,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import is from 'is_js';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import DataService from '../../services/DataService';
import FormInput from '../atoms/FormInput';
import { useStyles } from '../pages/store.styles';
// import ProductCountrySelector from "../molecules/Allies/ProductCountrySelector";
import DropzoneArea from '../../libs/Dropzone/DropzoneArea';
import StoreService from '../../services/StoreService';
import ImageUploadGallery from '../molecules/ImageUploadGallery';

const errorEnum = {
    COMPANY_NAME: 'Ingrese un Nombre de Aliado',
    SHORT_DESCRIPTION: 'Ingrese una Descripcion',
    LOGO: 'Ingrese un Logo',
    COUNTRY: 'Ingrese un pais',
    URL: 'Ingrese una Url',
    NONE: null,
};


const ProductModal = ({ open, setOpen, dataToEdit, refreshList, editInstance, setEditInstance, currentProduct, setCurrentProduct }) => {
    const [loading, setLoading] = useState(false);





    const haveDataToEdit = dataToEdit != null;

    const [newHash, setNewHash] = useState(null);


    const [error, setError] = useState(errorEnum.NONE);

    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [images, setImages] = useState(null);

    const validateFormData = () => {
        let errorsAux;
        setError(errorsAux);
        let isValid = errorsAux == null;
        return isValid;
    };


    const joinData = () => {
        const haveNewImage = newHash != null;
        let aux = {};
        aux.description = description;
        aux.title = productName;
        aux.country = +selectedCountry;
        aux.quantity = +quantity;
        aux.price = +price;
        aux.currency = 'USD';
        return aux;
    };

    const editProduct = async () => {
        let payload = joinData();
        payload.id = dataToEdit.id;
        await StoreService.updateProduct(payload)
            .then(() => {
                setEditInstance(false);
                setOpen(false);
                refreshList();
            });
    };

    const resetFields = () => {
        setProductName('');
        setDescription('');
        setQuantity(0);
        setPrice(0);
        setSelectedCountry({});
        setImages(null);
    };

    const handleProductCreation = async () => {
        if (currentProduct) {
            refreshList();
            resetFields();
            setOpen(false);
        }
        else {
            if (validateFormData()) {
                // setLoading(true);
                let payload = joinData();
                await StoreService.createProduct(payload)
                    .then((resp) => {
                        setCurrentProduct(resp.data);
                    });
            }


        }
    };

    const clearModal = () => {
        resetFields();
        setEditInstance(false);
        setOpen(false);
    };

    useEffect(() => {
        if (haveDataToEdit) {
            setCurrentProduct(dataToEdit);
            setImages(dataToEdit.images);
            setProductName(dataToEdit.title);
            setDescription(dataToEdit.description);
            setQuantity(dataToEdit.quantity);
            setPrice(dataToEdit.price);
            setSelectedCountry(dataToEdit.country.id);
        }
        else {
            setCurrentProduct(null);
            setImages([]);
            clearModal();
        }
    }, [dataToEdit]);

    return (
        <Box>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogContent>

                    <>
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormInput
                                    width={'91%'}
                                    title={'Nombre Completo'}
                                    value={productName}
                                    height={'30px'}
                                    required={true}
                                    action={(value) => setProductName(value)}
                                    propName={'fullName'}
                                />
                                <Box>
                                    <ProductCountrySelector
                                        selectedCountry={selectedCountry}
                                        setSelectedCountry={setSelectedCountry}
                                    />
                                </Box>
                                <FormInput
                                    width={'64%'}
                                    title={'Stock'}
                                    value={quantity}
                                    height={'30px'}
                                    required={true}
                                    action={(value) => setQuantity(value)}
                                    propName={'stock'}
                                />
                            </Box>

                            <Box style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
                                    <FormInput
                                        width={'100%'}
                                        title={'Precio en USD'}
                                        value={price}
                                        height={'30px'}
                                        required={true}
                                        action={(value) => setPrice(value)}
                                        propName={'stock'}
                                    />
                                    <FormInput
                                        multiline={true}
                                        width={'100%'}
                                        height={'200px'}
                                        title={'Descripcion'}
                                        value={description}
                                        required={true}
                                        action={(value) => setDescription(value)}
                                        propName={'stock'}
                                    />
                                </Box>

                                <Box
                                    style={{ width: '50%', marginLeft: 68 }}
                                >
                                    {currentProduct &&
                                        <ImageUploadGallery productId={currentProduct.id} images={images} setImages={() => setImages()} />
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </>
                </DialogContent>

                <DialogActions>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        {error != errorEnum.NONE && <Box style={{ color: 'red', fontSize: 22, marginBottom: 8 }}>{error}</Box>}
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>

                            <Box style={{ marginRight: 10 }}>
                                <Button onClick={() => clearModal()} color={'primary'}>
                                    Cancelar
                                </Button>
                            </Box>
                            <Box>

                                {loading
                                    ? <CircularProgress />
                                    : <Button onClick={() => {
                                        editInstance ? editProduct() : handleProductCreation();
                                    }}
                                    variant={'contained'}
                                    color={'primary'}
                                    disabled={loading}
                                    >
                                        {currentProduct ? 'Guardar' : 'Validar'}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProductModal;


export const ProductCountrySelector = ({ selectedCountry, setSelectedCountry }) => {

    const [countries, setCountries] = useState([]);

    const getCountriesOptions = async () => {
        let resp = await DataService.getCountries(true);
        setCountries(resp.data);
    };

    useEffect(() => {
        getCountriesOptions();
    }, []);

    const { _formInputTextField, _formInputTitle } = useStyles();
    return <>
        <Box className={_formInputTitle}>Pais</Box>
        <FormControl
            className={_formInputTextField}
            style={{
                minWidth: 300,
                padding: '10px',
                marginBottom: '20px',
                width: '60%',
                height: 30
            }}
        >

            <Select
                id={'country'}
                // style={{ zIndex: 9999 }}
                value={selectedCountry}
                defaultValue={selectedCountry}
                required
                name={'country'}
                onChange={(e) => setSelectedCountry(e.target.value)}
                displayEmpty
                input={
                    <InputBase
                        style={{ textDecoration: 'none' }}
                    />
                }

                InputProps={{
                    disableUnderline: true, shrink: true
                }}
            >
                {countries
                    .sort((a, b) => a.order - b.order)
                    .map((co) => (
                        <MenuItem key={co.name + '_ctry'} value={co.id}>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <ReactCountryFlag
                                    svg
                                    countryCode={co.name || ''}
                                    style={{ marginRight: 5, width: 32, height: 32 }}
                                />
                                <span>{co.internal_detail}</span>
                            </Box>
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>

    </>;
};





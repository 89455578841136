// External components
import {
    Box,
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core";
import is from "is_js";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import AlliesService from "../../../services/AlliesService";
import DropzoneArea from '../../../libs/Dropzone/DropzoneArea';

const errorEnum = {
    COMPANY_NAME: "Ingrese un Nombre de Aliado",
    SHORT_DESCRIPTION: "Ingrese una Descripcion",
    LOGO: "Ingrese un Logo",
    COUNTRY: "Ingrese un pais",
    URL: "Ingrese una Url",
    NONE: null,
};


const NewAlliesModal = ({ open, setOpen, id, dataToEdit, perkInstance, refreshList, editInstance, setEditInstance }) => {
    const [loading, setLoading] = useState(false);

    const [companyName_es, setCompanyName_es] = useState("");
    const [companyName_en, setCompanyName_en] = useState("");
    const [companyName_pt, setCompanyName_pt] = useState("");

    const companyNameValues = [
        {
            language: "es",
            value: companyName_es,
            action: setCompanyName_es,
        },
        {
            language: "us",
            value: companyName_en,
            action: setCompanyName_en,
        },
        {
            language: "br",
            value: companyName_pt,
            action: setCompanyName_pt,
        },
    ];

    const [shortDescription_es, setShortDescription_es] = useState("");
    const [shortDescription_en, setShortDescription_en] = useState("");
    const [shortDescription_pt, setShortDescription_pt] = useState("");

    const shortDescriptionValue = [
        {
            language: "es",
            value: shortDescription_es,
            action: setShortDescription_es,
        },
        {
            language: "us",
            value: shortDescription_en,
            action: setShortDescription_en,
        },
        {
            language: "br",
            value: shortDescription_pt,
            action: setShortDescription_pt,
        },
    ];

    // LOGO IMG

    const [logoBase64, setLogoBase64] = useState("");
    const [allyLogo, setAllyLogo] = useState(null);

    const [logoLoading, setLogoLoading] = useState(false);

    const haveDataToEdit = dataToEdit != null;

    const [newHash, setNewHash] = useState(null);
    const logoSelected = (images) => {
        if (images[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(images[0]);
            reader.onload = async function () {
                setLogoLoading(true);
                setLogoBase64(reader.result)
                let ally_id = haveDataToEdit ? dataToEdit?.id : 0;
                let data = {
                    ally_id: ally_id,
                    base64: reader.result
                };
                await AlliesService.postImage(data)
                    .then((resp) => {
                        haveDataToEdit && setNewHash(resp.data);
                        !haveDataToEdit && setAllyLogo(resp.data);
                        setLogoLoading(false);
                    });
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    };

    const [landingUrl, setLandingUrl] = useState("");

    const [error, setError] = useState(errorEnum.NONE);

    const validateFormData = () => {
        let errorsAux;
        if (allyLogo == null) { errorsAux = errorEnum.LOGO; }
        if (is.empty(companyName_es)) { errorsAux = errorEnum.COMPANY_NAME; }
        if (is.empty(companyName_en)) { errorsAux = errorEnum.COMPANY_NAME; }
        if (is.empty(companyName_pt)) { errorsAux = errorEnum.COMPANY_NAME; }
        if (is.empty(shortDescription_es)) { errorsAux = errorEnum.SHORT_DESCRIPTION; }
        if (is.empty(shortDescription_en)) { errorsAux = errorEnum.SHORT_DESCRIPTION; }
        if (is.empty(shortDescription_pt)) { errorsAux = errorEnum.SHORT_DESCRIPTION; }
        if (is.empty(landingUrl)) { errorsAux = errorEnum.URL; }
        setError(errorsAux);
        let isValid = errorsAux == null;
        return isValid;
    };


    const joinData = () => {
        const haveNewImage = newHash != null;
        let aux = {};
        if (editInstance) {
            aux.company_name_es = companyName_es;
            aux.company_name_en = companyName_en;
            aux.company_name_pt = companyName_pt;
            aux.ally_landing_url = landingUrl;
            aux.short_description_key_es = shortDescription_es;
            aux.short_description_key_en = shortDescription_en;
            aux.short_description_key_pt = shortDescription_pt;
            if (haveNewImage) {
                aux.ally_logo = newHash;
            } else {
                aux.ally_logo = allyLogo;
            }
        } else {
            aux.company_name_es = companyName_es;
            aux.company_name_en = companyName_en;
            aux.company_name_pt = companyName_pt;
            aux.ally_landing_url = landingUrl;
            aux.ally_logo = allyLogo;
            aux.short_description_key_es = shortDescription_es;
            aux.short_description_key_en = shortDescription_en;
            aux.short_description_key_pt = shortDescription_pt;
        }
        return aux;
    };

    const editAlly = async () => {
        let payload = joinData();
        payload.id = dataToEdit.id;
        await AlliesService.updateAlly(payload)
            .then((res) => {
                setEditInstance(false);
                setOpen(false);
                refreshList();
            });
    };

    const handleCreateAlly = async () => {
        if (validateFormData()) {
            setLoading(true);
            let payload = joinData();
            await AlliesService.createAlly(payload)
                .then((resp) => setLoading(false))
                .finally(() => {
                    refreshList();
                    clearModal();
                });
        }
    };

    const resetFields = () => {
        setCompanyName_es("");
        setCompanyName_en("");
        setCompanyName_pt("");
        setShortDescription_es("");
        setShortDescription_en("");
        setShortDescription_pt("");
        setLogoBase64("");
        setLandingUrl("");
    };

    const clearModal = () => {
        resetFields();
        setEditInstance(false);
        setOpen(false);
    };

    useEffect(() => {
        if (haveDataToEdit) {
            setCompanyName_es(dataToEdit.company_name_es);
            setCompanyName_en(dataToEdit.company_name_en);
            setCompanyName_pt(dataToEdit.company_name_pt);
            setShortDescription_es(dataToEdit.short_description_es);
            setShortDescription_en(dataToEdit.short_description_en);
            setShortDescription_pt(dataToEdit.short_description_pt);
            setLandingUrl(dataToEdit.landing_url);
            setLogoBase64(dataToEdit.ally_logo == null ? '' : dataToEdit.ally_logo.url);
            setAllyLogo(dataToEdit.hash);
        }
        else {
            resetFields();
        }
    }, [dataToEdit]);
    return (
        <Box>
            <Dialog open={open}>
                <DialogTitle style={{ textAlign: "center" }}>
                    <Box style={{ fontWeight: 600, fontSize: 22 }}>
                        {haveDataToEdit
                            ? `Editar Aliado`
                            : 'Nuevo Aliado'
                        }
                    </Box>
                </DialogTitle>

                <DialogContent>

                    <>
                        <Box style={{ width: '100%' }}>

                            <Box style={{ textAlign: '-webkit-center' }}>
                                <LogoUploader logoImg={logoBase64} setLogo={(value) => logoSelected(value)} logoLoading={logoLoading} setLogoLoading={setLogoLoading} />
                            </Box>

                            <br />


                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3>Nombre del Aliado</h3>

                                <Box>
                                    {companyNameValues.map(f => <CompanyNameForm key={"id_" + f.language} value={f.value} action={(value) => f.action(value)} lang={f.language} />)}
                                </Box>
                            </Box>
                            <br />

                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <CompanyNameForm value={landingUrl} action={(value) => setLandingUrl(value)} lang={"url"} />
                            </Box>

                            <br />
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3>Descripcion del Aliado</h3>
                                <Box>
                                    {shortDescriptionValue.map(f => <ShortDescriptionForm key={"id_" + f.language} value={f.value} action={(value) => f.action(value)} lang={f.language} />)}
                                </Box>
                            </Box>


                        </Box>
                    </>
                </DialogContent>

                <DialogActions>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        {error != errorEnum.NONE && <Box style={{ color: 'red', fontSize: 22, marginBottom: 8 }}>{error}</Box>}
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>

                            <Box style={{ marginRight: 10 }}>
                                <Button onClick={() => clearModal()} color='primary'>
                                    Cancelar
                                </Button>
                            </Box>
                            <Box>

                                {loading
                                    ? <CircularProgress />
                                    : <Button onClick={() => {
                                        editInstance
                                            ? editAlly()
                                            : handleCreateAlly();
                                    }}
                                        variant="contained"
                                        color='primary'
                                        disabled={loading}>
                                        Guardar
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default NewAlliesModal;


export const LogoUploader = ({ logoImg, setLogo, logoLoading, setLogoLoading }) => {

    const [isPhotoHover, setIsPhotoHover] = useState(false);

    const styles = {
        imgContainer: {
            height: '135px',
            width: '135px',
            borderRadius: '135px',
            backgroundColor: 'lightgrey',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#383839',
            textTransform: 'none',
            overflow: 'hidden',
            position: 'relative',
            opacity: 1,
            // boxShadow: '0px 0px 46px -11px rgba(0,0,0,0.75)'
            opacity: isPhotoHover ? 0.5 : 1,
        },
        imgLoading: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 99,
            height: '100%',
            width: '100%',
            backgroundColor: '#ffffff',
            opacity: 0.5
        }
    };

    return <>
        <Box
            onMouseEnter={() => setIsPhotoHover(true)}
            onMouseLeave={() => setIsPhotoHover(false)}
            style={styles.imgContainer}>

            {logoLoading &&
                <Box style={styles.imgLoading}>
                    <CircularProgress />
                </Box>
            }

            <DropzoneArea
                onChange={setLogo}
                showAlerts={false}
                filesLimit={1}
                maxFileSize={5000000}
                previewGridProps={{ item: { style: { width: '100%' } } }}
                dropzoneText={(logoImg) ? <img src={logoImg} alt="" style={{ width: '110px', height: '110px' }} /> : "Agregar Logo"} />
        </Box>
        <br />

    </>;
};

export const CompanyNameForm = ({ value, action, lang }) => {
    return <>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

            {lang != "url" ? <ReactCountryFlag
                svg
                countryCode={lang || ""}
                style={{ marginRight: 5, width: 32, height: 32 }}
            /> : <span style={{ marginRight: 5, width: 32, height: 32, fontWeigth: 700 }}>URL</span>}
            <Box>
                <TextField
                    value={value}
                    onChange={(e) => action(e.target.value)}
                    style={{ width: '300px' }}
                />
            </Box>
        </Box>
    </>;
};

export const ShortDescriptionForm = ({ value, action, lang }) => {
    return <>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {lang != "url" ? <ReactCountryFlag
                svg
                countryCode={lang || ""}
                style={{ marginRight: 5, width: 32, height: 32 }}
            /> : <span style={{ marginRight: 5, width: 32, height: 32, fontWeigth: 700 }}>URL</span>}
            <Box>
                <TextField
                    style={{ marginTop: '10px', width: '350px' }}
                    multiline
                    rows={3}
                    variant="outlined"
                    value={value}
                    onChange={(e) => action(e.target.value)}
                    inputProps={{ maxLength: 360 }}
                />
            </Box>
        </Box>
    </>;
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    _switchBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#F0F0F0',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '450px',
        height: '50px',
        textTransform: 'none',
        fontFamily: [
            'Poppins'
        ]
    },
    _switchSelectedBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '450px',
        height: '50px',
        textTransform: 'none',
        fontWeight: 700,
        borderTop: '2px #F0F0F0 solid',
        borderLeft: '2px #F0F0F0 solid',
        borderRight: '2px #F0F0F0 solid',
        fontFamily: [
            'Poppins'
        ]
    },
    _switchContraPerkBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#F0F0F0',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderTop: '2px #F0F0F0 solid',
        borderLeft: '2px #F0F0F0 solid',
        borderRight: '2px #F0F0F0 solid',
        margin: '10px',
        width: '270px',
        height: '50px',
        textTransform: 'none',
        fontFamily: [
            'Poppins'
        ]
    },
    _switchSelectedContraPerkBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '270px',
        height: '50px',
        textTransform: 'none',
        fontWeight: 700,
        borderTop: '2px #F0F0F0 solid',
        borderLeft: '2px #F0F0F0 solid',
        borderRight: '2px #F0F0F0 solid',
        fontFamily: [
            'Poppins'
        ]
    },
    _navBarContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    _navBarBox: {
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        },
    },
    _subNavbarContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '15px 0px'
    },
    _leftBtnsContainer: {
        width: '38%',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    _newAllyBtn: {
        backgroundColor: '#F0F0F0',
        borderRadius: 30,
        height: 35,
        color: 'black'
    },
    _searchFilterContainer: {
        width: '350px',
        marginLeft: 'auto'
    },
    _rigthBtnsPanel: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
    }
}));

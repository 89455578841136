import moment from 'moment';

export const getMonthsBetween = (from, to) => {
    let dateStart = moment.utc(from);
    let dateEnd = moment.utc(to);
    let interim = dateStart.clone();
    let months = [];

    const sortMonthRange = (a, b) => {
        return a.year - b.year || a.month - b.month;
    };

    const pad = (m) => (m < 10) ? '0' + m.toString() : m.toString();

    while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
        let monthInterim = +interim.format('MM');
        let yearInterim = +interim.format('YYYY');

        months.push(
            moment(`${yearInterim}-${pad(monthInterim)}`)
        );

        interim.add(1, 'month');
    }

    return months.sort(sortMonthRange);
};

export const getFormatedMonthsBetween = (from, to, dateFormat = 'YYYY-MM-DD', labelFormat = 'MMMM') => {
    const months = getMonthsBetween(from, to);

    const formattedMonths = months.map((m) => {
        return ({
            month: moment(m).format(dateFormat),
            label: moment(m).format(labelFormat),
        });
    });

    return formattedMonths;
};

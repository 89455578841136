import * as React from "react";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import ReactCountryFlag from "react-country-flag";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Grid,
  Tooltip,
  CircularProgress,
  ThemeProvider,
} from "@material-ui/core";

import { createTheme } from '@material-ui/core/styles';

import { DeleteForeverRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";

import InputMask from "react-input-mask";

import DataService from "../services/DataService";
import AdminService from "../services/AdminService";

import {
  bankOptions,
  contryPayerIdValidations,
  documentTypeCombo,
  bankAccountValidations,
  branchNameValidations,
  dinamicFieldsCountry,
  currencyComboPY
} from "../components/bankOptions.js";
import is from "is_js";

const EMPTY_FOR_VALUE = {
  bank_account: '',
  bank_code: -1,
  bank_branch: '',
  account_type: '',
  beneficiary_name: '',
  beneficiary_lastname: '',
  document_type: '',
  document_id: '',
  bank_province: '',
  bank_city: '',
  remitter_full_name: '',
  remitter_birth_date: '',
  remitter_country: '',
  purpose: '',
  country: '',
  pix: ''
};


const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      formControl: {
        fontSize: '18px',
        background: '#fff'
      },
    },
  },
});
const BankAccountForm = ({ host }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(false);


  const [addModalOpen, setAddModalOpen] = useState(false);
  const [forValue, setForValue] = useState(EMPTY_FOR_VALUE);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankName, setBankName] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState('');
  const [selectedCountry, setSelectedCountry] = useState("");

  let hostId = host?.id;

  const [errors, setErrors] = useState({});
  const [haveErrors, setHaveErrors] = useState(false);

  const generateBankName = (bankCode) => {
    const bank = bankOptions[selectedCountry]?.find((c) => +c.id === +bankCode);
    if (bankCode && bank)
      setBankName(bank?.name);
  };

  const onChangeFieldForm = (e) => {
    e.persist();
    e.preventDefault();
    if (('' + e.target.value)?.length > 0) {
      setErrors((err) => ({ ...err, [e.target.name]: "" }));
    } else {
      setErrors((err) => ({ ...err, [e.target.name]: "empty field" }));
    }

    if (e.target?.name)
      setForValue(fv => ({
        ...fv,
        [e.target.name]: e.target.value,
      }));
  };

  const addBank = async () => {
    try {
      const { bank_account, ...forValueAux } = forValue;
      await AdminService.setBank({
        ...forValueAux,
        bank_account: bank_account.replace(/_/g, ''),
        host_id: hostId,
        bank_branch_name: "",
        beneficiary_country: "",
        bank_province: ""
      })
        .catch(console.log);
      setSelectedCountry("");
      handleClose();
      await getBanks();
    } catch (error) {
      console.log(error);
    }
  };

  const getBanks = async () => {
    let resp = await AdminService.getAllBanks(hostId);
    setBankAccounts(resp.data);
  };

  const getBankById = async (id) => {
    let resp = await AdminService.getBankById(id);
    setForValue({ ...resp.data });
  };

  const setBankToEdit = async (id) => {
    setAddModalOpen(true);
    setLoadingAccount(true);
    id && setSelectedBankAccount(id);
    await getBankById(id);
    setLoadingAccount(false);
  };

  const patchBank = async () => {
    try {
      await AdminService.editBank(hostId, { ...forValue, host_id: hostId });
      setAddModalOpen(false);
      setSelectedBankAccount(null);
      setSelectedCountry("");
      getBanks();
    } catch (error) {
      console.log(error);
    }
  };

  const cleanAndCloseModal = () => {
    setSelectedBankAccount(null);
    setSelectedCountry("");
    handleClose(false);
  };

  const newBankAccount = () => {
    setSelectedCountry("");
    setAddModalOpen(true);
  };

  const [bankToDelete, setBankToDelete] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClose = () => setAddModalOpen(false);

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
    setBankToDelete(null);
  };

  useEffect(() => {
    bankToDelete && setOpenDeleteModal(true);
    // eslint-disable-next-line
  }, [bankToDelete]);

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setForValue(EMPTY_FOR_VALUE);
    getCountriesOptions(null);
    // eslint-disable-next-line
  }, [addModalOpen]);

  useEffect(() => {
    if (bankName && bankName.length) {
      setForValue({
        ...forValue,
        [`bank_name`]: bankName,
      });
    }
    // eslint-disable-next-line
  }, [bankName]);

  useEffect(() => {
    let he = false;
    if (errors && is.not.empty(errors)) {
      for (let key of Object.keys(errors)) {
        if (is.not.empty(errors[key])) {
          he = true;
          break;
        }
      }
    }
    setHaveErrors(he);
  }, [errors]);

  // COUNTRY SELECT COMBO
  const [countries, setCountries] = useState([]);

  const getCountriesOptions = async () => {
    let resp = await DataService.getDlocalCountries();
    setCountries(resp.data);
  };



  return (
    <>
      <Box
        style={{
          background: "#FFFFFF",
          borderRadius: "5px",
          padding: "15px",
          marginBottom: "20px",
          textAlign: "-webkit-center",
        }}
      >
        <Box style={{ padding: "20px 0" }}>
          <span
            style={{
              fontFamily: "PoppinsBold",
              fontWeight: "bold",
              color: "#383839",
              fontSize: "18px",
            }}
          >
            Cuentas bancarias
          </span>

        </Box>
        <Box>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            {bankAccounts?.map((b) => (
              <Grid
                key={"bank_" + b.id}
                item
                xs={12}
                sm={6}
                lg={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  minWidth: "350px",
                }}
              >
                <Box style={{ position: "relative" }}>
                  <Box className={`bank-card `}>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <Box className={`bank-card-text`}>
                        <b>{b.bank_name}</b>
                      </Box>
                      <Box className={`bank-card-text`}>
                        {b.account_type === "c"
                          ? "cuenta corriente"
                          : "caja de ahorro"}
                        : *** {b.bank_account.slice(-3)}
                      </Box>
                      <Box className={`bank-card-text`}>
                        <b>{b.country}</b>
                      </Box>
                      <Box className={`bank-card-text`}>
                        Titular :{" "}
                        {b.beneficiary_name + " " + b.beneficiary_lastname}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      style={{
                        position: "absolute",
                        right: "-54px",
                        bottom: "5%",
                      }}
                      onClick={() => setBankToDelete(b.id)}
                    >
                      <Tooltip title="Delete">
                        <DeleteForeverRounded
                          style={{ cursor: "pointer", padding: "0 15px" }}
                        />
                      </Tooltip>
                    </Box>
                    <Box
                      style={{
                        position: "absolute",
                        right: "-54px",
                        bottom: "50%",
                      }}
                      onClick={() => setBankToEdit(b.id)}
                    >
                      <Tooltip title="Edit">
                        <EditIcon
                          style={{ cursor: "pointer", padding: "0 15px" }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box style={{ marginTop: 25, padding: "10px" }}>
          <Button
            style={{
              backgroundColor: "#00D1BF",
              color: "#ffffff",
              fontFamily: "Poppins",
              borderRadius: "10px",
              boxShadow: "none",
              padding: 10,
              fontWeight: "bold",
              fontSize: "10px 15px",
            }}
            variant="contained"
            onClick={() => newBankAccount()}
          >
            <span style={{ fontSize: '16px', fontFamily: 'PoppinsBold' }}>
              +   Agregar cuenta bancaria
            </span>
          </Button>
        </Box>

        {addModalOpen && (
          <Box
            style={{
              display: "flex",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Dialog open={true} onClose={handleClose} fullScreen={isMobile}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <DialogTitle style={{ padding: 15 }}>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Nueva Cuenta Bancaria
                  </span>
                </DialogTitle>
              </Box>
              <DialogContent style={{ padding: "25px" }}>
                {loadingAccount ? <CircularProgress />
                  :
                  <NewBankForm
                    onChangeFieldForm={onChangeFieldForm}
                    forValue={forValue}
                    generateBankName={generateBankName}
                    countries={countries}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    errors={errors}
                    setErrors={setErrors}
                    bankName={bankName}
                  />}
              </DialogContent>
              <DialogActions
                style={{
                  padding: 15,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    borderColor: "#00D1BF",
                    color: "#00D1BF",
                    fontFamily: "Poppins",
                    borderRadius: "10px",
                    height: "40px",
                    width: "200px",
                  }}
                  onClick={() => cleanAndCloseModal()}
                  color="primary"
                  variant="outlined"
                  autoFocus
                >
                  Cancelar
                </Button>
                <Button
                  style={{
                    backgroundColor: haveErrors ? 'lightgrey' : "#00D1BF",
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                    borderRadius: "10px",
                    height: "40px",
                    width: "200px",
                  }}
                  disabled={loadingSave || haveErrors}
                  onClick={async () => {
                    setLoadingSave(true);
                    if (selectedBankAccount)
                      await patchBank();
                    else
                      await addBank();
                    setLoadingSave(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Guardar
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}

        <AlertDeleteModal
          open={openDeleteModal}
          handleClose={handleCloseModal}
          id={bankToDelete}
          hostId={hostId}
          getBanks={() => getBanks()}
        />
      </Box>
    </>
  );
};

export default BankAccountForm;

const NewBankForm = ({
  onChangeFieldForm,
  forValue,
  generateBankName,
  countries,
  selectedCountry,
  setSelectedCountry,
  errors,
  setErrors,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const validatePayerId = (selectedCountry) => {
    return contryPayerIdValidations[selectedCountry]?.(forValue?.document_id, forValue?.document_type);
  };

  useEffect(() => {
    if (
      selectedCountry &&
      forValue?.document_id &&
      !validatePayerId(selectedCountry)
    ) {
      setErrors((err) => ({
        ...err,
        document_id: "invalid id number for country " + selectedCountry,
      }));
    } else if (forValue?.document_id?.length > 0) {
      setErrors((err) => ({ ...err, document_id: "" }));
    }
    // eslint-disable-next-line
  }, [forValue?.document_id, selectedCountry]);

  // BANK ACCOUNT VALIDATION
  const validateBankAccount = (selectedCountry, bankAccountNum) => {
    if (["BR", "UY"].includes(selectedCountry))
      return true;
    else
      return bankAccountValidations[selectedCountry]?.(forValue?.bank_account);
  };



  useEffect(() => {
    if (
      selectedCountry &&
      forValue?.bank_account &&
      !validateBankAccount(selectedCountry, forValue?.bank_account)
    ) {
      setErrors((err) => ({
        ...err,
        bank_account: "invalid account number for country " + selectedCountry,
      }));
    } else if (forValue?.bank_account?.length > 0) {
      setErrors((err) => ({ ...err, bank_account: "" }));
    }
    // eslint-disable-next-line
  }, [forValue?.bank_account, selectedCountry]);

  const selectCountry = (e) => {
    setSelectedCountry(e.target.value);
    onChangeFieldForm(e);
  };

  useEffect(() => {
    generateBankName(forValue?.bank_code);
    // eslint-disable-next-line
  }, [forValue?.bank_code]);

  useEffect(() => {
    if (forValue.country) setSelectedCountry(forValue.country);
    // eslint-disable-next-line
  }, [forValue?.country]);

  const selectedBankMask =
    branchNameValidations[selectedCountry]?.[forValue?.bank_code] === undefined
      ? branchNameValidations[selectedCountry]?.["default"]
      : branchNameValidations[selectedCountry]?.[forValue?.bank_code];

  const isRequired = selectedCountry && dinamicFieldsCountry[selectedCountry];

  const validateEmail = (event) => {
    if (is.email(event.target.value)) {
      onChangeFieldForm(event);
    } else {
      onChangeFieldForm(event);
      setErrors((err) => ({ ...err, email: "Invalid Email account" }));
    }
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "95%",
        }}
      >
        <Box style={{ maxHeight: "80px" }}>
          <Box>
            <FormControl
              style={{
                minWidth: isMobile ? 120 : 300,
                padding: "10px",
                marginBottom: "20px",
                width: isMobile ? "100%" : "60%",
              }}
            >
              <ThemeProvider theme={theme}>
                <InputLabel
                  shrink
                  id="country-label"
                  style={{ marginLeft: "8px", marginTop: "5px" }}
                >
                  Pais/Region *
                </InputLabel>
              </ThemeProvider>
              <Select
                id="country"
                style={{ zIndex: 9999 }}
                value={selectedCountry}
                defaultValue={selectedCountry}
                required
                name={"country"}
                onChange={(e) => selectCountry(e)}
                displayEmpty
              >
                {countries
                  .sort((a, b) => a.order - b.order)
                  .map((co) => (
                    <MenuItem key={co.name + "_ctry"} value={co.name}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ReactCountryFlag
                          svg
                          countryCode={co.name || ""}
                          style={{ marginRight: 5, width: 32, height: 32 }}
                        />
                        <span>{co.internal_detail}</span>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <br />

        {selectedCountry && (
          <Box>
            <Box
              style={{
                marginRight: "15px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {isRequired.includes("bank_code") ? <><Box>
                <FormControl
                  required
                  variant="outlined"
                  style={{ margin: 2 }}
                >
                  <InputLabel id="bank-name">Banco</InputLabel>
                  <Select
                    style={{
                      width: !isRequired.includes("account_type") &&
                        !isRequired.includes("currency") ? "300px" : "150px"
                    }}
                    labelId="bank-name"
                    name={"bank_code"}
                    value={forValue?.bank_code || -1}
                    onChange={(event) => onChangeFieldForm(event)}
                  >
                    {bankOptions[selectedCountry]?.map((o) => (
                      <MenuItem
                        key={"id_" + o.id}
                        value={+o.id}
                        children={o.name}
                      />
                    ))}
                  </Select>
                </FormControl>
              </Box></> : null}

              {isRequired.includes("account_type") ? <><Box>
                <FormControl
                  required
                  variant="outlined"
                  style={{ margin: 2, width: "160px" }}
                >
                  <InputLabel shrink id="account-type">
                    Tipo de cuenta
                  </InputLabel>
                  <Select
                    labelId="account-type"
                    value={forValue?.account_type}
                    name={"account_type"}
                    onChange={(event) => onChangeFieldForm(event)}
                  >
                    <MenuItem value={"c"}>Cuenta Corriente</MenuItem>
                    <MenuItem value={"s"}>Cuenta de Ahorro</MenuItem>
                  </Select>
                </FormControl>
              </Box></> : null}
              {isRequired.includes("currency") ? <> <br /> <Box>
                <FormControl
                  required
                  variant="outlined"
                  style={{ margin: 2, width: "160px" }}
                >
                  <InputLabel shrink id="currency">
                    Divisa
                  </InputLabel>

                  <Select
                    labelId="currency"
                    value={forValue?.currency}
                    name={"currency"}
                    onChange={(event) => onChangeFieldForm(event)}
                  >
                    {currencyComboPY.map(pyc => <MenuItem key={"currency" + pyc.tag} value={pyc.tag}>{pyc.tag}</MenuItem>)}
                  </Select>
                </FormControl></Box> </> : null}
            </Box>

            {/* PIX */}
            {isRequired.includes("pix") ? <> <br />
              <Box>
                {["BR"].includes(selectedCountry) ? (
                  <TextField
                    value={forValue?.pix}
                    onChange={(event) => onChangeFieldForm(event)}
                    required
                    name={"pix"}
                    label={'Pix'}
                    style={{ width: "100%" }}
                    InputLabelProps={{ shrink: true }}
                  />
                ) : null}
              </Box></> : null}

            {isRequired.includes("bank_branch") ? <><br />
              <Box>
                {["BR", "UY"].includes(selectedCountry) ? (
                  <InputMask
                    value={forValue?.bank_branch || ''}
                    onChange={(event) => onChangeFieldForm(event)}
                    mask={selectedBankMask?.branch}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        required
                        name={"bank_branch"}
                        label={"Sucursal"}
                        error={!!errors.bank_branch}
                        helperText={errors.bank_branch}
                        style={{ width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </InputMask>
                ) : (
                  <TextField
                    required
                    value={forValue?.bank_branch}
                    name={"bank_branch"}
                    label={"Sucursal"}
                    error={!!errors.bank_branch}
                    helperText={errors.bank_branch}
                    onChange={(event) => onChangeFieldForm(event)}
                    style={{ width: "100%" }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Box></> : null}

            {isRequired.includes("bank_account") ? <><br />
              <Box>
                {["BR", "UY"].includes(selectedCountry) ? (
                  <InputMask
                    value={forValue?.bank_account || ''}
                    onChange={(event) => onChangeFieldForm(event)}
                    mask={
                      forValue?.account_type === "c"
                        ? selectedBankMask?.checking
                        : selectedBankMask?.saving
                    }
                  >

                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        required
                        name={"bank_account"}
                        label={"Cuenta Bancaria"}
                        style={{ width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.bank_account}
                        helperText={errors.bank_account}
                      />
                    )}
                  </InputMask>
                ) : (
                  <TextField
                    required
                    value={forValue?.bank_account}
                    name={"bank_account"}
                    label={"Cuenta Bancaria"}
                    error={!!errors.bank_account}
                    helperText={errors.bank_account}
                    onChange={(event) => onChangeFieldForm(event)}
                    style={{ width: "100%" }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Box></> : null}
            <br />
            <Box>
              {isRequired.includes("beneficiary_name") ? <>
                <Box>
                  <TextField
                    required
                    value={forValue?.beneficiary_name}
                    name={"beneficiary_name"}
                    label={"Nombre del Titular"}
                    type={"text"}
                    error={!!errors.beneficiary_name}
                    helperText={errors.beneficiary_name}
                    inputProps={{ maxLength: 100 }}
                    onChange={(event) => onChangeFieldForm(event)}
                    style={{ width: "100%" }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box> </> : null}

              {isRequired.includes("beneficiary_lastname") ? <><br /><Box>
                <TextField
                  required
                  value={forValue?.beneficiary_lastname}
                  name={"beneficiary_lastname"}
                  label={"Apellido del Titular"}
                  type={"text"}
                  error={!!errors.beneficiary_lastname}
                  helperText={errors.beneficiary_lastname}
                  inputProps={{ maxLength: 100 }}
                  onChange={(event) => onChangeFieldForm(event)}
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box> </> : null}

            </Box>
            {selectedCountry !== 'EG' && <br />}
            <Box
              style={{
                display: !isRequired.includes("document_type") ? "block" : "flex",
                flexDirection: "row",
                justifyContent: !isRequired.includes("document_type") ? "center" : "space-between",
              }}>
              {isRequired.includes("document_type") ? <><Box>
                <FormControl required style={{ minWidth: "60px", marginRight: "10px" }}>
                  <InputLabel id="document-type">Tipo</InputLabel>
                  <Select
                    value={forValue?.document_type || ''}
                    name={"document_type"}
                    labelId="document-type"
                    onChange={(event) => onChangeFieldForm(event)}
                  >
                    {selectedCountry &&
                      documentTypeCombo[selectedCountry]?.map((m) => (
                        <MenuItem key={"type_" + m.type} value={m.type}>
                          {m.type}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box> </> : null}

              {isRequired.includes("document_id") ? <><Box>
                <TextField
                  required
                  value={forValue?.document_id}
                  name={"document_id"}
                  error={!!errors.document_id}
                  helperText={errors.document_id}
                  onChange={(event) => onChangeFieldForm(event)}
                  label={selectedCountry && dinamicFieldsCountry["AR"] ? "Nro de CUIT/CUIL" : "Nro de Documento"}
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box> </> : null}
            </Box>
            <Box>

              {isRequired.includes("address") ? <><br /><Box>
                <TextField
                  required
                  value={forValue?.bank_province}
                  name={"bank_province"}
                  inputProps={{ maxLength: 200 }}
                  error={!!errors.bank_province}
                  helperText={errors.bank_province}
                  onChange={(event) => onChangeFieldForm(event)}
                  label={"Direccion"}
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box> </> : null}
              <br />
              {isRequired.includes("city") ? <><Box >
                <TextField
                  required
                  value={forValue?.bank_city}
                  inputProps={{ maxLength: 100 }}
                  name={"bank_city"}
                  error={!!errors.bank_city}
                  helperText={errors.bank_city}
                  onChange={(event) => onChangeFieldForm(event)}
                  label={"Ciudad"}
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
                <br /> </> : null}
              {isRequired.includes("email") ? <><Box >
                <TextField
                  required
                  value={forValue?.email}
                  inputProps={{ maxLength: 100 }}
                  name={"email"}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={(event) => validateEmail(event)}
                  label={"Email"}
                  type="email"
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box><br /></> : null}
              {/* phone */}
              {isRequired.includes("phone") ? <> <Box >
                <TextField
                  required
                  value={forValue?.phone}
                  name={"phone"}
                  error={!!errors.phone}
                  inputProps={{ maxLength: 20 }}
                  helperText={errors.phone}
                  onChange={(event) => onChangeFieldForm(event)}
                  label={"Telefono"}
                  type="number"
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
                <br /></> : null}
              {isRequired.includes("remitter_full_name") ? <><Box>
                <TextField
                  required
                  value={forValue?.remitter_full_name}
                  onChange={(event) => onChangeFieldForm(event)}
                  name={"remitter_full_name"}
                  error={!!errors.remitter_full_name}
                  helperText={errors.remitter_full_name}
                  label={"Nombre del Remitente"}
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box> <br /></> : null}
              {isRequired.includes("remitter_birth_date") ? <><Box>
                <TextField
                  required
                  value={forValue?.remitter_birth_date}
                  onChange={(event) => onChangeFieldForm(event)}
                  name={"remitter_birth_date"}
                  label={"Nacimiento del Remitente"}
                  error={!!errors.remitter_birth_date}
                  helperText={errors.remitter_birth_date}
                  type="date"
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
                <br /></> : null}
              {isRequired.includes("remitter_country") ? <><Box>
                <TextField
                  required
                  value={forValue?.remitter_country}
                  onChange={(event) => onChangeFieldForm(event)}
                  name={"remitter_country"}
                  label={"Pais del Remitente"}
                  error={!!errors.remitter_country}
                  helperText={errors.country}
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
                <br /></> : null}
              {isRequired.includes("purpose") ? <><Box>
                <TextField
                  required
                  value={forValue?.purpose}
                  onChange={(event) => onChangeFieldForm(event)}
                  name={"purpose"}
                  label={"Proposito"}
                  error={!!errors.purpose}
                  helperText={errors.purpose}
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box></> : null}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

const AlertDeleteModal = ({ open, handleClose, id, hostId, getBanks }) => {
  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await AdminService.removeBank(hostId, id);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    handleClose();
    getBanks();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle id="alert-delete-title">
          Borrar cuenta bancaria
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={(event) => {
              handleClose();
              event.stopPropagation();
            }}
            variant="outlined"
            color="primary"
            style={{
              borderColor: "#00D1BF",
              color: "#00D1BF",
              fontFamily: "Poppins",
              borderRadius: "10px",
              height: "40px",
              width: "200px",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
            variant="outlined"
            style={{
              backgroundColor: "#00D1BF",
              color: "#FFFFFF",
              fontFamily: "Poppins",
              borderRadius: "10px",
              height: "40px",
              width: "200px",
            }}
            autoFocus
          >
            Confirmar
            {isLoading && (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginRight: "15px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

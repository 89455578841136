import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    _title: {
        color: '#383839',
        marginTop: 15,
        marginBottom: '16px',
        fontSize: '18px'
    },
    _formControl: {
        minWidth: 250,
        padding: '10px',
        marginBottom: '20px',
    },
    _inputLabel: {
        marginLeft: '8px',
        marginTop: '5px'
    },
    _selectorContainer: {
        width: '100%',
        justifyContent: 'flex-start',
        display: 'flex'
    },
    _newTagContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    _addBtn: {
        marginTop: 10,
        marginRight: 60,
        alignSelf: 'center',
        color: '#00D1BF',
        fontWeight: '900',
        borderColor: '#00D1BF',
        fontFamily: 'Poppins',
        borderRadius: '15px',
        height: '30px',
        width: '200px'
    },


}));

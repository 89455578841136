import React from 'react';
import { Checkbox, Chip, FormControl, MenuItem, Select, Input } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import is from "is_js";

const SpaceTypeSelect = ({ spaceType = [], setSpaceType, spaceTypesOptions }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return <FormControl
        style={{
            border: "1px solid lightgrey", flexBasis: '35%', display: 'flex', justifyContent: 'center',
            backgroundColor: '#fff',
            textAlign: 'center',
            borderRadius: 20,
            flexGrow: 1,
        }}
    >
        <Select
            id="spaceType"
            className={!isMobile ? 'desktop' : ''}
            value={spaceType}
            onChange={e => {
                setSpaceType(e.target.value);
            }}
            displayEmpty
            multiple
            MenuProps={{
                getContentAnchorEl: () => null,
            }}
            input={<Input disableUnderline />}
            style={{ padding: 10, borderBottom: "none", fontFamily: 'Poppins', fontSize: 20 }}
            renderValue={(selected) => {
                let selectedToDisplay = selected;
                if (is.empty(selected)) return 'todos';
                return (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }} key={'s_' + selected.join()}>
                        {selectedToDisplay.map(value => {
                            if (value === -1) return <Chip label={`+ ${selected.length - 1}`} style={{ margin: 2 }} key={'st_sel'} />;
                            let typeName = (spaceTypesOptions.find(e => e.id === +value) || {}).name;
                            if (!typeName) return null;
                            return <Chip
                                key={'st_sel_' + typeName}
                                label={typeName}
                                style={{ margin: 2 }}
                                onDelete={() => {
                                    setSpaceType(spaceType.filter(sstt => sstt !== +value));
                                }}
                                onMouseDown={event => event.stopPropagation()} />;
                        })}
                    </div>
                );
            }}>
            {spaceTypesOptions?.map(st => <MenuItem key={'st_' + st.id} value={+st.id}>
                <Checkbox checked={spaceType?.map(a => +a).includes(st.id)} />
                {st.name}
            </MenuItem>)}
        </Select>
    </FormControl>;
};

export default SpaceTypeSelect;


// External components
import React, { useState } from 'react';
import { Avatar, Box, Button, Typography } from '@material-ui/core';

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import SmartBookingDetailModal from '../modals/SmartBookingDetailModal';
import { daysDictionary } from '../../helpers/dictionaries/daysDictionary';
import { PriceDisplay } from '../atoms/PriceDisplay';
import EmployeeSmartBookingModal from '../modals/EmployeeSmartBookingModal';
import { getLocationDisplayValue } from '../../helpers/funcs/getLocationDisplayValue';

// Internal components

const OpenModalEnum = {
    NONE: null,
    EDIT: 'EDIT',
    EMPLOYEE_BOOKINGS: 'EMPLOYEE_BOOKINGS',
};

const SmartBookingCard = ({ smartBooking, getSmartBookings }) => {
    const [openModal, setOpenModal] = useState(OpenModalEnum.NONE);

    const { _root, _content, _img } = useStyles();

    return (
        <Box className={_root}>
            {openModal === OpenModalEnum.EDIT && (
                <SmartBookingDetailModal
                    open={openModal === OpenModalEnum.EDIT}
                    setOpen={setOpenModal}
                    onConfirm={getSmartBookings}
                    smartBooking={smartBooking}
                />
            )}

            {openModal === OpenModalEnum.EMPLOYEE_BOOKINGS && (
                <EmployeeSmartBookingModal
                    open={openModal === OpenModalEnum.EMPLOYEE_BOOKINGS}
                    setOpen={setOpenModal}
                    smartBooking={smartBooking}
                />
            )}

            <Box className={_content}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
                    <Typography><b>#{smartBooking.id}</b></Typography>

                    <img src={smartBooking?.smartOffice?.images[0]?.original_url} className={_img} />
                </Box>

                <Box>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Avatar src={smartBooking?.company?.avatar?.original_url} />
                        <Typography>{smartBooking?.company?.name} (#{smartBooking?.company?.id})</Typography>
                    </Box>

                    <br/>

                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>#{smartBooking?.smartOffice?.id} {smartBooking?.smartOffice?.name}</Typography>

                    <br/>

                    <Typography>
                        Ubicación: {' '}

                        <Typography
                            component={'a'}
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(getLocationDisplayValue(smartBooking.smartOffice.location))}`}
                            target={'_blank'}
                        >
                            <b>{getLocationDisplayValue(smartBooking.smartOffice.location)}</b>
                        </Typography>
                    </Typography>

                    <Typography>
                        Bloques:
                        {' '}
                        <b>{smartBooking.bookingBlocks?.map((b) => b?.block?.name).join(', ')}</b>
                    </Typography>

                    <Typography>
                        Días seleccionadados:
                        {' '}
                        <b>
                            {Object.keys(smartBooking?.prices || {})
                                .filter((p) => !!smartBooking?.prices[p])
                                .map((p) => daysDictionary[p])
                                .join(' - ')
                            }
                        </b>
                    </Typography>

                    <Typography>Capacidad: <b>{smartBooking?.bookingBlocks?.reduce((a, b) => a + (b?.block?.capacity || 0), 0)}</b></Typography>

                    <Typography>Creación: <b>{moment(smartBooking.createdDate).format('LL')}</b></Typography>

                    <Typography>Desde: <b>{moment(smartBooking.dateFrom).format('LL')}</b></Typography>

                    <Typography>Hasta: <b>{moment(smartBooking.dateTo).format('LL')}</b></Typography>

                    <br/>

                    <Typography>Precio mensual: <b><PriceDisplay value={smartBooking.price} currency={'USD'}/></b></Typography>
                </Box>
            </Box>

            <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => setOpenModal(OpenModalEnum.EDIT)}
                >
                    Ver detalle
                </Button>

                <Button
                    color={'primary'}
                    onClick={() => setOpenModal(OpenModalEnum.EMPLOYEE_BOOKINGS)}
                >
                    Sub Bookings
                </Button>
            </Box>
        </Box>
    );

};

export default SmartBookingCard;

const useStyles = makeStyles((theme) => ({
    _root: {
        border: '1px solid lightgrey',
        borderRadius: theme.spacing(2),
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    _content: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
    },
    _img: {
        width: '250px',
        height: '250px',
        borderRadius: theme.spacing(2)
    }
}));

import React from "react";
import { Box } from "@material-ui/core";
import { modelBackgrounds } from "../Companies";

const CompanyTypeLabel = ({ company }) => {

    return <Box style={{ width: 120, height: 10, borderRadius: 15, padding: 12, background: modelBackgrounds[company?.type], display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'PoppinsBold', color: 'white', textShadow: '1px 1px 2px grey'}}>
        {company?.type}
    </Box>;
};

export default CompanyTypeLabel;
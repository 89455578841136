import React, { useState, useCallback } from "react";
import { Button, Box } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import ImageViewer from "react-simple-image-viewer";
import { EmailStatusIcon } from "./EmailStatusIcon";
import BankAccountForm from "./BankAccountForm";
const UserInfoModal = ({ host, open, setOpen }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewerImages, setviewerImages] = useState([]);

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        setviewerImages([]);
    };

    const setHostImageViewer = (index) => {
        setviewerImages([
            host?.avatar?.original_url,
            host?.identification_attachment_front?.original_url,
            host?.identification_attachment_back?.original_url
        ]);
        openImageViewer(index);
    }

    return <>
        {isViewerOpen && (
            <ImageViewer
                src={viewerImages}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                    zIndex: 9999
                }}
            />
        )}
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={"lg"}>
            <DialogTitle>
                <b>{`${host.name} ${host.lastname || ''}`}</b>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <img onClick={() => setHostImageViewer(0)} src={host?.avatar?.url} alt="" style={{ maxHeight: '300px', borderRadius: '15px', cursor: 'pointer' }} />
                </Box>
                <Box>
                    <b>DNI: </b>
                    <span>{host.identification_number}</span>
                </Box>
                <br />
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <img onClick={() => setHostImageViewer(1)} src={host?.identification_attachment_front?.url} alt="" style={{ maxWidth: '300px', cursor: 'pointer' }} />
                    <img onClick={() => setHostImageViewer(2)} src={host?.identification_attachment_back?.url} alt="" style={{ maxWidth: '300px', cursor: 'pointer' }} />
                </Box>
                <br />
                <Box>
                    <b>Fecha de Nacimiento: </b>
                    <span>{moment(host.birth).format('LL')}</span>
                </Box>
                <br />
                <Box>
                    <b>Teléfono: </b>
                    <span>{host.phone}</span>
                </Box>
                <br />
                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <b>Email: </b>
                    <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>{' ' + host.email} <EmailStatusIcon status={host.email_verified} /></span>
                </Box>
                <br />
                {host.summary && <Box>
                    <b>Descripción: </b>
                    <span>{host.summary}</span>
                </Box>}
                <br />
                {host.mercado_pago_id && <Box>
                    <b>MercadoPago ID: </b>
                    <span>{host.mercado_pago_id}</span>
                </Box>}
                {/* FORM */}
                <BankAccountForm host={host} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default UserInfoModal;



export const FeatureProviderNameEnum = {
    RAPPI: 'RAPPI',
    PEYA: 'PEYA',
    INTERNAL_STORE: 'INTERNAL_STORE',
    INTERNAL_COWORKINGS: 'INTERNAL_COWORKINGS',
    SPORT_CLUB: 'SPORT_CLUB',
    GYMPASS: 'GYMPASS',
    INTERNAL_SOCIAL: 'INTERNAL_SOCIAL',
    INTERNAL_ANALYTICS: 'INTERNAL_ANALYTICS',
    INTERNAL_FLEX: 'INTERNAL_FLEX',
};

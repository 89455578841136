// external components
import React, { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

// util & config
import { useStyles } from './pdfSelector.styles';
import moment from 'moment';


const MonthSelector = ({ months, selectedDate, setSelectedDate }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { _monthRoot, _formControl, _monthText, _menuText } = useStyles();

  const handleSelection = (e) => {
    setSelectedDate(e.target.value);
    setMenuOpen(false);
  };
  const formatDate = (date) => {
    return moment(date).format('MMMM YYYY');
  };
  // if(!months) return false

  return (
    <Box className={_monthRoot}>
      <FormControl variant="outlined" className={_formControl} >
        <InputLabel className={_monthText} >Período</InputLabel>
        <Select
          value={selectedDate}
          defaultValue={selectedDate}
          labelId="months"
          label={formatDate(selectedDate)}
          name={"months"}
          onChange={(m) => handleSelection(m)}

        >
          {months?.map((m) => (
            <MenuItem key={m} value={m}>
              <Box style={{ color: 'black', fontWeight: 400, opacity: 1, }}>
                <span className={_menuText}>{formatDate(m)}</span>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MonthSelector;

export const ValidateCountryDocument = {
    Colombia: (documentId, documentType) => {
        if (documentType === 'CC') return documentId.length >= 6 && documentId.length <= 10;    //	Between 6 to 10	numeric
        if (documentType === 'NIT') return documentId.length >= 6 && documentId.length <= 10;   //	10 or 11	numeric
        if (documentType === 'PASS') return documentId.length <= 12;                            //	up to 12	characters
        if (documentType === 'CE') return  documentId.length <= 12;                             //	up to 12	characters
        if (documentType === 'PEP') return documentId.length <= 15;                             //	up to 15	characters
    },

    CostaRica: (documentId, documentType) => {
        if (documentType === 'CI') return	documentId.length === 9;                              // 9	numeric
        if (documentType === 'CJ') return	documentId.length === 10 ;                            // 10	numeric
        if (documentType === 'CR') return	documentId.length >= 11 && documentId.length <= 22;   // 11 to 22	numeric
    },

    Ecuador: (documentId, documentType) => {
        if (documentType === 'CI') return	documentId.length === 10;                             //	10	numeric
        if (documentType === 'RUC') return	documentId.length === 13;                           //	13	numeric
        if (documentType === 'PASS') return	documentId.length >= 7 && documentId.length <= 12;  //	7 to 12	Characters
    },

    Paraguay: (documentId, documentType) => {
        if (documentType === 'CI') return	documentId.length === 7;                              // 7	numeric
        if (documentType === 'RUC') return	documentId.length === 8;                            // 8	numeric
    },

    Peru: (documentId, documentType) => {
        if (documentType === 'DNI') return documentId.length >= 8 && documentId.length <= 9;    // 8 Numeric or 9 Alphanumeric
        if (documentType === 'RUC') return documentId.length === 11;                            // 11 Numeric
        if (documentType === 'CE') return documentId.length >= 4 && documentId.length <= 12;    // Min 4 characters, Max 12 characters
        if (documentType === 'PASS') return documentId.length >= 4;                             // More than 4 characters
    },
};

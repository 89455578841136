// External components
import React, { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, Link, MenuItem, Select } from "@material-ui/core";
import moment from 'moment';
import MaterialTable, { MTableToolbar } from "material-table";

// Icons & Images
import InfoIcon from '@material-ui/icons/Info';

// Utils & Config
import AdminService from "../../../services/AdminService";
import { useMediaQuery } from "react-responsive";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';

// Internal components
import TableIcons from "../../molecules/TableIcons";
import { PriceDisplay } from "../../atoms/PriceDisplay";
import useDebounce from "../../../hooks/useDebounce";

const PAGE_SIZE = 300;

const TRANSACTION_TYPES = [
    'CREDIT_ACCUMULATION',
    'FOOD',
    'ALLY_VOUCHER',
    'EMPLOYEE_MONTHLY_FEE',
    'COMPANY_MONTHLY_FEE',
    'WELLNESS'
];

const CreditsTransactions = () => {

    const [transactions, setTransactions] = useState([]);
    const [transactionsReqLoading, setTransactionsReqLoading] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [paginatorNumber, setPaginatorNumber] = useState(10);
    const [totalCount, setTotalCount] = useState(1);
    const [searchValue, setSearcValue] = useState('');
    const [txType, setTxType] = useState('<ALL>');
    const debouncedSearchValue = useDebounce(searchValue)

    const getTransactions = async (pageNum, searchQuery) => {
        setTransactionsReqLoading(true);
        if (pageNum) setSelectedPage(pageNum);
        else setSelectedPage(selectedPage);

        try {
            const type = '<ALL>' == txType ? null : txType;
            const res = await AdminService.getTransactionsByStatus(PAGE_SIZE, pageNum, searchQuery, type)
            setTransactions(res.data.transactions);
            setTotalCount(res.data.total_count);
        } catch (error) {
            console.log(error);
        } finally {
            setTransactionsReqLoading(false);
        }
    };


    useEffect(() => {
        getTransactions();
        // eslint-disable-next-line
    }, [txType]);

    const getFilteredTransactions = async (searchQuery) => {
        setSelectedPage(1);
        setSearcValue(searchQuery)
        await getTransactions(1, debouncedSearchValue)
    }

    const handleChangePagination = (e, value) => {
        setSelectedPage(value);
        getTransactions(value);
    };

    const updatePaginator = () => {
        setPaginatorNumber(Math.ceil(totalCount / PAGE_SIZE));
    };

    useEffect(() => {
        updatePaginator();
        // eslint-disable-next-line
    }, [totalCount]);

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const classes = useStyles();

    return (
        <Box>
            <div style={{ marginLeft: isMobile ? 5 : '170px' }}>

                <MaterialTable
                    title={''}
                    icons={TableIcons}
                    isLoading={transactionsReqLoading}
                    onSearchChange={getFilteredTransactions}
                    options={{ pageSize: PAGE_SIZE }}
                    // actions={[
                    //     {
                    //         icon: () => <InfoIcon />,
                    //         tooltip: 'User Info',
                    //         // onClick: (event, rowData) => {
                    //         //     setClickedTransfer(rowData);
                    //         //     setTransferInfoModalOpen(true);
                    //         // }
                    //     }
                    // ]}
                    columns={[
                        {
                            title: 'ID',
                            render: b => <div>{`#${b.id}`}</div>, customFilterAndSearch: (term, rowData) => `${rowData.id}`?.includes(term)
                        },

                        {
                            title: 'Empresa',
                            render: b => <div>{`${b?.employee?.companyCountry?.company?.name || b?.companyCountry?.company?.name}`}</div>
                        },

                        {
                            title: 'TIPO',
                            render: b => <div>{b.type}</div>
                        },

                        {
                            title: 'Trabajador',
                            render: b => <div >{b?.employee?.user ? `${b?.employee?.user?.name || ''}${' ' + b?.employee?.user?.lastname || ''} ` : b?.employee?.email}</div>
                        },

                        {
                            title: 'Valor',
                            render: b => <div>
                                <PriceDisplay currency={b?.employee?.companyCountry?.currency?.code || b?.companyCountry?.currency?.code} value={+b.employee_budget_amount || +b.company_budget_amount} />
                                {b?.employee && <div style={{ fontSize: 14 }}>({((+b.employee_budget_amount / +b.employee.current_month_limit) * 100).toFixed(0)}% del presupuesto)</div>}
                            </div>
                        },

                        {
                            title: 'País',
                            render: b => b?.employee?.companyCountry?.country?.internal_detail || b?.companyCountry?.country?.internal_detail
                        },

                        {
                            title: 'Fecha',
                            render: b => `${moment(b.created_date).format('lll')}`
                        },
                    ]}
                    components={{
                        Pagination: props => (
                            <div className={classes.root}>
                                <Pagination style={{ width: "450px", margin: "0 auto" }} count={paginatorNumber} color="primary" page={selectedPage} onChange={handleChangePagination} showFirstButton showLastButton />
                            </div>
                        ),
                        Toolbar: props => <Box>
                            <MTableToolbar {...props} />
                            <FormControl style={{ width: '300px' }}>
                                <InputLabel shrink id="company-type" style={{}}>Tipo de Tx:</InputLabel>
                                <Select
                                    labelId="tx-type"
                                    id="type"
                                    value={txType}
                                    onChange={e => setTxType(e.target.value)}
                                    required
                                    label={'Tipo de Tx'}>
                                    <MenuItem value={'<ALL>'}>Todos</MenuItem>
                                    {TRANSACTION_TYPES.map(t => <MenuItem key={t + '_type'} value={t}>{t}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                    }}
                    data={transactions}
                    localization={{
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay transacciones para mostrar.',
                        }
                    }}
                />
            </div>
        </Box>
    );
};

export default CreditsTransactions;

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "25px",
        marginBottom: "25px"
    },
}));

// res.currency_conversion ? calcConversionRate(res.conversion_rate) : employee_budget_current_month

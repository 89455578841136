import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';

export const LangSelector = ({ lang, setLang }) => {

    const langOptions = [
        {
            text: 'Español',
            value: 'es_ar',
            name: 'ES',
            id: 1
        },
        {
            text: 'Ingles',
            value: 'en_us',
            name: 'US',
            id: 2
        },
        {
            text: 'Portugues',
            value: 'pt_br',
            name: 'BR',
            id: 3
        },
    ];

    return <>
        <FormControl
            style={{
                minWidth: 300,
                padding: '10px',
                marginBottom: '20px',
                width: '60%',
                marginRigth: 15
            }}
        >

            <Select
                id={'lang'}
                value={lang}
                defaultValue={lang}
                name={'Idioma'}
                onChange={(e) => setLang(e.target.value)}
                displayEmpty
                inputProps={{ shrink: true }}
            >
                {/* <MenuItem value={0}>Seleccione un Idioma</MenuItem> */}
                {langOptions
                    .sort((a, b) => a.order - b.order)
                    .map((co) => (
                        <MenuItem key={co.name + '_lang'} value={co.value}>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <ReactCountryFlag
                                    svg
                                    countryCode={co.name || ''}
                                    style={{ marginRight: 5, width: 32, height: 32 }}
                                />
                                <span>{co.text}</span>
                            </Box>
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    </>;
};

// External components
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

// Utils & Config
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import SmartBookingCard from '../molecules/SmartBookingCard';
import { SmartBookingStatusEnum } from '../../helpers/enums/SmartBookingStatusEnum';

// Internal components

const SmartBookingStatusEnumLabels = {
    [SmartBookingStatusEnum.CREATED]: 'Creados',
    [SmartBookingStatusEnum.APPROVED]: 'Aprobados',
    [SmartBookingStatusEnum.CANCELLED]: 'Cancelados',
    [SmartBookingStatusEnum.FINISHED]: 'Finalizados',
};

const ROWS_PER_PAGE = 20;

const SmartBookingsDisplay = ({ smartBookingsRequest, getSmartBookings }) => {
    const [page, setPage] = useState(1);

    const [status, setStatus] = useState(SmartBookingStatusEnum.CREATED);

    useEffect(() => {
        getSmartBookings({ status: status });
    }, [status]);

    return (
        <Box>
            <h1>Smart Bookings</h1>

            <Box style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 16 }}>
                {Object.keys(SmartBookingStatusEnum).map((key) => (
                    <Button
                        key={key}
                        variant={status === key ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setStatus(key)}
                    >
                        {SmartBookingStatusEnumLabels[key]}
                    </Button>
                ))}
            </Box>

            {smartBookingsRequest.status === ResponseStatusEnum.LOADING && (
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            {smartBookingsRequest.status === ResponseStatusEnum.ERROR && (
                <h1>Error</h1>
            )}

            {smartBookingsRequest.status === ResponseStatusEnum.SUCCESS && (
                <>
                    {smartBookingsRequest.data
                        .slice((page - 1) * ROWS_PER_PAGE, ((page - 1) * ROWS_PER_PAGE) + ROWS_PER_PAGE)
                        .map((smartBooking) => (
                            <Box key={smartBooking.id}>
                                <SmartBookingCard smartBooking={smartBooking} getSmartBookings={() => getSmartBookings({ status: status })} />
                            </Box>
                        ))
                    }


                    <Pagination
                        style={{
                            position: 'fixed',
                            bottom: '16px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            background: 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '16px',
                            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                            backdropFilter: 'blur(5px)',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                        }}
                        count={parseInt(smartBookingsRequest.data?.length / ROWS_PER_PAGE)}
                        color={'primary'}
                        page={page}
                        onChange={(e, p) => setPage(p)}
                        showFirstButton
                        showLastButton
                    />
                </>

            )}
        </Box>
    );
};

export default SmartBookingsDisplay;

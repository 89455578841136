// External components
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, { useState } from "react";


const DetailPerkModal = ({ open, setOpen, perks }) => {

    const promoCode = (c) => {
        if (!c.is_contra_perk) {
            if (c.promotional_code == "") {
                return " - ";
            }
            else {
                return c.promotional_code;
            }
        } else {
            return c.promocode.coupon;
        }
    };

    return (
        <Box>
            <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
                <DialogTitle style={{ textAlign: "center" }}>
                    <span style={{ fontWeight: 600, fontSize: 20 }}>
                        {` Beneficio Asociados al aliado`}
                    </span>
                </DialogTitle>

                <DialogContent>
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {['ID', 'URL', 'PROMOCODE', 'PAIS', 'CONTRA PERK'].map((title, i) => (
                                        <TableCell align='center' key={i}>
                                            <b>{title}</b>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {perks
                                    ?.map((c
                                    ) => (
                                        <TableRow hover key={c.id + "_code"}>
                                            <TableCell>
                                                {c.id}
                                            </TableCell>
                                            <TableCell>
                                                {c.ally_benefit_url}
                                            </TableCell>
                                            <TableCell>
                                                {promoCode(c)}
                                            </TableCell>
                                            <TableCell>
                                                <span style={{ fontWeight: 700, fontSize: 16 }}>
                                                    {c.country.internal_detail}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {c.is_contra_perk.toString()}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </>
                </DialogContent>

                <DialogActions>
                    <>
                        <Button onClick={() => setOpen(false)} color='primary'>
                            Cancelar
                        </Button>

                    </>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DetailPerkModal;


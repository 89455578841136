// External components
import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextareaAutosize,
    Tooltip,
    Typography
} from '@material-ui/core';

// Icons & Images
import LaunchIcon from '@material-ui/icons/Launch';

// Utils & Config
import AdminService from '../../services/AdminService';
import { RequestedSpacePriceChangeStatusEnum, requestedSpacePriceChangeStatusLabels } from '../../helpers/enums/RequestedSpacePriceChangeStatusEnum';
import { makeStyles } from '@material-ui/core/styles';
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import { priceRangeLabels } from '../../helpers/enums/RequestedSpacePriceChangeTypeEnum';

// Internal Components
import { PriceDisplay } from '../atoms/PriceDisplay';
import TableIcons from '../molecules/TableIcons';
import MaterialTable from 'material-table';
import moment from 'moment';

const RequestedSpacePriceChangesModal = ({ open, setOpen, onConfirm, space }) => {
    const [request, setRequest] = useState({ status: ResponseStatusEnum.IDLE, data: null });

    const handleClose = () => {
        setOpen(null);
    };

    const getRequestedSpacePriceChangeBySpaceId = async () => {
        setRequest({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            const res = await AdminService.getRequestedSpacePriceChangeBySpaceId(space.id)

            setRequest({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setRequest({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    useEffect(() => {
        getRequestedSpacePriceChangeBySpaceId();
    }, [space.id])

    const {_container } = useStyles();

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'md'} fullWidth>
            <DialogTitle>
                Cambios de precio de espacio #{space.id}
            </DialogTitle>

            <DialogContent className={_container}>
                {request.status === ResponseStatusEnum.ERROR &&
                    <Typography color={'error'}>
                        Hubo un error
                    </Typography>
                }

                {Boolean(request.status === ResponseStatusEnum.SUCCESS && request.data.length) && (
                    <Box>
                        <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                            <Avatar src={request.data[0]?.space?.host_avatar?.original_url} />

                            <Typography>
                                {request.data[0]?.space?.name ? request.data[0]?.space?.name : `${request.data[0]?.space?.host_name} ${request.data[0]?.space?.host_lastname}`}
                                {' '}
                                (<b>#{request.data[0]?.space?.host_id}</b>)
                            </Typography>
                        </Box>

                        <br />

                        <Typography style={{ textTransform: 'capitalize' }}>{request.data[0]?.space?.type?.name}</Typography>

                        <Typography>
                            {request.data[0]?.space?.city} - {request.data[0]?.space?.country}
                        </Typography>
                    </Box>
                )}

            <Typography>
                <a href={`/price-changes/${space.id}`} target="_blank" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    Ir a Acciones

                    <LaunchIcon color={'primary'} style={{ width: 16, height: 16 }} />
                </a>
            </Typography>

            <MaterialTable
                title={''}
                data={request.data ? request.data : []}
                icons={TableIcons}
                isLoading={request.status === ResponseStatusEnum.LOADING}
                options={{ pageSize: 20, search: false }}
                columns={[
                    {
                        title: 'Fecha',
                        field: 'createdDate',
                        render: row => <Typography>{moment(row.createdDate).format('DD/MM/YYYY')}</Typography>
                    },
                    {
                        title: 'Estado',
                        field: 'status',
                        render: row => <Typography>{requestedSpacePriceChangeStatusLabels[row.status]}</Typography>
                    },
                    {
                        title: 'Precio Anterior',
                        field: 'oldValue',
                        render: row => <PriceDisplay value={row.oldValue} currency={row.space.currency}/>,
                    },
                    {
                        title: 'Precio Solicitado',
                        field: 'newValue',
                        render: row => <PriceDisplay value={row.newValue} currency={row.space.currency}/>,
                    },
                    {
                        title: 'Rango',
                        field: 'type',
                        render: row => <Box>{priceRangeLabels[row.type]}</Box>,
                    },
                    // {
                    //     hidden: (row) =>  row.status !== RequestedSpacePriceChangeStatusEnum.PENDING,
                    //     render: row => (
                    //         <Box>
                    //             <Tooltip title="Rechazar Solicitud" style={{ color: 'red', rotate: '180deg' }}>
                    //                 <IconButton color="inherit" onClick={() => handleOpenModal('REJECT', row)}>
                    //                     <ThumbUpIcon color={'inherit'} />
                    //                 </IconButton>
                    //             </Tooltip>

                    //             <Tooltip title="Aprobar Solicitud" style={{ color: 'green' }}>
                    //                 <IconButton color="primary" onClick={() => handleOpenModal('APPROVE', row)}>
                    //                     <ThumbUpIcon color={'inherit'} />
                    //                 </IconButton>
                    //             </Tooltip>
                    //         </Box>
                    //     )
                    // },
                    {
                        title: 'Motivo',
                        field: 'reason',
                        // hidden: selectedStatus === RequestedSpacePriceChangeStatusEnum.PENDING,
                        render: row => <Box>{row.reason || '-'}</Box>
                    },
                ]}
                localization={{
                    header: { actions: '' },
                    body: { emptyDataSourceMessage: 'No hay datos para mostrar.' }
                }}
            />
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Button
                        color={'primary'}
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default RequestedSpacePriceChangesModal;

const useStyles = makeStyles((theme) => ({
    _textArea: {
        backgroundColor: 'white',
        fontFamily: 'Poppins',
        borderRadius: theme.spacing(.5),
        fontSize: theme.spacing(2),
        padding: theme.spacing(2),
        '&:disabled': {
            opacity: .80
        }
    },
    _container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: theme.spacing(2),
    },
}));

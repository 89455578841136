import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
    {
        _selectedSection: {
            display:'flex',
            // flexDirection:  props => props.isMobile ? 'column' : 'column',
            alignItems:'center',
            // marginTop: 10,
            marginBottom: 'auto',
            height:  'auto',
            backgroundColor: '#FAF2FC',
            borderLeft:  '6px solid #9900CC',
            borderRadius: '6px',
            // width: props => props.isUp1366Res ? '46%' : '42%',
            width:'99%',
            opacity: (props) => props.sectionLoading ? 0 : 1,
            transition: '.3s linear',
        },
        _section: {
            display:'flex',
            flexDirection:  (props) => props.isMobile ? 'column' : 'column',
            alignItems:'center',
            // marginTop: 10,
            marginBottom: 'auto',
            height:  'auto',
            backgroundColor: '#F9F9F9',
            borderLeft:  '6px solid #959595',
            borderRadius: '6px',
            // width: props => props.isUp1366Res ? '46%' : '42%',
            width:'99%',
            opacity: (props) => props.sectionLoading ? 0 : 1,
            transition: '.3s linear',
        },
        _checkBoxStyle: {
            checked: {
                color: 'black',
                backgroundColor: 'black'
            }
        },
    }

));

import React, { useState, useEffect } from "react";
import AdminService from "../services/AdminService";
import DataService from "../services/DataService";
import { Link, Box, Button, TableHead, Table, TableRow, TableCell, TableBody, Accordion, CircularProgress, AccordionSummary, AccordionDetails, Checkbox, Select, MenuItem, Tooltip, IconButton } from "@material-ui/core";
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import is from 'is_js';
import { PriceDisplay } from "./atoms/PriceDisplay";
import UserInfoModal from "./UserInfoModal";
import { useMediaQuery } from "react-responsive";
import { PaymentStatusEnum } from "../helpers/enums/PaymentStatusEnum";
import GetAppIcon from '@material-ui/icons/GetApp';
import { PaymentConceptEnum } from "../helpers/enums/PaymentConceptEnum";

const PAYPAL_CURRENCIES = ['bob', 'eur', 'usd', 'nio'];

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [paymentsLoading, setPaymentsLoading] = useState(false);

    const [hostPayments, setHostPayments] = useState([]);
    const [hostPaymentsLoading, setHostPaymentsLoading] = useState(null);

    const [selectedStatus, setSelectedStatus] = useState([PaymentStatusEnum.NEW, PaymentStatusEnum.PENDING]);

    const [expanded, setExpanded] = useState(false);
    const [paymentValue, setPaymentValue] = useState(0);
    const [paymentCurrency, setPaymentCurrency] = useState('');
    const [payLoading, setPayLoading] = useState(false);

    const [paymentType, setPaymentType] = useState(0);
    const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [selectedBankAccount, setSelectedBankAccount] = useState(0);

    const [clickedHost, setClickedHost] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const openHostModal = (hostId) => {
        AdminService.getHostById(hostId).then(resp => {
            setClickedHost(resp.data);
            setInfoModalOpen(true);
        }).catch(console.log);
    }

    const getPayments = async (statusArray) => {
        setSelected([]);
        setPaymentsLoading(true);
        setHostPayments([])
        setExpanded(false)
        try {
            const resp = await AdminService.getPayments(statusArray);
            setPayments(resp.data.currencies);
            setSelectedStatus(statusArray);
            setPaymentsLoading(false);
        } catch (e) {
            setPaymentsLoading(false);
            console.log(e);
        }
    }

    const getHostPayments = async (statusArray, hostId, currencyName) => {
        setSelected([]);
        setHostPaymentsLoading(hostId);
        try {
            const resp = await AdminService.getPaymentsByHost(statusArray, hostId, currencyName);
            setHostPayments(resp.data);
            setHostPaymentsLoading(null);
        } catch (e) {
            setHostPaymentsLoading(null);
            console.log(e);
        }
    }

    const getBankAccounts = async (hostId, currency) => {
        if (!hostId) return;
        let resp = await AdminService.getAllBanks(hostId, currency);
        setBankAccounts(resp.data);
    }

    useEffect(() => {
        getBankAccounts();
        getPayments([PaymentStatusEnum.NEW, PaymentStatusEnum.PENDING]);
        DataService.getPaymentTypes().then(resp => {
            setPaymentTypeOptions(resp.data);
        }).catch(console.log)
        // eslint-disable-next-line
    }, []);

    const handleExpandChange = (panel) => async (event, isExpanded) => {
        const currencyName = panel?.split('_')?.[2];
        const hostId = panel?.split('_')?.[0];

        if (isExpanded) await getHostPayments(selectedStatus, hostId, currencyName);

        setSelectedBankAccount(0);
        setExpanded(isExpanded ? panel : false);
        setPaymentCurrency(isExpanded ? currencyName : false);
        setSelected([]);
    };

    const paymentSelection = payment => {
        if (!isStatus([PaymentStatusEnum.NEW, PaymentStatusEnum.PENDING])) return;

        const selectedIndex = selected.indexOf(payment.id);
        setPaymentCurrency(payment.currency);
        if (selectedIndex !== -1) {
            setSelected(sel => {
                sel.splice(selectedIndex, 1);
                return [...sel];
            });
        } else {
            setSelected(sel => {
                if (is.empty(sel)) getBankAccounts(payment.host_id, payment.currency)
                return [...sel, payment.id]
            });
        }
    }

    const selectAll = (pymnts) => {
        if (selected.length === pymnts.length)
            setSelected([]);
        else
            setSelected(pymnts.map(s => s.id));
    }

    /**
     * @param statusArr PaymentStatusEnum[] - matches payment.admin_status
     */
    const isStatus = (statusArr) => {
        return selectedStatus.some(status => statusArr.includes(status));
    }

    const downloadPaymentsExcel = async (host) => {
        let pdfUrl;

        let statusLabel;

        if (isStatus([PaymentStatusEnum.NEW, PaymentStatusEnum.PENDING])) statusLabel = 'Nuevos'
        if (isStatus([PaymentStatusEnum.COMPLETED])) statusLabel = 'Completados'
        if (isStatus([PaymentStatusEnum.CANCELLED])) statusLabel = 'Cancelados'

        try {
            setHostPaymentsLoading(host.id);
            const response = await AdminService.generateHostPaymentsExcel(selectedStatus, host.id);

            const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.data?.data).buffer], { type: 'application/vnd.ms-excel;charset=utf-8' }));

            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `Pagos ${statusLabel} de Host #${host.id} ${host.name ?? ''} ${host.lastname ?? ''}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } finally{
            setHostPaymentsLoading(null);

            window.onload = () => {
                URL.revokeObjectURL(pdfUrl);
            };
        }
    };

    const executePayment = () => {
        setPayLoading(true);

        AdminService.pay(selected, paymentType, selectedBankAccount)
            .then(resp => {
                setSelected([]);
                setSelectedBankAccount(0);
                getPayments(selectedStatus);
            })
            .catch(e => {
                console.log(e);
                alert('Ha habido un error: \n' + (e?.response?.data?.message || ''))
            })
            .finally(() => {
                setPayLoading(false);
            })
    }

    useEffect(() => {
        const newPaymentValue = hostPayments.filter(ps => selected.includes(ps.id))
            .reduce((acc, current) => acc + parseFloat(current.payment_amount), 0);
        setPaymentValue(parseFloat(newPaymentValue));

        if (is.empty(selected)) {
            setPaymentType(0);
            setBankAccounts([]);
        }
        // eslint-disable-next-line
    }, [selected]);

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <Box>
            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                {is.not.empty(selected) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', position: 'fixed', padding: 20, backgroundColor: '#ffffff', bottom: 0, zIndex: 99, width: 'calc(100% - 170px)', borderTop: '1px solid grey' }}>
                    <Button variant="outlined" onClick={() => openHostModal(expanded?.split?.('_')?.[0])}>Host</Button>
                    <Box>
                        <span>Total a pagar a </span>
                        <span>{(() => {
                            const selectedHost = payments?.find(p => p.currency === paymentCurrency)?.hosts?.filter(p => p.id + '_host_' + paymentCurrency === expanded)[0];
                            return <b>{selectedHost?.name} {selectedHost?.lastname}</b>
                        })()}: </span>
                        <span>
                            <PriceDisplay value={paymentValue} currency={paymentCurrency} />
                        </span>
                    </Box>
                    <Select
                        value={paymentType}
                        onChange={e => {
                            setSelectedBankAccount(0);
                            setPaymentType(e.target.value);
                        }}>
                        <MenuItem value={0}>Elija un medio de pago...</MenuItem>
                        {paymentTypeOptions.filter(o => {
                            // shitty fix to filter payment methods depending on currency
                            if (PAYPAL_CURRENCIES.includes(paymentCurrency))
                                return (o.name !== 'dlocal' && o.name !== 'mercado-pago');
                            else if (paymentCurrency === 'ars')
                                return o.name !== 'paypal';
                            else
                                return (o.name !== 'paypal' && o.name !== 'mercado-pago');
                        }).map(o => <MenuItem value={o.id} key={o.id + '_paymenttype_o'}>{o.name}</MenuItem>)}
                    </Select>
                    <UserInfoModal host={{ ...clickedHost, user: clickedHost }} open={infoModalOpen} setOpen={setInfoModalOpen} />
                    {paymentType === 3 && <Select style={{ width: "350px" }}
                        value={selectedBankAccount}
                        onChange={e => setSelectedBankAccount(e.target.value)}>

                        <MenuItem value={0} disabled={bankAccounts.length === 0 ? true : false}>
                            {bankAccounts.length === 0 ? "No tiene cuentas asociadas a esta moneda" : "Elija una cuenta bancaria"}
                        </MenuItem>
                        {bankAccounts.map(o => <MenuItem value={o.id} key={o.id + '_bankAccount'}>
                            <Box>
                                <Box>{o.bank_name} ({o.country})</Box>
                                <Box style={{ color: 'grey', fontSize: 14 }}>Cuenta: {o.bank_account}</Box>
                            </Box>
                        </MenuItem>)}
                    </Select>}

                    <Button
                        disabled={payLoading || paymentType === 0 || (paymentType === 3 && !selectedBankAccount)}
                        variant="contained"
                        color="secondary"
                        onClick={executePayment}
                    >
                        {payLoading ? <CircularProgress /> : 'Pagar'}
                    </Button>
                </div>}
                <h1>Pagos</h1>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        variant={isStatus([PaymentStatusEnum.NEW, PaymentStatusEnum.PENDING]) ? "contained" : "outlined"}
                        color="primary"
                        onClick={() => getPayments([PaymentStatusEnum.NEW, PaymentStatusEnum.PENDING])}
                    >
                        Nuevos
                    </Button>
                    <Button
                        variant={isStatus([PaymentStatusEnum.COMPLETED]) ? "contained" : "outlined"}
                        color="primary"
                        onClick={() => getPayments([PaymentStatusEnum.COMPLETED])}
                    >
                        Completados
                    </Button>
                    <Button
                        variant={isStatus([PaymentStatusEnum.CANCELLED]) ? "contained" : "outlined"}
                        color="primary"
                        onClick={() => getPayments([PaymentStatusEnum.CANCELLED])}
                    >
                        Cancelados
                    </Button>
                </div>
                {paymentsLoading ? <CircularProgress style={{ marginTop: 20 }} /> : <>
                    {is.empty(payments) && <Box style={{ marginTop: 20 }}>0 resultados</Box>}

                    {payments.map(paymentByCurrency => (
                        <Box key={paymentByCurrency.currency}>
                            <h3>Moneda <b>{paymentByCurrency.currency?.toUpperCase()}</b></h3>

                            {paymentByCurrency.hosts?.map(host => (
                                <Accordion
                                    key={host.id + '_host_' + paymentByCurrency.currency}
                                    expanded={expanded === host.id + '_host_' + paymentByCurrency.currency}
                                    onChange={handleExpandChange(host.id + '_host_' + paymentByCurrency.currency)}
                                    style={{ backgroundColor: '#F3F2F2', marginTop: 20 }}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="Descargar Excel">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={(e) => { e.stopPropagation(); downloadPaymentsExcel(host) }}
                                                    >
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Link style={{ cursor: 'pointer' }}>{`#${host.id} ${host?.name} ${host?.lastname || ''}`}</Link>
                                                </div>
                                            </Box>

                                            {hostPaymentsLoading == host.id && (
                                                <CircularProgress style={{ width: 22, height: 22}} />
                                            )}

                                            <div>
                                                <span>Total: </span>
                                                <b><PriceDisplay value={host.total_amount} currency={paymentByCurrency.currency} /></b>
                                            </div>
                                        </Box>
                                    </AccordionSummary>

                                    {(expanded === host.id + '_host_' + paymentByCurrency.currency) && (
                                        <AccordionDetails>
                                            <Box style={{ width: '100%' }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            {isStatus([0, 1]) && (
                                                                <TableCell style={{ textAlign: 'center' }}>
                                                                    <Checkbox
                                                                        indeterminate={selected.length > 0 && selected.length < hostPayments.length}
                                                                        checked={hostPayments.length > 0 && selected.length === hostPayments.length}
                                                                        onChange={() => selectAll(hostPayments)}
                                                                    />
                                                                </TableCell>
                                                            )}
                                                            <TableCell style={{ fontWeight: 600 }}>ID</TableCell>
                                                            <TableCell style={{ fontWeight: 600 }}>Fecha de Reserva</TableCell>
                                                            <TableCell style={{ fontWeight: 600 }}>Concepto</TableCell>
                                                            <TableCell style={{ fontWeight: 600 }}>Booking ID</TableCell>
                                                            <TableCell style={{ fontWeight: 600 }}>Medio de Pago</TableCell>
                                                            {isStatus([0, 1]) && <TableCell style={{ fontWeight: 600 }}>Pagar en...</TableCell>}
                                                            <TableCell style={{ fontWeight: 600 }}>Cuotas</TableCell>
                                                            {isStatus([2]) && <TableCell style={{ fontWeight: 600 }}>Fecha de Pago</TableCell>}
                                                            {isStatus([2]) && <TableCell style={{ fontWeight: 600 }}>ID Pago DLOCAL</TableCell>}
                                                            <TableCell style={{ fontWeight: 600 }}>Monto</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {hostPayments.sort((a, b) => {
                                                            if (a.date !== b.date)
                                                                return moment(a.date) - moment(b.date);
                                                            else if (a.booking_id !== b.booking_id)
                                                                return a.booking_id - b.booking_id;
                                                            else
                                                                return a.installment_number - b.installment_number
                                                        }).map(p => (
                                                            // FIXME: Code duplicated on PaymentXLSXRowDTO (lib common)
                                                            <TableRow hover key={p.id + '_' + host.id} onClick={() => paymentSelection(p)} selected={selected.includes(p.id)}>
                                                                {isStatus([0, 1]) && <TableCell><Checkbox checked={selected.includes(p.id)} /></TableCell>}

                                                                <TableCell>#{p.id}</TableCell>

                                                                <TableCell>{p.date ? `${moment(p.date).format('DD-MM-YYYY')}` : '-'}</TableCell>

                                                                <TableCell>
                                                                    {`${conceptLabels[p.concept]} ${p.name ? `(${p.name})` : ''}`}
                                                                </TableCell>

                                                                <TableCell>
                                                                    {p.concept === PaymentConceptEnum.HOST_PAYOUT_FEE && [...new Set(p.description?.split(',')?.map(id => `#${id}`))].join(', ')}
                                                                    {p.concept !== PaymentConceptEnum.HOST_PAYOUT_FEE && (p.booking_id ? `#${p.booking_id}` : '-')}
                                                                </TableCell>

                                                                <TableCell>
                                                                    {p.concept === PaymentConceptEnum.COMPANY && 'Créditos de empresa'}
                                                                    {p.concept === PaymentConceptEnum.HOST_PAYOUT_FEE && '-'}
                                                                    {p.concept === PaymentConceptEnum.COINS && 'WorknCoins'}
                                                                    {p.concept === PaymentConceptEnum.HOST_SERVICE && '-'}
                                                                    {p.concept === PaymentConceptEnum.POINTS && 'OFFi Points'}
                                                                    {![PaymentConceptEnum.COMPANY, PaymentConceptEnum.HOST_PAYOUT_FEE, PaymentConceptEnum.COINS].includes(p.concept) && p.payment_method_name?.toUpperCase()}
                                                                </TableCell>

                                                                {isStatus([0, 1]) && <TableCell>{(() => {
                                                                    if (p.payment_method_id === 1) return '-';
                                                                    const daysToPay = moment(p.date).add(30, 'd').add(p.installment_number - 1, 'month').diff(moment(), 'days');
                                                                    let text = daysToPay + ' días';
                                                                    if (daysToPay === 1) text = 'HOY';
                                                                    return <Box style={{ color: daysToPay < 4 ? 'red' : '' }}>{text}</Box>;
                                                                })()}</TableCell>}

                                                                <TableCell>{`${p.installment_number} de ${p.total_installments}`}</TableCell>

                                                                {isStatus([2]) && <TableCell>{p.payment_date ? `${moment(p.payment_date).format('DD-MM-YYYY')}` : '-'}</TableCell>}

                                                                {isStatus([2]) && <TableCell>{p.cashout_id}</TableCell>}

                                                                <TableCell>
                                                                    <PriceDisplay value={p.payment_amount} currency={paymentByCurrency.currency} />
                                                                    {p.points_spent && ` (${p.points_spent} OFFi Point/s)`}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </AccordionDetails>
                                    )}
                                </Accordion>
                            ))}
                        </Box>
                    ))}
                </>}
            </div>
        </Box >
    );
};

export default Payments;

const conceptLabels = {
    0: 'Reserva',
    1: 'Referido',
    2: 'Código Promocional',
    3: 'Reserva de Empresa',
    4: 'Fee de payout',
    5: 'Reserva',
    6: 'Háblalo',
    7: 'Reserva'
}

// External components
import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import CreateSmartOfficeModal from '../modals/CreateSmartOfficeModal';
import moment from 'moment';
import { daysDictionary } from '../../helpers/dictionaries/daysDictionary';

// Internal components

const OpenModalEnum = {
    NONE: null,
    EDIT: 'EDIT',
    DELETE: 'DELETE',
};

const dto2FormValues = (smartOffice) => ({
    ...smartOffice,
    imageHashes: smartOffice.images,
    blocks: smartOffice.blocks
        .reduce((a, v, i) => ({
            ...a,
            [`block${i}`]: {
                ...v,
                id: v.id,
                imageHashes: v.images,
                services: v.services.map((service) => ({ ...service, service_id: service.info.id })),
            }
        }),
        {}),
});

const SmartOfficeCard = ({ smartOffice, getSmartOffices }) => {
    const [openModal, setOpenModal] = useState(OpenModalEnum.NONE);

    const { _root, _content, _img } = useStyles();

    return (
        <Box className={_root}>
            {openModal === OpenModalEnum.EDIT && (
                <CreateSmartOfficeModal
                    open={openModal === OpenModalEnum.EDIT}
                    setOpen={setOpenModal}
                    onConfirm={getSmartOffices}
                    smartOffice={dto2FormValues(smartOffice)}
                    mode={'EDIT'}
                />
            )}


            <Box className={_content}>
                <img src={smartOffice.images[0]?.original_url} className={_img} />

                <Box>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>#{smartOffice.id} {smartOffice.name}</Typography>

                    <br/>

                    <Typography>Ubicación: <b>{getLocationDisplayValue(smartOffice.location)}</b></Typography>
                    <Typography>Escritorios: <b>{smartOffice.totalCapacity}</b></Typography>
                    <Typography>Superficie: <b>{smartOffice.totalArea} m2</b></Typography>
                    <Typography>Días disponibles:
                        {' '}
                        <b>
                            {Object.keys(smartOffice?.prices || {})
                                .filter((p) => !!smartOffice?.prices[p])
                                .map((p) => daysDictionary[p])
                                .join(' - ')
                            }
                        </b>
                    </Typography>
                    <Typography>Desde: <b>{moment(smartOffice.openingDate).format('LLL')}</b></Typography>
                </Box>
            </Box>

            <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => setOpenModal(OpenModalEnum.EDIT)}
            >
                Ver detalle / editar
            </Button>
        </Box>
    );

};

export default SmartOfficeCard;

const useStyles = makeStyles((theme) => ({
    _root: {
        border: '1px solid lightgrey',
        borderRadius: theme.spacing(2),
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    _content: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
    },
    _img: {
        width: '250px',
        height: '250px',
        borderRadius: theme.spacing(2)
    }
}));

export const getLocationDisplayValue = (location) => {
    return (location.street_number ? location.street_number + ' ' + (location.street ? location.street + ', ' : '') : '') + location?.city + ', ' + location?.country;
};

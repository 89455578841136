// External components
import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import RUG, { DropArea } from 'react-upload-gallery';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import DataService from '../../services/DataService';
import { hackFnToUpdateOrderAfterLoading } from '../../helpers/funcs/hackFnToUpdateOrderAfterLoading';
import Axios from 'axios';
import SmartOfficeService from '../../services/SmartOfficeService';
import { updateNestedProperty } from '../../helpers/funcs/updateNestedProperty';
import { BlockServiceTypeEnum } from '../../helpers/enums/BlockServiceTypeEnum';

// Internal Components
import UploadImagesButton from '../atoms/UploadImagesButton';
import BlockServiceInputs from '../molecules/BlockServiceInput';

/**
 * @param {Object} props
 * @param {'EDIT' | 'CREATE'} props.mode
 */
const CreateBlocksModal = ({ open, setOpen, formValues, setFormValues, mode }) => {
    const initialBlockValues = {
        name: '',
        capacity: '',
        area: '',
        imageHashes: [],
        newImageHashes: [], // used for patch
        services: [],
    };

    const initialFormValues = {
        blocks: {
            block0: initialBlockValues
        },
    };

    const [imageWarning, setImageWarning] = useState(false);
    const [blocksQuantity, setBlocksQuantity] = useState(formValues.blocks ? Object.keys(formValues.blocks).length : 1);
    const [availableServices, setAvailableServices] = useState([]);

    const [currFormValues, setCurrFormValues] = useState(formValues.blocks ? { blocks: formValues.blocks } : initialFormValues);

    const handleFormValues = (path, value) => {
        const formValuesDraft = { ...currFormValues };
        updateNestedProperty(formValuesDraft, path, value);
        setCurrFormValues(formValuesDraft);
    };

    const toggleBlockService = (blockIndex, service, checked) => {
        const blockPath = `block${blockIndex}`;

        if (checked) {
            let type = BlockServiceTypeEnum.FREE;

            for (const block of Object.values(currFormValues.blocks)) {
                const sameServiceInOtherBlock = block.services.find((s) => s.service_id == service.service_id);

                if (sameServiceInOtherBlock) type = sameServiceInOtherBlock.type;
            }

            currFormValues.blocks[blockPath].services = [...currFormValues.blocks[blockPath].services, { ...service, type }];
            setCurrFormValues({ ...currFormValues });
        } else {
            if (mode === 'EDIT') {
                const blockService = currFormValues.blocks[blockPath].services.find((s) => s.service_id == service.service_id);

                if (blockService) SmartOfficeService.deleteBlockService(blockService.id);

                currFormValues.blocks[blockPath].services = currFormValues.blocks[blockPath].services.filter((s) => s.service_id != service.service_id);

                setFormValues({
                    ...formValues,
                    blocks: {
                        ...formValues.blocks,
                        [blockPath]: {
                            ...formValues.blocks[blockPath],
                            services: currFormValues.blocks[blockPath].services
                        }
                    }
                });
            }

            currFormValues.blocks[blockPath].services = currFormValues.blocks[blockPath].services.filter((s) => s.service_id != service.service_id);
            setCurrFormValues({ ...currFormValues });
        }
    };

    const updateBlockServicePrice = (blockIndex, serviceId, price) => {
        const serviceOfBlock = currFormValues.blocks[`block${blockIndex}`].services.find((s) => s.service_id == serviceId);
        serviceOfBlock.price = Number(price);
        setCurrFormValues({ ...currFormValues });
    };

    const updateBlockServiceQuantity = (blockIndex, serviceId, quantity) => {
        const serviceOfBlock = currFormValues.blocks[`block${blockIndex}`].services.find((s) => s.service_id == serviceId);
        serviceOfBlock.quantity = Number(quantity);
        setCurrFormValues({ ...currFormValues });
    };

    const updateBlockServiceType = (blockIndex, serviceId, type) => {
        for (const block of Object.values(currFormValues.blocks)) {
            const sameServiceInOtherBlock = block.services.find((s) => s.service_id == serviceId);

            if (sameServiceInOtherBlock && sameServiceInOtherBlock.type != type) {
                sameServiceInOtherBlock.type = type;
                sameServiceInOtherBlock.price = 0;
                sameServiceInOtherBlock.quantity = 0;
            }
        }

        const serviceOfBlock = currFormValues.blocks[`block${blockIndex}`].services.find((s) => s.service_id == serviceId);

        serviceOfBlock.type = type;
        setCurrFormValues({ ...currFormValues });
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleConfirm = async () => {
        const allBlockServicesHavePrices = Object.values(currFormValues.blocks).every((block) => {
            return block.services.every((service) => {
                if (service.type === BlockServiceTypeEnum.FREE) return true;
                return Boolean(service.price);
            });
        });

        if (!allBlockServicesHavePrices) {
            alert('Los servicios no gratuitos de los bloques deben tener un precio');
            return;
        }

        setFormValues({
            ...formValues,
            blocks: currFormValues.blocks
        });
        setOpen(null);
    };

    const handleAddBlock = () => {
        const newBlocksQuantity = blocksQuantity + 1;

        if (newBlocksQuantity > 5) {
            alert('La cantidad maxima de bloques es 5');
            return;
        }

        const formValuesDraft = { ...currFormValues };

        formValuesDraft.blocks[`block${blocksQuantity}`] = initialBlockValues;

        setCurrFormValues(formValuesDraft);

        setBlocksQuantity(newBlocksQuantity);
    };

    const handleDeleteBlock = (i) => {
        try {
            if (mode === 'EDIT') {
                SmartOfficeService.deleteBlock(currFormValues.blocks[`block${i}`].id);
            }

            const formValuesDraft = { ...currFormValues };
            delete formValuesDraft.blocks[`block${i}`];
            setCurrFormValues(formValuesDraft);
            setBlocksQuantity(blocksQuantity - 1);
        } catch (error) {
            console.log(error, 'errorrrrrrrrrrrrrrrrrrrrrr');
            alert('No se pudo borrar el bloque');
        }
    };

    const removeImage = async (blockIndex, image) => {
        if (mode === 'EDIT') {
            await SmartOfficeService.deleteBlockImage(currFormValues.blocks[`block${blockIndex}`].id, image.code);
        }

        const newBlockImages = currFormValues.blocks[`block${blockIndex}`].imageHashes.filter((i) => i.uuid != image.code);
        handleFormValues(`blocks.block${blockIndex}.imageHashes`, newBlockImages);
    };

    const getAvailableServices = async () => {
        const res = await DataService.getSpaceServices();
        const formattedServices = res.data.map((service) => {
            // it was confusing to handle both BlockService.id and Service.id
            const formattedService = { ...service, price: '', service_id: service.id };
            delete formattedService.id;
            return formattedService;
        });
        setAvailableServices(formattedServices);
    };

    const customRequest = ({ uid, file, action, onProgress, onSuccess, onError }, blockIndex) => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();

        const uploadProgressFn = ({ total, loaded }) => {
            onProgress(uid, Math.round(loaded / total * 100));
        };

        var reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = async function () {
            const res = await SmartOfficeService.uploadBlockImage(reader.result, uploadProgressFn, source.token);

            const newBlockImages = [
                ...currFormValues.blocks[`block${blockIndex}`].imageHashes,
                res.data
            ];

            if (mode === 'EDIT') {
                handleFormValues(`blocks.block${blockIndex}.newImageHashes`, [...currFormValues.blocks[`block${blockIndex}`].newImageHashes || [], res.data]);
            }

            handleFormValues(`blocks.block${blockIndex}.imageHashes`, newBlockImages);

            onSuccess(uid, res.data, res.data);
        };

        reader.onerror = function (error) {
            onError(uid, {
                action,
                status: error.request,
                response: error.response
            });
        };

        return {
            abort() {
                source.cancel();
            }
        };
    };

    useEffect(() => {
        getAvailableServices();
    }, []);

    const { _content } = useStyles();

    const blocksArr = [...Array(blocksQuantity)];

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'xl'} fullWidth>
            <DialogTitle>
                Bloques
            </DialogTitle>

            <DialogContent className={_content}>
                {blocksArr.map((_, i) => (
                    <Box key={i}  style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 16 }}>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h1>Bloque {i + 1}</h1>

                            {i >= 1 && i === blocksArr.length - 1 && (
                                <Tooltip title={<Typography variant={'body2'}>Quitar el bloque</Typography>}>
                                    <IconButton onClick={() => handleDeleteBlock(i)}>
                                        <DeleteForeverIcon color={'error'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>

                        <TextField
                            value={currFormValues.blocks[`block${i}`].name}
                            onChange={(e) => handleFormValues(`blocks.block${i}.name`, e.target.value)}
                            label={'Nombre'}
                            variant={'outlined'}
                        />

                        <TextField
                            value={currFormValues.blocks[`block${i}`].capacity}
                            onChange={(e) => handleFormValues(`blocks.block${i}.capacity`, Number(e.target.value))}
                            label={'Cantidad de escritorios'}
                            type={'number'}
                            variant={'outlined'}
                        />

                        <TextField
                            value={currFormValues.blocks[`block${i}`].area}
                            onChange={(e) => handleFormValues(`blocks.block${i}.area`, Number(e.target.value))}
                            label={'Superficie (m2)'}
                            type={'number'}
                            variant={'outlined'}
                        />

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <InputLabel>
                                        Servicios del Bloque
                                    {currFormValues.blocks[`block${i}`].services.length > 0 && ` (${currFormValues.blocks[`block${i}`].services.length})`}
                                </InputLabel>
                            </AccordionSummary>

                            <AccordionDetails
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-around',
                                    rowGap: 16,
                                }}
                            >
                                {availableServices.map((serviceIterable) => (
                                    <BlockServiceInputs
                                        key={`service_${serviceIterable.service_id}`}
                                        toggleSelectedService={toggleBlockService}
                                        updateServicePrice={updateBlockServicePrice}
                                        updateServiceType={updateBlockServiceType}
                                        updateServiceQuantity={updateBlockServiceQuantity}
                                        blockIndex={i}
                                        blockService={currFormValues?.blocks[`block${i}`]?.services?.find((serv) => serv.service_id == serviceIterable.service_id)}
                                        dbService={serviceIterable}
                                    />
                                ))}
                            </AccordionDetails>
                        </Accordion>

                        <InputLabel style={{ marginTop: '16px' }}>Fotos del Bloque</InputLabel>

                        <FormControl>
                            {imageWarning && <FormHelperText>{imageWarning}</FormHelperText>}

                            <RUG
                                inOrder={true}
                                onSuccess={hackFnToUpdateOrderAfterLoading}
                                source={(response) => response.original_url}
                                sorting={{ axis: 'xy', pressDelay: 200, distance: 0 }}
                                customRequest={(params) => customRequest(params, i)}
                                initialState={currFormValues.blocks[`block${i}`]?.imageHashes.map((i) => ({ source: i.original_url, code: i.uuid })) || []}
                                onDeleted={(image) => removeImage(i, image)}
                                header={({ openDialogue }) => (
                                    <DropArea>
                                        {(isDrag) => (
                                            <div style={{ border: 'dashed', borderColor: 'rgba(0, 0, 0, 0.12)', borderRadius: '4px', background: isDrag ? 'lightgrey' : '#fff' }}>
                                                <UploadImagesButton openDialogue={openDialogue} />
                                            </div>
                                        )}
                                    </DropArea>
                                )}
                                onChange={() => null}
                                onWarning={(type, rules) => {
                                    switch (type) {
                                    case 'limit':
                                        setImageWarning('Has cargado el máximo de imagenes' + ': ' + rules.limit);
                                        break;
                                    case 'size':
                                        setImageWarning('El tamañno máximo de imagen es 5mb.');
                                        break;
                                    default:
                                    }
                                }}
                            />
                        </FormControl>

                        {blocksArr.length > 1 && (
                            <Divider style={{ margin: '16px 0' }} />
                        )}
                    </Box>
                ))}

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant={'contained'} color={'primary'} onClick={handleAddBlock} disabled={blocksQuantity.length >= 5}>
                        <AddIcon style={{ marginRight: 16 }} />
                        Añadir otro
                    </Button>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    color={'primary'}
                    onClick={handleClose}
                >
                    Cerrar
                </Button>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleConfirm}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateBlocksModal;

const useStyles = makeStyles((theme) => ({
    _content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

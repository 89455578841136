// External components
import React, { useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
} from "@material-ui/core";

// Utils & Config
import FeaturesService from "../../services/FeaturesService";
import { ResponseStatusEnum } from "../../helpers/enums/ResponseStatusEnum";
import { HostServiceStatusEnum } from "../../helpers/enums/HostServiceStatusEnum";

const HostProModal = ({ open, setOpen, host, refreshList }) => {
    const [isPro, setIsPro] = useState(host?.proStatus === HostServiceStatusEnum.ACTIVE_SUSCRIPTION)
    const [reqStatus, setReqStatus] = useState(ResponseStatusEnum.IDLE)

    const hostName = `#${host.id} ${host.name} ${host.lastname}`

    const handleSwitch = async () => {
        const confirmed = window.confirm(`${isPro ? 'Desactivar' : 'Activar'} modo PRO para Host ${hostName}?`)
        if (!confirmed) return;

        setReqStatus(ResponseStatusEnum.LOADING)

        try {
            const payload = {}
            payload.serviceName = 'PRO'
            payload.hostId = host.id

            await FeaturesService.createHostService(payload)

            setIsPro(!isPro)
            setReqStatus(ResponseStatusEnum.SUCCESS)
            refreshList()
        } catch (error) {
            setReqStatus(ResponseStatusEnum.ERROR)
            alert('Hubo un error al activar PRO')
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    if (process.env.REACT_APP_ENABLED_HOST_PRO_FEATURE !== 'true') return null;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                {hostName} - {' '}
                {host.proStatus === HostServiceStatusEnum.ACTIVE_SUSCRIPTION && 'Host PRO'}
                {host.proStatus === HostServiceStatusEnum.NOT_SUSCRIPTED && 'Host NO PRO'}
                {host.proStatus === HostServiceStatusEnum.PENDING_ADMIN_APPROVAL && 'PRO Solicitado'}
            </DialogTitle>

            <DialogContent>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isPro}
                            onChange={(_, checked) => handleSwitch(checked)}
                            color="primary"
                        />
                    }
                    label={isPro ? 'PRO Activado' : 'PRO Desactivado'}
                />
            </DialogContent>

            <DialogActions>
                {reqStatus === ResponseStatusEnum.LOADING &&
                    <CircularProgress />
                }

                <Button
                    variant={"contained"}
                    color={'primary'}
                    onClick={handleClose}
                    disabled={reqStatus === ResponseStatusEnum.LOADING}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HostProModal;

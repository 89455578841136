import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    capitalize,
    useTheme
} from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import AdminService from '../../services/AdminService';
import { PriceDisplay } from '../atoms/PriceDisplay';
import TableIcons from '../molecules/TableIcons';
import GetAppIcon from '@material-ui/icons/GetApp';

const MonthlyPaymentsModal = ({ host, isOpen, onToggle }) => {
    const [payments, setPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [summaryDownloadLoading, setSummaryDownloadLoading] = useState(false);

    const theme = useTheme();

    const getMonthlyPayments = async () => {
        setIsLoading(true);
        try {
            const { data } = await AdminService.getHostPayments(host.id);
            setPayments(data?.reverse() || []);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const downloadPaymentsSummaryExcel = async () => {
        try {
            setSummaryDownloadLoading(true);
            const response = await AdminService.getPaymentsSummaryExcel(host.id);

            const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.data?.data).buffer], { type: 'application/vnd.ms-excel;charset=utf-8' }));

            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `Resumen de Pagos a Host #${host.id} ${host.name ?? ''} ${host.lastname ?? ''}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } finally{
            setSummaryDownloadLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen && host.id) {
            getMonthlyPayments()
        }
    }, [isOpen, host])

    const TableOptions = {
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
        sorting: true,
        thirdSortClick: false
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onToggle}
            aria-labelledby="host-dialog-payments"
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="host-dialog-payments">
                {
                    <Tooltip title="Descargar Excel">
                        <IconButton
                            color="primary"
                            onClick={(e) => { e.stopPropagation(); downloadPaymentsSummaryExcel() }}
                        >
                            {summaryDownloadLoading
                                ? <CircularProgress style={{ width: 22, height: 22}} />
                                : <GetAppIcon />
                            }
                        </IconButton>
                    </Tooltip>
                }

                <b>Pagos a {host.name || ''} {`#${host.id}`}</b>
            </DialogTitle>\

            <DialogContent>
                <MaterialTable
                    icons={TableIcons}
                    title={''}
                    options={{
                        ...TableOptions,
                        rowStyle: p => {
                            const isCurrentMonth = Boolean(moment(p.month).format('MM-YYYY') === moment().format('MM-YYYY'));
                            return ({
                                backgroundColor: isCurrentMonth && theme.palette.action.hover,
                                fontWeight: isCurrentMonth && 'bold'
                            })
                        }
                    }}
                    columns={[
                        {
                            title: <b>Fecha</b>,
                            field: 'month',
                            render: p => capitalize(moment(p.month).utc().format('MMMM - YYYY')),
                            editable: 'never',
                        },
                        {
                            title: <b>Pendiente</b>,
                            field: 'pending_amount',
                            render: p => <PriceDisplay value={+p.pending_amount} currency={p.currency} />,
                        },
                        {
                            title: <b>Cobrado</b>,
                            field: 'paid_amount',
                            render: p => <PriceDisplay value={+p.paid_amount} currency={p.currency} />,
                        },
                        {
                            title: <b>Total</b>,
                            render: p => <PriceDisplay value={+p.paid_amount + +p.pending_amount} currency={p.currency} />,
                        },
                    ]}
                    isLoading={isLoading}
                    data={payments}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} pago(s) selecionados'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay pagos para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtros'
                            },
                            editRow: {
                                deleteText: '¿Desea eliminar este pago?'
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onToggle} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default MonthlyPaymentsModal

// External components
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';

// Utils & Config
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';

// Internal components
import SmartOfficeService from '../../services/SmartOfficeService';
import { getLocationDisplayValue } from '../../helpers/funcs/getLocationDisplayValue';
import OwnOfficeEmployeeBokingsModal from './OwnOfficeEmployeeBokingsModal';

// Icons
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const OpenModalEnum = {
    NONE: null,
    OWN_OFFICES_BOOKINGS: 'OWN_OFFICES_BOOKINGS',
};

const CompanyOwnOfficesModal = ({ open, setOpen, company }) => {
    const [ownOfficesReq, setOwnOfficesReq] = useState({ status: ResponseStatusEnum.IDLE, data: [] });

    const [openModal, setOpenModal] = useState(OpenModalEnum.NONE);
    const [selectedOwnOffice, setSelectedOwnOffice] = useState(null);

    const searchOwnOffices = async () => {
        setOwnOfficesReq({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            const res = await SmartOfficeService.searchResOwnOffices({ companyId: company?.id });

            setOwnOfficesReq({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setOwnOfficesReq({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    useEffect(() => {
        searchOwnOffices();
    }, [company?.id]);

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'lg'} fullWidth>
            {openModal === OpenModalEnum.OWN_OFFICES_BOOKINGS && (
                <OwnOfficeEmployeeBokingsModal open={openModal === OpenModalEnum.OWN_OFFICES_BOOKINGS} setOpen={setOpenModal} ownOffice={selectedOwnOffice} />
            )}

            <DialogTitle>
                Own Offices Bookings
            </DialogTitle>

            <DialogContent>
                {ownOfficesReq.status === ResponseStatusEnum.LOADING && (
                    <CircularProgress />
                )}

                {(ownOfficesReq.status === ResponseStatusEnum.SUCCESS && !ownOfficesReq.data?.length) && (
                    <h3>No posee</h3>
                )}

                {ownOfficesReq.status === ResponseStatusEnum.SUCCESS && ownOfficesReq.data?.map?.((ownOffice) => (
                    <Box key={ownOffice.id} style={{ marginLeft: 8 }}>
                        <Typography
                            component={'a'}
                            onClick={() => { setSelectedOwnOffice(ownOffice); setOpenModal(OpenModalEnum.OWN_OFFICES_BOOKINGS); }}
                            style={{ display: 'flex', alignItems: 'center', gap: 8, marginLeft: 8, cursor: 'pointer' }}
                        >
                            <ReactCountryFlag countryCode={ownOffice.location.country} svg style={{ width: 24, height: 24 }} />

                            <b>{getLocationDisplayValue(ownOffice.location)}</b>

                            <OpenInNewIcon style={{ marginLeft: 8 }} />
                        </Typography>
                    </Box>
                ))}
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {ownOfficesReq.status === ResponseStatusEnum.ERROR &&
                        <Typography color={'error'}>
                            Hubo un error
                        </Typography>
                    }
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={() => setOpen(null)}
                    >
                        Cerrar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CompanyOwnOfficesModal;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    _dropzone: {
        width: 'auto',
        border: '2px dashed #9900CC',
        color: '#9900CC'
    },
    _switchBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#F0F0F0',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '450px',
        height: '50px',
        textTransform: 'none',
        fontFamily: [
            'Poppins'
        ]
    },
    _switchSelectedBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '450px',
        height: '50px',
        textTransform: 'none',
        fontWeight: 700,
        borderTop: '2px #F0F0F0 solid',
        borderLeft: '2px #F0F0F0 solid',
        borderRight: '2px #F0F0F0 solid',
        fontFamily: [
            'Poppins'
        ]
    },
    _switchContraPerkBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#F0F0F0',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderTop: '2px #F0F0F0 solid',
        borderLeft: '2px #F0F0F0 solid',
        borderRight: '2px #F0F0F0 solid',
        margin: '10px',
        width: '270px',
        height: '50px',
        textTransform: 'none',
        fontFamily: [
            'Poppins'
        ]
    },
    _switchSelectedContraPerkBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        width: '270px',
        height: '50px',
        textTransform: 'none',
        fontWeight: 700,
        borderTop: '2px #F0F0F0 solid',
        borderLeft: '2px #F0F0F0 solid',
        borderRight: '2px #F0F0F0 solid',
        fontFamily: [
            'Poppins'
        ]
    },
    _navBarContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    _navBarBox: {
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        },
    },
    _subNavbarContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '15px 0px'
    },
    _leftBtnsContainer: {
        width: '38%',
        marginRight: 'auto',
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    _newProductBtn: {
        backgroundColor: '#9900CC',
        borderRadius: 30,
        height: 35,
        color: 'white',
        '&:hover': {
            backgroundColor: '#9900CC',
        }
    },
    _searchFilterContainer: {
        width: '350px',
        marginLeft: 'auto'
    },
    _rigthBtnsPanel: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
    },
    _btnroot: {
    },
    _formInputTextField: {
        height: 40,
        backgroundColor: '#EDEDED'
    },
    _formInputVisualizer: {
        height: 40,
        backgroundColor: 'white'
    },
    _formHeader: {
        margin: '10px 0px 10px 20px',
        fontSize: 22,
        fontFamily: 'PoppinsBold',
        textAlign: 'left'
    },
    _formInputRoot: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'flex-end',
        marginRight: 'auto',
        width: '100%',
        marginLeft: 25
    },
    _formInputTitle: {
        fontSize: 18,
        fontFamily: 'Poppins',
        textAlign: 'left'
    },
    _rowFlex: {
        display: 'flex',
        flexDirection: 'row'
    },
    _stepBtnContainer: {
        margin: '20px 0px 20px auto',
        display: 'flex',
        flexDirection: 'row'
    }
}));

import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

const CityAutocomplete = ({ inputName, inputValue, handleChange, selectedCountry }) => {
    const [citiesAutocomplete, setCitiesAutocomplete] = useState(null);

    const citiesAutocompleteLoad = (ac) => setCitiesAutocomplete(ac);

    const handlePlaceChange = () => {
        const place = citiesAutocomplete.getPlace();

        let cityValue = place.formatted_address;
        cityValue = cityValue.substring(0, cityValue.lastIndexOf(','));

        handleChange({ target: { name: inputName, value: cityValue } });
    };

    if (!selectedCountry) return <DisabledInput />;

    return (
        <LoadScriptNext
            loadingElement={<DisabledInput />}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            libraries={['places']}
            language={'es'}
        >
            <Autocomplete
                options={{
                    types: ['(cities)'],
                    componentRestrictions: { country: selectedCountry }
                }}
                onLoad={citiesAutocompleteLoad}
                onPlaceChanged={handlePlaceChange}
            >
                <TextField
                    required
                    id={'city'}
                    name={inputName}
                    value={inputValue}
                    onChange={handleChange}
                    variant={'outlined'}
                    placeholder={''}
                    label={'Ciudad'}
                    type={'text'}
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    autoComplete={'off'}
                />
            </Autocomplete>
        </LoadScriptNext>
    );
};

export default CityAutocomplete;

const DisabledInput = () => (
    <TextField
        variant={'outlined'}
        disabled
        required
        id={'cityDisabled'}
        placeholder={''}
        label={'Ciudad'}
        type={'text'}
        style={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        autoComplete={'off'}
    />
);

// External components
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent
} from "@material-ui/core";
import React from "react";
import ImageUploadGallery from "../molecules/ImageUploadGallery";


const ImageVisualizerModal = ({ open, setOpen, data }) => {

    return (
        <Box>
            <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
                <DialogContent>
                    <ImageUploadGallery productId={data?.id} images={data?.images} setImages={() => null} />
                </DialogContent>

                <DialogActions>
                    <>
                        <Button onClick={() => setOpen(false)} color='primary'>
                            Salir
                        </Button>

                    </>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ImageVisualizerModal;


import React, { useEffect, useState } from 'react';
// external components
import { Box, Switch, TableCell, withStyles } from '@material-ui/core';
// Icons & Images
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import StoreService from '../../services/StoreService';
import { PriceDisplay } from '../atoms/PriceDisplay';
import DeleteProductModal from '../../components/modals/DeleteProductModal';

const StatusEnum = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    // DELETED: 'DELETED'
};
const ProductsTableCell = ({ data, openEditModal, openImageModal, refreshList }) => {

    const generateStatus = () => {
        if (data?.quantity == 0) return false;
        if (data?.status == StatusEnum.ACTIVE) return true;
        if (data?.status == StatusEnum.PENDING) return false;
    };

    const [productStatus, setProductStatus] = useState(generateStatus());

    const updateStatus = async (chkd, id) => {
        setProductStatus(chkd);
        const status = chkd ? StatusEnum.ACTIVE : StatusEnum.PENDING;
        let data = {
            product_id: id,
            status: status
        };
        await StoreService.updateStatus(data);
    };

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleRemoveProduct = async (product_id) => {
        await StoreService.deleteProduct(product_id);
        refreshList();
    };


    return <>
        <DeleteProductModal open={openDeleteModal} setOpen={setOpenDeleteModal} product={data} deleteProduct={(value) => handleRemoveProduct(value)} />
        <TableCell align='center'>
            <EditIcon size="small" style={{ cursor: 'pointer' }} onClick={() => openEditModal(data)} />
        </TableCell>
        <TableCell align='center'>
            <DeleteIcon size="small"
                onClick={() => setOpenDeleteModal(true)}
                style={{ cursor: 'pointer' }} />
        </TableCell>
        <TableCell align='center'>
            <span style={{ fontWeight: 700 }}>
                {data?.id}
            </span>
        </TableCell>
        <TableCell align='center'>
            <span style={{ fontWeight: 700 }}>
                {data?.title}
            </span>
        </TableCell>
        <TableCell align='center'>
            <Box onClick={() => openImageModal(data)} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                {data?.images.length}
            </Box>
        </TableCell>

        <TableCell align='center'>
            {data?.country.internal_detail}
        </TableCell>
        <TableCell align='center'>
            <PriceDisplay value={data?.price} currency={data?.currency?.code} />
        </TableCell>
        <TableCell align='center'>
            {data?.quantity}
        </TableCell>
        <TableCell align='center'>
            {data?.sold_products}
        </TableCell>
        <TableCell align='center'>
            {data?.description}
        </TableCell>

        <TableCell align='center'>
            {
                <PurpleSwitch
                    value={productStatus}
                    checked={productStatus}
                    onChange={(e) => updateStatus(e.target.checked, data.id)}
                />
            }
        </TableCell>
    </>;
};

export default ProductsTableCell;

export const PurpleSwitch = withStyles({
    switchBase: {
        color: 'grey',
        '&$checked': {
            color: '#9900CC',
        },
        '&$checked + $track': {
            backgroundColor: '#9900CC',
        },
    },
    checked: {},
    track: {},
})(Switch);


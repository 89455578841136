import { Box, Button, FormControl, FormHelperText } from '@material-ui/core';
import Axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import RUG, { DropArea } from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';
import StoreService from '../../services/StoreService';



const ImageUploadGallery = ({ images, setImages, productId }) => {

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    const hackFnToUpdateOrderAfterLoading = () => {
        return false;
    };
    const [loadingCounter, setLoadingCounter] = useState(0);

    const customRequest = ({ uid, file, send, action, headers, onProgress, onSuccess, onError, index }) => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();


        const uploadProgressFn = ({ total, loaded }) => {
            onProgress(uid, Math.round(loaded / total * 100));
        };

        var reader = new FileReader();
        let count = 1;
        reader.readAsDataURL(file);

        reader.onload = async function () {
            setLoadingCounter(c => c + 1);

            let resp = await StoreService.uploadBase64(reader.result, productId, uploadProgressFn, source.token);
            if (images == null) {
                setImages([resp.data]);
            }
            if (images != null) {
                images.push(resp.data);
            }
            onSuccess(uid, resp.data, { code: resp.data.uuid });
            setLoadingCounter(c => c - 1);

        };
        reader.onerror = function (error) {
            onError(uid, {
                action,
                status: error.request,
                response: error.response
            }, console.log("aaaaaaaaaaaaa.err", error));
        };

        return {
            abort() {
                count = 1;
                // source.cancel();
            }
        };
    };

    const removeImage = async (props) => {
        const uuid = props.code;
        const idProduct = productId;
        await StoreService.deleteImage(idProduct, uuid);
    };

    const styles = {
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '30px',
            width: '100%'
        },
        // ...contextStyles
    };


    const [productImages, setProductImages] = useState(null);
    const [imageWarning, setImageWarning] = useState(null);
    return (<>
        <Box className="onboardingForm">

            <FormControl
                style={{ ...styles.formControl }}
            >
                {/* {imageWarning && <FormHelperText>{imageWarning}</FormHelperText>}
            <FormHelperText>{errors.images}</FormHelperText> */}
                <RUG rules={{ limit: 4, size: 7000000 }}
                    inOrder={true}
                    onSuccess={hackFnToUpdateOrderAfterLoading}
                    onSortEnd={images => setProductImages(images.map(i => i.code))}
                    source={response => response.url}
                    sorting={{ axis: 'xy', pressDelay: 200, distance: 0 }}
                    customRequest={customRequest}
                    initialState={images?.map(i => ({ source: i.url, code: i.uuid })) || []}
                    onDeleted={removeImage}
                    header={({ openDialogue }) => (
                        <DropArea>
                            {(isDrag) => <div style={{ border: 'dashed', borderColor: 'rgba(0, 0, 0, 0.12)', borderRadius: '4px', background: isDrag ? 'lightgrey' : '#fff', textAlign: 'center' }}>
                                <UploadButton openDialogue={openDialogue} />
                            </div>}
                        </DropArea>
                    )}
                    onChange={() => null}
                    onWarning={(type, rules) => {
                        switch (type) {
                            case 'limit':
                                setImageWarning('El maximo de imagenes es ' + ': ' + rules.limit);
                                break;
                            case 'size':
                                setImageWarning('Supero el peso limite de la imagen');
                                break;
                            default:
                        }
                    }}
                />

            </FormControl>
        </Box>
    </>);
};

export default ImageUploadGallery;


export const UploadButton = (openDialogue) => {

    return <Box style={{ marginTop: '5%', zIndex: 99 }}>
        <Button onClick={() => openDialogue.openDialogue()} variant="contained" style={{
            borderRadius: '10px',
            borderColor: '#9d0ace',
            backgroundColor: '#9d0ace',
            height: '50px',
            color: '#ffffff',
            fontFamily: 'Poppins',
            fontWeight: 'bold'
        }}>CARGAR IMÁGENES</Button>
        <div style={{ color: '#383839', fontFamily: 'Poppins', marginTop: '5px', marginBottom: '20px', fontSize: '14px' }}>o arrastralas.</div>
    </Box>;
};

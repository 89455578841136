export const RequestedSpacePriceChangeTypeEnum =  {
    MONTHLY: 'MONTHLY',
    DAILY: 'DAILY',
    HOURLY: 'HOURLY',
};

export const priceRangeLabels = {
    [RequestedSpacePriceChangeTypeEnum.MONTHLY]: 'Por mes',
    [RequestedSpacePriceChangeTypeEnum.DAILY]: 'Por día',
    [RequestedSpacePriceChangeTypeEnum.HOURLY]: 'Por hora',
};

import React, { useState } from 'react';
// external components
import { Box, Switch, TableCell } from '@material-ui/core';
// Icons & Images
import AddBoxIcon from "@material-ui/icons/AddBox";
import AlliesService from '../../../services/AlliesService';
import EditIcon from '@material-ui/icons/Edit';

const StatusEnum = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    // DELETED: 'DELETED'
};
const AlliesTableCell = ({ data, addPerk, showAllyPerks, openEditModal, resetEdit }) => {

    const generateStatus = () => {
        if (data.status == StatusEnum.ACTIVE) return true;
        if (data.status == StatusEnum.PENDING) return false;
    };

    const [allyStatus, setAllyStatus] = useState(generateStatus());

    const updateStatus = async (chkd) => {
        setAllyStatus(chkd);
        const status = chkd ? StatusEnum.ACTIVE : StatusEnum.PENDING
        let payload = {
            ally_id: data.id,
            status: status
        };
        await AlliesService.changeAllyStatus(payload)

    };

    const handleAddPerk = (id) => {
        resetEdit()
        addPerk(id)
    }
    return <>
        <TableCell align='center'>
        <EditIcon size="small" style={{cursor: 'pointer'}} onClick={() => openEditModal(data)}/>
        </TableCell>
        <TableCell align='center'>
            <span style={{ fontWeight: 700 }}>
                {data.id}
            </span>
        </TableCell>
        <TableCell align='center'>
            <img src={data?.ally_logo?.original_url} alt="" style={{ width: 60, maxHeight: 60, borderRadius: 5 }} />
        </TableCell>
        <TableCell align='center'>
            <span style={{ fontWeight: 700 }}>
                {data.company_name}
            </span>
        </TableCell>
        <TableCell align='center'>
            {data.short_description_key}
        </TableCell>

        <TableCell align='center'>
            <Box onClick={() => { window.open(data.ally_landing_url, "_blank"); }} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                {data.ally_landing_url}
            </Box>
        </TableCell>

        <TableCell align='center' style={{ color: 'blue', cursor: 'pointer' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: 22, fontWeight: 700 }}>
                <Box onClick={() => showAllyPerks(data.id)}>
                    {data?.ally_perks?.length || 0}
                </Box>

                <Box>
                    <AddBoxIcon style={{ marginLeft: 10 }} onClick={() => handleAddPerk(data.id)} />
                </Box>
            </Box>
        </TableCell>



        <TableCell align='center'>
            {
                <Switch
                    value={allyStatus}
                    checked={allyStatus}
                    onChange={(e) => updateStatus(e.target.checked)}
                />
            }
        </TableCell>
    </>;
};

export default AlliesTableCell;

export const AllyName = ({ logo, name }) => {

    return <>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
            <span style={{ fontWeight: 700 }}>{name}</span>
        </Box>
    </>;
};

import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';


const SearchFilter = ({ filterText, setFilterText }) => {

    return <>
        <TextField
            // style={{ width: '45%' }}
            placeholder={'Buscar'}
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            InputProps={{
                startAdornment: (
                    <>
                        <InputAdornment position={'start'}>
                            <SearchIcon size={'small'} />
                        </InputAdornment>
                    </>
                ),
                endAdornment: (
                    <>
                        <InputAdornment position={'end'}>
                            <div onClick={() => setFilterText('')}>
                                <CloseIcon style={{ cursor: 'pointer' }} />
                            </div>
                        </InputAdornment>
                    </>
                ),
                style: {
                    width: '100%', backgroundColor: '#F0F0F0', borderRadius: 20, height: 35, padding: 10
                },
                disableUnderline: true
            }}
        />

    </>;
};

export default SearchFilter;
import moment from 'moment';

const BookingService = {

    getDateDisplay: (booking) => {
        let dateDisplay = moment(booking.date).format("L");
    
        if (booking.multiple_date)
          dateDisplay = `${moment(booking.date).format("L")} a ${moment(booking.last_date).format("L")}`;
    
        if (booking.custom_date)
          dateDisplay = `Entre ${moment(booking.date).format("L")} y ${moment(booking.last_date).format("L")}`;
    
        if (booking.monthly)
          dateDisplay = `${moment(booking.date).format("MMM YYYY")} a ${moment(booking.last_date).format("MMM YYYY")}`;
    
        return dateDisplay;
      },
}

export default BookingService;
// External components
import {
    Box,
    IconButton,
    Switch, Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    withStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PauseIcon from '@material-ui/icons/Pause';
import BlockIcon from '@material-ui/icons/Block';

// Icons & Images
import { HighlightOff } from "@material-ui/icons";


// Utils & Config
import { useMediaQuery } from "react-responsive";
import { useStyles } from "./store.styles";

// Internal components
import moment from "moment";
import FeaturesService from "../../services/FeaturesService";
import SubscriptionModal from "./SubscriptionModal";


const Wellness = () => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    const { _leftBtnsContainer, _btnroot, _newProductBtn, _rigthBtnsPanel, _searchFilterContainer, _subNavbarContainer } = useStyles();
    const [loader, setLoader] = useState(false);

    const tableTitles = [
        "Suscripción ID",
        "Fecha",
        "tipo",
        "Provedor",
        "Suscripciòn",
        "CC ID",
        "Employee ID",
        "Email",
        "Precio",
        "Estado",
        "Acciones",
    ];

    const INITIAL_VALUES = { status: null, subscriptionVoucherId: null };

    const SUBSCRIPTION_STATUS = {
        'ACTIVE': { id: 0, transName: 'Activo', name: 'ACTIVE' },
        'PENDING': { id: 1, transName: 'Pendiente', name: 'PENDING' },
        'PAYMENT_REJECTED': { id: 2, transName: 'Pago Rechazado', name: 'PAYMENT_REJECTED' },
        'CANCELLED': { id: 3, transName: 'Cancelado', name: 'CANCELLED' },
    };

    const [voucherList, setVoucherList] = useState([]);
    const [payload, setPayload] = useState(INITIAL_VALUES);
    const [open, setOpen] = useState(false);

    const getVouchers = async () => {
        let resp = await FeaturesService.getVouchers();
        setVoucherList(resp.data);
    };

    const handleModal = async (status, id) => {
        setPayload({ status: status, subscriptionVoucherId: id });
        setOpen(true);

    };
    const updateStatus = async () => {
        if (payload.status != null && payload.subscriptionVoucherId != null) {
            try {
                await FeaturesService.updateStatus(payload);
            }
            catch (e) {
                console.log(e);
            }
            finally {
                getVouchers();
                setPayload(INITIAL_VALUES);
            }
        }
    };

    useEffect(() => {
        getVouchers();
    }, []);

    const formatPrice = (p) => {
        const addPeriods = (num) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const price = Number(p).toFixed();
        const formattedPrice = addPeriods(price);
        if (isNaN(p)) {
            return 'Cargando ...';
        }
        else {
            return `${formattedPrice}`;
        }
    };

    const getSubscriptionType = (minMonths) => {
        if (minMonths === 1) return 'Subscripción Mensual';
        if (minMonths === 12) return 'Subscripción Anual';
        return 'Subscripción Regular';
    };
    return (<>
        <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
            {/* <h3>Listado de Suscripciones</h3> */}
            <SubscriptionModal open={open} setOpen={setOpen} payload={payload} updateStatus={updateStatus} />
            <Box className={_subNavbarContainer}>
            </Box>
            {loader ? <Box>Cargando ...</Box>
                :
                <Box overflow={isNarrowWidth ? 'scroll' : ''}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableTitles.map((title, i) => (
                                    <TableCell style={{ fontSize: '12px' }} align='center' key={i}>
                                        <b>{title}</b>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {voucherList
                                .sort((a, b) => b.id - a.id)
                                .map((p
                                ) => (
                                    <TableRow hover key={p.id + "_code"}>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 700 }}>
                                                {p.id}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 500, padding: 3, fontSize: 12 }}>
                                                {moment(p.creation_date).format('DD-MM-YYYY')}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 700, fontSize: 12 }}>
                                                WELLNESS
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 700, fontSize: 12 }}>
                                                {p.providerName}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 500, fontSize: 12 }}>
                                                {getSubscriptionType(p.minimumSubscriptionMonth)}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 500, fontSize: 12 }}>
                                                {p.companyCountryId}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 500, fontSize: 12 }}>
                                                {p.employeeId}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 500, fontSize: 12 }}>
                                                {p.employeeEmail}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 700, color: p.status === 'PAYMENT_REJECTED' ? 'red' : 'black', fontSize: 12 }}>
                                                {`${p.currency} ${formatPrice(p.price)}`}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span style={{ fontWeight: 700, color: p.status === 'PAYMENT_REJECTED' ? 'red' : 'black', fontSize: 12 }}>
                                                {SUBSCRIPTION_STATUS[p.status].transName}
                                            </span>
                                        </TableCell>

                                        <StatusCell status={p.status} action={handleModal} id={p.id} />

                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>}
        </div>
    </>);
};

export default Wellness;

export const StatusCell = ({ status, action = () => null, id }) => {
    if (status === 'PENDING')
        return <TableCell align='left'>
            <Box style={{ display: 'flex', alignItems: 'center' }}>

                <Tooltip title="Activar Suscripción" className="approveHost">
                    <IconButton style={{ fontSize: 30 }} color="primary" onClick={() => action('ACTIVE', id)}>
                        <ThumbUpIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancelar Subscripcion" className="deactivateHost">
                    <IconButton style={{ fontSize: 30 }} color="primary" onClick={() => action('CANCELLED', id)}>
                        <BlockIcon />
                    </IconButton>
                </Tooltip>

            </Box>
        </TableCell>;
    if (status === 'ACTIVE')
        return <TableCell align='left'>
            <Box style={{ display: 'flex', alignItems: 'center' }}>

                <Tooltip title="Cancelar Subscripcion" className="deactivateHost">
                    <IconButton style={{ fontSize: 30 }} color="primary" onClick={() => action('CANCELLED', id)}>
                        <BlockIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </TableCell>;
    return <TableCell></TableCell>;
};
export const PurpleSwitch = withStyles({
    switchBase: {
        color: 'grey',
        '&$checked': {
            color: '#9900CC',
        },
        '&$checked + $track': {
            backgroundColor: '#9900CC',
        },
    },
    checked: {},
    track: {},
})(Switch);


// External Components
import React from 'react';
import { Box, Button } from '@material-ui/core';
import 'react-upload-gallery/dist/style.css';

const UploadImagesButton = (openDialogue) => {
    return (
        <Box style={{ marginTop: '5%', zIndex: 99, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Button
                onClick={() => openDialogue.openDialogue()}
                variant={'contained'}
                style={{
                    borderRadius: '10px',
                    borderColor: '#6B2D87',
                    backgroundColor: '#6B2D87',
                    height: '50px',
                    color: '#ffffff',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold'
                }}
            >
              Subir Fotos
            </Button>

            <div style={{ color: '#383839', fontFamily: 'Poppins', marginTop: '5px', marginBottom: '20px', fontSize: '14px' }}>
              O arrastralas
            </div>
        </Box>
    );
};

export default UploadImagesButton;

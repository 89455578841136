import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const SubscriptionModal = ({ open, setOpen, payload, updateStatus }) => {
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const handleClose = async (isCancel) => {
        if (isCancel) {
            setLoading(true);
            try {
                await updateStatus();
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        setOpen(false);
    };

    return <Dialog open={open} onClose={() => handleClose(false)} className={isMobile ? 'mobile cancelBookingDialog' : 'cancelBookingDialog'}>
        <DialogTitle style={{ padding: 15 }}><span style={{ fontFamily: 'Poppins' }}>Cambiar Estado de la Subscripción</span></DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            <div style={{ fontFamily: 'Poppins' }}>{`Esta seguro que desea cambiar la subscripcion con id: ${payload.subscriptionVoucherId}, a el estado ${payload.status}`}</div>
        </DialogContent>
        <DialogActions style={{ padding: 15, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Button
                disabled={loading}
                style={{ backgroundColor: '#FF4941', color: '#FFFFFF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(true)}
                variant={'contained'}
                color={'primary'}
                autoFocus
            >
                Cambiar Estado
            </Button>
            <Button
                disabled={loading}
                style={{ borderColor: '#FF4941', color: '#FF4941', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(false)}
                variant={'outlined'}
                color={'primary'}
            >
                {loading ? <CircularProgress /> : 'Salir'}
            </Button>
        </DialogActions>
    </Dialog>;
};

export default SubscriptionModal;


import { Checkbox, Chip, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import AdminService from '../../../services/AdminService';

const icon = <CheckBoxOutlineBlankIcon fontSize={'small'} />;
const checkedIcon = <CheckBoxIcon fontSize={'small'} />;

const ContraPerkPromocode = ({ setPromocodeSelected, promocodeSelected }) => {

    const [promoCode, setPromoCode] = useState([]);
    const promocodes = async () => {
        try {
            let resp = await AdminService.getPromoCodes();
            setPromoCode(resp.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        promocodes();
    }, []);

    const handleChange = async (event, newValue) => {
        setPromocodeSelected(newValue);
    };
    return (
        <Autocomplete
            id={'checkboxes-promocode'}
            options={promoCode}
            disableCloseOnSelect
            value={promocodeSelected}
            onChange={(event, newValue) => handleChange(event, newValue)}
            getOptionLabel={(option) => (option.coupon)}
            getOptionSelected={(option, value) => option.id == value.id}
            ChipProps={{
                variant: 'outlined',
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip
                    key={option.id + '_emmmm'}
                    label={option ? (option.coupon) : ''}

                    {...getTagProps({ index })}
                />)
            }
            renderOption={(option, { selected }) => (
                <>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.coupon}
                </>
            )}
            renderInput={(params) => (
                <TextField {...params} variant={'outlined'} label={'Promocode'} />
            )}
        />

    );
};
export default ContraPerkPromocode;

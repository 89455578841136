import React, { useState, useEffect } from "react";
import { Box, InputBase, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, FormHelperText, Select, MenuItem, Tooltip, TextField, Paper } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import is from "is_js";
import { PriceDisplay } from "../atoms/PriceDisplay";
import DeleteIcon from '@material-ui/icons/Delete';
import { useMediaQuery } from "react-responsive";
import AddBoxIcon from '@material-ui/icons/AddBox';

const DiscountsByChair = ({ space, chairDiscountRanges, setChairDiscountRanges }) => {
    const [addChariDiscountRangeModalOpen, setAddChariDiscountRangeModalOpen] = useState(false);

    const validateNewDiscount = ({ quantity_from, quantity_to, discount_percentage }) => {
        let resp = {
            errors: [],
            valid: false
        }

        let positiveRange = +quantity_from <= +quantity_to;
        if (!positiveRange) resp.errors.push('Rango invalido');

        let rangeIntersection = chairDiscountRanges.some(cd => +cd.quantity_from <= +quantity_to && +quantity_from <= +cd.quantity_to);
        if (rangeIntersection) resp.errors.push('Los rangos se solapan');

        let discountIsZero = +discount_percentage <= 0;
        if (discountIsZero) resp.errors.push('El descuento debe ser mayor a cero.');

        resp.valid = is.empty(resp.errors);
        return resp;
    }

    const addDiscount = (newDisc) => {
        let resp = validateNewDiscount(newDisc);
        if (resp.valid)
            setChairDiscountRanges(cdr => ([...cdr, newDisc]).sort((a, b) => a.quantity_from - b.quantity_from));
        return resp;
    }

    return <Box>
        <Box style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
            <Box>Descuento por Nro de Sillas</Box>
            <Button style={{
                backgroundColor: '#ffffff',
                color: '#00D1BF',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                boxShadow: 'none',
                height: '40px',
                marginLeft: 10,
                fontWeight: 'bold',
            }} variant="contained" onClick={() => setAddChariDiscountRangeModalOpen(true)}><AddBoxIcon style={{ marginRight: 10 }} />Agregar</Button>
        </Box>
        <Box>
            {chairDiscountRanges.map(cd => <Discount disc={cd} setChairDiscountRanges={setChairDiscountRanges} space={space} key={cd.quantity_from + '_' + cd.quantity_to + '_range'} />)}
        </Box>
        <AddChairsDiscountRangeModal open={addChariDiscountRangeModalOpen} setOpen={setAddChariDiscountRangeModalOpen} addDiscount={addDiscount} space={space} chairDiscountRanges={chairDiscountRanges} />
    </Box>

};

export default DiscountsByChair;

const Discount = ({ disc, setChairDiscountRanges, space }) => {
    const isLessThan1000pxWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    const deleteRange = () => {
        setChairDiscountRanges(cdr => cdr.filter(cd => !(cd.quantity_from === disc.quantity_from && cd.quantity_to === disc.quantity_to)))
    };

    return <Paper elevation={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: isLessThan1000pxWidth ? 'column' : 'row', margin: 15, padding: 5, paddingLeft: 15 }}>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <FormControl style={{ margin: 5, marginRight: 15 }}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel control={<>
                        <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                            <NumberFormat value={+disc.discount_percentage}
                                decimalScale={1}
                                readOnly
                                label="" required
                                isNumericString={true}
                                placeholder="0% OFF"
                                customInput={InputBase}
                                suffix={'% OFF'} />
                        </div>
                    </>} />
                </Box>
            </FormControl>
            <Box style={{ marginRight: 15 }}>desde <b>{disc.quantity_from}</b> sillas</Box>
            <Box style={{ marginRight: 15 }}>hasta <b>{disc.quantity_to}</b> sillas</Box>
        </Box>
        <br />
        <DiscountPreview space={space} disc={disc} />
        <Tooltip title={'Eliminar'}>
            <IconButton onClick={deleteRange}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    </Paper>
}

const AddChairsDiscountRangeModal = ({ open, setOpen, addDiscount, space, chairDiscountRanges }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [errors, setErrors] = useState([]);

    const getNewDisc = () => {
        let nd = {
            quantity_from: 1,
            quantity_to: +space.capacity,
            discount_percentage: 0
        }

        if (is.not.empty(chairDiscountRanges)) {
            nd.quantity_from = Math.min(+chairDiscountRanges[chairDiscountRanges.length - 1].quantity_to + 1, space.capacity);
            nd.quantity_to = +space.capacity;
        }
        return nd;
    }
    const [newDisc, setNewDisc] = useState(getNewDisc());

    useEffect(() => {
        if (open) {
            setNewDisc(getNewDisc());
            setErrors([]);
        }
        // eslint-disable-next-line
    }, [open]);

    const confirm = () => {
        setErrors([]);
        let resp = addDiscount(newDisc);
        if (resp.valid)
            setOpen(false);
        else if (resp.errors) setErrors(resp.errors);
    };

    return <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'md'} fullScreen={isMobile}>
        <DialogTitle style={{ padding: 15 }}>Añadir rango de descuento por silla</DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            <Box style={{}}>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', marginBottom: 50, height: '90%' }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Box>desde</Box>
                        <FormControl style={{ margin: 5, width: 40 }}>
                            <TextField
                                value={newDisc.quantity_from}
                                inputProps={{ min: 1, max: space.capacity, style: { textAlign: 'center' } }}
                                onChange={e => { e.persist(); setNewDisc(nd => ({ ...nd, quantity_from: e.target.value })) }}
                                type="number"
                                InputLabelProps={{ shrink: true, }} />
                        </FormControl>
                        <Box>sillas</Box>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Box>Hasta</Box>
                        <FormControl style={{ margin: 5, width: 40, textAlign: 'center' }}>
                            <TextField
                                value={newDisc.quantity_to}
                                inputProps={{ min: newDisc.quantity_from, max: space.capacity, style: { textAlign: 'center' } }}
                                onChange={e => { e.persist(); setNewDisc(nd => ({ ...nd, quantity_to: e.target.value })) }}
                                type="number"
                                InputLabelProps={{ shrink: true, }} />
                        </FormControl>
                        <Box>sillas</Box>
                    </Box>
                    <FormControl style={{ margin: 5 }}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel control={<>
                                <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                    <NumberFormat value={newDisc.discount_percentage}
                                        decimalScale={1}
                                        onValueChange={e => {
                                            let value = e.value;
                                            if (value >= 101) value = 100;
                                            if (value <= 0) value = '';
                                            setNewDisc(nd => ({ ...nd, discount_percentage: value }));
                                        }}
                                        label="" required
                                        isNumericString={true}
                                        placeholder="0% OFF"
                                        customInput={InputBase}
                                        suffix={'% OFF'} />
                                </div>
                            </>} />
                        </Box>
                    </FormControl>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                    <Box style={{ marginRight: 10, fontWeight: 600, marginBottom: 5 }}>Ejemplo:</Box>
                    <DiscountPreview space={space} disc={newDisc} />
                </Box>
            </Box>
            <br />
            {errors.map(e => <FormHelperText error={true} key={e} style={{ textAlign: 'center' }}>{e}</FormHelperText>)}
        </DialogContent>
        <DialogActions style={{ padding: 15, display: 'flex' }}>
            <Button
                style={{ borderColor: '#00D1BF', color: '#00D1BF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', minWidth: '150px' }}
                onClick={() => setOpen(false)}
                color="primary"
                variant="outlined"
                autoFocus>
                cancelar
            </Button>
            <Button
                style={{ backgroundColor: '#00D1BF', color: '#FFFFFF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', minWidth: '150px' }}
                onClick={confirm}
                variant="contained"
                color="primary">
                Agregar
            </Button>
        </DialogActions>
    </Dialog>
};

const DiscountPreview = ({ space, disc }) => {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState('');

    useEffect(() => {
        if (disc && is.not.empty(disc)) {
            let leng = Math.max(+disc.quantity_to - +disc.quantity_from + 1, 0);
            setOptions([...Array(leng).keys()].map(i => i + +disc.quantity_from));
        }
    }, [disc]);

    useEffect(() => {
        if (is.not.empty(options)) setSelected(options[0]);
    }, [options]);

    if (!space?.price) return null;

    return <Box>
        <PriceDisplay value={space.price} currency={space.currency?.name} />
        <span>{` x `}</span>
        <Select
            value={selected}
            onChange={e => setSelected(e.target.value)}>
            {options.map(o => <MenuItem value={o} key={o + '_item_o'}><b>{o}</b></MenuItem>)}
        </Select>
        <span>{` sillas - ${disc.discount_percentage}% = `}</span>
        <b>
            <PriceDisplay value={space.price * selected - (space.price * selected * disc.discount_percentage / 100)} currency={space.currency?.name} /> por dia
        </b>
    </Box>

};
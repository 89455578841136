import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        // marginRight: `${theme.spacing(4)}px`,
        margin: 'auto'
    },
    title: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        marginTop: `${theme.spacing(3)}px`,
        marginLeft: (props) => props.isLessThan1300pxWidth ?  '0' :  `${theme.spacing(8)}px`,
        fontSize: `${theme.spacing(2)}px`,
        fontFamily: 'PoppinsBold',
        textAlign: (props) => props.isLessThan1300pxWidth ? 'center' : 'left',
    },
    container: {
        marginTop: `${theme.spacing(4)}px`,
        width: (props) => props.isLessThan1300pxWidth ? '95vw' : '70vw',
        display: 'flex',
        flexDirection: (props) => props.isLessThan1300pxWidth ? 'column-reverse' : 'row',
        marginLeft: (props) => props.isLessThan1300pxWidth ?  '0' :  `${theme.spacing(8)}px`,
        justifyContent: 'space-around',
    },
    chartContainer: {
        width: (props) => props.isLessThan1300pxWidth ? '100%' : '60%',
        marginTop: (props) => props.isLessThan1300pxWidth ? `${theme.spacing(2)}px` : '',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(1)}px`,
        margin: (props) => props.yAxis ? 0 : `${theme.spacing(2)}px`,
        maxWidth: (props) => props.isLessThan1300pxWidth ? '' : '274px'
    },
    legendContainer: {
        display: 'flex',
        flexDirection: (props) => props.isLessThan1300pxWidth ? 'row' : 'column',
        justifyContent: (props) => props.isLessThan1300pxWidth ? 'space-around' : '',
        marginTop: `${theme.spacing(1)}px`,
        gap: (props) => props.isLessThan1300pxWidth ? `${theme.spacing(4)}px` : `${theme.spacing(2)}px`,
    }
}));
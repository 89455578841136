import React, { useState } from "react";
import { Button, Box, Link } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PriceDisplay } from "./atoms/PriceDisplay";
import SpaceTitle from "./SpaceTitle";
import is from 'is_js';
import moment from "moment";
import { BookingDatesCalendarModal } from "./BookingDatesCalendarModal";
import AdminService from '../services/AdminService';
import FinalPdfVisualizer from "./molecules/FinalPdfVisualizer";
import BookingService from "../services/BookingService";

const BookingInfoModal = ({ booking, open, setOpen, reloadBookings, selectedStatus }) => {
    const [datesModalOpen, setDatesModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const cancelWithPenalty = (withPenalty) => {
        if (window.confirm(`Esta seguro que desea CANCELAR el booking ${withPenalty ? 'CON' : 'SIN' } PENALIDAD?`)) {
            setLoading(true);

            AdminService.cancelBookingAsUser(booking?.id, { withPenalty })
                .then(resp => {
                    console.log(resp);
                    setOpen(false);
                    reloadBookings();
                })
                .catch(e => console.log(e))
                .finally(() => setLoading(false));
        }
    }

    const cancelAsHost = () => {
        if (window.confirm('Esta seguro que desea CANCELAR el booking como HOST?')) {
            setLoading(true);
            AdminService.cancelBookingAsHost(booking?.id)
                .then(resp => {
                    console.log(resp);
                    setOpen(false);
                    reloadBookings();
                })
                .catch(e => console.log(e))
                .finally(() => setLoading(false));
        }
    }

    const acceptBooking = () => {
        if (window.confirm('Esta seguro que desea ACEPTAR el booking como HOST?')) {
            setLoading(true);
            AdminService.acceptBookingAsHost(booking?.id)
                .then(resp => {
                    console.log(resp);
                    setOpen(false);
                    reloadBookings();
                })
                .catch(e => console.log(e))
                .finally(() => setLoading(false));
        }
    }

    const getLinkToSignContractByAdmin = async (bookingId) => {
        try {
            setLoading(true)
            const resp = await AdminService.getLinkToSignContractByAdmin(bookingId);
            if (resp.data.redirectUrl) {
                // window.open(resp.data.redirectUrl, '_blank')}
                window.location.href = resp.data.redirectUrl
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
        // reloadBookings();
    };

    const openSpacePublication = spaceId => {
        const newWindow = window.open(`${process.env.REACT_APP_WEB_APP_URL}/publication/${spaceId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    };

    let acumPartnersCredits = booking?.partners?.map(p => +p.employee_budget_amount).reduce((prev, current) => prev + current, 0) || 0;
    let isContractRequired = booking?.space?.contract_required;
    let isDepositRequired = booking?.space?.deposit_required;
    let isSetupFeeRequired = booking?.space?.setup_fee_required;
    let isPaymentPending = booking?.final_price > 0 && booking?.payment_method?.name !== 'cash' && [1,2].includes(booking?.user_status);

    return <>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="xl">
            <DialogTitle>
                Detalles de la Reserva #{booking.id}
            </DialogTitle>
            <DialogContent>

                {isPaymentPending && <Box style={{ color: 'red', padding: 10, border: '1px solid red', borderRadius: 10, margin: 10, backgroundColor: 'pink', marginBottom: 20}}>Esta reserva se encuentra PENDIENTE DE PAGO</Box>}

                <Box style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 10 }}>
                    {selectedStatus === 1 && <Button disabled={isContractRequired || isPaymentPending} variant="contained" color="primary" onClick={() => acceptBooking()}>{`Aprobar`}</Button>}
                    {[1, 2, 5].includes(selectedStatus) && <Button variant="contained" color="secondary" onClick={() => cancelAsHost()}>{`Cancelar/Rechazar como HOST`}</Button>}
                    {[1, 2, 5].includes(selectedStatus) && <Button variant="contained" color="secondary" onClick={() => cancelWithPenalty(true)}>{`Cancelar como usuario con penalidad`}</Button>}
                    {[1, 2, 5].includes(selectedStatus) && <Button variant="contained" color="secondary" onClick={() => cancelWithPenalty(false)}>{`Cancelar como usuario sin penalidad`}</Button>}
                    {selectedStatus === 5 && <Box> <Button variant="contained" color="primary" onClick={() => getLinkToSignContractByAdmin(booking.id)}>{!loading ? `Firmar Contrato` : `Cargando...`}</Button></Box>}
                </Box>

                <Box onClick={() => openSpacePublication(booking.space?.id)} style={{ display: 'flex', marginBottom: 20, padding: 10, border: '1px solid lightgrey', borderRadius: 15, cursor: 'pointer' }}>
                    <img src={booking.space?.images?.[0]?.url} style={{ height: 100, width: 100, borderRadius: 15 }} alt="" />
                    <div style={{ marginLeft: 15 }}>
                        <div style={{ fontSize: '16px', color: '#383839', fontWeight: 500 }}><SpaceTitle space={booking.space} /></div>
                        <div style={{ fontSize: '14px', color: '#383839' }}>{booking.space?.buildingType?.name}</div>
                    </div>
                </Box>
                <Box>
                    <Link onClick={() => setDatesModalOpen(true)} style={{ cursor: 'pointer' }}>
                        {BookingService.getDateDisplay(booking)}
                    </Link>
                    {booking.hourly && <Box>{`${moment.utc(booking.start_time).format('HH:mm A')} - ${moment.utc(booking.end_time).format('HH:mm A')}`}</Box>}
                </Box>
                <BookingDatesCalendarModal
                    open={datesModalOpen}
                    setOpen={setDatesModalOpen}
                    booking={booking}
                />

                {booking.booking_contract && <FinalPdfVisualizer bookingId={booking?.id} />}

                <br />
                {booking?.monthly ?
                    <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                        <Box>{`${booking.months_count || 1} meses, por persona (${booking?.space_quantity || 1})`}</Box>
                        {!booking.private && !booking.parentBooking && <PriceDisplay value={booking.space_total_price} currency={booking.currency} />}
                    </Box>
                    :
                    <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                        <Box>{`${booking.dates?.length || 1} jornada, por persona (${booking?.space_quantity || 1})`}</Box>
                        {!booking.private && !booking.parentBooking && <PriceDisplay value={booking.space_total_price} currency={booking.currency} />}
                    </Box>
                }

                {booking.private ? <>
                    <b>Espacio Privado</b>
                </> : <>
                    {booking.parentBooking ? `Sub-Reserva de #${booking.parentBooking?.id}` : <>
                        {+booking.days_discount_amount > 0 && <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                            <Box>{`Descuento`}</Box>
                            <PriceDisplay value={-booking.days_discount_amount} currency={booking.currency} />
                        </Box>}
                        {+booking.quantity_discount_amount > 0 && <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                            <Box>{`Descuento por cantidad de sillas (${+booking.quantity_discount_percentage}%)`}</Box>
                            <PriceDisplay value={-booking.quantity_discount_amount} currency={booking.currency} />
                        </Box>}
                        <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                            <Box>{`Costo de Servicio`}</Box>
                            <PriceDisplay value={booking.fee_amount} currency={booking.currency} />
                        </Box>

                        {isDepositRequired && <> <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                            <Box>{`Costo de Deposito`}</Box>
                            <PriceDisplay value={booking?.deposit_value} currency={booking.currency} />
                        </Box></>}

                        {isSetupFeeRequired && <> <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                            <Box>{`Costo de Setup Fee`}</Box>
                            <PriceDisplay value={booking?.setup_fee_value} currency={booking.currency} />
                        </Box></>}

                        {is.not.empty(booking.space_services) && <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                            <Box>{`Servicios adicionales`}</Box>
                            <PriceDisplay value={booking.services_total_price} currency={booking.currency} />
                        </Box>}
                        {booking.space_services?.map(ss =>
                            <Box key={ss.service_name + '_ss'} style={{ display: 'flex', justifyContent: 'space-between', color: 'grey', marginLeft: '10px', lineHeight: 2 }}>
                                <Box>{`${ss.service_name} (${ss.quantity})`}</Box>
                                <PriceDisplay value={ss.total_price} currency={booking.currency} />
                            </Box>
                        )}
                        {booking.promocode_amount > 0 && <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                            <Box>{`Código promocional`}</Box>
                            <PriceDisplay value={-booking.promocode_amount} currency={booking.currency} />
                        </Box>}
                        <Box style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 600, lineHeight: 2 }}>
                            <Box>{`Total`}</Box>
                            <PriceDisplay value={+booking.final_price + +booking.employee_budget_amount + +booking.company_budget_amount + +acumPartnersCredits} currency={booking.currency} />
                        </Box>
                        <Box style={{ marginTop: 10, paddingTop: 10, borderTop: '1px solid lightgray' }}>
                            <Box style={{ marginRight: 3 }}>Medio de Pago</Box>

                            {booking.total_points_spent && <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                POINTS {booking.total_points_spent}
                            </Box>}

                            {booking.final_price > 0 && <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ marginRight: 5, marginLeft: 10 }}>
                                    {booking.payment_method?.name}
                                </span>
                                <PriceDisplay value={booking.final_price} currency={booking.currency} />
                            </Box>}

                            {(+booking.employee_budget_amount > 0) && <>
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ marginRight: 5, marginLeft: 10 }}>Créditos de Empresa</span>
                                    <PriceDisplay value={+booking.employee_budget_amount + +acumPartnersCredits} currency={booking.currency} />
                                </Box>

                                <br />
                                <Box>Participantes</Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ marginRight: 5, marginLeft: 10 }}>{`${booking.user.name} ${booking.user.lastname || ''} (${booking?.user.email || ''})`}</span>
                                    <PriceDisplay value={+booking.employee_budget_amount} currency={'Creditos'} />
                                </Box>
                                {booking?.partners.map(p => <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ marginRight: 5, marginLeft: 10 }}>{`${p?.employee?.user?.name || ''} ${p?.employee?.user?.lastname || ''} (${p?.email || ''})`}</span>
                                    <PriceDisplay value={+p.employee_budget_amount} currency={'Creditos'} />
                                </Box>)}
                            </>}

                            {(+booking.company_budget_amount > 0) && <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ marginRight: 5, marginLeft: 10 }}>Créditos de Empresa</span>
                                <PriceDisplay value={booking.company_budget_amount} currency={booking.currency} />
                            </Box>}
                        </Box>
                    </>}
                </>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default BookingInfoModal;



// External components
import {
    Box,
    Button, Checkbox, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField
} from "@material-ui/core";
import is from "is_js";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useStyles } from "../../../components/pages/allies.styles";
import AlliesService from "../../../services/AlliesService";
import { TagSelector } from "../../atoms/Allies/TagSelector";
import CountrySelector from "../../molecules/Allies/CountrySelector";
import ContraPerkPromocode from "./ContraPerkPromocode";


const errorEnum = {
    BENEFIT_DESCRIPTION: "Ingrese una descripcion del beneficio",
    BENEFIT_LONG_DESCRIPTION: "Ingrese una descripcion del beneficio",
    URL: "Ingrese una Url",
    TAGS: "Seleccion al menos una categoria",
    LOGO: "Ingrese un Logo",
    COUNTRY: "Ingrese un pais",
    CONTRAPROMOCODE: "Seleccione un Codigo promocional",
    PROMO_CODE: "Ingrese el codigo promocional",
    NONE: null,
};
const contraPerkStatusEnum = {
    CONTRA: 'CONTRA',
    PERK: 'PERK',
    CLOSE: 'CLOSE'
};


const NewBenefitModal = ({ open, setOpen, allyId, refreshList, dataToEdit, editInstance, setEditInstance }) => {
    const [contraPerk, setContraPerk] = useState(contraPerkStatusEnum.CLOSE);
    const [promocodeSelected, setPromocodeSelected] = useState({});
    const {
        _switchBtn,
        _switchSelectedBtn,
        _navBarContainer,
        _navBarBox,
        _switchSelectedContraPerkBtn,
        _switchContraPerkBtn,
    } = useStyles();

    const [loading, setLoading] = useState(false);
    const [benefitDescription_es, setBenefitDescription_es] = useState("");
    const [benefitDescription_en, setBenefitDescription_en] = useState("");
    const [benefitDescription_pt, setBenefitDescription_pt] = useState("");

    const [benefitLongDescription_es, setBenefitLongDescription_es] = useState("");
    const [benefitLongDescription_en, setBenefitLongDescription_en] = useState("");
    const [benefitLongDescription_pt, setBenefitLongDescription_pt] = useState("");

    const benefitDescriptionValues = [
        {
            language: "es",
            value: benefitDescription_es,
            action: setBenefitDescription_es,
        },
        {
            language: "us",
            value: benefitDescription_en,
            action: setBenefitDescription_en,
        },
        {
            language: "br",
            value: benefitDescription_pt,
            action: setBenefitDescription_pt,
        },
    ];

    const benefitLongDescriptionValues = [
        {
            language: "es",
            value: benefitLongDescription_es,
            action: setBenefitLongDescription_es,
        },
        {
            language: "us",
            value: benefitLongDescription_en,
            action: setBenefitLongDescription_en,
        },
        {
            language: "br",
            value: benefitLongDescription_pt,
            action: setBenefitLongDescription_pt,
        },
    ];

    const [benefitUrl, setBenefitUrl] = useState("");

    const [hasCode, setHastCode] = useState(false);
    const [promoCode, setPromoCode] = useState("");

    const [selectedTags, setSelectedTags] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState("");


    const [error, setError] = useState(errorEnum.NONE);
    const isContraPerk = () => {
        if (contraPerk == contraPerkStatusEnum.PERK) return false;
        if (contraPerk == contraPerkStatusEnum.CONTRA) return true;
        return null;
    };
    const allyBtnClass = contraPerk == contraPerkStatusEnum.CLOSE ? _switchContraPerkBtn : isContraPerk() ? _switchContraPerkBtn : _switchSelectedContraPerkBtn;
    const perkBtnClass = contraPerk == contraPerkStatusEnum.CLOSE ? _switchContraPerkBtn : isContraPerk() ? _switchSelectedContraPerkBtn : _switchContraPerkBtn;

    const validateFormData = () => {
        let errorsAux;
        if (isContraPerk()) {
            if (is.empty(promocodeSelected)) { errorsAux = errorEnum.CONTRAPROMOCODE; }
        } else {
            if (is.empty(selectedTags)) { errorsAux = errorEnum.TAGS; }
        }

        if (is.empty(benefitUrl)) { errorsAux = errorEnum.URL; }
        if (is.empty(benefitDescription_es)) { errorsAux = errorEnum.BENEFIT_DESCRIPTION; }
        if (is.empty(benefitDescription_en)) { errorsAux = errorEnum.BENEFIT_DESCRIPTION; }
        if (is.empty(benefitDescription_pt)) { errorsAux = errorEnum.BENEFIT_DESCRIPTION; }
        if (is.empty(benefitLongDescription_es)) { errorsAux = errorEnum.BENEFIT_LONG_DESCRIPTION; }
        if (is.empty(benefitLongDescription_en)) { errorsAux = errorEnum.BENEFIT_LONG_DESCRIPTION; }
        if (is.empty(benefitLongDescription_pt)) { errorsAux = errorEnum.BENEFIT_LONG_DESCRIPTION; }
        if (selectedCountry == "") { errorsAux = errorEnum.COUNTRY; }
        if (hasCode && promoCode == '') {
            errorsAux = errorEnum.PROMO_CODE
        }
        setError(errorsAux);
        let isValid = errorsAux == null;
        return isValid;
    };


    const joinData = () => {

        let aux = {};
        aux.ally_id = allyId;
        aux.benefit_description_es = benefitDescription_es;
        aux.benefit_description_en = benefitDescription_en;
        aux.benefit_description_pt = benefitDescription_pt;
        aux.benefit_long_description_es = benefitLongDescription_es;
        aux.benefit_long_description_en = benefitLongDescription_en;
        aux.benefit_long_description_pt = benefitLongDescription_pt;
        aux.promotional_code = hasCode ? promoCode : "";
        aux.ally_benefit_url = benefitUrl;
        aux.country_id = selectedCountry;
        aux.is_contra_perk = isContraPerk();
        aux.promocode_id = isContraPerk() ? promocodeSelected.id : {};
        // aux.country_id = 9;
        aux.promocode = hasCode
        aux.tag_id = selectedTags;
        return aux;
    };

    const editPerk = async () => {
        let payload = joinData();
        if (validateFormData()) {
        payload.ally_id = dataToEdit.allyId;
        payload.id = dataToEdit.id;
        await AlliesService.updatePerk(payload)
            .then((res) => {
                setEditInstance(false);
                setOpen(false);
                refreshList();
            });
        }
    };

    const handleCreatePerk = async () => {
        if (validateFormData()) {
            setLoading(true);
            let payload = joinData();
            await AlliesService.createBenefit(payload)
                .then((resp) => setLoading(false))
                .catch((err) => console.log("error", err))
                .finally(() => {
                    clearModal();
                    refreshList();
                    setOpen(false);
                });
        }
    };

    const resetFields = () => {
        setBenefitDescription_es("");
        setBenefitDescription_en("");
        setBenefitDescription_pt("");
        setBenefitLongDescription_es("");
        setBenefitLongDescription_en("");
        setBenefitLongDescription_pt("");
        setPromoCode("");
        setSelectedTags([]);
        setBenefitUrl("");
        setSelectedCountry(0);
        setPromocodeSelected({});
        setHastCode(false);
    };
    const clearModal = () => {
        resetFields();
        setEditInstance(false);
        setOpen(false);
        setContraPerk(contraPerkStatusEnum.CLOSE);
        setPromocodeSelected({});
        setPromoCode("");
        setHastCode(false);
    };
    const haveDataToEdit = dataToEdit != null;

    useEffect(() => {
        if (haveDataToEdit) {
            setBenefitDescription_es(dataToEdit.benefitDescription_es);
            setBenefitDescription_en(dataToEdit.benefitDescription_en);
            setBenefitDescription_pt(dataToEdit.benefitDescription_pt);
            setBenefitLongDescription_es(dataToEdit.benefitLongDescription_es);
            setBenefitLongDescription_en(dataToEdit.benefitLongDescription_en);
            setBenefitLongDescription_pt(dataToEdit.benefitLongDescription_pt);
            setSelectedTags(dataToEdit.tag_id);
            setSelectedCountry(dataToEdit.country_id);
            setBenefitUrl(dataToEdit.benefitUrl);
            setSelectedTags(dataToEdit.tags_id);
            setPromocodeSelected(isContraPerk() ? dataToEdit.promocode : {});
            if (dataToEdit.promotional_code != "") {
                setHastCode(true);
                setPromoCode(dataToEdit.promotional_code);
            }else{
                setHastCode(false)
                setPromoCode("");
            }
        }
        else {
            resetFields();
        }
    }, [dataToEdit]);

    return (
        <Box>
            <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle style={{ textAlign: "center" }}>
                    <Box style={{ fontWeight: 600, fontSize: 20 }}>
                        {haveDataToEdit
                            ? 'Editar Beneficio'
                            : `Nuevo Beneficio para el Aliado con allyId #${allyId}`}
                    </Box>
                    {/* {is.not.empty(codeError) && <Alert severity="error">{codeError}</Alert>} */}
                </DialogTitle>

                <DialogContent>
                    <>
                        {haveDataToEdit ?
                            <Box className={_navBarContainer}>

                                <Box className={_navBarBox}>
                                    {dataToEdit?.is_contra_perk ?

                                        <Box
                                            className={perkBtnClass}
                                            onClick={() => { setContraPerk(contraPerkStatusEnum.CONTRA); }}>
                                            Contra Perk
                                        </Box>
                                    :
                                    <Box
                                    className={allyBtnClass}
                                    onClick={() => { setContraPerk(contraPerkStatusEnum.PERK); }}>
                                    Perk
                                </Box>
                                    }
                                </Box>
                            </Box>
                            : <Box className={_navBarContainer}>
                                <Box className={_navBarBox}>
                                    <Box
                                        className={allyBtnClass}
                                        onClick={() => { setContraPerk(contraPerkStatusEnum.PERK); }}>
                                        Perk
                                    </Box>
                                    <Box
                                        className={perkBtnClass}
                                        onClick={() => { setContraPerk(contraPerkStatusEnum.CONTRA); }}>
                                        Contra Perk
                                    </Box>
                                </Box>
                            </Box>}
                        {contraPerk == contraPerkStatusEnum.CLOSE ? <Box style={{ width: '100%', height: '40vh' }}></Box> : <Box style={{ width: '100%' }}>

                            <Box>
                                <CountrySelector selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3>Descripción corta del beneficio</h3>

                                <Box>
                                    {benefitDescriptionValues.map(f => <BenefitDescriptionForm key={"id_" + f.language} value={f.value} action={(value) => f.action(value)} lang={f.language} />)}
                                </Box>
                            </Box>

                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3>Descripción larga del beneficio</h3>

                                <Box>
                                    {benefitLongDescriptionValues.map(f => <LongDescriptionForm key={"id_" + f.language} value={f.value} action={(value) => f.action(value)} lang={f.language} />)}
                                </Box>
                            </Box>
                            <br />
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <BenefitDescriptionForm value={benefitUrl} action={(value) => setBenefitUrl(value)} lang={"url"} />
                            </Box>
                            <br />
                            {contraPerk == contraPerkStatusEnum.PERK && <Box>
                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Box style={{ marginRight: 10 }}>
                                        <span>Codigo Promocional</span>
                                    </Box>
                                    <Box>
                                        <Checkbox
                                        value={hasCode}
                                        checked={hasCode}
                                        // onChange={(e) => setHastCode(!hasCode)}
                                        onChange={(e) =>
                                            setHastCode(e.target.checked)
                                        }
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    {hasCode && <>
                                        <TextField value={promoCode} onChange={(e) => setPromoCode(e.target.value)} style={{ width: '300px' }} />
                                    </>}
                                </Box>
                                <br />
                                {/* SELECTOR CONTAINERS  */}
                                <Box>
                                    <TagSelector allyId={allyId} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
                                </Box>
                            </Box>}
                            {contraPerk == contraPerkStatusEnum.CONTRA && <Box>
                                < ContraPerkPromocode promocodeSelected={promocodeSelected} setPromocodeSelected={setPromocodeSelected} /></Box>}
                        </Box>}
                    </>
                    {/* <AllyForm newAllyData={newAllyData} setNewAllyData={setNewAllyData}/> */}
                </DialogContent>

                <DialogActions>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        {error != errorEnum.NONE && <Box style={{ color: 'red', fontSize: 22, marginBottom: 8 }}>{error}</Box>}
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>

                            <Button onClick={() => clearModal()} color='primary'>
                                Cancelar
                            </Button>

                            {loading ? <CircularProgress /> :
                                <Button onClick={() => editInstance ? editPerk() : handleCreatePerk()} variant="contained" color='primary' disabled={loading}>
                                    Guardar
                                </Button>
                            }
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default NewBenefitModal;


export const BenefitDescriptionForm = ({ value, action, lang }) => {
    return <>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

            {/* {lang.toUpperCase()} */}
            {lang != "url" ? <ReactCountryFlag
                svg
                countryCode={lang || ""}
                style={{ marginRight: 5, width: 32, height: 32 }}
            /> : <span style={{ marginRight: 5, width: 32, height: 32 }}>URL</span>}
            <Box>
                <TextField
                    value={value}
                    variant="outlined"
                    onChange={(e) => action(e.target.value)}
                    style={{ width: '500px', margin: '5px' }}
                />
            </Box>
        </Box>
    </>;
};

export const LongDescriptionForm = ({ value, action, lang }) => {
    return <>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>

            {/* {lang.toUpperCase()} */}
            {lang != "url" ? <ReactCountryFlag
                svg
                countryCode={lang || ""}
                style={{ marginRight: 5, width: 32, height: 32 }}
            /> : <span style={{ marginRight: 5, width: 32, height: 32 }}>URL</span>}
            <Box>
                <TextField
                    value={value}
                    variant="outlined"
                    onChange={(e) => action(e.target.value)}
                    style={{ width: '500px', margin: '5px' }}
                    multiline
                    rows={4}
                />
            </Box>
        </Box>
    </>;
};

export const ShortDescriptionForm = ({ value, action, lang }) => {
    return <>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {lang != "url" ? <ReactCountryFlag
                svg
                countryCode={lang || ""}
                style={{ marginRight: 5, width: 32, height: 32 }}
            /> : <span style={{ marginRight: 5, width: 32, height: 32, fontWeigth: 700 }}>URL</span>}
            <Box>
                <TextField
                    style={{ marginTop: '10px', width: '350px' }}
                    multiline
                    rows={3}
                    variant="outlined"
                    value={value}
                    onChange={(e) => action(e.target.value)}
                    inputProps={{ maxLength: 360 }}
                />
            </Box>
        </Box>
    </>;
};


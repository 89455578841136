import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './pdfSelector.styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import moment from 'moment';
import AdminService from '../../../services/AdminService';

const PdfButton = ({ selectedDate, idCompany }) => {

    const [loader, setLoader] = useState(false);

    const openPdf = async () => {
        let pdfUrl;
        try{
            setLoader(true);
            let windowRef = window.open();
            const response = await AdminService.getSummaryPdf(idCompany, selectedDate);
            pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            windowRef.location = pdfUrl;
        }
        finally{
            setLoader(false);
            window.onload = () => {
                URL.revokeObjectURL(pdfUrl);
            };
        }
    };

    const { _pdfBtn, _iconPosition, _pdfTxt } = useStyles();

    return (
        <Box className={_pdfBtn} onClick={() => loader ? null : openPdf()}>
            <Box className={_pdfTxt}>{loader ? 'Generando PDF...' : 'Descargar Resumen'}</Box>
            <Box className={_iconPosition}><PictureAsPdfIcon style={{ fontSize: 15, color: '#9900CC' }} /></Box>
        </Box>
    );
};

export default PdfButton;

import { Switch, TableCell } from '@material-ui/core';
import React, { useState } from 'react';
import AlliesService from '../../../services/AlliesService';
import EditIcon from '@material-ui/icons/Edit';

const StatusEnum = {
    ACCEPTED: 'ACCEPTED',
    PENDING: 'PENDING'
};
const BenefitsTablecell = ({ data, openEditModal }) => {

    const generateStatus = () => {
        if (data.status == StatusEnum.ACCEPTED) return true;
        if (data.status == StatusEnum.PENDING) return false;
    };

    const [perkStatus, setPerkStatus] = useState(generateStatus());

    const updateStatus = async (chkd) => {
        setPerkStatus(chkd);
        const status = chkd ? StatusEnum.ACCEPTED : StatusEnum.PENDING;
        let payload = {
            ally_id: data.id,
            status: status
        };
        await AlliesService.changePerkStatus(payload);

    };

    const generateCountryName = () => {
        if (data.country) {
            return data.country.internal_detail;
        }
        else {
            return ' - ';
        }
    };

    const tagList = () => {
        // render value formated to avoid table colapse
        let names = [];
        if (data.ally_tags.length <= 3) {
            names = data.ally_tags.map((p) => p.name).join(', ');
        }
        else {
            let firstTag = data.ally_tags[0].name;
            let secondTag = data.ally_tags[1].name;
            names = `${firstTag}, ${secondTag}, ...`;
        }
        return names;
    };
    const promoCode = !data.is_contra_perk ? data.promotional_code : data.promocode.coupon;
    return <>
        <TableCell align={'center'}>
            <EditIcon size={'small'} style={{ cursor: 'pointer' }} onClick={() => openEditModal(data)} />
        </TableCell>
        <TableCell align={'center'}>
            {data.id}
        </TableCell>
        <TableCell align={'center'}>
            <span style={{ fontWeight: 700, fontSize: 17 }}>
                {data.ally.company_name}
            </span>
        </TableCell>
        <TableCell align={'center'}>
            {generateCountryName()}
        </TableCell>
        <TableCell align={'center'}>
            {data.ally_benefit_url}
        </TableCell>
        <TableCell align={'center'}>
            {tagList()}
        </TableCell>
        <TableCell align={'center'}>
            {promoCode }
        </TableCell>
        <TableCell align={'center'}>
            {data.benefit_description}
        </TableCell>
        <TableCell align={'center'}>
            <Switch
                value={perkStatus}
                checked={perkStatus}
                onChange={(e) => updateStatus(e.target.checked)}
            />
        </TableCell>
        <TableCell>
            {data.is_contra_perk.toString()}
        </TableCell>

    </>;
};

export default BenefitsTablecell;

import axios from 'axios';

const DataService = {

    getCurrencies: (countryId) => {
        console.log(`DataService.getCurrencies(${countryId})`);
        let url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/currency`;
        if (countryId) url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/country/${countryId}/currency`;
        return axios.get(url);
    },

    getSpaceAdditionalServices: () => {
        console.log(`DataService.getSpaceAdditionalServices()`);
        const url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/additional-benefit`;
        return axios.get(url);
    },

    getPaymentTypes: () => {
        console.log(`DataService.getPaymentTypes()`);
        const url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/payment_type`;
        return axios.get(url);
    },

    getDlocalCountries: () => {
        console.log(`DataService.getDlocalCountries()`);
        const url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/country-dlocal`;
        return axios.get(url);
    },

    getCountries: (hasCurrencies = true) => {
        console.log(`DataService.getCountries()`);
        const url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/country?hasCurrencies=${hasCurrencies}`;
        return axios.get(url);
    },

    getSpaceTypes: () => {
        console.log(`DataService.getSpaceTypes()`);
        const url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/space_type`;
        return axios.get(url);
    },

    getSpaceServices: () => {
        console.log(`DataService.getSpaceServices()`);
        const url = `${process.env.REACT_APP_API_PRINCIPAL_URL}/data/service`;
        return axios.get(url);
    },
}

export default DataService;

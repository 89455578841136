export const getEmployeeDisplayName = (employee, showMailIfNoName = true) => {
    let displayName = showMailIfNoName ? employee?.email : '';

    if (employee?.name || employee?.lastName)
        displayName = (employee?.name || '') + ' ' + (employee?.lastName || '');

    if (employee?.user?.name)
        displayName = (employee?.user?.name || '') + ' ' + (employee?.user?.lastname || '');

    return displayName;
};

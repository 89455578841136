// External components
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent
} from "@material-ui/core";
import React from "react";
import StoreService from "../../services/StoreService";
import FormVisualizerInput from "../atoms/FormVisualizerInput";
import { usePurchaseStyles } from "../atoms/purchase.styles";
import ImageUploadGallery from "../molecules/ImageUploadGallery";


const PurchaseInfoModal = ({ open, setOpen, data, reloadPurchases, setSelectedStatus }) => {

    const statusByNumber = (status) => {
        if (status === 'PENDING') return 1;
        if (status === 'ACCEPTED') return 2;
        if (status === 'DELIVERED') return 3;
        if (status === 'CANCELLED') return 4;
    };
    const changeToStatus = async (status) => {
        let payload = {
            purchase_id: data?.id,
            status: status
        };
        StoreService.changeStatus(payload);
        setOpen(false);
        reloadPurchases(status, 1);
        let num = statusByNumber(status);
        setSelectedStatus(num);
    };


    const { _shippingRoot, _formHeader } = usePurchaseStyles();
    return (
        <Box>
            <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
                <DialogContent>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: 15 }}>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {data.status == 'PENDING' && <Box style={{ marginRight: 20 }}><Button onClick={() => changeToStatus('ACCEPTED')} variant="contained" color="primary" >CAMBIAR ESTADO A ACEPTADO</Button></Box>}
                            {data.status == 'ACCEPTED' && <Box style={{ marginRight: 20 }}><Button onClick={() => changeToStatus('DELIVERED')} variant="contained" color="primary" >CAMBIAR ESTADO A ENTREGADO</Button></Box>}
                            {data.status != 'CANCELED' && data.status != 'DELIVERED' && <Box><Button onClick={() => changeToStatus('CANCELLED')} variant="contained" color="secondary" >CANCELAR COMPRA</Button></Box>}
                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Box style={{ fontWeight: 700, fontSize: 16, marginLeft: 40 }}>{`ESTADO ACTUAL:  ${data.status}`}</Box>

                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-evenly', marginTop: 40 }}>
                            <Box className={_shippingRoot}>

                                <Box className={_formHeader}>Datos de la entrega</Box>
                                <Box style={{ marginTop: 15 }}>

                                    <FormVisualizerInput
                                        value={data?.address?.street}
                                        width={'90%'}
                                        title={'Calle'}
                                    />

                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 15 }}>
                                    <Box stlye={{ width: '100%' }}>
                                        <FormVisualizerInput
                                            value={data?.address?.street_number}
                                            width={'95%'}
                                            title={data?.store_product?.country?.name == 'MX' ? 'Número exterior':'Numero'} />
                                    </Box>

                                    <Box stlye={{ width: '100%' }}>
                                        <FormVisualizerInput
                                            value={data?.address?.zip_code}
                                            width={'107%'}
                                            title={'CP'} />
                                    </Box>
                                </Box>
                                {data?.store_product?.country?.name == 'BR' &&
                                 <Box stlye={{ width: '100%' }}>
                                    <FormVisualizerInput
                                        value={data?.address?.complement}
                                        width={'90%'}
                                        title={'Complmento'} />
                                </Box>}

                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 15 }}>

                                    <Box stlye={{ width: '100%' }}>
                                        <FormVisualizerInput
                                            value={data?.address?.floor}
                                            width={'70%'}
                                            title={data?.store_product?.country?.name == 'MX' ? 'Número interior':'Piso'} />
                                    </Box>
                                    <Box stlye={{ width: '100%' }}>
                                        <FormVisualizerInput
                                            value={data?.address?.apt_number}
                                            width={'70%'}
                                            title={'Depto.'} />
                                    </Box>
                                    <Box stlye={{ width: '100%' }}>
                                        <FormVisualizerInput
                                            value={data?.address?.tower}
                                            width={'70%'}
                                            title={'Torre'} />
                                    </Box>
                                </Box>


                                <Box stlye={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                    <Box style={{ width: '100%', marginTop: 15 }}>

                                        <FormVisualizerInput
                                            value={data?.address?.city}
                                            width={'90%'}
                                            title={data?.store_product?.country?.name == 'MX' ? 'Municipio/Alcaldia':'Ciudad'} />

                                    </Box>

                                    <Box style={{ width: '100%', marginTop: 15 }}>

                                        <FormVisualizerInput
                                            value={data?.address?.province_district}
                                            width={'90%'}
                                            title={data?.store_product?.country?.name == 'MX' ? 'Estado' : 'Provincia/Distrito'} />

                                    </Box>

                                    {data?.store_product?.country?.name == 'MX' &&
                                 <Box stlye={{ width: '100%', marginTop: 15 }}>
                                    <FormVisualizerInput
                                        value={data?.address?.colony}
                                        width={'90%'}
                                        title={'Colonia'} />
                                </Box>}

                                </Box>

                            </Box>
                            <Box style={{ borderRight: '1px solid black', height: 450 }}></Box>

                            <Box className={_shippingRoot}>

                                <Box className={_formHeader}>Datos del destinatario</Box>

                                <Box style={{ marginTop: 35 }}>

                                    <FormVisualizerInput
                                        value={data?.address?.name}
                                        width={'90%'}
                                        title={'Nombre completo'} />

                                </Box>
                                <Box style={{ marginTop: 15 }}>

                                    <FormVisualizerInput
                                        value={data?.address?.document}
                                        width={'90%'}
                                        title={'DNI'} />

                                </Box>
                                <Box style={{ marginTop: 15 }}>

                                    <FormVisualizerInput
                                        value={data?.address?.email}
                                        width={'90%'}
                                        title={'Correo electrónico'} />

                                    <FormVisualizerInput
                                        value={data?.address?.phone}
                                        width={'90%'}
                                        title={'Número de teléfono'} />

                                </Box>




                            </Box>
                        </Box>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <>
                        <Button onClick={() => setOpen(false)} color='primary'>
                            Salir
                        </Button>

                    </>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PurchaseInfoModal;

export const ChangeStatusBtns = (status) => {

    if (status == 'PENDING') return <>
        <Box><Button>CAMBIAR ESTADO A ACEPTAR</Button></Box>
    </>;

    if (status == 'ACCEPTED') return <>
        <Box><Button>CAMBIAR ESTADO A ENTREGADO</Button></Box>
    </>;

    return <>
    </>;
};

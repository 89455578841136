// External components
import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextareaAutosize,
    Tooltip,
    Typography
} from '@material-ui/core';

// Icons & Images
import LaunchIcon from '@material-ui/icons/Launch';

// Utils & Config
import AdminService from '../../services/AdminService';
import { RequestedSpacePriceChangeStatusEnum } from '../../helpers/enums/RequestedSpacePriceChangeStatusEnum';
import { makeStyles } from '@material-ui/core/styles';
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import { priceRangeLabels } from '../../helpers/enums/RequestedSpacePriceChangeTypeEnum';
import { PriceDisplay } from '../atoms/PriceDisplay';

/**
 * @param {Object} props
 * @param {'REJECT' | 'APPROVE'} props.action
 */
const ApproveOrRejectRequestedSpacePriceChangeModal = ({ requestedPriceChange, open, setOpen, onConfirm, action }) => {
    const [reqStatus, setReqStatus] = useState(ResponseStatusEnum.IDLE);
    const [reason, setReason] = useState('');

    const handleClose = () => {
        setOpen(null);
    };

    const handleConfirm = async () => {
        setReqStatus(ResponseStatusEnum.LOADING);

        try {
            const body = {
                id: requestedPriceChange.id,
                reason,
            };

            if (action === 'REJECT') body['status'] = RequestedSpacePriceChangeStatusEnum.REJECTED;
            if (action === 'APPROVE') body['status'] = RequestedSpacePriceChangeStatusEnum.APPROVED;

            await AdminService.toggleRequestedSpacePriceChange(body);

            setReqStatus(ResponseStatusEnum.SUCCESS);
            setOpen(null);
            await onConfirm();
        } catch (error) {
            setReqStatus(ResponseStatusEnum.ERROR);
        }
    };

    const hostName = `#${requestedPriceChange.space.host_id} ${requestedPriceChange.space.host_name} ${requestedPriceChange.space.host_lastname} ${requestedPriceChange.space.name}`;

    const { _textArea, _container } = useStyles();

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                {action === 'APPROVE' && 'Aprobar solicitud de cambio de precio'}
                {action === 'REJECT' && 'Rechazar solicitud de cambio de precio'}
            </DialogTitle>

            <DialogContent className={_container}>
                <Typography>
                    {action === 'APPROVE' && `¿Deseas aprobar la solicitud del host ${hostName} sobre el espacio #${requestedPriceChange.space.id}?`}
                    {action === 'REJECT' && `¿Deseas rechazar la solicitud del host ${hostName} sobre el espacio #${requestedPriceChange.space.id}?`}

                    <Tooltip title={'Ir al Espacio'}>
                        <IconButton onClick={() => window.open(`${process.env.REACT_APP_WEB_APP_URL}/publication/${requestedPriceChange.space.id}`, '_blank')}>
                            <LaunchIcon color={'primary'} />
                        </IconButton>
                    </Tooltip>
                </Typography>

                <Typography>
                    De {' '}
                    <PriceDisplay value={requestedPriceChange.oldValue} currency={requestedPriceChange.space.currency}/> {' '}
                    a {' '}
                    <PriceDisplay value={requestedPriceChange.newValue} currency={requestedPriceChange.space.currency}/> {' '}
                    {priceRangeLabels[requestedPriceChange.type].toLowerCase()}.
                </Typography>

                <TextareaAutosize
                    disabled={reqStatus === ResponseStatusEnum.LOADING}
                    name={'reason'}
                    placeholder={'Motivo...'}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    minRows={5}
                    maxRows={25}
                    className={_textArea}
                />

            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {reqStatus === ResponseStatusEnum.LOADING &&
                        <CircularProgress size={25} />
                    }

                    {reqStatus === ResponseStatusEnum.ERROR &&
                        <Typography color={'error'}>
                            Hubo un error
                        </Typography>
                    }
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={handleClose}
                        disabled={reqStatus === ResponseStatusEnum.LOADING}
                    >
                        {action === 'APPROVE' && 'Cerrar sin aprobar'}
                        {action === 'REJECT' && 'Cerrar sin rechazar'}
                    </Button>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleConfirm}
                        disabled={reqStatus === ResponseStatusEnum.LOADING}
                    >
                        {action === 'APPROVE' && 'Aprobar'}
                        {action === 'REJECT' && 'Rechazar'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ApproveOrRejectRequestedSpacePriceChangeModal;

const useStyles = makeStyles((theme) => ({
    _textArea: {
        backgroundColor: 'white',
        fontFamily: 'Poppins',
        borderRadius: theme.spacing(.5),
        fontSize: theme.spacing(2),
        padding: theme.spacing(2),
        '&:disabled': {
            opacity: .80
        }
    },
    _container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: theme.spacing(2),
    },
}));

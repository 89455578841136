import { useMediaQuery } from 'react-responsive';

const useResolutions = () => {

    // mobile
    // added new resolutions por 2 standart model (iphone SE / iphone XR)
    const smallPhone = useMediaQuery({ query: '(min-width: 0px) and (max-width: 375px)' });
    const largePhone = useMediaQuery({ query: '(min-width: 410px) and (max-width: 770px)' });
    // legacy
    const isPhone = useMediaQuery({ query: '(max-width: 770px)' });
    const isPortrait = useMediaQuery({ query: '(orientation:portrait)' });
    const isMobile = isPhone || isPortrait;

    // 1920 x 1080
    const isLargeScreen = useMediaQuery({ query: '(min-width: 1919px)' });
    // 1440 X 900
    const mediumScreen = useMediaQuery({ query: '(min-width: 1439px)' && '(max-width: 1919px)' });
    // 1366 x 768
    const isUp1366Res = useMediaQuery({ query: '(min-width: 1360px)' && '(max-width: 1428px)' });

    // shitty-fix to avoid cross-resolutions
    const isMediumScreen = !isUp1366Res && mediumScreen;

    return {
        isMobile,
        isLargeScreen,
        isMediumScreen,
        isUp1366Res,
        smallPhone,
        largePhone
    };
};
export default useResolutions;

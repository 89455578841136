
import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { usePurchaseStyles } from './purchase.styles';

const FormVisualizerInput = ({ width, title, value }) => {

    const { _formInputVisualizer, _formInputRoot, _formInputTitle, _rowFlex } = usePurchaseStyles();

    return (
        <Box className={_formInputRoot}>
            <Box className={_rowFlex}>
                <Box className={_formInputTitle}>{title}</Box>
            </Box>
            <Box
                className={_formInputVisualizer}
                style={{ width: width, textAlign:'left' }}
            >
                <span style={{ fontSize: 20, fontWeight: 700 }}>
                    {value ? value : ' - '}
                </span>
            </Box>
        </Box>
    );
};

export default FormVisualizerInput;

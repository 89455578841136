// External components
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import SearchIcon from '@material-ui/icons/Search';

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import CityAutocomplete from '../atoms/CityAutocomplete';
import DataService from '../../services/DataService';
import SmartOfficeService from '../../services/SmartOfficeService';

const FIELDS = [
    { key: 'street', label: 'Calle' },
    { key: 'street_number', label: 'Número' },
    { key: 'zip_code', label: 'Código Postal' },
    { key: 'apt_number', label: 'Piso' },
];

const initialFormValues = {
    country: '',
    city: '',
    street: '',
    street_number: '',
    apt_number: '',
    zip_code: '',
    latitude: '',
    longitude: '',
};

const CreateSmartOfficeLocationModal = ({ open, setOpen, onConfirm, formValues, setFormValues }) => {
    const [currFormValues, setCurrFormValues] = useState(formValues.location ? formValues.location : initialFormValues);

    const [countries, setCountries] = useState([]);

    const { isLoaded: isMapLoaded, loadError: mapLoadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        language: 'es',
        libraries: ['places'],
    });

    const updateMarkerPosition = ({ latitude, longitude }) => {
        setCurrFormValues({
            ...currFormValues,
            latitude,
            longitude,
        });
    };

    const handleFormValues = (e) => {
        setCurrFormValues({
            ...currFormValues,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleConfirm = async () => {
        setFormValues({
            ...formValues,
            location: currFormValues
        });
        setOpen(null);
    };

    const getCountries = async () => {
        const res = await DataService.getCountries();
        setCountries(res.data);
    };

    const getLocationFromAddressString = async () => {
        try {
            const adressString = encodeURIComponent(`${currFormValues.street} ${currFormValues.street_number}, ${currFormValues.city}, ${currFormValues.country}`);
            const res = await SmartOfficeService.getLocationFromStringAddress(adressString, currFormValues.country);

            updateMarkerPosition({ latitude: res.results[0].geometry.location.lat, longitude: res.results[0].geometry.location.lng });

        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getCountries();
    }, []);

    const coordinates = { lat: currFormValues.latitude, lng: currFormValues.longitude };

    const { _content } = useStyles();

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'lg'} fullWidth>
            <DialogTitle>
                Smart Office - Ubicación
            </DialogTitle>

            <DialogContent className={_content}>
                <FormControl variant={'outlined'}>
                    <InputLabel id={'country_select'}>País</InputLabel>

                    <Select
                        label={'País'}
                        required
                        labelId={'country_select'}
                        value={currFormValues?.country}
                        name={'country'}
                        onChange={handleFormValues}
                        disabled={!countries.length}
                        displayEmpty
                    >
                        {countries
                            .sort((a, b) => a.order - b.order)
                            .map((country) => <MenuItem key={country?.id} value={country?.name}>{country?.internal_detail}</MenuItem>)
                        }
                    </Select>
                </FormControl>

                <CityAutocomplete
                    inputName={'city'}
                    inputValue={currFormValues.city}
                    handleChange={handleFormValues}
                    selectedCountry={currFormValues.country}
                />

                {FIELDS.map((field) => (
                    <TextField
                        key={field.key}
                        name={field.key}
                        onChange={handleFormValues}
                        value={currFormValues[field.key]}
                        label={field.label}
                        variant={'outlined'}
                    />
                ))}

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant={'contained'} color={'primary'} onClick={getLocationFromAddressString}>
                        <SearchIcon style={{ marginRight: 16 }} />
                        Buscar Ubicación
                    </Button>
                </Box>

                <Box>
                    <Typography>Verficar Marcador:</Typography>

                    <br />

                    {mapLoadError && 'Error loading map'}

                    {!isMapLoaded && <CircularProgress />}

                    {Boolean(isMapLoaded && !mapLoadError && currFormValues.latitude && currFormValues.longitude) && (
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '500px' }}
                            center={coordinates}
                            zoom={15}
                        >
                            <Marker draggable={true} onDragEnd={(e) => updateMarkerPosition({ latitude: e.latLng.lat(), longitude:  e.latLng.lng() })} position={coordinates} />
                        </GoogleMap>
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    color={'primary'}
                    onClick={handleClose}
                >
                      Cerrar
                </Button>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleConfirm}
                >
                        Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateSmartOfficeLocationModal;

const useStyles = makeStyles((theme) => ({
    _content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

// External components
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

// Utils & Config
import { ResponseStatusEnum } from '../../helpers/enums/ResponseStatusEnum';
import SmartOfficeService from '../../services/SmartOfficeService';
import { getLocationDisplayValue } from '../../helpers/funcs/getLocationDisplayValue';
import { getEmployeeDisplayName } from '../../helpers/funcs/getEmployeeDisplayName';

// Icons
import ReactCountryFlag from 'react-country-flag';

const OwnOfficeEmployeeBokingsModal = ({ open, setOpen, ownOffice }) => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const [onlyFromPast, setOnlyFromPast] = useState(false);
    const [ownOfficesBookingsReq, setOwnOfficesBookingsReq] = useState({ status: ResponseStatusEnum.IDLE, data: [] });

    const getOwnOfficesBookings = async (req) => {
        setOwnOfficesBookingsReq({ status: ResponseStatusEnum.LOADING, data: null });

        try {
            if (onlyFromPast) {
                req.dateTo = new Date();
            } else {
                req.dateFrom = new Date();
            }

            const res = await SmartOfficeService.searchSmartBookingsEmployee(req);

            setOwnOfficesBookingsReq({ status: ResponseStatusEnum.SUCCESS, data: res.data });
        } catch (error) {
            setOwnOfficesBookingsReq({ status: ResponseStatusEnum.ERROR, data: null });
        }
    };

    useEffect(() => {
        getOwnOfficesBookings({ ownOfficeId: ownOffice?.id });
    }, [ownOffice?.id, onlyFromPast]);

    return (
        <Dialog open={open} onClose={() => setOpen(null)} maxWidth={'lg'} fullWidth>
            <DialogTitle>
                Own Office Bookings -

                {' '}

                <ReactCountryFlag countryCode={ownOffice.location.country} svg style={{ width: 24, height: 24 }} />

                {' '}

                <b>{getLocationDisplayValue(ownOffice.location)}</b>
            </DialogTitle>

            <DialogContent>
                <Box style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 16 }}>
                    <Button
                        variant={onlyFromPast  ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOnlyFromPast(true)}
                    >
                        Anteriores
                    </Button>

                    <Button
                        variant={!onlyFromPast  ? 'contained' : 'outlined'}
                        color={'primary'}
                        onClick={() => setOnlyFromPast(false)}
                    >
                        Posteriores
                    </Button>
                </Box>

                {ownOfficesBookingsReq.status === ResponseStatusEnum.LOADING && (
                    <CircularProgress />
                )}

                {ownOfficesBookingsReq.status === ResponseStatusEnum.ERROR && (
                    <h3>Error</h3>
                )}

                {Boolean(ownOfficesBookingsReq.status === ResponseStatusEnum.SUCCESS && !ownOfficesBookingsReq.data?.length) && (
                    <h3>No posee</h3>
                )}

                {Boolean(ownOfficesBookingsReq.status === ResponseStatusEnum.SUCCESS && ownOfficesBookingsReq.data?.length) && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Id</b></TableCell>
                                    <TableCell><b>Fecha de creación</b></TableCell>
                                    <TableCell><b>Fecha</b></TableCell>
                                    <TableCell><b>Empleado</b></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {ownOfficesBookingsReq.data
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((booking) => (
                                        <TableRow key={booking.id}>
                                            <TableCell>{booking.id}</TableCell>
                                            <TableCell>{booking.createdDate}</TableCell>
                                            <TableCell>{booking.date}</TableCell>
                                            <TableCell style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Avatar src={booking.employee?.user?.avatar?.original_url} />
                                                <b>#{booking.employee?.id}</b> {getEmployeeDisplayName(booking.employee)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component={'div'}
                                        count={ownOfficesBookingsReq.data?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(e, p) => setPage(p)}
                                        onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>

            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {ownOfficesBookingsReq.status === ResponseStatusEnum.ERROR &&
                        <Typography color={'error'}>
                            Hubo un error
                        </Typography>
                    }
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={() => setOpen(null)}
                    >
                        Cerrar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default OwnOfficeEmployeeBokingsModal;

// External components
import { Box, Button, Link } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";

// Icons & Images
import InfoIcon from '@material-ui/icons/Info';

// Utils & Config
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from "@material-ui/lab";
import { useMediaQuery } from "react-responsive";

// Internal components
import StoreService from "../../../services/StoreService";
import PurchaseInfoModal from "../../modals/PurchaseInfoModal";
import TableIcons from "../../molecules/TableIcons";
import moment from "moment";
import { PriceDisplay } from "../../atoms/PriceDisplay";

const PAGE_SIZE = 20;
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "25px",
        marginBottom: "25px"
    },
}));

const WnmStore = () => {

    const purchaseStatusEnum = {
        ACCEPTED: "ACCEPTED",
        PENDING: "PENDING",
        CANCELLED: "CANCELLED",
        DELIVERED: "DELIVERED"
    };

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [purchases, setPurchases] = useState([]);

    const [purchasesLoading, setPurchasesLoading] = useState(false);
    // pagination
    const [selectedPage, setSelectedPage] = useState(1);
    const [paginatorNumber, setPaginatorNumber] = useState(10);
    const [totalCount, setTotalCount] = useState(1);

    // fixme armar purchase info
    const [clickedPurchase, setClickedPurchase] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    // const [clickedBooking, setClickedBooking] = useState({});
    // const [bookingInfoModalOpen, setBookingInfoModalOpen] = useState(false);
    // const [datesModalOpen, setDatesModalOpen] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState(1);

    const getPendingPurchases = (pageNum, searchQuery) => { setSelectedStatus(1); return getBookings(purchaseStatusEnum.PENDING, 1); };
    const getAcceptedPurchases = (pageNum, searchQuery) => { setSelectedStatus(2); return getBookings(purchaseStatusEnum.ACCEPTED, 1); };
    const getSendPurchases = (pageNum, searchQuery) => { setSelectedStatus(3); return getBookings(purchaseStatusEnum.DELIVERED, 1); };
    const getRejectedPurchases = (pageNum, searchQuery) => { setSelectedStatus(4); return getBookings(purchaseStatusEnum.CANCELLED, 1); };

    const classes = useStyles();

    const statusGetFunctions = {
        1: getPendingPurchases,
        2: getAcceptedPurchases,
        3: getSendPurchases,
        4: getRejectedPurchases,
    };

    const getBookings = async (status, pageNum) => {
        setPurchasesLoading(true);
        if (pageNum) setSelectedPage(pageNum);
        let resp = await StoreService.getPurchaseByStatus({ status });
        console.log(resp.data);
        setPurchases(resp.data);
        setPurchasesLoading(false);
    };
    {/*
    unmock on release


        const reloadBookings = () => {
            getBookings(statusGetFunctions[selectedStatus]);
            console.log('entra a reload');
        };


    */}
    useEffect(() => {
        getBookings(purchaseStatusEnum.PENDING);
        // eslint-disable-next-line
    }, []);
    //FUNCION PÁRA EL SEARCH
    const getById = (searchQuery) => {
        setSelectedPage(1);
        //SEARCH QUERY ES EL PARAMETRO Q ESCRIBO EN EL SEARCH,

        // getBookings(statusGetFunctions[selectedStatus], 1, searchQuery);
    };

    //FUNCION PARA CAMBIAR DE PAGINA
    const handleChangePagination = (e, value) => {
        setSelectedPage(value);
        // getBookings(statusGetFunctions[selectedStatus], value);
        //VALUE ES EL NUMERO DE LA PAGINA QUE SELECCIONO
        //
    };

    //FUNCION QUE DIVIDE LA CANTIDAD DE BOOKINGS EN PAGINAS
    const updatePaginator = () => {
        setPaginatorNumber(Math.ceil(totalCount / PAGE_SIZE));
    };

    useEffect(() => {
        updatePaginator();
        // eslint-disable-next-line
    }, [totalCount]);

    const [purchaseInfoModal, setPurchaseInfoModal] = useState(false);

    const sortedPurchases = purchases.sort(function (a, b) {
        return b.id - a.id;
    });

    const modeBtnConfig = [
        {
            statusName: purchaseStatusEnum.PENDING,
            status: 1,
            title: `Pendientes de Aprobacion`,
            variant: selectedStatus === 1 ? "contained" : "outlined",
        },
        {
            statusName: purchaseStatusEnum.ACCEPTED,
            status: 2,
            title: `Aceptados`,
            variant: selectedStatus === 2 ? "contained" : "outlined"
        },
        {
            statusName: purchaseStatusEnum.DELIVERED,
            status: 3,
            title: `Recibidos`,
            variant: selectedStatus === 3 ? "contained" : "outlined"
        },
        {
            statusName: purchaseStatusEnum.CANCELLED,
            status: 4,
            title: `Cancelados`,
            variant: selectedStatus === 4 ? "contained" : "outlined"
        },
    ];

    return (<Box>
        <PurchaseInfoModal open={purchaseInfoModal} setOpen={setPurchaseInfoModal} data={clickedPurchase} reloadPurchases={getBookings} setSelectedStatus={setSelectedStatus}/>
        <div style={{ marginLeft: isMobile ? 5 : '170px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 45 }}>
                {modeBtnConfig.map(m => (
                    <Button
                        key={m.status + 'status_num'}
                        variant={m.variant}
                        color="primary"
                        onClick={() => { getBookings(m.statusName, 1); setSelectedStatus(m.status); }}
                    >
                        {m.title}
                    </Button>
                )
                )}
            </div>
            <MaterialTable
                title={''}
                pagination={false}
                icons={TableIcons}
                isLoading={purchasesLoading}
                onSearchChange={getById}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [25,50,100],
                  }}
                // options={{ pageSize: 20 }}
                actions={[
                    {
                        icon: () => <InfoIcon />,
                        tooltip: 'Purchase Info',
                        onClick: (event, rowData) => {
                            setClickedPurchase(rowData);
                            setPurchaseInfoModal(true);
                        }
                    }
                ]}
                columns={[
                    { title: 'ID', render: b => <div>{`#${b?.id}`}</div>, customFilterAndSearch: (term, rowData) => `${rowData.id}`?.includes(term) },
                    { title: 'Trabajador', render: b => <Box>{`${b?.employee?.email}`}</Box> },
                    { title: 'País', render: b => <Box>{b?.store_product?.country?.internal_detail}</Box> },
                    { title: 'ID Prod.', render: b => <Box>{`#${b?.store_product?.id}`}</Box> },
                    { title: 'Producto', render: b => <Box>{b?.store_product.title}</Box> },
                    { title: 'Cantidad', render: b => <Box>{b?.quantity}</Box> },
                    { title: 'Créditos', render: b => <Box><PriceDisplay value={b?.price_in_employee_currency} currency={b?.company_country?.currency?.code} /></Box> },
                    { title: 'Valor USD', render: b => <Box><PriceDisplay value={b?.price_in_original_currency} currency={'USD'} /> </Box> },
                    { title: 'Fecha', render: b => <Box>{moment(b?.created_date).format('MM-DD-YYYY')}</Box> },
                ]}
                // components={{
                //     Pagination: props => (
                //         <div className={classes.root}>
                //             <Pagination style={{ width: "450px", margin: "0 auto" }} count={paginatorNumber} color="primary" page={selectedPage} onChange={handleChangePagination} showFirstButton showLastButton />
                //         </div>
                //     ),
                // }}
                data={sortedPurchases}
                localization={{
                    header: {
                        actions: ' '
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay Compras para mostrar.',
                    }
                }}
            />
        </div>
    </Box>);
};

export default WnmStore;

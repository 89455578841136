export const RequestedSpacePriceChangeStatusEnum = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    CANCELLED: 'CANCELLED',
};

export const requestedSpacePriceChangeStatusLabels = {
    [RequestedSpacePriceChangeStatusEnum.PENDING]: 'Pendiente',
    [RequestedSpacePriceChangeStatusEnum.APPROVED]: 'Aprobado',
    [RequestedSpacePriceChangeStatusEnum.REJECTED]: 'Rechazado',
    [RequestedSpacePriceChangeStatusEnum.CANCELLED]: 'Cancelado',
};

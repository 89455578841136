// External components
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Switch,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import NumberFormat from "react-number-format";

// Utils & Config
import moment from "moment";
import is from "is_js";

import AdminService from "../../services/AdminService";
import DataService from "../../services/DataService";
import { PromocodeTypeEnum } from "../../helpers/consts";

const REQUIERED_FIELD_ERROR = "Este campo es requerido.";
const AMOUNT_OR_PERCENTAGE_ERROR = "Es necesario agregar un valor fijo o un porcentaje.";

const NewPromoCodeModal = ({ open, setOpen, getPromoCodes, codeData, setCodeDataToEdit }) => {
    // Disclaimer: this code was not written by me, I merely extracted the modals to a dedicated file.
    const [code, setCode] = useState("");
    const [amount, setAmount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [validFrom, setValidFrom] = useState(moment().format("yyyy-MM-DD"));
    const [validTo, setValidTo] = useState(moment().add(1, 'month').format("yyyy-MM-DD"));
    const [active, setActive] = useState(true);
    const [selectedCurrencyId, setSelectedCurrencyId] = useState(0);

    const [loading, setLoading] = useState(false);

    const [type, setType] = useState(PromocodeTypeEnum.MULTIPLE_USES);
    const [limitAmount, setLimitAmount] = useState(0);
    const [currencies, setCurrencies] = useState([]);

    const [errors, setErrors] = useState({});
    const [codeError, setCodeError] = useState('');


    useEffect(() => {
        DataService.getCurrencies().then((resp) => {
            setCurrencies(resp.data);
        }).catch((e) => console.log(e));
    }, []);

    const validateFormData = () => {
        let errorsAcum = {};

        if (type === PromocodeTypeEnum.UNIQUE_USE_PER_USER) {
            if (+limitAmount > 0 && is.empty(selectedCurrencyId)) errorsAcum.selectedCurrencyId = REQUIERED_FIELD_ERROR;
            if (+limitAmount <= 0 && percentage <= 0) errorsAcum.limitAmount = AMOUNT_OR_PERCENTAGE_ERROR;
        } else {
            if (+amount > 0 && is.empty(selectedCurrencyId)) errorsAcum.selectedCurrencyId = REQUIERED_FIELD_ERROR;
            if (amount <= 0 && percentage <= 0) errorsAcum.amount = AMOUNT_OR_PERCENTAGE_ERROR;
        }

        if (is.empty(code)) errorsAcum.code = REQUIERED_FIELD_ERROR;
        if (is.empty(validFrom)) errorsAcum.validFrom = REQUIERED_FIELD_ERROR;
        if (is.empty(validTo)) errorsAcum.validTo = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);

        return isValid;
    };

    const resetFormData = () => {
        // setCode("");
        setAmount(0);
        setPercentage(0);
        setValidFrom(moment().format("yyyy-MM-DD"));
        setValidTo(moment().add(1, 'month').format("yyyy-MM-DD"));
        setActive(true);
        setSelectedCurrencyId(0);
        setType(PromocodeTypeEnum.MULTIPLE_USES)
        setLimitAmount(0)
        setErrors({});
    }

    useEffect(() => {
        if (is.empty(codeData)) {
            resetFormData()
        } else {
            setCode(codeData.coupon);
            setAmount(codeData.amount);
            setPercentage(codeData.percentage);
            setValidFrom(moment(codeData.valid_from).utc().format("yyyy-MM-DD"));
            setValidTo(moment(codeData.valid_to).utc().format("yyyy-MM-DD"));
            setType(`${codeData.type}`)
            setLimitAmount(codeData.limit_amount || 0)
            setActive(codeData.active);
            setSelectedCurrencyId(currencies.find(cu => cu.name === codeData.currency)?.id);
        }
    }, [codeData, currencies]);

    const handleActive = () => {
        setActive((prevState) => !prevState);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const addNewCode = async () => {
        if (is.not.empty(codeData)) {
            try {
                setLoading(true);
                const data = {
                    promocode_id: codeData.id,
                    valid_from: validFrom,
                    valid_to: validTo,
                    active: active,
                }
                await AdminService.updatePromoCode(codeData.id, data);
                handleCancel();
                getPromoCodes();
                setCodeDataToEdit('');
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        } else {
            setCodeError('');
            if (validateFormData()) {
                setLoading(true);
                const data = {
                    coupon: code,
                    amount,
                    percentage,
                    valid_from: validFrom,
                    valid_to: validTo,
                    currency_id: selectedCurrencyId,
                    active,
                    type: type,
                    limit_amount: limitAmount,
                };

                try {
                    await AdminService.createPromoCode(data);
                    handleCancel();
                    getPromoCodes();
                } catch (e) {
                    setCodeError(e.response.data.message);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const handleTypeChange = (e) => {
        resetFormData();
        setType(e.target.value)
    }

    return (
        <Box>
            <Dialog open={open}>
                <DialogTitle style={{ textAlign: "center" }}>
                    <Box style={{ fontWeight: 600, fontSize: 22 }}>{is.empty(codeData) ? 'Nuevo' : 'Editar'} Código Promocional</Box>
                    {is.not.empty(codeError) && <Alert severity="error">{codeError}</Alert>}
                </DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        label='Código'
                        type='text'
                        fullWidth
                        InputProps={{
                            readOnly: is.not.empty(codeData),
                        }}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        helperText={errors.code}
                        error={!!errors.code}
                    />

                    {type !== PromocodeTypeEnum.CONSUMABLE && <NumberFormat value={+percentage}
                        decimalScale={2}
                        style={{ width: '100%', marginTop: "10px" }}
                        onValueChange={e => {
                            let value = e.value;
                            if (value >= 101) value = 100;
                            if (value <= 0) {
                                value = ''
                            }
                            setPercentage(+value);
                        }}
                        InputProps={{
                            readOnly: is.not.empty(codeData),
                        }}
                        label="Porcentaje de desc." required
                        isNumericString={true}
                        placeholder="0 %"
                        error={!!errors.amount || !!errors.limitAmount}
                        customInput={TextField}
                        suffix={' %'}
                    />}

                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <FormControl error={!!errors.selectedCurrencyId} style={{ marginTop: "10px", flexGrow: 1 }}>
                            <InputLabel id="currencyLabel">Moneda</InputLabel>

                            <Select
                                value={selectedCurrencyId}
                                onChange={e => setSelectedCurrencyId(e.target.value)}
                                id="currency"
                                labelId={'currencyLabel'}
                                inputProps={{ readOnly: is.not.empty(codeData) }}>
                                {currencies.map(curr => <MenuItem value={curr.id} key={curr.id + '_curr'}>{curr.code}</MenuItem>)}
                            </Select>
                            <FormHelperText>{errors.selectedCurrencyId}</FormHelperText>
                        </FormControl>


                        {type === PromocodeTypeEnum.UNIQUE_USE_PER_USER
                            ? <TextField
                                label='Tope de descuento'
                                type='number'
                                InputProps={{
                                    readOnly: is.not.empty(codeData),
                                }}
                                value={limitAmount}
                                error={!!errors.limitAmount}
                                onChange={(e) => setLimitAmount(e.target.value)}
                                style={{ marginTop: "10px", marginLeft: 8 }}
                            />
                            : <TextField
                                label='Monto de Descuento'
                                type='number'
                                InputProps={{
                                    readOnly: is.not.empty(codeData),
                                }}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                error={!!errors.amount}
                                style={{ marginTop: "10px", marginLeft: 8 }}
                            />
                        }
                    </Box>

                    {!!errors.amount && <FormHelperText error>{errors.amount}</FormHelperText>}
                    {!!errors.limitAmount && <FormHelperText error>{errors.limitAmount}</FormHelperText>}

                    <InputLabel id='dateFromLabel' style={{ marginTop: "10px" }}>
                        Validez desde:
                    </InputLabel>
                    <TextField
                        required
                        fullWidth
                        id='outlined-required'
                        labelid='dateFromLabel'
                        type='Date'
                        InputProps={{ inputProps: { min: moment().format("yyyy-MM-DD") } }}
                        value={validFrom}
                        helperText={errors.validFrom}
                        error={!!errors.validFrom}
                        onChange={(e) => {
                            setValidFrom(moment(e.target.value).format("yyyy-MM-DD"));
                        }}
                    />

                    <InputLabel id='dateToLabel' style={{ marginTop: "10px" }}>
                        Validez hasta:
                    </InputLabel>
                    <TextField
                        required
                        fullWidth
                        id='outlined-required'
                        labelid='dateToLabel'
                        type='Date'
                        helperText={errors.validTo}
                        error={!!errors.validTo}
                        value={validTo}
                        InputProps={{ inputProps: { min: moment().format("yyyy-MM-DD") } }}
                        onChange={(e) => setValidTo(moment(e.target.value).format("yyyy-MM-DD"))}
                    />

                    <RadioGroup
                        style={{ marginTop: "10px", display: 'flex', justifyContent: 'center' }}
                        row
                        name='type'
                        value={type}
                        required
                        onChange={(e) => handleTypeChange(e)}
                    >
                        <FormControlLabel
                            disabled={is.empty(codeData) ? false : true}
                            value={PromocodeTypeEnum.MULTIPLE_USES}
                            control={<Radio />}
                            label='MULTIPLES USOS'
                            labelPlacement='start'
                        />
                        <FormControlLabel
                            disabled={is.empty(codeData) ? false : true}
                            value={PromocodeTypeEnum.UNIQUE_USE}
                            control={<Radio />}
                            label='USO ÚNICO'
                            labelPlacement='start'
                        />
                        <FormControlLabel
                            disabled={is.empty(codeData) ? false : true}
                            value={PromocodeTypeEnum.UNIQUE_USE_PER_USER}
                            control={<Radio />}
                            label='USO ÚNICO POR USUARIO'
                            labelPlacement='start'
                        />
                        <FormControlLabel
                            disabled={is.empty(codeData) ? false : true}
                            value={PromocodeTypeEnum.CONSUMABLE}
                            control={<Radio />}
                            label='CONSUMIBLE'
                            labelPlacement='start'
                        />
                    </RadioGroup>

                    {/* FIXME: add proper text */}
                    {type === PromocodeTypeEnum.UNIQUE_USE_PER_USER &&
                        <Typography align="center" style={{ marginTop: '8px' }}>
                            Los promocodes de uso único por usuario pueden crearse con un porcentaje y/o un tope en moneda.
                            Creado de esta forma, al aplicarse toma el mínimo entre ambos.
                            La moneda seleccionada para el promocode se convertirá a la moneda del espacio.
                        </Typography>
                    }
                    {/* FIXME: add proper text */}
                    {type === PromocodeTypeEnum.CONSUMABLE &&
                        <Typography align="center" style={{ marginTop: '8px' }}>
                            Los promocodes CONSUMIBLES permiten ser usados varias veces hasta que se acabe su saldo. 
                            Si queda saldo pendiente luego de hacer un booking, se puede utilizar en otra oprotunidad.
                        </Typography>
                    }

                    <div style={{ marginTop: "20px" }}>
                        <label>Activo</label>

                        <Switch
                            checked={active}
                            onChange={(e) => handleActive(e)}
                        />
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancel} color='primary'>
                        Cancelar
                    </Button>

                    <Button onClick={addNewCode} variant="contained" color='primary' disabled={loading}>
                        {loading ? <CircularProgress /> : <>{is.empty(codeData) ? 'Crear código' : 'Guardar cambios'}</>}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default NewPromoCodeModal;

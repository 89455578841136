// External components
import React, { useState, useEffect } from "react";
import { Box, Typography, MenuItem } from "@material-ui/core";
import { Pie } from 'react-chartjs-2';

// Utils & Config
import { useMediaQuery } from "react-responsive";
import { useStyles } from "./PieChart.styles";
import AdminService from '../../../../services/AdminService';
import { PieChartOptions } from "../../../../helpers/KpiChartsOptions";
import moment from "moment";
import { getFormatedMonthsBetween } from "../../../../helpers/funcs/getMonthsBetween";

// Internal components
import TextInBox from "../../../atoms/TextInBox";
import CountrySelectInput from "../../../atoms/CountrySelectInput";
import ColorfulTable from "../../../molecules/ColorfulTable";
import useDebounce from "../../../../hooks/useDebounce";
import ChartLegend from "../../../atoms/ChartLegend";
import CustomSelectInput from "../../../atoms/CustomSelectInput";

const CompaniesTotal = (props) => {

    const {
        countries,
        companiesTotalSelectedCountry,
        setCompaniesTotalSelectedCountry,
    } = props;

    const currentMonth = moment().startOf('month').format('YYYY-MM-DD');
    const from = moment('2022-01-01').format('YYYY-MM-DD');
    const lastMonth = moment(currentMonth).subtract(1, 'month').format('YYYY-MM-DD');

    const [primaryData, setPrimaryData] = useState([]);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [companiesPercent, setCompaniesPercent] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);


    const months = getFormatedMonthsBetween(from, lastMonth);
    const debouncedSearchValue = useDebounce(searchValue);

    useEffect(() => {

        AdminService.getCompaniesEmployeesPercentByCountry(companiesTotalSelectedCountry.name, selectedMonth)
            .then((resp) => setCompaniesPercent(resp.data))
            .catch((err) => console.log(err));

    }, [companiesTotalSelectedCountry, selectedMonth]);

    useEffect(() => {

        setIsLoading(true);

        AdminService.getCompaniesByCountry(companiesTotalSelectedCountry.name, debouncedSearchValue, selectedMonth)
            .then((resp) => setPrimaryData(resp.data))
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));

        AdminService.getCompaniesCountByCountry(companiesTotalSelectedCountry.name, selectedMonth)
            .then((resp) => setTotalCompanies(resp.data))
            .catch((err) => console.log(err));

    }, [companiesTotalSelectedCountry, debouncedSearchValue, selectedMonth]);


    const pieLabels = {
        'lessThan50Employees': 'Up to 50 employees',
        'lessThan150Employees': '51 to 150 employees',
        'lessThan250Employees': '151 to 250 employees',
        'greaterThan250Employees': '+250 employees',
    };

    const data = {
        labels: companiesPercent?.map((c, i) => `${pieLabels[c.label]}`),
        datasets: [
            {
                label: 'Companies by country',
                data: companiesPercent?.map((c) => +c?.percent?.toFixed(1)),
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    };

    const handleSearch = (val) => {
        setIsLoading(true);
        setSearchValue(val);
    };

    const options = PieChartOptions({ prevChar: '', postChar: '%' });

    const isLessThan1300pxWidth = useMediaQuery({ query: '(max-width: 1300px)' });
    const stylesProps = { isLessThan1300pxWidth };
    const { root, title, container, chartContainer, legendContainer, inputContainer, helperText } = useStyles(stylesProps);
    // const left = isLessThan1300pxWidth ? 284 : 192;
    const top = isLessThan1300pxWidth ? 8 : 2;
    const left = isLessThan1300pxWidth ? -32 : -16;


    return (
        <Box className={root}>
            <TextInBox text='Companies' />
            <Typography className={title}>Companies by Country</Typography>

            <Box className={container}>
                <Box className={chartContainer}>
                    <Pie options={options} data={data} />

                    <Box className={legendContainer}>
                        {companiesPercent.map((c, i) => (
                            +c.percent > 0 && (
                                <ChartLegend
                                    KPI={`${pieLabels[c.label]}: ${+c.percent.toFixed(1)} %`}
                                    country={{}}
                                    color={colors[i]}
                                />
                            )
                        ))}
                    </Box>
                </Box>

                <Box>
                    <Box className={inputContainer}>
                        <CountrySelectInput
                            allCountriesLabel
                            countries={countries}
                            selectedCountry={companiesTotalSelectedCountry}
                            setSelectedCountry={setCompaniesTotalSelectedCountry}
                            top={top}
                            left={left}
                        />

                        <CustomSelectInput
                            label='Current month'
                            items={months}
                            selectedItem={selectedMonth}
                            setSelectedItem={setSelectedMonth}
                            defaultValue={currentMonth}
                            top={top}
                            left={left}
                        >
                            <MenuItem value={currentMonth}>
                                Current month
                            </MenuItem>

                            {months.reverse().map((m, i) => (
                                <MenuItem value={m.month} key={i}>
                                    {m.label}
                                </MenuItem>
                            ))}
                        </CustomSelectInput>
                    </Box>

                    <ColorfulTable
                        isCompaniesByCountry
                        isScrollable
                        isLoading={isLoading}
                        withInput
                        // useDebounce
                        inputValue={searchValue}
                        onInputChange={(e) => handleSearch(e.target.value)}
                        onClearInput={() => handleSearch('')}
                        title={`${totalCompanies} Companies`}
                        placeholder={'Search'}
                        headers={['Company', 'Employees *', 'Budget (USD) **']}
                        data={primaryData}
                    />

                    <Box marginTop={'24px'}>
                        <Typography variant='body2' className={helperText}>
                            * Active / Pending Employees
                        </Typography>

                        <Typography variant='body2' className={helperText}>
                            ** Budget / Spent Credits
                        </Typography>
                    </Box>

                </Box>
            </Box>
        </Box>

    );

};

// Original
// const colors = [
//     '#BD3AEF',
//     '#E15FFF',
//     '#FF82FF',
//     '#FFA5FF',
//     '#9900CC',
// ];

const colors = [
    '#bb5adb', // Violet
    '#71c5c5', // Cyan
    '#61976c', // Green
    '#d7a469', // Yellow
    '#b73f2f', // Red
];

export default CompaniesTotal;

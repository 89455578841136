// External Components
import React, { useState, useContext } from 'react';
import { Box, Button,  FormHelperText, Tooltip, FormControl, withStyles, TextField, CircularProgress } from '@material-ui/core';

import RUG, { DropArea } from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';

// Utils & Config
import is from 'is_js';
import { useMediaQuery } from 'react-responsive';
import Axios from 'axios';
import AdminService from '../../services/AdminService';
import { hackFnToUpdateOrderAfterLoading } from '../../helpers/funcs/hackFnToUpdateOrderAfterLoading';

// Internal Components
import UploadImagesButton from '../atoms/UploadImagesButton';

const Photos = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { context } = props;
    const { space, stepUtils } = useContext(context);

    const [description, setDescription] = useState(space?.description || '');
    const [spaceImagesIds, setSpaceImagesIds] = useState(space?.images?.map((i) => i.uuid) || []);

    const [loadingCounter, setLoadingCounter] = useState(0);
    const [errors, setErrors] = useState({});
    const [imageWarning, setImageWarning] = useState(false);

    const styles = {
        stepsView: {
            backgroundColor: '#ffffff',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 159px)',
            paddingBottom: isNarrowWidth ? '20px' : 0,
            paddingLeft: isMobile ? '10px' : '50px',
            paddingRight: isMobile ? '10px' : '50px'
        },
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '30px',
        },
    };

    const removeImage = async (image) => {
        const spaceId = space.id;
        const hostId = space.host.id;

        try {
            await AdminService.deleteSpacePhoto(hostId, spaceId, image.code)

            if (image.uploading) setLoadingCounter((c) => c - 1);

            setSpaceImagesIds((sii) => sii.filter((i) => i !== image.code));
        } catch (error) {
            console.log(error)
        }
    };


    const customRequest = ({ uid, file, send, action, headers, onProgress, onSuccess, onError }) => {

        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();

        const uploadProgressFn = ({ total, loaded }) => {
            onProgress(uid, Math.round(loaded / total * 100));
        };

        var reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = async function () {
            setLoadingCounter((c) => c + 1);

            const spaceId = space.id;
            const hostId = space.host.id;

            const res = await AdminService.postSpaceImage(hostId, spaceId, reader.result, uploadProgressFn, source.token);

            setSpaceImagesIds((imagesIds) => ([...imagesIds, res.data.uuid]));
            onSuccess(uid, res.data, { code: res.data.uuid });

            setLoadingCounter((c) => c - 1);
        };

        reader.onerror = function (error) {
            onError(uid, {
                action,
                status: error.request,
                response: error.response
            });
        };

        return {
            abort() {
                source.cancel();
            }
        };
    };

    const validateFormData = () => {
        let errorsAcum = {};

        if (description.length < 100) errorsAcum.description = 'Este campo debe tener al menos 100 caracteres.';
        if (is.empty(spaceImagesIds)) errorsAcum.images = 'Debes agregar al menos una foto al espacio.';

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    };

    const getSpaceData = () => ({
        description,
        images: spaceImagesIds.flat()
    });

    return (
        <Box style={styles.stepsView}>
            <Box className={'onboardingForm spacePhotosForm'}>
                <div style={styles.onboardingTitle}>
                    <span>Fotos y descripción del espacio</span>
                    <Box style={{ minHeight: '20px' }}></Box>
                </div>

                <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                        <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <span>Dale vida al anuncio con fotos</span>
                        </div>

                        <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>
                            Sacá fotos del espacio con una cámara o celular y subí al menos una para publicar el anuncio. Podes editar y seguir subiendo imágenes a la galería más tarde.
                        </div>

                        <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>
                            Cuando hayas subido las imágenes, arrastralas y movelas para que se muestren en el orden que quieras.
                            <b>La primera imagen será la de portada.</b>
                        </div>
                    </Box>

                    <FormControl style={{ ...styles.formControl }} error={!!errors.images || imageWarning}>
                        {imageWarning && <FormHelperText>{imageWarning}</FormHelperText>}

                        <FormHelperText>{errors.images}</FormHelperText>

                        <RUG
                            rules={{ limit: 30, size: 70000 }}
                            inOrder={true}
                            onSuccess={hackFnToUpdateOrderAfterLoading}
                            onSortEnd={(images) => setSpaceImagesIds(images.map((i) => i.code))}
                            source={(response) => response.url}
                            sorting={{ axis: 'xy', pressDelay: 200, distance: 0 }}
                            customRequest={customRequest}
                            initialState={space?.images?.map((i) => ({ source: i.url, code: i.uuid })) || []}
                            onDeleted={removeImage}
                            header={({ openDialogue }) => (
                                <DropArea>
                                    {(isDrag) => <div style={{ border: 'dashed', borderColor: 'rgba(0, 0, 0, 0.12)', borderRadius: '4px', background: isDrag ? 'lightgrey' : '#fff' }}>
                                        <UploadImagesButton openDialogue={openDialogue} />
                                    </div>}
                                </DropArea>
                            )}
                            onChange={() => null}
                            onWarning={(type, rules) => {
                                switch (type) {
                                case 'limit':
                                    setImageWarning('Has cargado el máximo de imagenes' + ': ' + rules.limit);
                                    break;
                                case 'size':
                                    setImageWarning('El tamañno máximo de imagen es 5mb.');
                                    break;
                                default:
                                }
                            }}
                        />

                    </FormControl>

                    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                        <FormControl style={{ ...styles.formControl, flexGrow: 3 }} >
                            <div style={{ color: '#383839', fontSize: '18px', textAlign: 'left' }}>
                                Contales a los trabajadores sobre el espacio
                            </div>

                            <TextField
                                style={{ marginTop: '20px' }}
                                id={'summary'}
                                multiline
                                error={!!errors.description}
                                helperText={errors.description}
                                rows={description.split(/\r\n|\r|\n/).length + 3}
                                variant={'outlined'}
                                value={description}
                                onChange={(event) => setDescription(event.target.value)}
                            />

                            <div style={{ textAlign: 'right', color: '#818181', fontFamily: 'Poppins', fontSize: '10px', marginTop: '5px' }}>{description ? description.length : 0} Caracteres</div>
                        </FormControl>
                    </div>
                </Box>
            </Box>

            {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Validar datos ingresados.</FormHelperText>}

            <EditionButtons
                saveFn={() => validateFormData() && stepUtils.updateSpace(getSpaceData())}
                saveDisabled={loadingCounter > 0}
            />
        </Box>
    )
};

export default Photos;

const EditionButtons = ({ saveFn, saveDisabled }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [saveLoading, setSaveLoading] = useState(false);

    const save = async () => {
        try {
            setSaveLoading(true);
            await saveFn();
        } catch (e) {
            setSaveLoading(false);
            console.log(e);
        } finally {
            setSaveLoading(false);
        }
    };

    const styles = {
        cancelButton: {
            marginLeft: isMobile ? '0' : '30px',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '140px',
            height: '50px',
            fontWeight: 'bold',
            color: '#6D7278',
            marginBottom: isMobile ? '10px' : '0'
        },
        saveButton: {
            marginLeft: isMobile ? '0' : '30px',
            backgroundColor: '#00D1BF',
            color: saveDisabled? 'grey' : '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '168px',
            height: '50px',
            fontWeight: 'bold',
            marginBottom: isMobile ? '10px' : '0',
        },
    };

    return (
        <Box style={{ justifyContent: 'flex-end', display: 'flex', paddingBottom: '15px', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Button onClick={save} variant={'contained'} style={styles.saveButton} disabled={saveDisabled}>
                {saveLoading ? <CircularProgress color={'secondary'} /> : 'Guardar'}
            </Button>
        </Box>
    )
};

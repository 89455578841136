import React, { useState, useEffect } from "react";
import AdminService from "../services/AdminService";
import MaterialTable from "material-table";
import { Button, Box, IconButton, Tooltip, Link, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@material-ui/core";
import TableIcons from "./molecules/TableIcons";
import UserInfoModal from "./UserInfoModal";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PauseIcon from '@material-ui/icons/Pause';
import BlockIcon from '@material-ui/icons/Block';
import { EmailStatusIcon } from "./EmailStatusIcon";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { useMediaQuery } from "react-responsive";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { PriceDisplay } from "./atoms/PriceDisplay";
import EventIcon from '@material-ui/icons/Event';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useHistory } from "react-router";
import { ActiveByHostCheck, AdminReviewCheck, AutoApprovalCheck, SpaceDetailPanel } from "./Spaces";
import SpaceInfoModal from "./SpaceInfoModal";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import HostProModal from "./modals/HostProModal";
import { HostServiceStatusEnum } from "../helpers/enums/HostServiceStatusEnum";
import MonthlyPaymentsModal from "./modals/MonthlyPaymentsModal";
import { MonetizationOnRounded } from "@material-ui/icons";
import ReactCountryFlag from "react-country-flag";

const PAGE_SIZE = 20;
const STATUS_PROPERTIES = {
    0: 'pending_count',
    1: 'actives_count',
    2: 'paused_count',
    3: 'suspended_count',
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "25px",
        marginBottom: "25px"
    },
}));

const Hosts = () => {
    const classes = useStyles();

    const [hosts, setHosts] = useState([]);
    const [hostsLoading, setHostsLoading] = useState(false);
    const [clickedHost, setClickedHost] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('')
    const [hostsCount, setHostsCount] = useState({});
    const [selectedPage, setSelectedPage] = useState(1);
    const [paginatorNumber, setPaginatorNumber] = useState(10);
    const [spacesModalOpen, setSpacesModalOpen] = useState(false);
    const [proModalOpen, setProModalOpen] = useState(false);
    const [paymentsHostModal, setPaymentsHostModal] = useState(false);
    const onTogglePaymentsHostModal = () => setPaymentsHostModal(prev => !prev);

    const getHostsByStatus = (statusId, pageSize, pageNumber, searchQuery) => {
        setHostsLoading(true);
        AdminService.getHostsByStatus(statusId, pageSize, pageNumber, searchQuery)
            .then(resp => {
                const { hosts, ...counts } = resp.data;
                setHosts(hosts);
                setHostsCount(counts);
                setSelectedStatus(statusId)
                setHostsLoading(false);
            }).catch(e => {
                setHostsLoading(false);
                console.log(e)
            });
    }

    const searchByName = (searchQuery) => {
        setSelectedPage(1)
        getHostsByStatus(selectedStatus, PAGE_SIZE, 1, searchQuery)
    }

    useEffect(() => {
        getHostsByStatus(0, PAGE_SIZE, 1);
    }, []);

    const approveHost = async hostId => {
        await AdminService.updateHostStatus(hostId, 1);
        getHostsByStatus(selectedStatus, PAGE_SIZE, selectedPage);
    };

    const refuseHost = async hostId => {
        await AdminService.updateHostStatus(hostId, 2);
        getHostsByStatus(selectedStatus, PAGE_SIZE, selectedPage);
    };

    const deactivateHost = async hostId => {
        await AdminService.updateHostStatus(hostId, 3);
        getHostsByStatus(selectedStatus, PAGE_SIZE, selectedPage);
    };

    const handleChangePagination = (e, value) => {
        setSelectedPage(value);
        getHostsByStatus(selectedStatus, PAGE_SIZE, value);
    };

    const updatePaginator = (totalCount) => {
        setPaginatorNumber(Math.ceil(totalCount / PAGE_SIZE));
    };

    const refreshList = () => {
        getHostsByStatus(selectedStatus, PAGE_SIZE, selectedPage)
    }

    const handleOpenProModal = (host) => {
        setClickedHost(host);
        setProModalOpen(true);
    }

    const handleHostPaymentsModal = (e, host) => {
        setClickedHost(host);
        onTogglePaymentsHostModal();
    }

    useEffect(() => {
        updatePaginator(hostsCount[STATUS_PROPERTIES[selectedStatus]]);
        // eslint-disable-next-line
    }, [selectedStatus]);

    const HostActionButtons = ({ host }) => {
        switch (host.status) {
            case 0:
                return <>
                    <Tooltip title="Activar Host" className="approveHost">
                        <IconButton color="primary" onClick={() => approveHost(host.id)}>
                            <ThumbUpIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Suspender Host" className="refuseHost">
                        <IconButton color="primary" onClick={() => refuseHost(host.id)}>
                            <PauseIcon />
                        </IconButton>
                    </Tooltip>
                </>
            case 1:
                return <>
                    <Tooltip title="Suspender Host" className="refuseHost">
                        <IconButton color="primary" onClick={() => refuseHost(host.id)}>
                            <PauseIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Desactivar Host" className="deactivateHost">
                        <IconButton color="primary" onClick={() => deactivateHost(host.id)}>
                            <BlockIcon />
                        </IconButton>
                    </Tooltip>
                </>
            case 2:
                return <>
                    <Tooltip title="Activar Host" className="approveHost">
                        <IconButton color="primary" onClick={() => approveHost(host.id)}>
                            <ThumbUpIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Desactivar Host" className="deactivateHost">
                        <IconButton color="primary" onClick={() => deactivateHost(host.id)}>
                            <BlockIcon />
                        </IconButton>
                    </Tooltip>
                </>
            case 3:
                return <>
                    <Tooltip title="Activar Host" className="approveHost">
                        <IconButton color="primary" onClick={() => approveHost(host.id)}>
                            <ThumbUpIcon />
                        </IconButton>
                    </Tooltip>
                </>
            default:
                return null;
        }
    }

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <Box>
            <UserInfoModal host={clickedHost} open={infoModalOpen} setOpen={setInfoModalOpen} />
            <HostSpacesModal host={clickedHost} open={spacesModalOpen} setOpen={setSpacesModalOpen} />
            <MonthlyPaymentsModal host={clickedHost} isOpen={paymentsHostModal} onToggle={onTogglePaymentsHostModal} />
            {proModalOpen && <HostProModal host={clickedHost} open={proModalOpen} setOpen={setProModalOpen} refreshList={refreshList} />}

            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                <h1>Hosts</h1>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant={selectedStatus === 0 ? "contained" : "outlined"} color="primary" onClick={() => getHostsByStatus(0, PAGE_SIZE, 1)}>{`Pendientes (${hostsCount.pending_count || 0})`}</Button>
                    <Button variant={selectedStatus === 1 ? "contained" : "outlined"} color="primary" onClick={() => getHostsByStatus(1, PAGE_SIZE, 1)}>{`Activos (${hostsCount.actives_count || 0})`}</Button>
                    <Button variant={selectedStatus === 2 ? "contained" : "outlined"} color="primary" onClick={() => getHostsByStatus(2, PAGE_SIZE, 1)}>{`Suspendidos (${hostsCount.paused_count || 0})`}</Button>
                    <Button variant={selectedStatus === 3 ? "contained" : "outlined"} color="primary" onClick={() => getHostsByStatus(3, PAGE_SIZE, 1)}>{`Desactivados (${hostsCount.suspended_count || 0})`}</Button>
                </div>
                <MaterialTable
                    title={statusLabels[selectedStatus] || ''}
                    icons={TableIcons}
                    onSearchChange={searchByName}
                    isLoading={hostsLoading}
                    options={{ pageSize: 20 }}
                    actions={[
                        {
                            icon: () => <InfoIcon />,
                            tooltip: 'User Info',
                            onClick: (event, rowData) => {
                                setClickedHost({ ...rowData.user, ...rowData });
                                setInfoModalOpen(true);
                            }
                        },
                        {
                            icon: () => <HomeWorkIcon />,
                            tooltip: 'Espacios',
                            onClick: (event, rowData) => {
                                setClickedHost({ ...rowData.user, ...rowData });
                                setSpacesModalOpen(true);
                            }
                        },
                        {
                            icon: () => <MonetizationOnRounded />,
                            tooltip: 'Pagos',
                            onClick: handleHostPaymentsModal
                        },

                    ]}
                    columns={[
                        {
                            title: '',
                            render: h => (
                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                    {process.env.REACT_APP_ENABLED_HOST_PRO_FEATURE === 'true' &&
                                        <Tooltip title={'Host Pro'}>
                                            <IconButton onClick={() => handleOpenProModal(h)}>
                                                <LoyaltyIcon style={{ color: h.proStatus === HostServiceStatusEnum.ACTIVE_SUSCRIPTION ? '#9900CC' : 'lightgrey' }} />
                                                {h.proStatus === HostServiceStatusEnum.PENDING_ADMIN_APPROVAL &&
                                                    <Typography variant={'h5'} style={{ color: '#9900CC' }}>!</Typography>
                                                }
                                            </IconButton>
                                        </Tooltip>
                                    }

                                    {h.hasHablalo &&
                                        <Tooltip title={'Suscrito a Háblalo'}><img src={require('../assets/images/icons/hablalo-icon.webp')} style={{ width: '24px', marginTop: '8px' }} /></Tooltip>
                                    }
                                </Box>
                            )
                        },
                        { title: 'ID', render: b => <div>{`#${b.id}`}</div> },
                        { title: 'Nombre', field: 'name', render: h => `${h.name} ${h.lastname || ''}` },
                        { title: 'Mail', field: 'email', render: h => <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}><EmailStatusIcon status={h?.user?.email_verified} /> {h.email}</div> },
                        { title: 'Teléfono', field: 'phone' },
                        { title: 'Fecha de creación', field: 'creation_date', render: h => `${moment(h.creation_date).format('DD-MM-YYYY')}` },
                        { title: 'Estado', field: 'status', render: h => statusLabels[h.status], sorting: false },
                        {
                            title: 'Acciones',
                            sorting: false,
                            field: '',
                            render: h => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <HostActionButtons host={h} />
                            </div>,
                        },
                    ]}
                    data={hosts}
                    components={{
                        Pagination: props => (
                            <div className={classes.root}>
                                <Pagination style={{ width: "450px", margin: "0 auto" }} count={paginatorNumber} color="primary" page={selectedPage} onChange={handleChangePagination} showFirstButton showLastButton />
                            </div>
                        ),
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsSelect: 'por página'
                        },
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay hosts para mostrar.',
                        }
                    }}
                />
            </div>
        </Box>
    );
};

export default Hosts;

const statusLabels = {
    0: 'Pendiente de Aprobación',
    1: 'Activo',
    2: 'Suspendido',
    3: 'Desactivado'
};

export const HostSpacesModal = ({ host, open, setOpen }) => {
    const [spaces, setSpaces] = useState([]);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const history = useHistory();

    const openSpacePublication = spaceId => {
        const newWindow = window.open(`${process.env.REACT_APP_WEB_APP_URL}/publication/${spaceId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    };

    const getSpaces = () => {
        setLoading(true);
        AdminService.getSpacesByHostId(host?.id)
            .then(resp => {
                setSpaces(resp?.data);
            })
            .catch(console.log)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (open) {
            getSpaces();
        } else {
            setSpaces([])
        }
    }, [open]);

    return <>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>
                <b>{host?.name || ''}</b>
                <Box>{`#${host?.id}`}</Box>
            </DialogTitle>
            <DialogContent>
                <MaterialTable
                    icons={TableIcons}
                    options={{ pageSize: 20 }}
                    isLoading={loading}
                    title={' '}
                    actions={[
                        {
                            icon: () => <EventIcon />,
                            tooltip: 'Editar Disponibilidad',
                            onClick: (event, rowData) => history.push(`/publication/edit/${rowData.id}/0`)
                        },
                        {
                            icon: () => <AttachMoneyIcon />,
                            tooltip: 'Editar Precio',
                            onClick: (event, rowData) => history.push(`/publication/edit/${rowData.id}/1`)

                        }
                    ]}
                    columns={[
                        {
                            title: 'Revisado por ADMIN',
                            field: 'admin_review',
                            render: s => <AdminReviewCheck space={s} />
                        },
                        {
                            title: 'ID',
                            field: 'id',
                            render: s => <Link style={{ cursor: 'pointer' }} onClick={() => openSpacePublication(s?.id)}>#{s?.id}</Link>,
                            customSort: (a, b) => {
                                const aName = a?.buildingType?.name;
                                const bName = b?.buildingType?.name;
                                let c = 0;
                                if (aName > bName) c = -1;
                                if (bName > aName) c = 1;
                                return c;
                            },
                            customFilterAndSearch: (term, rowData) => `${rowData.id}`?.includes(term)
                        },
                        {
                            title: 'Pais',
                            field: 'location',
                            render: s => <Tooltip title={s?.location?.country}>
                                <ReactCountryFlag
                                    svg
                                    countryCode={s?.location?.country || ""}
                                    style={{ marginRight: 5, width: 32, height: 32 }}
                                />
                            </Tooltip>,
                            customSort: (a, b) => {
                                const aName = a?.type?.name;
                                const bName = b?.type?.name;
                                let c = 0;
                                if (aName > bName) c = -1;
                                if (bName > aName) c = 1;
                                return c;
                            }
                        },
                        {
                            title: 'Host',
                            field: 'host.name',
                            render: s => <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Link style={{ cursor: 'pointer' }} >{`${s?.host?.name} ${s?.host?.lastname || ''}`}</Link>
                            </div>,
                            customSort: (a, b) => {
                                const aName = a?.host?.name;
                                const bName = b?.host?.name;
                                let c = 0;
                                if (aName > bName) c = -1;
                                if (bName > aName) c = 1;
                                return c;
                            }
                        },
                        {
                            title: 'Espacio',
                            field: 'type.name',
                            render: s => `${s?.type?.name}`,
                            customSort: (a, b) => {
                                const aName = a?.type?.name;
                                const bName = b?.type?.name;
                                let c = 0;
                                if (aName > bName) c = -1;
                                if (bName > aName) c = 1;
                                return c;
                            }
                        },
                        {
                            title: 'Activo por host',
                            field: 'active',
                            render: s => <ActiveByHostCheck space={s} />
                        },
                        {
                            title: 'Auto-aprobar reservas',
                            field: 'auto_approval',
                            render: s => <AutoApprovalCheck space={s} />
                        },
                    ]}
                    data={spaces}
                    detailPanel={rowData => <SpaceDetailPanel space={rowData} />}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                            labelRowsSelect: 'por página'
                        },
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay hosts para mostrar.',
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    </>;
};


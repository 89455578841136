/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';

import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import MaterialTable from 'material-table';

import TableIcons from '../molecules/TableIcons';
import EditIcon from '@material-ui/icons/Edit';

import AdminService from '../../services/AdminService';
import { companyAdminRolesDictionary } from '../../helpers/dictionaries/companyAdminRolesDictionary';
import CreateOrUpdateCompanyAdminsModal from './CreateOrUpdateCompanyAdminModal';

const OpenModalEnum = {
    'NONE': 'null',
    'CREATE_ADMINS': 'CREATE_ADMINS',
    'UPDATE_ADMIN': 'UPDATE_ADMIN',
};

const CompanyAdminListModal = ({ company, open, setOpen }) => {
    const [companyAdmins, setCompanyAdmins] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [openModal, setOpenModal] = useState(OpenModalEnum.NONE);

    const refreshAdmins = () => {
        setLoading(true);
        AdminService.getCompanyAdmins(company.id)
            .then((resp) => setCompanyAdmins(resp?.data || []))
            .catch(console.log)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (open && company.id) {
            refreshAdmins();
        }
    }, [open, company]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
            {openModal === 'CREATE_ADMINS' && (
                <CreateOrUpdateCompanyAdminsModal
                    open={openModal === 'CREATE_ADMINS'}
                    onClose={() => setOpenModal('NONE')}
                    onConfirm={() => { setOpenModal('NONE'); refreshAdmins(); }}
                    company={company}
                    mode={'CREATE'}
                />
            )}

            {openModal === 'UPDATE_ADMIN' && (
                <CreateOrUpdateCompanyAdminsModal
                    open={openModal === 'UPDATE_ADMIN'}
                    onClose={() => setOpenModal('NONE')}
                    onConfirm={() => { setOpenModal('NONE'); refreshAdmins(); }}
                    company={company}
                    mode={'UPDATE'}
                    admin={selectedAdmin}
                />
            )}

            <DialogTitle>
                <b>{'Administradores de ' + company.name}</b>
            </DialogTitle>

            <DialogContent>
                <MaterialTable
                    icons={TableIcons}
                    title={''}
                    options={{ pageSize: 10, pageSizeOptions: [10, 25, 50, 100], sorting: true, thirdSortClick: false }}
                    columns={[
                        { title: 'Admin ID', render: (b) => <div>{`#${b.id}`}</div> },
                        {
                            title: <b>Nombre</b>,
                            field: 'name',
                            render: (rowData) => <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt={`${rowData.user?.name} ${rowData.user?.lastname || ''}`} style={{ width: 40, borderRadius: '50%', marginRight: 5 }}>{`${rowData.user?.name?.[0] || ''}${rowData.user?.lastname?.[0] || ''}`}</Avatar>
                                <Box>{`${rowData.user?.name || ''} ${rowData.user?.lastname || ''}`}</Box>
                            </Box>,
                            editable: 'never',
                            customSort: (a, b) => {
                                const aName = `${a.user?.name || ''} ${a.user?.lastname || ''}`;
                                const bName = `${b.user?.name || ''} ${b.user?.lastname || ''}`;
                                if (aName < bName) return -1;
                                if (aName > bName) return 1;
                                return 0;
                            }
                        },
                        {
                            title: <b>Email</b>,
                            render: (b) => b?.email || b?.user?.email,
                        },
                        {
                            title: <b>Teléfono</b>,
                            render: (b) => b?.user?.phone,
                        },
                        {
                            title: <b>Roles</b>,
                            render: (b) => b.roles.map((role) => <Chip key={role} label={companyAdminRolesDictionary[role]} />)
                        },
                        {
                            render: (b) => (
                                <IconButton onClick={() => { setSelectedAdmin(b); setOpenModal('UPDATE_ADMIN');}}>
                                    <EditIcon />
                                </IconButton>
                            )
                        }
                    ]}
                    isLoading={loading}
                    data={companyAdmins}
                    actions={[
                        {
                            icon: TableIcons.Add,
                            tooltip: 'Agregar ADMIN',
                            isFreeAction: true,
                            onClick: () => setOpenModal(OpenModalEnum.CREATE_ADMINS),
                        }
                    ]}
                    editable={{
                        onRowDelete: async (oldData) => {
                            try {
                                await AdminService.deleteAdmin(company.id, oldData.id);
                            }
                            catch (error) { console.log(error); }
                            finally {
                                refreshAdmins();
                            }
                        },
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} admins(s) selecionados'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay admins para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtros'
                            },
                            editRow: {
                                deleteText: '¿Desea eliminar este admin?'
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color={'primary'}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompanyAdminListModal;

// External Components
import React from 'react';
import { AppBar, Toolbar, Avatar, Divider, Box, IconButton } from '@material-ui/core';

// Utils & Config
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';

// Internal components
import LogoutButton from '../LogoutButton';

// Icons and images
import MenuIcon from '@material-ui/icons/Menu';
const LogoImage = (props) => <img src={'https://worknmates-data.s3.amazonaws.com/config/offi-logo/01_Principal.png'} style={{ maxHeight: props.maxHeight }} alt="OFFI Logo" />;

const NavBar = ({ viewRef, toggleDrawer }) => {
    const { isAuthenticated, user, isLoading } = useAuth0();

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1080px)' });
    const { appBar, toolBar, container, items, avatarContainer, avatar } = useStyles({ isNarrowWidth });

    return (
        <>
            <AppBar className={appBar} position={'fixed'} ref={viewRef}>
                <Toolbar className={toolBar}>
                    <Box className={container}>
                        {isNarrowWidth &&
                            <IconButton>
                                <MenuIcon onClick={toggleDrawer(true)} style={{ width: '30px', height: '30px' }} />
                            </IconButton>
                        }

                        <Link to={'/'}>
                            <LogoImage maxHeight={'45px'} />
                        </Link>

                        {!isLoading &&
                            <Box className={items}>
                                {isAuthenticated && !isNarrowWidth &&
                                    <Box className={avatarContainer}>
                                        <Avatar className={avatar} alt={user.name} src={user.picture} />
                                    </Box>
                                }

                                {isAuthenticated && <LogoutButton />}
                            </Box>
                        }
                    </Box>
                </Toolbar>
                <Divider orientation={'horizontal'} />
            </AppBar >

        </>
    );
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#ffffff',
        zIndex: 999,
        boxShadow: 'none',
        width: '100%'
    },
    toolbar: {
        padding: '8px 24px',
        [theme.breakpoints.down('xs')]: {
            padding: '8px 0',
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    items: {
        display: 'flex',
        alignItems: 'center'
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '10px'
    },
    avatar: {
        width: '40px',
        height: '40px'
    }
}));

export default NavBar;

// External components
import {
    Box
} from '@material-ui/core';
import React, { useState } from 'react';

// Icons & Images

// Utils & Config
import { useMediaQuery } from 'react-responsive';
import { useStyles } from './transactions.styles';

// Internal components
import Bookings from '../Bookings';
import CreditsTransactions from './Transactions/Transactions';
import WnmStore from './Transactions/WnmStore';
import Wellness from './Wellness';

const Transactions = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [selectedView, setSelectedView] = useState(ViewsEnun.BOOKINGS);

    // style
    const {
        _switchBtn,
        _switchSelectedBtn,
        _navBarContainer,
        _navBarBox,
    } = useStyles(isMobile);

    const bookingsBtnClass = (selectedView === ViewsEnun.BOOKINGS) ? _switchSelectedBtn : _switchBtn;
    const transferCreditsBtnClass = (selectedView === ViewsEnun.TRANSACTIONS) ? _switchSelectedBtn : _switchBtn;
    const storeBtnClass = (selectedView === ViewsEnun.STORE) ? _switchSelectedBtn : _switchBtn;
    const wellnessBtnClass = (selectedView === ViewsEnun.SUSCRIPTION) ? _switchSelectedBtn : _switchBtn;

    const buttons = [
        { key: ViewsEnun.BOOKINGS, classActive: _switchSelectedBtn, classDesactive: _switchBtn, text: 'Bookings', btnClass: bookingsBtnClass },
        { key: ViewsEnun.TRANSACTIONS, classActive: _switchSelectedBtn, classDesactive: _switchBtn, text: 'Transacciones', btnClass: transferCreditsBtnClass },
        { key: ViewsEnun.STORE, classActive: _switchSelectedBtn, classDesactive: _switchBtn, text: ' OFFI Store', btnClass: storeBtnClass },
        { key: ViewsEnun.SUSCRIPTION, classActive: _switchSelectedBtn, classDesactive: _switchBtn, text: 'Suscripciones', btnClass: wellnessBtnClass },
    ];

    return (
        <div>
            <Box className={_navBarContainer}>

                <Box className={_navBarBox}>

                    {buttons.map((button) => (
                        <Box className={button.btnClass} onClick={() => {
                            setSelectedView(button.key);
                        }}
                        >
                            {button.text}
                        </Box>
                    ))}
                </Box>
            </Box>

            {selectedView === ViewsEnun.BOOKINGS && <Bookings />}

            {selectedView === ViewsEnun.TRANSACTIONS && <CreditsTransactions />}

            {selectedView === ViewsEnun.STORE && <WnmStore />}

            {selectedView === ViewsEnun.SUSCRIPTION && <Wellness />}
        </div>
    );
};

const ViewsEnun = {
    BOOKINGS: 'BOOKINGS',
    TRANSACTIONS: 'TRANSACTIONS',
    STORE: 'STORE',
    SUSCRIPTION: 'SUSCRIPTION'
};

export default Transactions;

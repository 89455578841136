import { Accordion, AccordionDetails, AccordionSummary, Box, Link } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import AdminService from '../services/AdminService';
import MaterialTable from "material-table";
import moment from 'moment';
import TableIcons from "./molecules/TableIcons";
import { PriceDisplay } from "./atoms/PriceDisplay";
import groupBy from '../helpers/groupBy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from 'react-responsive';
import ReactCountryFlag from 'react-country-flag';

const PAGE_SIZE = 20;
const CompanyPayments = () => {

    const [companyPaymentData, setCompanyPaymentData] = useState([]);
    const [companyPayLoading, setCompanyPayLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const getCompanyPayments = async () => {
        setCompanyPayLoading(true);
        try {

            const resp = await AdminService.getCompanyPayments({});
            let pays = resp.data;
            let grouped = groupBy(pays, p => p.budget?.companyCountry?.company?.name);
            setCompanyPaymentData(grouped);
        } catch (e) {
            console.log(e);
        }
        setCompanyPayLoading(false);
    };


    useEffect(() => {
        getCompanyPayments();
    }, []);

    const handleExpandChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <Box>
            <div style={{ marginLeft: isNarrowWidth ? '' : '170px' }}>
                <h1>Pagos de empresa</h1>

                {Object.keys(companyPaymentData)?.map(companyName => <Accordion key={companyName + '_ccc'} expanded={expanded === companyName} onChange={handleExpandChange(companyName)} style={{ backgroundColor: '#F3F2F2', marginTop: 20 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Link style={{ cursor: 'pointer' }}>{companyName}</Link>
                            </div>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: '100%' }}>
                            <MaterialTable
                                title={' '}
                                icons={TableIcons}
                                options={{ pageSize: PAGE_SIZE, minBodyHeight: 0 }}
                                isLoading={companyPayLoading}
                                columns={[
                                    { title: 'ID', render: b => <div>{`#${b.id}`}</div> },
                                    {
                                        title: 'Pais',
                                        render: c => <ReactCountryFlag
                                            svg
                                            countryCode={c.budget?.companyCountry?.country?.name || ""}
                                            style={{ marginRight: 5, width: 32, height: 32 }}
                                        />,
                                        customSort: (a, b) => {
                                            const aName = a?.budget?.companyCountry?.country?.name;
                                            const bName = b?.budget?.companyCountry?.country?.name;
                                            let c = 0;
                                            if (aName > bName) c = -1;
                                            if (bName > aName) c = 1;
                                            return c;
                                        }
                                    },
                                    {
                                        title: 'Mes',
                                        render: c => `${moment(c?.budget?.date).format("MMMM YYYY")}`,
                                        defaultSort: 'desc',
                                        customSort: (a, b) => moment(a?.budget?.date).diff(moment(b?.budget?.date), 'days')
                                    },
                                    {
                                        title: 'Fecha de Pago',
                                        field: 'payment_date',
                                        render: c => `${moment(c.payment_date).format('DD-MM-YYYY')}`,
                                        customSort: (a, b) => moment(a.payment_date).diff(moment(b.payment_date), 'days')
                                    },
                                    {
                                        title: 'Gasto',
                                        field: 'amount',
                                        render: c => <PriceDisplay value={`${c.amount}`} currency={c.budget?.companyCountry?.currency} />,
                                        customSort: (a, b) => +a.amount - +b.amount
                                    },
                                    {
                                        title: 'Tarjeta',
                                        render: c => c.pay_card ? `${c.pay_card?.brand} ****${c.pay_card?.last_four_digit}` : '-',
                                        customSort: (a, b) => {
                                            const aName = a.pay_card?.brand;
                                            const bName = b.pay_card?.brand;
                                            let c = 0;
                                            if (aName > bName) c = -1;
                                            if (bName > aName) c = 1;
                                            return c;
                                        }
                                    },
                                    {
                                        title: 'Fee pago tarjeta',
                                        render: c => c.fee_amount ? <PriceDisplay value={`${c.fee_amount}`} currency={c.budget?.companyCountry?.currency} /> : '-',
                                        customSort: (a, b) => +a.fee_amount - +b.fee_amount

                                    },
                                    {
                                        title: 'Total',
                                        field: 'amount',
                                        render: c => <PriceDisplay value={+c.amount + +c.fee_amount} currency={c.budget?.companyCountry?.currency} />,
                                        customSort: (a, b) => +a.amount - +b.amount
                                    },
                                ]}
                                data={companyPaymentData[companyName]}

                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>)}
            </div>
        </Box>
    );
};


export default CompanyPayments;
